<template>
  <div
    v-if="Object.keys(prezData).length > 0"
    class="prezentation__container"
    :data-pendo-id="isFirstcard ? 'prezentations-card' : null"
  >
    <v-card
      style="
         {
          color: white;
        }
      "
      class="mx-auto card-wrapper"
    >
      <div
        class="carouselOrImgWrapper"
        @mouseenter="handleGetCarouselThumbnails"
        @mouseleave="handleCardLeave"
        @click="onClick(prezData)"
      >
        <div class="icons-wrapper">
          <template v-if="isDownloaded">
            <v-tooltip
              bottom
              max-width="200"
              content-class="tooltip-content"
              fixed
            >
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  src="/assets/img/already-downloaded.svg"
                  alt
                  width="34px"
                  height="34px"
                  class="bpe-deck-download--auto"
                />
              </template>
              <span>{{ $t('common.downloaded') }}</span>
            </v-tooltip>
          </template>
          <v-tooltip
            bottom
            max-width="200"
            content-class="tooltip-content"
            fixed
          >
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-if="isTrending"
                v-bind="attrs"
                v-on="on"
                src="/assets/img/trending.svg"
                alt
                width="34px"
                height="34px"
                class="bpe-deck-trending--auto"
              />
            </template>
            <span>{{ $t('bestPracticesGuide.knowSlideCard.trending') }}</span>
          </v-tooltip>
        </div>
        <v-carousel
          :key="carouselKey"
          hide-delimiters
          cycle
          :show-arrows="false"
          :interval="3500"
        >
          <v-carousel-item
            v-for="(item, i) in prezData.prezentationCarouselsThumbnails"
            :key="i"
          >
            <v-img
              :src="item"
              alt="image"
              min-width="200"
              :aspect-ratio="16 / 9"
              :elevation="isCardHovered === true ? 12 : 2"
              class="blue--text align-end"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              @load="onCarouselImageLoad(i)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <div class="image-wrapper">
          <div class="badge-container">
            <v-badge
              v-if="showNewContentTag"
              :content="$t('common.newContent')"
              overlap
              tile
              offset-x="0"
              offset-y="0"
              :style="{ zIndex: 4 }"
            ></v-badge>
          </div>
          <v-img
            :src="thumbnailUrl"
            alt="image"
            min-width="200"
            :aspect-ratio="16 / 9"
            :elevation="isCardHovered === true ? 12 : 2"
            class="blue--text align-end"
            lazy-src="/assets/img/slides/placeholder-slide.svg"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
          </v-img>
        </div>
      </div>
      <div
        class="prezInfoAndActions"
        :data-pendo-id="isFirstcard ? 'prezentations-summary' : null"
      >
        <div class="nameAndActions">
          <div class="name" v-if="!isEditing" @click="onClick(prezData)">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ prezData.name }}
                </span>
                <img
                  v-if="isPrezentSourceBestPractice"
                  src="/assets/img/prezent_avatar.svg"
                  alt
                  width="22px"
                />
              </template>
              <span>{{ prezData.name }}</span>
            </v-tooltip>
          </div>
          <div class="card-edit-name" v-if="isEditing">
            <div class="errorText" v-if="prezData.name.length > 85">
              {{ $t('prezentationsList.nameCannotExceed') }}
            </div>
            <v-text-field
              v-model="prezData.name"
              hide-details
              :label="prezData.name"
              solo
              @focus="handleEditNameFocus()"
              @blur="handleEditNameBlur()"
              autofocus
            >
              <template #append>
                <v-btn
                  color="white"
                  rounded
                  min-width="25"
                  height="40"
                  @click="handleSubmit"
                  :disabled="prezData.name === '' || prezData.name.length > 85"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <div
            class="actions"
            :data-pendo-id="isFirstcard ? 'quick-actions-list' : null"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="isChangingFavorite === true"
              :size="22"
            />
            <template v-else>
              <v-tooltip
                bottom
                max-width="200"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!isFavorite"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddPrezAsFav(prezData)"
                  >
                    mdi-heart-outline
                  </v-icon>
                  <v-icon
                    v-else
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="handleRemovePrezAsFav(prezData)"
                  >
                    mdi-heart
                  </v-icon>
                </template>
                <span>
                  {{
                    `${
                      isFavorite && isFavorite === true
                        ? $t('slideDetails.removeFavorite')
                        : $t('slideDetails.addFavorite')
                    }`
                  }}
                </span>
              </v-tooltip>
            </template>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="isLocalDownloading"
              :size="22"
            />
            <Download
              v-else
              :mode="'icon'"
              :file-name="getFilename()"
              :download-file-name="getDownloadFilename()"
              :merged-url="getMergedUrl"
              :item="prezData"
              :log-download="logDownload"
              :on-download="() => handlePrezDownload()"
              origin="prezentation"
              :speedDialPosTop="'-140'"
              :speedDialPosRight="'0'"
              limitsKey="bpe-deck"
            />
            <template>
              <v-tooltip
                bottom
                max-width="200"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="copyPrezentationLink(prezData)"
                  >
                    mdi-link-variant
                  </v-icon>
                </template>
                <span>
                  {{
                    `${
                      showCopied
                        ? $t('bestPracticesGuide.knowSlideCard.linkCopied')
                        : $t('navbarActions.shareFreeTrial.copyLink')
                    }`
                  }}
                </span>
              </v-tooltip>
            </template>
            <!-- <v-menu
              top
              absolute
              offset-y
              :close-on-click="true"
              :close-on-content-click="closeEllipsisMenu"
              :position-x="menuPos.x"
              :position-y="menuPos.y"
              :key="menuKey"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  :data-pendo-id="
                    index === 0 ? 'prezentations-quick-actions' : null
                  "
                >
                  mdi-dots-vertical
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in getActionsWithPermissions(
                    prezData.prezType,
                    prezData.permission_level,
                  )"
                  :key="index"
                  @click="handleDotOptionClick(action.name)"
                >
                  <v-list-item-title>
                    <v-tooltip top>
                      <template #activator="{ on }">
                        <div
                          v-on="on"
                          v-if="action.name === 'Copy link'"
                          class="copyLink"
                        >
                          <div class="copyIconAndTitle">
                            <div class="icon">
                              <v-icon>{{ action.icon }}</v-icon>
                            </div>
                            <div class="actionName">{{ action.name }}</div>
                          </div>
                          <div v-if="showCopied === true" class="copiedStatus">
                            <v-icon small color="#21a7e0">mdi-check</v-icon>
                            <div>{{ 'Copied' }}</div>
                          </div>
                        </div>
                        <div v-else v-on="on" class="otherActions">
                          <div class="icon">
                            <v-icon>{{ action.icon }}</v-icon>
                          </div>
                          <div class="actionName">{{ action.name }}</div>
                        </div>
                      </template>
                      <span>
                        {{ action.name }}
                      </span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </div>
        </div>
        <div class="typeAndDate" @click="onClick(prezData)">
          <v-tooltip
            bottom
            max-width="400"
            content-class="tooltip-content"
            fixed
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="descripion" v-bind="attrs" v-on="on">
                {{
                  $t('slides.descriptionLabel', {
                    descriptionText: prezData.description,
                  })
                }}
              </div>
            </template>
            <span>
              {{ prezData.description }}
            </span>
          </v-tooltip>
        </div>
        <div class="viewAndtype" @click="onClick(prezData)">
          <div class="views">
            {{ prezData.viewCount ? prezData.viewCount : $t('common.no') }}
            {{ $t('learn.movieDialog.views') }}
          </div>
          <template v-if="isPrezAliasText">
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div class="also-known-as">
              <v-tooltip
                bottom
                max-width="400"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="descripion" v-bind="attrs" v-on="on">
                    {{ $t('bestPracticesGuide.knowContainer.alsoKnownAs') }}
                    {{ prezData.alias }}
                  </div>
                </template>
                <span>
                  {{ prezData.alias }}
                </span>
              </v-tooltip>
            </div></template
          >
        </div>
        <div class="authorAndAudience" @click="onClick(prezData)">
          <div class="">
            {{
              $t('prezentationsList.sourceLabel', { sourceName: sourceName })
            }}
            <span v-if="matchedSlideIndices.length">
              |
              {{ $t('common.slidesWithMatchingResults') }}
              {{ matchedSlideIndices }}
            </span>
          </div>
        </div>
      </div>
      <!-- <div v-if="matchedSlideIndices.length" class="matchingSlidesWrapper">
        <div class="matchingSlidesResults">
          <v-icon
            class="icon"
            v-text="`mdi-magnify`"
            name="global-search-icon"
            size="18"
          />
          {{ $t('common.slidesWithMatchingResults') }} {{ matchedSlideIndices }}
        </div>
      </div> -->
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="550" class="deletePrezModal">
      <v-card class="modalCard">
        <v-card-text class="pt-4">
          <div class="text-h2 pa-12 remove-prezentation-title">
            {{ $t('prezentationsList.deleteWarning', { name: prezData.name }) }}
          </div>
          <div class="d-flex justify-end">
            <v-btn
              class="delete-btn-prezentation ma-2 py-2"
              outlined
              rounded
              color="#20a7e0"
              width="100"
              height="30"
              :loading="deleteInProgress"
              @click="deleteConfirm"
            >
              {{ $t('common.delete') }}
            </v-btn>
            <v-btn
              style="color: white !important"
              class="cancel-btn-prezentation ma-2 py-2"
              rounded
              color="#20a7e0"
              width="155"
              height="30"
              @click="dialogDelete = false"
            >
              {{ $t('common.cancel') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--
    <OpRatingPopup
      :opRequest="opPrezentation"
      ref="ratingPopup"
      @update="onRatingUpdate"
    />
    !-->
  </div>
</template>
<script>
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import API from '../../../utils/api';
import {
  getPrezentationThumbnailUrl,
  getThumbnailsForCarousel,
} from '../../../store/modules/helper';
import utils from '../../utils';
import {
  capitalizeFirstLetter,
  // downloadFileWithCustomName,
} from '../../../utils/common';
import { PrezentationViewTypes } from '../../../utils/constants';
import Download from '../../common/Download.vue';
import {
  MatomoAnalyticsHandler,
  THUMBNAIL,
} from '../../common/Analytics/MatomoAnalyticsHandler';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import { miscConfig } from '../../../runtimeConfig';
// import usersApi from '../../../API/users-api';
import {
  addPrezentationAsFavorite,
  removePrezentationAsFavorite,
  addPrezentationAsDownloaded,
  updatePrezentationAsDownloaded,
  getSlideDetail,
} from '@/utils/api-helper';
import { callMergeSlidesApi } from '@/utils/merge-slide-helper';
import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import {
  TD_AUD,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_DOWNLOADTYPE_GS,
  TD_HYBRIDCHIP,
  TD_HYBRID_RESULTS_SECTION_CLICK,
  TD_HYBRID_RESULTS_SECTION_PREZ,
  TD_HYBRID_SEARCH,
  TD_ISDOWNLOAD,
  TD_ISFAVORITED,
  TD_NUMBEROFSLIDES,
  TD_PREZENTATIONAUTHOR,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_QUERY,
  TD_PREZENTATION_CATEGORY,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_IDEABEGIN,
  TD_IDEAMIDDLE,
  TD_IDEAEND,
  TD_SECTION_4,
  TD_SECTION_5,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import SharePrezentation from '../Prezentations/SharePrezentation.vue';
// import RequestChangeModal from '../OvernightPrezentations/RequestChangeModal.vue';
// import OpRatingPopup from '../OvernightPrezentations/OpRatingPopup';

export default {
  name: 'KnowDeckCards',
  components: {
    Download,
    // OpRatingPopup,
  },
  props: {
    prezentationStatus: {
      type: String,
      default: undefined,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    prezentation: {
      type: Object,
      required: true,
    },
    toggleFn: {
      type: Function,
      default: undefined,
    },
    onCloneFn: {
      type: Function,
      default: undefined,
    },
    onDeleteFn: {
      type: Function,
      default: undefined,
    },
    onUpdateFn: {
      type: Function,
    },
    visibility: {
      type: String,
      default: undefined,
    },
    showAuthor: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      required: false,
    },
    isFirstcard: {
      type: Boolean,
      required: false,
      default: false,
    },
    trendingRange: {
      type: Array,
      default: () => [],
    },
    // thumbnailAsset: {
    //   type: String,
    //   required: true,
    // },
    advancedFilterActive: {
      type: Boolean,
      default: false,
    },
    index: Number,
    searchText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialogDelete: false,
      mergedSlideId: null,
      isEditing: false,
      newName: '',
      thumbnailUrl: {
        type: String,
        default: null,
      },
      permissionLevel: {
        type: Number,
        default: null,
      },
      allowedViewTypes: [
        PrezentationViewTypes.PREZENTATIONS_TO_REVIEW,
        PrezentationViewTypes.TEAM_PREZENTATIONS,
      ],
      deleteInProgress: false,
      matomo_thumbnail: THUMBNAIL,
      prezData: {},
      isCardHovered: false,
      opPrezentation: {},
      dotActions: [
        {
          name: 'Rename',
          icon: 'mdi-pencil-outline',
          tooltipRequired: true,
          tooltipContent: 'Edit Prezentation Name',
        },
        {
          name: 'Duplicate',
          icon: 'mdi-content-copy',
          tooltipRequired: true,
          tooltipContent: 'Duplicate Prezentation',
        },
        {
          name: 'Copy link',
          icon: 'mdi-link-variant',
          tooltipRequired: true,
          tooltipContent: 'Copy Prezentation Link',
        },
        {
          name: 'Share',
          icon: 'mdi-account-check-outline',
          tooltipRequired: true,
          tooltipContent: 'Share Prezentation Access',
        },
        {
          name: 'Delete',
          icon: 'mdi-delete-outline',
          tooltipRequired: true,
          tooltipContent: 'Delete Prezentation',
        },
      ],
      menuPos: {
        x: 0,
        y: 0,
      },
      mergedUrl: '',
      // downloading: false,
      isChangingFavorite: false,
      carouselKey: 0,
      metaData: {},
      showCopied: false,
      closeEllipsisMenu: true,
      menuKey: 0,
      cardNameBeforeUpdate: '',
    };
  },
  mounted() {
    getPrezentationThumbnailUrl(this.prezentation, this.currentUser).then(
      (url) => {
        this.thumbnailUrl = url;
      },
    );
    // this.prezData = this.prezentation;
  },
  watch: {
    // thumbnailAsset(newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     getPrezentationThumbnailUrl(this.prezentation, this.currentUser).then(
    //       (url) => {
    //         this.thumbnailUrl = url;
    //       },
    //     );
    //   }
    // },
    prezentation: {
      handler(val) {
        this.prezData = val;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'isTrialExpired',
      'themes',
      'hybridSearchQueryPayload',
      'leftSideBarStatus',
    ]),
    isSearchPage() {
      return this.origin === 'hybrid_search';
    },
    createBPLSnapshot() {
      return {
        searchText: this.searchText,
      };
    },
    matchedSlideIndices() {
      if (
        !this.prezentation.is_vector &&
        this.prezentation.slides &&
        this.prezentation.slide_ids
      ) {
        const hasInvalidField = this.prezentation.slides.some((slideItem) =>
          slideItem.highlights.some(
            (highlight) =>
              highlight.field.includes('name') ||
              highlight.field.includes('description') ||
              highlight.field === 'alias' ||
              highlight.field.includes('storyline'),
          ),
        );

        // If any invalid field exists, return an empty list
        if (hasInvalidField) {
          return '';
        }
        return this.prezentation.sections.list[0].slides
          .map((slideItem, index) =>
            this.prezentation.slide_ids.includes(slideItem.uniqueID)
              ? index + 1
              : -1,
          )
          .filter((index) => index !== -1)
          .join(', ');
      }
      return '';
    },
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),
    isLocalDownloading() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.prezData.prezentationId,
      );
      return ldr && ldr.downloading;
    },
    isPrezAliasText() {
      return this.prezData?.alias && this.prezData?.alias.length > 0;
    },
    isDownloaded() {
      if (typeof this.prezData.isDownloaded === 'boolean') {
        return this.prezData.isDownloaded;
      }
      return this.prezData?.downloaded_by?.includes(
        this.currentUser.user.num_id,
      );
    },
    isFavorite() {
      if (typeof this.prezData.isFavorite === 'boolean') {
        return this.prezData.isFavorite;
      }
      return this.prezData?.favourites_by?.includes(
        this.currentUser.user.num_id,
      );
    },

    // Computed flag for isTrending bpl deck
    isTrending() {
      /*
        Finding if the bpl deck viewCount is within the trending range
        and considering it as isTrending
      */
      return (
        this.trendingRange.includes(Number(this.prezData.viewCount)) &&
        Number(this.prezData.viewCount) > 0
      );
    },
    ...mapGetters('users', ['isPrezentationCommentFullAccess']),

    isBestPractices() {
      return (
        this.prezentation.prezentationType === 'Best Practices' &&
        this.isIOCUpload
      );
    },
    showNewContentTag() {
      return this.isPrezentSourceBestPractice && this.showBadge;
    },
    isPrezentSourceBestPractice() {
      return (
        this.prezentation.prezentationType === 'Best Practices' &&
        this.prezentation?.source?.toLowerCase() === 'prezent' &&
        this.isIOCUpload
      );
    },
    isIOCUpload() {
      return this.prezentation.prezentationSource === 'IOCUpload';
    },
    sourceName() {
      return capitalizeFirstLetter(this.prezentation.source);
    },
    showBadge() {
      if (!this.currentUser.user.createdAt || !this.prezData.createdAt) {
        return false;
      }
      const userCreatedAt = moment(this.currentUser.user.createdAt);
      const prezDataCreatedAt = moment(this.prezData.createdAt);
      const currentDate = moment();

      return (
        currentDate.diff(prezDataCreatedAt, 'days') < 28 &&
        userCreatedAt.isBefore(prezDataCreatedAt)
      );
    },
  },
  methods: {
    ...mapActions('search', ['updateRecentQueries']),
    ...mapActions('bestPracticesExamples', [
      'updateBPEDeckWithAction',
      'setHighlightedSlides',
      'saveBPLStateSnapshot',
    ]),
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      'setNavFromAddToLibOrFavSnackbar',
      'setAddedToLibOrFavSnackbar',
    ]),
    openSharingOptions(prezentation) {
      let origin = `${this.origin}-${this.matomo_thumbnail}-icon`;
      if (prezentation.prezentationSource === 'User Uploaded') {
        origin = 'upload-prezentation';
      }
      this.$modal.show(
        SharePrezentation,
        {
          prezentation,
          prezentationUpdatedFn: this.handleShareSettingsUpdate,
          origin,
          source: 'prezentationcard',
        },
        {
          name: 'SharePrezentation',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    handleEditNameFocus() {
      this.cardNameBeforeUpdate = this.prezData.name;
    },
    handleEditNameBlur() {
      this.prezData.name = this.cardNameBeforeUpdate;
      this.isEditing = false;
    },

    goToFavBpLib() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavPrezBp',
        origin: 'fav_prez_bp',
      });
      this.$router.push('/home/best-practice-library').catch(() => {});
    },

    async handleAddPrezAsFav(prez) {
      this.isChangingFavorite = true;
      console.log(prez);
      await addPrezentationAsFavorite({
        prezentationId: prez.prezentationId,
      })
        .then((resp) => {
          console.log(resp);
          this.updateBPEDeckWithAction({
            deck: prez,
            action: 'addFav',
            origin: this.origin,
            currentUser: this.currentUser,
          });
          this.isChangingFavorite = false;

          this.setAddedToLibOrFavSnackbar({
            show: true,
            ctaText: 'snackBarItems.goToFavorites',
            snackbarText: 'snackBarItems.prezAddedToFav',
            navFn: this.goToFavBpLib,
            snackbarType: 'goToFav',
          });

          trackPrezentationEvents.prezentationsFavourited(this.currentUser, {
            ...this.getMatomoOtherData(prez),
            [TD_ISFAVORITED]: 1,
          });
        })
        .catch((err) => {
          console.log(err);
          this.isChangingFavorite = false;
        });
    },
    async handleRemovePrezAsFav(prez) {
      this.isChangingFavorite = true;
      await removePrezentationAsFavorite(prez.prezentationId)
        .then((resp) => {
          console.log(resp);
          this.updateBPEDeckWithAction({
            deck: prez,
            action: 'removeFav',
            origin: this.origin,
            currentUser: this.currentUser,
          });
          this.isChangingFavorite = false;
          trackPrezentationEvents.prezentationsFavourited(this.currentUser, {
            ...this.getMatomoOtherData(prez),
            [TD_ISFAVORITED]: 0,
          });
        })
        .catch((err) => {
          console.log(err);
          this.isChangingFavorite = false;
        });
    },
    async getMetaDataForSlides(slideIds) {
      const params = {
        assetIds: slideIds,
        getMeta: true,
        themeID: this.prezentation.theme,
      };
      await getSlideDetail(params)
        .then((res) => {
          this.metaData = res.data || {};
        })
        .catch((err) => console.log(err));
    },
    getSectionDeatils(value) {
      let details = '';
      if (this.prezentation?.sections?.list[value]?.sectionName) {
        details = `${this.prezentation?.sections?.list[value]?.sectionName}_`;
        this.prezentation?.sections?.list[value]?.slides.forEach((slide) => {
          details += slide.outline;
          details += '_';
        });
      }
      return details;
    },
    async logDownload(currentPrezentation, itemReplaced, fromDrive) {
      // TODO: Use these two params when matomo events for this type of log downloads are integrated
      trackPrezentationEvents.prezentationsThumbnailDownload(this.currentUser, {
        ...this.getMatomoOtherData(currentPrezentation),
        [TD_ISDOWNLOAD]: 1,
        [TD_DOWNLOADTYPE]: fromDrive
          ? TD_DOWNLOADTYPE_GS
          : TD_DOWNLOADTYPE_DIRECT,
      });

      const prezUniqueIds = [];
      currentPrezentation.sections.list.forEach((section) => {
        section.slides.forEach((slide) => {
          prezUniqueIds.push(slide.uniqueID);
        });
      });
      await this.getMetaDataForSlides(prezUniqueIds);
      if (
        (currentPrezentation.isDownloaded &&
          currentPrezentation.isDownloaded === true) ||
        currentPrezentation.downloaded_by.includes(this.currentUser.user.num_id)
      ) {
        updatePrezentationAsDownloaded({
          prezentationId: currentPrezentation.prezentationId,
        })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        addPrezentationAsDownloaded({
          prezentationId: currentPrezentation.prezentationId,
          downloadCount: 1,
        })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // updating vuex state with download info for bpl deck obj for all log downloads
      this.updateBPEDeckWithAction({
        deck: currentPrezentation,
        action: 'downloadDeck',
        origin: this.origin,
        currentUser: this.currentUser,
      });
    },

    async handlePrezDownload() {
      // this.downloading = true;
      this.setShowDownloadSnackbar(true);
      this.setDownloadLoaders({
        loaderId: this.prezData.prezentationId,
        downloading: true,
      });
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'bpLibraryDecks',
        logDownloadCallback: () => {
          this.logDownload(this.prezData);
        },
        mergeSlidesCallback: (payload) => {
          const prezentationData = {
            id: payload.prezId,
            mergedSlides: payload.mergedslide,
          };
          this.mergedSlideId = payload.mergedslide;
          this.updatePrezentation(prezentationData)
            .then((value) => {
              console.log(value);
            })
            .catch((err) => {
              console.log(err);
            });
        },
        downloadStatusCallback: (payload) => {
          // this.downloading = false;
          this.setDownloadLoaders({
            loaderId: payload.prezId,
            downloading: false,
          });
        },
        metaData: {
          ...this.prezData,
          mergedSlideId: this.mergedSlideId,
          // fileName: this.isBestPractices
          //   ? `${this.prezData.name}`
          //   : `${this.prezData.name}_${this.prezData.type}`,
          fileName: this.prezData.name,
          limitsKey: 'bpe-deck',
          origin: 'prezentation',
          downloadStatusCallbackPayload: {
            prezId: this.prezData.prezentationId,
          },
          mergeSlidesCallbackPayload: {
            prezId: this.prezData.prezentationId,
          },
        },
      });
      // let s3url = '';
      // if (this.mergedSlideId || this.prezData.mergedSlides) {
      //   s3url = `${miscConfig.aws_prezentation_distribution}/${
      //     this.mergedSlideId ? this.mergedSlideId : this.prezData.mergedSlides
      //   }`;
      // } else {
      //   s3url = await this.getMergedUrl();
      // }
      // const fileName = this.isBestPractices
      //   ? `${this.prezData.name}`
      //   : `${this.prezData.name}_${this.prezData.type}`;
      // downloadFileWithCustomName(s3url, fileName, this.downloadInProgress);
      // this.logDownload(this.prezData);
    },
    // downloadInProgress(flag) {
    //   this.downloading = flag;
    // },
    async getMergedUrl() {
      const s = {
        sections: this.prezData.sections,
      };
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            const prezentationData = {
              id: this.prezData.prezentationId,
              mergedSlides: mergedslide,
            };
            this.mergedSlideId = mergedslide;
            this.updatePrezentation(prezentationData)
              .then((value) => {
                console.log(value);
              })
              .catch((err) => {
                console.log(err);
              });
            this.mergedUrl = `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return this.mergedUrl;
    },
    getFilename() {
      return this.prezData.name;
    },
    getDownloadFilename() {
      return this.isBestPractices
        ? `${this.prezData.name}.pptx`
        : `${this.prezData.name}_${this.prezData.type}.pptx`;
    },
    isPrezentationOwner() {
      return (
        this.prezData.ownerID &&
        this.currentUser &&
        this.prezData.ownerID.toLowerCase() ===
          this.currentUser.user.id.toLowerCase()
      );
    },
    getActionsWithPermissions(prezType, level) {
      let actions = [];
      if (this.prezentation.prezentationType === 'OP') {
        actions = [...this.dotActions].filter(
          (act) => act.name !== 'Duplicate',
        );
        return actions;
      }

      if (this.isPrezentationOwner()) {
        actions = [...this.dotActions];
      } else {
        switch (level) {
          case 1:
          case 2:
            actions = [
              ...this.dotActions.filter(
                (act) =>
                  (act.name === 'Duplicate' && !this.isBestPractices) ||
                  act.name === 'Copy link',
              ),
            ];
            break;

          case 3:
            actions = [
              ...this.dotActions.filter(
                (act) =>
                  act.name === 'Duplicate' ||
                  act.name === 'Rename' ||
                  act.name === 'Copy link',
              ),
            ];
            break;

          default:
            break;
        }
      }
      return actions;
    },
    getCommentsStr(comments) {
      let str = '';
      if (comments <= 0) {
        str = this.$t('prezentation.noNewComments');
      } else if (comments === 1) {
        str = this.$t('prezentation.oneNewComment');
      } else {
        str = this.$t('prezentation.newComments', { count: comments });
      }
      return str;
    },
    // handleDotOptionClick(type) {
    //   switch (type) {
    //     case 'Delete':
    //       this.showDeleteConfirmation();
    //       break;

    //     case 'Rename':
    //       this.cardNameBeforeUpdate = this.prezData.name;
    //       this.isEditing = !this.isEditing;
    //       break;

    //     case 'Duplicate':
    //       this.onCloneFn(this.prezData);
    //       break;

    //     // case 'Copy link':
    //     //   this.copyPrezentationLink(this.prezData);
    //     //   break;

    //     case 'Share':
    //       this.openSharingOptions(this.prezData);
    //       break;

    //     // case 'Rate Prezentation':
    //     //   this.openRatingPopup(this.prezData);
    //     //   break;

    //     // case 'Change Request':
    //     //   this.handleChangeRequest();
    //     //   break;

    //     // case 'Conversation History':
    //     //   this.gotoHistory(this.prezData);
    //     //   break;

    //     default:
    //       break;
    //   }
    // },
    copyPrezentationLink(data) {
      this.closeEllipsisMenu = false;
      navigator.clipboard.writeText(
        `${window.location.origin}/home/best-practice-library-decks/${data.prezentationId}`,
      );
      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
        this.closeEllipsisMenu = true;
        this.menuKey++;
      }, 1000);
      trackPrezentationEvents.prezentationsElipsisCopylink(
        this.currentUser,
        this.getMatomoOtherData(this.prezData),
      );
    },
    // handleChangeRequest() {
    //   let prezSelected = [];
    //   prezSelected = this.opItems.find(
    //     (item) => item.name === this.prezentation?.name,
    //   );
    //   this.$modal.show(
    //     RequestChangeModal,
    //     {
    //       opRequest: prezSelected,
    //       reqOrigin: 'myRequests',
    //     },
    //     {
    //       name: 'RequestChangeModal',
    //       width: '650px',
    //       height: 'auto',
    //       styles: {
    //         'max-height': '75%',
    //         'max-width': '800px',
    //         overflow: 'auto',
    //       },
    //       clickToClose: true,
    //     },
    //   );
    // },
    // gotoHistory(data) {
    //   let prezSelected = [];
    //   prezSelected = this.opItems.find((item) => item.name === data.name);
    //   this.$router.push({
    //     path: `/home/my-overnight-prezentation/${prezSelected?.ticketId}?origin=overnight`,
    //   });
    // },
    // openRatingPopup(data) {
    //   let prezSelected = [];
    //   prezSelected = this.opItems.find((item) => item.name === data.name);
    //   this.opPrezentation = prezSelected;
    //   this.$refs.ratingPopup.open();
    // },
    // onRatingUpdate(payload) {
    //   this.$emit('onRatingUpdate', payload);
    // },
    handleThreeDotsClick(e) {
      this.menuPos.x = e.clientX - 110;
      this.menuPos.y = e.clientY;
    },
    getDateWithMoment(date) {
      return moment(date).format('MM/DD/YYYY');
    },
    handleCardLeave() {
      this.isCardHovered = false;
    },
    onCarouselImageLoad(imageNumber) {
      /** Log Matomo event on Prezentation card hover when the second image starts to load */
      if (imageNumber === 1) {
        try {
          trackPrezentationEvents.prezentationsThumbnailHover(
            this.currentUser,
            this.getMatomoOtherData(this.prezData),
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    handleGetCarouselThumbnails() {
      this.isCardHovered = true;
      this.carouselKey++;
      if (this.prezData && !this.prezData.prezentationCarouselsThumbnails) {
        getThumbnailsForCarousel(this.prezData, this.currentUser).then(
          (resp) => {
            this.prezData = {
              ...this.prezData,
              prezentationCarouselsThumbnails: resp,
            };
          },
        );
      }
    },
    getMatomoOtherData(prezData) {
      try {
        const {
          audienceID,
          author,
          name,
          type,
          sections,
          prezentationType,
          prezentationSource,
          source,
        } = prezData;

        let slidesLength = 0;
        sections.list.forEach((section) => {
          slidesLength += section.slides.length;
        });
        return {
          [TD_AUD]: audienceID,
          [TD_PREZENTATIONAUTHOR]: author,
          [TD_PREZNAME]: name,
          [TD_PREZTYPE]: type,
          [TD_NUMBEROFSLIDES]: slidesLength,
          [TD_PREZENTATION_CATEGORY]: prezentationType,
          [TD_PREZENTATION_ORIGIN]: prezentationSource,
          [TD_PREZENTATION_COMPANY_SOURCE]: source,
          [TD_IDEABEGIN]: this.getSectionDeatils(0),
          [TD_IDEAMIDDLE]: this.getSectionDeatils(1),
          [TD_IDEAEND]: this.getSectionDeatils(2),
          [TD_SECTION_4]: this.getSectionDeatils(3),
          [TD_SECTION_5]: this.getSectionDeatils(4),
        };
      } catch (e) {
        return {};
      }
    },
    handleShareSettingsUpdate(isPrivate) {
      this.updatePrezentationWithAction({
        prezentation: {
          ...this.prezData,
          shared: !isPrivate,
          origin: this.origin,
        },
        action: 'share',
      });
      // this.prezData.shared = !isPrivate;
    },
    handleSubmit() {
      const prezentationName = this.prezData.name.trim();
      if (!prezentationName) {
        this.isEditing = false;
        return false;
      }
      this.onUpdateFn(prezentationName, this.prezData);
      this.isEditing = false;
      return true;
    },
    async onClick(prezentation, editable) {
      if (this.origin && this.origin === TD_HYBRID_SEARCH) {
        const payload = {
          object: {
            ...prezentation,
            num_id: prezentation.id,
            id: prezentation.prezentationId,
          },
          type: 'Best Practice Decks',
          value: prezentation.name,
          subvalue: prezentation.name,
          attributes: `/home/best-practice-library-decks/${prezentation.prezentationId}`,
        };
        this.updateRecentQueries(payload);
        const { selectedChip, query } = this.hybridSearchQueryPayload;
        trackSearchEvents.hybridSearchPrezentationsClick(
          this.currentUser,
          prezentation,
          {
            [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_PREZ,
            [TD_HYBRIDCHIP]: selectedChip,
            [TD_QUERY]: query,
          },
        );
      } else {
        trackPrezentationEvents.prezentationsThumbnailClick(
          this.currentUser,
          prezentation,
        );
      }
      // saving snapshot before navigation
      this.saveBPLStateSnapshot(this.createBPLSnapshot);
      this.$router.push({
        name: 'bestPractice.detail',
        params: {
          id: prezentation.prezentationId,
          currentItemIsEditable:
            editable || prezentation.permission_level === 3,
          origin: this.origin,
        },
      });
      this.setHighlightedSlides(this.matchedSlideIndices.split(', '));
      this.$emit('select', prezentation);
    },
    timeSince: utils.timeSince,
    showDeleteConfirmation() {
      const prezDataForMatomo = {
        accessType: this.prezData.shared ? 'Shared' : 'Private',
        ...this.prezData,
      };
      MatomoAnalyticsHandler.trackPrezDeleteIconClick(
        this.currentUser.user,
        prezDataForMatomo,
      );
      this.dialogDelete = true;
    },
    async deleteConfirm() {
      this.deleteInProgress = true;
      await this.deletePrezentation(this.prezData.prezentationId).then(() => {
        this.onDeleteFn(this.prezData);
        this.dialogDelete = false;
        this.deleteInProgress = false;
      });
    },
    async deletePrezentation(toDeleteId) {
      const path = `/prezentation/${toDeleteId}`;
      return API.delete(path);
    },
    async updatePrezentation(prezentationData) {
      const path = '/prezentation';
      return API.put(path, prezentationData);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.prezentation__container {
  padding: 8px;
  cursor: pointer;
  .card-wrapper {
    min-width: 200px;
    transition: all ease 0.5s;
    .badge-container {
      display: flex;
      top: 40px;
      left: 14px;
      position: absolute;
      z-index: 4;

      ::v-deep .v-badge__badge {
        background-image: $new-tag-bg;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 600;
        height: fit-content;
        padding: 6px 16px;
        width: fit-content;
        z-index: 1;
      }
    }
    .carouselOrImgWrapper {
      .icons-wrapper {
        display: flex;
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 1;
      }
      .v-window {
        display: none;
      }
      .image-wrapper {
        height: 220px !important;
        padding: 12px;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
      }
      &:hover {
        .icons-wrapper {
          display: flex;
          position: absolute;
          right: 12px;
          top: 12px;
          z-index: 1;
        }
        .v-window {
          display: block;
          height: 220px !important;
          padding: 12px;
        }
        .image-wrapper {
          display: none;
        }
      }
    }
    .prezInfoAndActions {
      border-top: 0.5px solid rgb(235 236 235);
      padding: 8px 8px;
      .nameAndActions {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
        margin: 4px 0px;
        .name {
          width: 70%;
          display: flex;
          gap: 2px;
          align-items: center;

          span {
            display: inline-block;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .card-edit-name {
          align-items: center;
          // display: flex;
          cursor: pointer;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 5px;
          .errorText {
            font-size: 10px;
            text-align: left;
            color: red;
            margin: -11px 0px 0px 13px;
          }
          ::v-deep .v-input {
            .v-input__control {
              min-height: 20px !important;
              .v-input__slot {
                .v-text-field__slot {
                  input {
                    font-size: 16px;
                    padding: 0px !important;
                    font-family: 'Lato', sans-serif;
                    font-weight: 500;
                    letter-spacing: 0.0125em;
                    word-break: break-all;
                  }
                }
              }
            }
          }
          .v-btn {
            height: 21px !important;
            margin: 0px 0px 0px 10px;
          }
        }
        .actions {
          display: flex;
          justify-content: space-between;
          width: 29%;
          max-width: 100px;
          .v-icon {
            width: 25px;
            height: 25px;
            &:hover {
              background-color: #21a7e01c;
              border-radius: 20px;
            }
            &:after {
              background-color: transparent;
            }
          }
        }
      }
      .typeAndDate {
        color: rgb(130 131 130);
        font-size: 12px;
        display: flex;
        margin: 2px 0px;
        .descripion {
          text-align: left;
          max-width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .viewAndtype {
        color: rgb(130 131 130);
        font-size: 12px;
        display: flex;
        margin: 2px 0px;
        .views {
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 20%;
        }
        .also-known-as {
          max-width: 75%;
        }
        .descripion {
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .authorAndAudience {
        color: rgb(130 131 130);
        font-size: 12px;
        display: flex;
        margin: 2px 0px;
        .author {
          text-align: left;
          max-width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .matchingSlidesWrapper {
      margin: 5px 5px 10px 5px;
      height: 40px;
      .matchingSlidesResults {
        background-color: #ffe1b7;
        border-radius: 5px;
        padding: 5px 5px 10px 5px;
        text-align: start;
        margin: 0px 0px 5px 0px;
        height: 30px;
      }
    }
    &:hover {
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
    }
  }
}
.v-menu__content {
  .v-list {
    .v-list-item {
      cursor: pointer;
      .v-list-item__title {
        .copyLink {
          .copyIconAndTitle {
            display: flex;
            .actionName {
              padding: 2px 4px 0px 4px;
            }
          }
          .copiedStatus {
            width: 60%;
            margin: 0 auto;
            font-size: 11px;
            display: flex;
            .v-icon {
              font-size: 12px !important;
            }
          }
        }
        .otherActions {
          display: flex;
          .actionName {
            padding: 2px 4px 0px 4px;
          }
        }
      }
      &:hover {
        background-color: #d8d8d8;
      }
    }
  }
}
.modalCard {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #68788c;
  height: 170px !important;
  width: 559px;
  .delete-btn-prezentation {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 18px;
    height: 36px !important;
    margin: 0 30px 0 0 !important;
    padding: 11px 42px 11px 43px !important;
    text-transform: unset !important;
    letter-spacing: normal;
  }
  .cancel-btn-prezentation {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 18px;
    height: 36px !important;
    margin: 0 30px 0 0 !important;
    padding: 11px 42px 11px 43px !important;
    text-transform: unset !important;
    max-width: 64px;
    padding-right: 48px !important;
    padding-left: 48px !important;
    letter-spacing: normal;
  }
  .remove-prezentation-title {
    color: #000;
    font-family: 'Roboto', sans-serif !important;
    font-size: 20px !important;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal !important;
    line-height: normal;
    padding-bottom: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 20px !important;
  }
}
</style>
