<template>
  <div class="prezentation-details">
    <div
      class="prezLockedTopBanner"
      v-if="
        !isPrezentationDataLoading &&
        !isOpPrezentation &&
        !isBestPracticePrezLanding
      "
    >
      <div class="templateRemovedAlertBanner" v-if="isTemplateRemoved(themes)">
        <div class="lockAlertMessage">
          <span class="alertText">
            <v-icon>mdi-lock</v-icon>
            {{ $t('build.step3.templateRemovedBanner') }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="isPrezError" class="error-page-container">
      <img
        :src="`${
          isForbidden
            ? '/assets/img/prezentations/prez_not_shared.svg'
            : '/assets/img/prezentations/prez_does_not_exist.svg'
        }`"
        alt
      />
      <div class="error-message">
        {{
          isForbidden
            ? $t('build.step3.forbiddenErrorText')
            : $t('build.step3.nonForbiddenErrorText')
        }}
      </div>
      <div class="action-container">
        <div v-if="isPrezDetailLandingPage">
          <v-btn
            class="primary-btn ma-2 py-2"
            center
            id="new-prez-btn"
            rounded
            filled
            color="primary"
            height="50px !important"
            @click="goBackToPrezentationPage"
          >
            {{ $t(backToPrezentationPageText) }}
          </v-btn>
        </div>
        <div v-if="isForbidden" class="request-prezentation-container">
          <v-btn
            :loading="isPrezShareAccessSendingNotification"
            class="send-assessment ma-2 py-2"
            width="262px !important"
            height="50px !important"
            @click="handleRequestPrezentation"
          >
            {{ $t('build.step3.requestAccess') }}
          </v-btn>
        </div>
      </div>
      <div v-if="isBestPracticePrezLanding" class="goToSlidesBtnCntr">
        <v-btn
          class="primary-btn ma-2 py-2 goToSlidesBtn"
          center
          id="new-bp-prez-btn"
          rounded
          filled
          color="primary"
          height="50px !important"
          @click="() => goBackToBpLibray()"
        >
          {{ $t('bestPracticesGuide.goToBestPracticesLibrary') }}
        </v-btn>
      </div>
    </div>
    <div
      class="prezentation-details-wrapper"
      v-if="!isPrezentationDataLoading && !isPrezError"
      :style="{
        'padding-right': `${
          this.origin === 'build' && !isFullScreenMode && showRightPanel
            ? '30px'
            : '0px'
        }`,
      }"
    >
      <div
        class="prez-detail-middle"
        :style="{
          width: showRightPanel ? '70%' : '89%',
          margin: `${
            this.origin === 'build' && !isFullScreenMode && showRightPanel
              ? '0'
              : '0 auto'
          }`,
          'margin-top': `${
            this.origin === 'build' && isFullScreenMode ? '20px' : '0px'
          }`,
        }"
      >
        <div
          class="backToSlidesContainer"
          v-if="origin !== 'build' && !isFullScreenMode"
        >
          <v-btn
            @click="
              isBestPracticePrezLanding && previousRoute !== 'search'
                ? goBackToBpLibray()
                : backButton('back')
            "
          >
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t(backButtonLabel) }}
          </v-btn>
          <span
            v-if="isDateAndUser && !isBestPracticePrezLanding"
            class="last-modified"
          >
            {{ $t('prezentationsList.lastModified') }}
            {{ prezentationData.updatedAt | formatDate('MM/DD/YYYY') }}
            {{ $t('prezentationsList.by') }}
            {{
              currentUser.user.id === prezentationData.updatedByID
                ? $t('build.step1.me')
                : `${prezentationData.updatedBy || ''}`
            }}
          </span>
        </div>
        <div
          class="title-row"
          v-if="!isFullScreenMode || isBestPracticePrezLanding"
        >
          <div class="title-wrapper">
            <v-tooltip top content-class="tooltip-content">
              <template v-slot:activator="{ on, attrs }">
                <h2
                  class="prez-name"
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    'max-width': showRightPanel ? '315px' : '525px',
                  }"
                >
                  {{ prezentationData.name }}
                </h2>
              </template>
              <span> {{ prezentationData.name }}</span>
            </v-tooltip>
            <span
              class="saved-status"
              v-if="savedStatus && !isBestPracticePrezLanding"
            >
              {{ savedStatus }}
            </span>
          </div>
          <QuickAccessActions
            :rightPanelOpen="handleRightPanelOpen"
            :rightPanelClose="handleRightPanelClose"
            :origin="origin"
          />
        </div>
        <div
          :class="`banner-row ${
            isBestPracticePrezLanding ? 'bannerRowForBp' : ''
          }`"
          v-if="!isFullScreenMode"
        >
          <PrezentationDetailBanner :origin="origin" />
        </div>
        <div class="main-action-container">
          <MainActions
            :rightPanelOpen="handleRightPanelOpen"
            :rightPanelClose="handleRightPanelClose"
            :updatePrezentation="handleUpdatePrezentation"
            :origin="origin"
          />
          <div class="title-wrapper" v-if="isFullScreenMode">
            <span class="saved-status" v-if="savedStatus">
              {{ savedStatus }}
            </span>
            <QuickAccessActions
              v-if="!isBestPracticePrezLanding"
              :rightPanelOpen="handleRightPanelOpen"
              :rightPanelClose="handleRightPanelClose"
              :origin="origin"
            />
          </div>
        </div>

        <SlideActions
          :rightPanelOpen="handleRightPanelOpen"
          :showRightPanel="showRightPanel"
          :origin="origin"
        />
        <ListView
          v-if="inListViewMode"
          :rightPanelOpen="handleRightPanelOpen"
          :rightPanelClose="handleRightPanelClose"
          :updatePrezentation="handleUpdatePrezentation"
          :showRightPanel="showRightPanel"
          :origin="origin"
          :selectedAudienceData="selectedAudienceData"
        />
        <MosaicView
          v-if="!inListViewMode"
          :rightPanelOpen="handleRightPanelOpen"
          :updatePrezentation="handleUpdatePrezentation"
        />
      </div>
      <div
        class="prez-detail-right"
        v-if="showRightPanel"
        :style="{
          'padding-top': paddingTop,
          height: `${
            this.origin === 'build' && isFullScreenMode
              ? 'calc(100vh - 44px)'
              : 'calc(100vh - 68px)'
          }`,
        }"
        :class="{
          'prez-detail-build': this.origin === 'build' && !isFullScreenMode,
          'banner-active': isBannerActive,
        }"
      >
        <div class="right-panel-component">
          <component
            class="right-panel-context"
            :is="getRightPanelComponent"
            :updatePrezentation="handleUpdatePrezentation"
            :updateSlide="handleUpdateSlideInPrezentation"
            :origin="origin"
            :isNew="isNew"
          />
        </div>
        <div class="smart-tools-icon-panel">
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="handleRightPanelClose"
                  :color="hover ? '#21A7E0' : '#000'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon name="close-icon"> mdi-close </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span
              v-if="
                selectedQuickAction &&
                selectedMainAction &&
                selectedQuickAction.id !== 'prezentationComments' &&
                selectedMainAction.id === 'editSlide'
              "
            >
              {{ $t('generate.closeSmartTools') }}
            </span>
            <span
              v-else-if="
                selectedMainAction && selectedMainAction.id === 'addSlide'
              "
            >
              {{ $t('build.step3.closeGallery') }}
            </span>
            <span
              v-else-if="
                selectedMainAction &&
                selectedQuickAction &&
                selectedMainAction.id !== 'addSlide' &&
                selectedMainAction.id !== 'editSlide' &&
                selectedQuickAction.id !== 'prezentationComments'
              "
              >{{ $t('build.step3.close') }}</span
            >
            <span v-else>{{ $t('build.step3.closeComments') }}</span>
          </v-tooltip>
          <template
            v-if="
              selectedMainAction &&
              selectedQuickAction &&
              selectedMainAction.id === 'editSlide' &&
              selectedQuickAction.id !== 'prezentationComments'
            "
          >
            <template v-for="(action, index) in smartToolsActions">
              <v-tooltip
                left
                max-width="250"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
                v-if="action && action.visible"
                :key="index"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="
                        action.id !== 'deleteSlide' &&
                        action.id !== 'duplicateSlide'
                      "
                      depressed
                      small
                      icon
                      :ripple="false"
                      @click="handleSmartToolsIconClick(action)"
                      v-bind="attrs"
                      v-on="on"
                      :color="
                        action.id === selectedSlideAction.id || hover
                          ? '#21A7E0'
                          : '#000'
                      "
                      :disabled="action.disabled"
                    >
                      <div class="custom-icon" v-if="action.isCustomIcon">
                        <img
                          v-if="selectedSlideAction.id === action.id || hover"
                          :src="getHighlightedIcon(action)"
                        />
                        <img v-else :src="getIcon(action)" />
                      </div>
                      <v-icon
                        :name="action.id + '-icon'"
                        v-else-if="action.icon"
                      >
                        {{ action.icon }}
                      </v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <span>{{ $t(action.tooltipText) }}</span>
              </v-tooltip>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="loading-container" v-else>
      <clip-loader
        :loading="isPrezentationDataLoading"
        :color="`#21a7e0`"
        :width="40"
        :height="40"
      />
    </div>
    <div
      :class="`prez-detail-actions-wrapper ${prezentationDetailActionsClass}`"
      v-if="!isPrezentationDataLoading && !isPrezError"
    >
      <v-menu
        top
        v-model="otherActionsOpen"
        offset-y
        v-if="!isBestPracticePrezLanding"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            outlined
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="action-btn"
          >
            {{ $t('userUploadPrezentation.otherActions') }}
            <v-icon color="primary">
              {{ `mdi-menu-${otherActionsOpen ? 'up' : 'down'}` }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(action, index) in getOtherActionsOptions"
            :key="index"
            @click="handleActionsOptionClick(action)"
            :disabled="
              prezentationData.ownerID != currentUser.user.id &&
              action.id === 'share-prezentation'
            "
          >
            <v-list-item-title> {{ $t(action.name) }} </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="showRevertToOriginalButton"
        :class="
          !isPremiumFeatureActionCompleted ? 'disabledRevertBtn' : 'revertBtn'
        "
        :disabled="!isPremiumFeatureActionCompleted"
        @click="handleRevertOriginal"
        data-pendo-id="revert-to-original-btn"
      >
        {{ $t('slideDetails.revertToOriginal') }}
      </v-btn>
      <Download
        :downloading="isLocalDownloading"
        :file-name="getFilename()"
        :download-file-name="getDownloadFilename()"
        :merged-url="getMergedUrl"
        :item="prezentationData"
        :log-download="logDownload"
        :on-download="() => handleSlideDownload()"
        :origin="getDownloadOrigin"
        limitsKey="prezentation"
        :speedDialPosRight="'10'"
        :isDisabled="isLocalDownloading"
      />
    </div>

    <ConfirmationDialog
      :show="showResetConfirmDailog"
      :on-primary-click="onRevertVisualsConfirm"
      :on-secondary-click="onRevertVisualsCancel"
      :on-close="onRevertVisualsCancel"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      title="Reset Prezentation"
      text="All your changes on this Prezentation will be lost. Would you like to continue?"
    />

    <ConfirmationDialog
      :show="showExitConfirmDailog"
      :on-primary-click="onExitVisualsConfirm"
      :on-secondary-click="onExitVisualsCancel"
      :on-close="onExitVisualsCancel"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      :title="getExitConfirmTitle"
      text="Any changes to the Best Practice Deck will be lost. Would you like to leave anyway?"
    />

    <v-overlay
      class="overlay"
      :absolute="true"
      :light="true"
      z-index="20"
      :value="loadingIllustrations || loadingAnimations"
      color="#fff"
      opacity="0.4"
    ></v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {
  getPrezentationByID,
  updatePrezentationFunction,
  updatePrezentationAsDownloaded,
  addPrezentationAsDownloaded,
  sendNotification,
  updatePrezentationView,
  logSearchQuery,
  downloadComplyDeck,
  postUpdateActions,
} from '../../../../utils/api-helper';
import EventBus from '../../../common/event-bus';
import { callMergeSlidesApi } from '@/utils/merge-slide-helper';
import { AnalyticsHandler } from '../../../common/Analytics/AnalyticsHandler';
import { MatomoAnalyticsHandler } from '../../../common/Analytics/MatomoAnalyticsHandler';
import {
  MY_PREZENTATION,
  getBuildPrezentationData,
  BUILD,
  EXPANDED_VIEW,
  MY_PREZENTATION_COPIED,
  TD_COMMON_COLUMN_NAME,
  TD_DOWNLOAD_AS_PDF,
  TD_COMMON_COLUMN_VALUE,
  TD_YES,
  TD_NO,
  TD_ISDOWNLOAD,
  TD_DOWNLOADTYPE_DIRECT,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_GS,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_PREZENTATIONAUTHOR,
  getPrezData,
  TD_PLACEMENT,
  PREZENTATION_PLACEMENT,
  TD_TIME_SPENT_IN_SECS,
  TD_REPLACE_IMAGE_SOURCE,
  NA,
  TD_SLIDE_SOURCE,
  TD_SLIDE_NAME,
  TD_SLIDEID,
  TD_VIEWTYPE,
  TD_MOSAIC,
  TD_LIST,
  TD_IDEA,
  TD_CONSTRUCT,
  TD_DECK_ID,
  TD_HASCARTOON,
  TD_HASICONS,
  TD_HASIMAGES,
  TD_IS3D,
  TD_PREZENTATION_CATEGORY,
  TD_NODECOUNT,
  // TD_TAB,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import PrezentationDetailBanner from './PrezentationDetailBanner.vue';
import MainActions from './MainActions.vue';
import SlideActions from './SlideActions.vue';
import QuickAccessActions from './QuickAccessActions/QuickAccessActions.vue';
import PrezentationComments from './QuickAccessActions/PrezentationComments.vue';
import ChangeVisual from './ChangeVisuals/ChangeVisual.vue';
import ChangeLayout from './ChangeLayout.vue';
import ChangeNode from './ChangeNode.vue';
import ListView from './ListView.vue';
import MosaicView from './MosaicView.vue';
import Download from '../../../common/Download.vue';
import SharePrezentation from '../../Prezentations/SharePrezentation.vue';
import { miscConfig } from '../../../../runtimeConfig';
import EditTag from './EditTag.vue';
import AddSlide from './AddSlide';
import EditBestPractices from './EditBestPractices/EditBestPractices.vue';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';
import { trackIconReplacementEvents } from '../../../common/Analytics/IconReplacementEvents';
import AudiencePanel from './AudiencePanel/AudiencePanel.vue';
import { slideActions } from '../config/actionConfig';
import Anonymize from './Anonymize.vue';
import audienceApi from '../../../../API/audience-api';
import PrezentationShareRequestSent from '../../MyPrezentation/PrezentationShareRequestSent.vue';
import ConvertTemplate from './ConvertTemplate/ConvertTemplate.vue';
import SynthesizeContainer from './Synthesize/SynthesizeContainer.vue';
import { processPrezentationForThumbnail } from '../store';
import ConfirmationDialog from '../../../common/ConfirmationDialog.vue';

const backButtonConfig = {
  my_prezentation: {
    label: 'build.step3.backToPrezentationText',
  },
  my_prezentation_copied: {
    label: 'upload.goToPrezentations',
  },
  hybrid_search: {
    label: 'slides.backToSearch',
  },
};
export default {
  name: 'PrezentationDetails',
  components: {
    PrezentationDetailBanner,
    MainActions,
    SlideActions,
    QuickAccessActions,
    ListView,
    ClipLoader,
    Download,
    SharePrezentation,
    MosaicView,
    ConfirmationDialog,
  },
  data() {
    return {
      previousRoute: '',
      iconMap: {
        'format-icon': '/assets/img/synthesize/format-icon.svg',
        'emotion-icon': '/assets/img/synthesize/emotion-icon.svg',
        'tone-icon': '/assets/img/synthesize/tone-icon.svg',
        'data-style-icon': '/assets/img/synthesize/dataStyle-icon.svg',
      },
      highlightIconMap: {
        'format-icon': '/assets/img/synthesize/format-icon-filled.svg',
        'emotion-icon': '/assets/img/synthesize/emotion-icon-filled.svg',
        'tone-icon': '/assets/img/synthesize/tone-icon-filled.svg',
        'data-style-icon': '/assets/img/synthesize/dataStyle-icon-filled.svg',
      },
      showRightPanel: false,
      smartToolsActions: [], // slideActions.editSlide,
      isPrezentationDataLoading: true,
      prezentationDetailActionsClass: 'menu-minimized',
      otherActionsOpen: false,
      // downloading: false,
      mergedUrl: '',
      slidesParam: '',
      isPrezError: false,
      isForbidden: false,
      prezOwnerId: '',
      prezeName: '',
      isPrezShareAccessSendingNotification: false,
      doesntExist: false,
      savedStatus: '',
      matomo_expanded_view: EXPANDED_VIEW,
      paddingTop: null,
      mapRightPanelComponents: {
        changeVisual: ChangeVisual,
        changeLayout: ChangeLayout,
        changeNodeCount: ChangeNode,
        editTag: EditTag,
        editBestPractices: EditBestPractices,
        addSlide: AddSlide,
        prezentationComments: PrezentationComments,
        changeAudiance: AudiencePanel,
        anonymize: Anonymize,
        convertTemplate: ConvertTemplate,
        synthesize: SynthesizeContainer,
      },
      otherActionsOptions: [
        {
          id: 'build-prezentation',
          name: 'profile.storylinesTabContent.buildNewPrezentation',
          displayName: 'Build new prezentation',
        },
        {
          id: 'goto-prezentations',
          name:
            this.origin === 'build' || this.origin === MY_PREZENTATION_COPIED
              ? 'upload.goToPrezentations'
              : 'build.step3.backToPrezentationText',
          displayName:
            this.origin === 'build' || this.origin === MY_PREZENTATION_COPIED
              ? 'Go to Prezentations'
              : 'Back to Prezentations',
        },
      ],
      currentAudienceID: '',
      showResetConfirmDailog: false,
      showExitConfirmDailog: false,
    };
  },
  props: {
    prezentation: {
      type: Object,
      default: () => {},
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      required: false,
      default: MY_PREZENTATION_COPIED,
    },
    isSaveStoryLine: {
      type: Boolean,
      default: false,
    },
    onEditStoryLine: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    selectedMainAction() {
      if (this.selectedMainAction.id === 'modifyStoryline') {
        this.handleEditOutlineClick();
      }
    },
    selectedSlideAction() {
      if (
        !this.selectedSlideAction?.id ||
        this.selectedSlideAction?.id === 'deleteSlide' ||
        this.selectedSlideAction?.id === 'duplicateSlide'
      ) {
        this.rightPanelOpen = false;
      }
    },
    selectedSlide: {
      handler(val) {
        if (val && this.prezentationData) {
          this.smartToolsActions = slideActions.editSlide.map((action) =>
            this.getAction(action),
          );
        }
      },
      deep: true,
    },
    origin() {
      this.handlePadding();
    },
    isFullScreenMode() {
      this.handleRightPanelClose();
      this.handlePadding();
    },
    showRightPanel() {
      this.smartToolsActions = slideActions.editSlide.map((action) =>
        this.getAction(action),
      );
      console.log(this.smartToolsActions);
    },
    // prezentationData: {
    //   async handler(val) {
    //     if (
    //       val &&
    //       val.sections &&
    //       val.sections.list &&
    //       val.sections.list.length > 0
    //     ) {
    //       await this.mergeSlides(true);
    //     }
    //   },
    // },
    '$route.fullPath': {
      async handler() {
        this.logSearchQuery();
        if (this.$route.params.id) {
          await this.getDeckDetailsOnMount();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'themes',
      'currentTheme',
      'currentUserFavorites',
      'typesenseRealtimeAudiences',
      'isBannerActive',
    ]),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlideAction',
      'selectedMainAction',
      'selectedSlide',
      'selectedSlideIndex',
      'selectedSectionIndex',
      'selectedQuickAction',
      'isFullScreenMode',
      'inListViewMode',
      'selectedAudienceData',
      'loadingIllustrations',
      'loadingAnimations',
      'actionCompletedInfo',
      'prezentationOriginalData',
    ]),
    ...mapState('search', ['recentQueries']),
    ...mapGetters('users', [
      'getOPAccessLevel',
      'isUploadLimittedAccess',
      'isUploadFullAccess',
      'getUploadAccessLevel',
      'allowReplaceImageOverall',
      'allowReplaceIconOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
    ]),
    ...mapGetters('prezentationDetails', [
      'isViewAccess',
      'isRestrictedAccess',
      'isOpPrezentation',
      'isBestPractices',
      'showBestPracticeToggle',
      'isPrezentSourceBestPractice',
      'isUserUploadedPrezentation',
      'showShareOption',
      'isIOCUpload',
      'isGenerated',
      'isRedesigned',
      'isComply',
      'isTemplateRemoved',
      'currentItemIsEditable',
      'getComplyUploadId',
      'getSlideImages',
    ]),
    ...mapState('prezentations', [
      'selectedPrezentationView',
      'currNewPrezentation',
      'prezentationViewOrigin',
      'currentItemIsEditable',
    ]),
    ...mapGetters('bestPracticesExamples', ['getHighlightedSlides']),
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),
    isLocalDownloading() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.prezentationData.id,
      );
      return ldr && ldr.downloading;
    },
    showRevertToOriginalButton() {
      return (
        (this.selectedMainAction.id === 'changeVisual' ||
          this.selectedMainAction.id === 'convertTemplate') &&
        this.isPrezentSourceBestPractice
      );
    },
    getDownloadOrigin() {
      let downloadOrigin = '';
      if (this.origin === 'bpe-slide' || this.origin === 'build') {
        downloadOrigin = this.origin;
      } else if (this.isBestPractices) {
        downloadOrigin = 'bpe-deck';
      } else {
        downloadOrigin = 'prezentation';
      }
      return downloadOrigin;
    },
    isPrezDetailLandingPage() {
      return this.$route?.name === 'myPrezentation.detail';
    },
    backToPrezentationPageText() {
      if (this.origin === 'hybrid_search') {
        return this.$t('slides.backToSearch');
      }
      if (
        this.origin === 'my_prezentation' &&
        this.prezentationViewOrigin === 'Prezentations'
      ) {
        return this.$t('slides.backToSearch');
      }
      return this.$t('upload.goToPrezentations');
    },
    isDateAndUser() {
      return (
        this.prezentationData?.updatedAt && this.prezentationData?.updatedBy
      );
    },
    isBestPracticePrezLanding() {
      return this.$route?.name === 'bestPractice.detail';
    },
    backButtonLabel() {
      if (this.origin !== 'build' && !this.isBestPracticePrezLanding) {
        const config = backButtonConfig[this.origin];
        return config?.label;
      }
      if (this.previousRoute === 'search') {
        return 'slides.backToSearch';
      }
      if (this.isBestPracticePrezLanding) {
        return 'common.back';
      }
      return '';
    },
    originalRealtimeAudiences() {
      if (this.origin === 'generate') {
        return this.typesenseRealtimeAudiences.filter(
          (i) => i.type !== 'standard',
        );
      }
      return this.typesenseRealtimeAudiences;
    },
    showShareOption() {
      return (
        this.prezentationData?.prezentationType === 'Best Practices' &&
        this.prezentationData?.source?.toLowerCase() === 'prezent'
      );
    },
    getOtherActionsOptions() {
      if (!this.showShareOption) {
        return [
          {
            id: 'share-prezentation',
            name: 'build.step3.sharePrezentation',
            displayName: 'Share this Prezentation',
          },
          ...this.otherActionsOptions,
        ];
      }

      return [...this.otherActionsOptions];
    },
    getRightPanelComponent() {
      if (this.selectedQuickAction?.id === 'prezentationComments') {
        return this.mapRightPanelComponents[this.selectedQuickAction.id];
      }

      if (
        this.selectedMainAction?.id === 'synthesize' ||
        this.selectedSlideAction?.id === 'changeEmotion' ||
        this.selectedSlideAction?.id === 'changeFormat' ||
        this.selectedSlideAction?.id === 'changeTone' ||
        ((this.selectedSlideAction?.id === 'changeNodeCount' ||
          this.selectedSlideAction?.id === 'changeLayout') &&
          this.selectedSlide.isSynthesis) ||
        this.selectedSlideAction?.id === 'dataStyle'
      ) {
        return this.mapRightPanelComponents.synthesize;
      }

      if (this.selectedSlideAction?.id) {
        return this.mapRightPanelComponents[this.selectedSlideAction.id];
      }

      if (
        this.selectedMainAction?.id === 'changeAudiance' ||
        this.selectedMainAction?.id === 'addSlide' ||
        this.selectedMainAction?.id === 'anonymize' ||
        this.selectedMainAction?.id === 'changeVisual' ||
        this.selectedMainAction?.id === 'convertTemplate'
      ) {
        return this.mapRightPanelComponents[this.selectedMainAction.id];
      }

      this.handleRightPanelClose();

      return null;
    },
    isOwnerOfPrezentation() {
      return this.prezentationData?.ownerID === this.currentUser?.user?.id;
    },
    isPremiumFeatureActionCompleted() {
      return (
        this.actionCompletedInfo?.isReplaceVisualsCompleted ||
        this.actionCompletedInfo?.isTcCompleted
      );
    },
    getExitConfirmTitle() {
      if (this.selectedMainAction?.id === 'convertTemplate') {
        return 'Exit Convert Template';
      }
      return 'Exit Replace Visuals';
    },
  },

  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    EventBus.$off('DO_DROPDOWN_SEARCH');
    this.resetPrezState();
    this.updateActionCompletedInfo({});
    this.setHighlightedSlides([]);
  },

  mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    EventBus.$emit('MINIMIZE_LEFTNAV');
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.handleRightPanelClose();
      this.prezentationDetailActionsClass = 'menu-expanded';
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.prezentationDetailActionsClass = 'menu-minimized';
    });
    this.handlePadding();
    if (!this.isNew) {
      updatePrezentationView(this.$route.params.id)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (!this.$route.params.id) {
      this.setPrezentationData({
        prezentation: this.prezentation,
        currentUser: this.currentUser,
      });
      this.setDefaultAudience(this.prezentation);
      setTimeout(() => {
        this.isPrezentationDataLoading = false;
      }, 1);
    }
    this.setIsActionsLoading(true);
    this.setIsRightPanelOpen(false);
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setPrezentationData',
      'updatePrezentationData',
      'setSelectedSlideData',
      'setSelectedMainAction',
      'setSelectedQuickAction',
      'setSelectedSlideAction',
      'resetPrezState',
      'setIsActionsLoading',
      'setIsRightPanelOpen',
      'setSelectedAudienceData',
      'setLoadingAnimations',
      'updateActionCompletedInfo',
      'resetComplyData',
    ]),
    ...mapActions('prezentations', [
      'updatePrezentationWithAction',
      'setPrezentationBackClicked',
    ]),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
    ]),
    ...mapActions('bestPracticesExamples', ['setHighlightedSlides']),
    getIcon(action) {
      return this.iconMap[action?.icon];
    },
    getHighlightedIcon(action) {
      return this.highlightIconMap[action?.icon];
    },

    logSearchQuery() {
      if (this.$route.query.log_search) {
        const logPayload = {
          type: '',
          searchTerm: this.recentQueries[0]?.name,
          searchTermResult: true,
        };
        if (this.isBestPracticePrezLanding)
          logPayload.type = 'Best practice guides';
        else logPayload.type = 'Prezentation Library';
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      }
    },

    async getDeckDetailsOnMount() {
      this.isPrezentationDataLoading = true;
      this.setPrezentationData({});
      await getPrezentationByID(this.$route.params.id)
        .then((resp) => {
          this.setPrezentationData({
            prezentation: resp,
            currentUser: this.currentUser,
          });
          const {
            isError,
            doesntExist,
            isForbidden,
            prezentationName,
            ownerId,
          } = resp;
          this.setDefaultAudience(resp);
          if (isError) {
            this.isPrezError = isError;
            this.isForbidden = isForbidden;
            this.prezOwnerId = ownerId;
            this.prezeName = prezentationName;
            this.doesntExist = doesntExist;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isPrezentationDataLoading = false;
        });
    },

    async handleRequestPrezentation() {
      const notificationMessage = `${
        this.currentUser.user.firstName
          ? `${this.currentUser.user.firstName} ${
              this.currentUser.user.lastName || ''
            }`
          : this.currentUser.user.cognitoID
      } has requested access to view ${this.prezeName} prezentation`;
      const notificationData = {
        from_user: this.currentUser.user.id,
        to: this.prezOwnerId,
        scope: 'user',
        notification: notificationMessage,
        use_case: 'prezentation_access_request',
      };

      this.isPrezShareAccessSendingNotification = true;
      try {
        await sendNotification(notificationData);
        this.isPrezShareAccessSendingNotification = false;
      } catch (e) {
        console.log(e);
        this.isPrezShareAccessSendingNotification = false;
      }

      this.$modal.show(
        PrezentationShareRequestSent,
        {
          author: this.prezOwnerId,
        },
        {
          name: 'PrezentationShareRequestSent',
          width: '400',
          height: '350px',
        },
      );
    },
    goBackToPrezentationPage() {
      if (
        this.origin === 'hybrid_search' ||
        (this.origin === 'my_prezentation' &&
          this.prezentationViewOrigin === 'Prezentations')
      ) {
        this.back();
      } else {
        this.$router.push({
          path: `/home/prezentations`,
        });
      }
    },

    isFeatureEnabled(key) {
      return this.currentUser?.features?.find((f) => f.feature_name === key)
        ?.enabled;
    },

    getAction(action) {
      switch (action.id) {
        case 'editTag':
          if (
            this.selectedSlide &&
            (this.selectedSlide.isUserUploaded ||
              this.selectedSlide.isGenerated ||
              this.selectedSlide.isRedesign ||
              this.selectedSlide.isSynthesis ||
              this.selectedSlide.isAnonymized ||
              this.selectedSlide.source === 'generated' ||
              this.selectedSlide.source === 'synthesis' ||
              this.selectedSlide.source === 'comply') &&
            this.prezentationData.prezentationType !== 'Shell' &&
            !this.isViewAccess
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }
          return action;

        case 'changeLayout':
        case 'changeVisual':
        case 'changeNodeCount':
          if (
            this.selectedSlide &&
            (this.selectedSlide.isUserUploaded ||
              this.selectedSlide.isUploaded ||
              this.selectedSlide.isGenerated ||
              this.selectedSlide.isSynthesis ||
              this.selectedSlide.isOPUploaded ||
              this.selectedSlide.isRedesign ||
              this.selectedSlide.isComply ||
              this.selectedSlide.source === 'generated' ||
              this.selectedSlide.source === 'uploaded' ||
              this.selectedSlide.source === 'comply' ||
              this.selectedSlide.source === 'synthesis' ||
              this.selectedSlide.source === 'redesign' ||
              this.isOpPrezentation)
          ) {
            action.disabled = true;
            action.visible = false;
            if (
              action.id === 'changeVisual' &&
              !(
                this.selectedSlide.isComply ||
                this.selectedSlide.source === 'comply'
              )
            ) {
              action.disabled = false;
              action.visible = true;
            }
          } else if (
            this.selectedSlide?.prefs?.is_know === 'yes' &&
            action.id === 'changeVisual'
          ) {
            action.disabled = true;
            action.visible = false;
          } else if (
            this.selectedSlide?.isIOCUploaded &&
            action.id === 'changeVisual'
          ) {
            action.disabled = false;
            action.visible = true;
          } else if (
            this.selectedSlide?.prefs?.is_know === 'yes' &&
            action.id === 'changeLayout'
          ) {
            action.disabled = true;
            action.visible = false;
          } else if (
            ((this.selectedSlide?.isAddedFromBestpracticeDecks &&
              this.selectedSlide.isAnonymized) ||
              this.selectedSlide?.isIOCUploaded) &&
            (action.id === 'changeLayout' || action.id === 'changeNodeCount')
          ) {
            action.disabled = true;
            action.visible = false;
          } else {
            action.disabled = false;
            action.visible = true;
          }

          if (this.prezentationData.convertedTo === 'Comply') {
            if (
              action.id === 'changeNodeCount' &&
              this.prezentationData.prezentationType === 'Shell'
            ) {
              action.disabled = true;
              action.visible = true;
            } else if (
              action.id !== 'changeNodeCount' &&
              this.prezentationData.prezentationType === 'Shell'
            ) {
              action.disabled = false;
              action.visible = true;
            }

            if (
              action.id !== 'changeVisual' &&
              this.prezentationData.prezentationSource === 'Generated'
            ) {
              action.disabled = true;
              action.visible = false;
            }
          }
          if (
            action.id === 'changeVisual' &&
            !(
              (this.allowReplaceImageOverall && this.allowReplaceImageTabs) ||
              (this.allowReplaceIconOverall && this.allowReplaceIconTabs)
            )
          ) {
            action.disabled = true;
            action.visible = false;
          }

          if (
            this.selectedSlide.isSynthesis &&
            this.selectedSlideIndex === 0 &&
            this.actionCompletedInfo?.synthesisCompleted
          ) {
            action.disabled = false;
            action.visible = true;
          }

          return action;

        case 'editBestPractices':
          if (
            this.selectedSlide &&
            (this.selectedSlide.isUserUploaded ||
              this.selectedSlide.isUploaded ||
              this.selectedSlide.isGenerated ||
              this.selectedSlide.isSynthesis ||
              this.selectedSlide.isRedesign ||
              this.selectedSlide.source === 'generated' ||
              this.selectedSlide.source === 'uploaded' ||
              this.selectedSlide.source === 'comply' ||
              this.selectedSlide.source === 'synthesis' ||
              this.selectedSlide.source === 'redesign') &&
            this.prezentationData.isBestPracticesEnabled
          ) {
            action.disabled = this.isViewAccess;
            action.visible = !this.isViewAccess;
          } else if (
            this.prezentationData &&
            this.prezentationData.prezentationSource === 'Product'
          ) {
            action.visible = false;
            action.disabled = true;
          } else if (
            this.isOwnerOfPrezentation ||
            this.selectedSlide.isAnonymized
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }

          return action;

        case 'addToSlides':
          if (
            this.getUploadAccessLevel &&
            this.prezentationData &&
            this.prezentationData.prezentationSource === 'User Uploaded' &&
            this.isOwnerOfPrezentation &&
            !this.selectedSlide.isSynthesis
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }

          return action;

        case 'changeFormat':
        case 'changeEmotion':
        case 'dataStyle':
        case 'changeTone':
          if (
            !this.isViewAccess &&
            !this.isTemplateRemoved(this.themes) &&
            this.selectedSlide.isSynthesis &&
            this.selectedSlideIndex === 0 &&
            this.actionCompletedInfo?.synthesisCompleted
          ) {
            action.disabled = false;
            action.visible = true;
          } else {
            action.disabled = true;
            action.visible = false;
          }
          return action;

        // Note: Duplicate & Delete Slide should not be visible on smart tools panel
        case 'duplicateSlide':
        case 'deleteSlide':
          // if (
          //   !this.isViewAccess &&
          //   !this.isTemplateRemoved(this.themes) &&
          //   !this.isOpPrezentation
          // ) {
          //   action.disabled = false;
          //   action.visible = true;
          // } else {
          action.disabled = true;
          action.visible = false;
          // }
          return action;

        default:
          return action;
      }
    },
    handlePadding() {
      if (this.origin === 'build' && this.isFullScreenMode) {
        this.paddingTop = '49px';
      } else if (this.origin === 'build' && !this.isFullScreenMode) {
        this.paddingTop = '20px'; // this should be changed after correcting panel.
      } else if (this.origin === 'my_prezentation' && this.isFullScreenMode) {
        this.paddingTop = '29px';
      } else {
        this.paddingTop = '24px';
      }
    },
    getFilename() {
      return this.prezentationData.name;
    },
    back() {
      const origin = this.prezentationViewOrigin || 'Prezentations';
      this.setPrezentationBackClicked(true);
      if (origin === 'Prezentations' && this.previousRoute !== 'search') {
        this.$router.push({
          name: origin,
        });
      } else {
        this.$router.back();
      }
    },
    goBackToBpLibray() {
      this.$router.push('/home/best-practice-library');
    },
    backButton(from, navigationNext) {
      this.dataChangeActionFrom = from;
      this.navigationNext = navigationNext;
      if (this.isPremiumFeatureActionCompleted && this.isBestPractices) {
        this.showExitConfirmDailog = true;
      } else {
        this.handelDataChangeActionsFrom();
      }
    },
    handelDataChangeActionsFrom() {
      if (this.dataChangeActionFrom === 'back') {
        this.back();
      } else if (this.dataChangeActionFrom === 'share') {
        this.openSharingOptions(
          this.prezentationData,
          `${this.matomo_expanded_view}-icon`,
        );
      } else if (this.dataChangeActionFrom === 'navigate-prezentation') {
        this.$router.push(this.navigationNext);
      } else if (this.dataChangeActionFrom === 'other-page') {
        if (this.navigationNext) this.navigationNext();
      } else if (this.dataChangeActionFrom === 'navigate-page') {
        if (this.onEditStoryLine && this.origin === 'build') {
          this.onEditStoryLine(1);
        } else {
          this.$router.push(this.navigationNext);
        }
      }
    },
    handleSmartToolsIconClick(data) {
      if (data.id === 'deleteSlide') {
        this.$root.$emit(
          'deleteSlide',
          this.selectedSlideIndex,
          this.selectedSectionIndex,
        );
      } else if (data.id === 'duplicateSlide') {
        this.$root.$emit(
          'duplicateSlide',
          this.selectedSlide,
          this.selectedSlideIndex,
          this.selectedSectionIndex,
        );
      }
      this.setSelectedQuickAction({});
      this.setSelectedSlideAction(data);
      this.handleMatomoEvents(data.id);
      if (this.showRightPanel) this.handleRightPanelOpen(!data.noRightPanel);
    },
    handleMatomoEvents(slideAction) {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_PREZENTATIONAUTHOR]:
          slideAction === 'addToSlides' ? this.prezentationData.author : '',
      };
      if (this.origin === 'build') {
        switch (slideAction) {
          case 'changeLayout':
            trackBuildEvents.buildChangeLayoutClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeVisual':
            trackBuildEvents.buildReplaceVisualClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeNodeCount':
            trackBuildEvents.buildEditNodeCountClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'editTag':
            trackBuildEvents.buildEditTagtClick(this.currentUser, otherData);
            break;
          default:
            break;
        }
      } else {
        switch (slideAction) {
          case 'changeLayout':
            trackPrezentationEvents.prezentationsEditSlideChangeLayoutClick(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeVisual':
            trackPrezentationEvents.prezentationsReplaceVisualsClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'changeNodeCount':
            trackPrezentationEvents.prezentationsEditNodeCountClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'editTag':
            trackPrezentationEvents.prezentationsEditTagClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'editBestPractices':
            trackPrezentationEvents.prezentationsEditBestPracticesClicked(
              this.currentUser,
              otherData,
            );
            break;
          case 'addToSlides':
            trackPrezentationEvents.prezentationsThumbnailClickAddToSlides(
              this.currentUser,
              otherData,
            );
            break;
          default:
            break;
        }
      }
    },
    handleActionsOptionClick(action) {
      switch (action.id) {
        case 'share-prezentation':
          this.backButton('share');
          break;
        case 'build-prezentation':
          this.backButton('navigate-page', '/home/build');
          break;
        case 'goto-prezentations':
          this.backButton('navigate-prezentation', '/home/prezentations');
          break;
        default:
          this.backButton('navigate-prezentation', '/home/prezentations');
          break;
      }
    },
    openSharingOptions(prezentation, origin) {
      let orig = `${this.origin || MY_PREZENTATION}-${origin}`;
      if (
        origin === 'upload-prezentation' ||
        this.prezentationData.prezentationSource === 'User Uploaded'
      ) {
        orig = 'upload-prezentation';
      }
      this.$modal.show(
        SharePrezentation,
        {
          prezentation: prezentation || this.prezentationData,
          origin: orig,
        },
        {
          name: 'SharePrezentation',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    getDownloadFilename() {
      let fileName = this.isBestPractices
        ? `${this.prezentationData.name}`
        : `${this.prezentationData.name}_${this.prezentationData.type}`;
      fileName = `${fileName}${
        this.prezentationData.isRestricted ||
        this.prezentationData.permission_level === 0
          ? '.pdf'
          : '.pptx'
      }`;
      return fileName;
    },
    handleRevertOriginal() {
      this.showResetConfirmDailog = true;
    },
    onRevertVisualsCancel() {
      this.showResetConfirmDailog = false;
    },
    onRevertVisualsConfirm() {
      this.setPrezentationData({
        prezentation: this.prezentationOriginalData,
        currentUser: this.currentUser,
        noOriginalUpdate: true,
      });
      this.updateActionCompletedInfo({});
      this.resetComplyData();
      EventBus.$emit('TC_RESET_COMPLY_DATA');
      this.showResetConfirmDailog = false;
    },
    onExitVisualsConfirm() {
      this.setPrezentationData({
        prezentation: this.prezentationOriginalData,
        currentUser: this.currentUser,
        noOriginalUpdate: true,
      });
      this.updateActionCompletedInfo({});
      this.handelDataChangeActionsFrom();
      this.showExitConfirmDailog = false;
    },
    onExitVisualsCancel() {
      this.showExitConfirmDailog = false;
    },
    async handleSlideDownload() {
      // this.downloading = true;
      // await this.mergeSlides(true);
      this.downloadAll();
    },
    async downloadAll() {
      if (this.prezentationData?.sections?.list.length > 0) {
        AnalyticsHandler.prezentationDownloadStarted(
          this.currentUser,
          this.prezentationData,
        );
        try {
          this.setShowDownloadSnackbar(true);
          this.setDownloadLoaders({
            loaderId: this.prezentationData.id,
            downloading: true,
          });
          this.setDownloadItems({
            status: 'assembling',
            itemIndex: uuidv4(),
            originType: this.isBestPracticePrezLanding
              ? 'bpLibraryDecks'
              : 'prezLibraryDetail',
            logDownloadCallback: (payload) => {
              let otherPayload = {};
              if (
                payload.isPrezentSourceBestPractice &&
                payload.actionCompletedInfo.isTcCompleted
              ) {
                otherPayload = {
                  source: 'comply',
                  referenceID: payload.getComplyUploadId,
                };
              } else if (
                payload.isPrezentSourceBestPractice &&
                payload.actionCompletedInfo.isReplaceVisualsCompleted
              ) {
                otherPayload = {
                  // source: 'comply',
                  replacedImage:
                    payload.actionCompletedInfo?.replacedImage || [],
                  replacedIcon: payload.actionCompletedInfo?.replacedIcon || [],
                };
              }
              if (
                payload.currentPrezentation.isDownloaded &&
                payload.currentPrezentation.isDownloaded === true
              ) {
                updatePrezentationAsDownloaded({
                  prezentationId: payload.currentPrezentation.id,
                  ...otherPayload,
                })
                  .then((resp) => {
                    console.log(resp);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                addPrezentationAsDownloaded({
                  prezentationId: payload.currentPrezentation.id,
                  downloadCount: 1,
                  ...otherPayload,
                })
                  .then((resp) => {
                    console.log(resp);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }

              AnalyticsHandler.logUserStats(payload.currentUser);

              const otherData = {
                [TD_ISDOWNLOAD]: 1,
                [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
                [TD_VIEWTYPE]: payload.inListViewMode ? TD_LIST : TD_MOSAIC,
                [TD_PREZENTATION_CATEGORY]:
                  payload.currentPrezentation.prezentationType,
                [TD_SLIDE_SOURCE]: this.getSlideSource(payload.selectedSlide),
              };

              const buildPrezData = getBuildPrezentationData(
                payload.currentPrezentation,
              );

              if (payload.isNew) {
                if (payload.isSaveStoryLine) {
                  trackBuildEvents.buildAcceptSaveStorylineDownload(
                    payload.currentUser,
                    {
                      ...buildPrezData,
                      ...otherData,
                    },
                  );
                } else {
                  trackBuildEvents.buildAcceptStorylineDownload(
                    payload.currentUser,
                    {
                      ...buildPrezData,
                      ...otherData,
                    },
                  );
                }
              }

              AnalyticsHandler.prezentationDownloadComplete(
                payload.currentUser,
                payload.currentPrezentation,
              );
              if (payload.isNew) {
                MatomoAnalyticsHandler.newPrezentationDownloaded(
                  payload.currentPrezentation,
                  payload.currentUser,
                );
              }
            },
            mergeSlidesCallback: (payload) => {
              if (payload.currentItemIsEditable) {
                const prezentationData = {
                  id: payload.prezId,
                  mergedSlides: payload.mergedslide,
                };
                updatePrezentationFunction(prezentationData)
                  .then((resp) => {
                    this.setPrezentationData({
                      prezentation: resp.prezentationData,
                      currentUser: payload.currentUser,
                    });

                    this.updatePrezentationWithAction({
                      prezentation: resp.prezentationData,
                      action: 'updated',
                      origin: payload.origin,
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            },
            downloadStatusCallback: (payload) => {
              // this.downloading = false;
              this.setDownloadLoaders({
                loaderId: payload.prezId,
                downloading: false,
              });
            },
            metaData: {
              ...this.prezentationData,
              fileName: this.prezentationData.name,
              // fileName: this.isBestPractices
              //   ? `${this.prezentationData.name}`
              //   : `${this.prezentationData.name}_${this.prezentationData.type}`,
              limitsKey: this.isBestPracticePrezLanding
                ? 'bpe-deck'
                : 'prezentation',
              origin: 'prezentation',
              downloadStatusCallbackPayload: {
                prezId: this.prezentationData.id,
              },
              mergeSlidesCallbackPayload: {
                prezId: this.prezentationData.id,
                currentItemIsEditable: this.currentItemIsEditable,
                currentUser: { ...this.currentUser },
                origin: this.origin,
              },
              logDownloadCallbackPayload: {
                currentPrezentation: { ...this.prezentationData },
                isPrezentSourceBestPractice: this.isPrezentSourceBestPractice,
                actionCompletedInfo: { ...this.actionCompletedInfo },
                getComplyUploadId: this.getComplyUploadId,
                currentUser: { ...this.currentUser },
                inListViewMode: this.inListViewMode,
                selectedSlide: { ...this.selectedSlide },
                isNew: this.isNew,
                isSaveStoryLine: this.isSaveStoryLine,
              },
            },
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    async mergeSlides(onlyPath = false) {
      if (this.prezentationData?.sections?.list.length > 0) {
        let s = {
          sections: this.prezentationData.sections,
        };
        if (
          this.prezentationData.isRestricted ||
          this.prezentationData.permission_level === 0
        ) {
          s = {
            ...s,
            outputFormat: 'pdf',
          };
        }
        this.slidesParam = s;
        await callMergeSlidesApi(s)
          .then((res) => {
            const mergedslide = res.data;
            if (mergedslide.includes('.pptx')) {
              if (this.currentItemIsEditable) {
                const prezentationData = {
                  id: this.prezentationData.id,
                  mergedSlides: mergedslide,
                };
                this.handleUpdatePrezentation(prezentationData, null, true);
              }
            }

            if (onlyPath) this.mergedUrl = `${mergedslide}`;
            else
              this.mergedUrl = `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async logComplyMetrics() {
      console.log('PrezentationDetails- logComplyMetrics');
      const s = {
        slides: [],
        outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        outputPath: `private/template-converter/comply/${this.getComplyUploadId}/mergeslides/`,
      };

      const slides = Object.values(this.getSlideImages || {});
      slides.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.pptx?.s3_path?.charAt(0) === '/'
              ? slide?.pptx?.s3_path?.substring(1)
              : slide?.pptx?.s3_path,
          isReplaced: false,
          isUploaded: false,
          isGenerated: false,
          isSynthesis: false,
          isRedesign: false,
          isComply: true,
        });
      });
      let s3url = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          s3url = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      const url = `private/template-converter/comply/${this.getComplyUploadId}/mergeslides/${s3url}`;
      console.log(
        'PrezentationDetails - logMetricsOnSaveToGoogleDrive url ===>',
        url,
      );
      await downloadComplyDeck(this.getComplyUploadId, url);
    },
    async logDownload(currentPrezentation, itemReplaced, fromDrive) {
      console.log('currentPrezentation ==>', currentPrezentation);
      let otherPayload = {};
      if (
        this.isPrezentSourceBestPractice &&
        this.actionCompletedInfo.isTcCompleted
      ) {
        otherPayload = {
          source: 'comply',
          referenceID: this.getComplyUploadId,
        };
      } else if (
        this.isPrezentSourceBestPractice &&
        this.actionCompletedInfo.isReplaceVisualsCompleted
      ) {
        otherPayload = {
          // source: 'comply',
          replacedImage: this.actionCompletedInfo?.replacedImage || [],
          replacedIcon: this.actionCompletedInfo?.replacedIcon || [],
        };
      }
      if (
        currentPrezentation.isDownloaded &&
        currentPrezentation.isDownloaded === true
      ) {
        updatePrezentationAsDownloaded({
          prezentationId: currentPrezentation.id,
          ...otherPayload,
        })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        addPrezentationAsDownloaded({
          prezentationId: currentPrezentation.id,
          downloadCount: 1,
          ...otherPayload,
        })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      AnalyticsHandler.logUserStats(this.currentUser);
      if (fromDrive) {
        const otherData = {
          [TD_ISDOWNLOAD]: 1,
          [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_GS,
          [TD_VIEWTYPE]: this.inListViewMode ? TD_LIST : TD_MOSAIC,
          [TD_PREZENTATION_CATEGORY]: this.prezentationData?.prezentationType,
          [TD_SLIDE_SOURCE]: this.getSlideSource(this.selectedSlide),
        };
        if (this.isNew) {
          MatomoAnalyticsHandler.newPrezentationDownloadedGS(
            currentPrezentation,
            this.currentUser,
          );
          const buildPrezData = getBuildPrezentationData(currentPrezentation);
          if (this.isSaveStoryLine) {
            if (this.origin.toLowerCase() === BUILD) {
              trackBuildEvents.buildAcceptSaveStorylineDownload(
                this.currentUser,
                {
                  ...buildPrezData,
                  ...otherData,
                },
              );
            }
          } else if (this.origin.toLowerCase() === BUILD) {
            trackBuildEvents.buildAcceptStorylineDownload(this.currentUser, {
              ...buildPrezData,
              ...otherData,
            });
          }
        } else {
          otherData[TD_COMMON_COLUMN_NAME] = TD_DOWNLOAD_AS_PDF;
          otherData[TD_COMMON_COLUMN_VALUE] =
            currentPrezentation.permission_level === 0 ? TD_YES : TD_NO;
          otherData[TD_ISDOWNLOAD] = 1;
          otherData[TD_DOWNLOADTYPE] = fromDrive
            ? TD_DOWNLOADTYPE_GS
            : TD_DOWNLOADTYPE_DIRECT;
          trackPrezentationEvents.prezentationsThumbnailClickDownload(
            this.currentUser,
            currentPrezentation,
            otherData,
          );
        }
      }

      AnalyticsHandler.prezentationDownloadComplete(
        this.currentUser,
        this.prezentationData,
      );
      if (this.isNew) {
        MatomoAnalyticsHandler.newPrezentationDownloaded(
          this.prezentationData,
          this.currentUser,
        );
      }
    },
    async getMergedUrl() {
      await this.mergeSlides(true);
      return `${miscConfig.aws_prezentation_distribution}/${this.mergedUrl}`;
    },
    handleRightPanelClose() {
      this.showRightPanel = false;
      this.setIsRightPanelOpen(false);
      this.setSelectedQuickAction({});
      this.setSelectedSlideAction({});
      if (this.selectedMainAction?.id !== 'editSlide') {
        this.setSelectedMainAction({});
      }
      this.prezentationDetailActionsClass = 'right-panel-close';
    },
    async handleUpdatePrezentation(
      prezentationData,
      callback,
      isPartialData = false,
    ) {
      if (this.isPrezentSourceBestPractice) {
        prezentationData = await processPrezentationForThumbnail(
          prezentationData,
          this.currentUser,
        );

        let totalSlidesInPrez = 0;
        this.prezentationData.sections.list.forEach((sections) => {
          sections.slides.forEach((slide) => {
            if (slide.assetId) {
              totalSlidesInPrez++;
            }
          });
        });
        postUpdateActions({
          assetType: 'Deck',
          prezentation_id: prezentationData.id,
          operation: 'Comply',
          level: 'Deck',
          additional_data: {},
          noofslides: totalSlidesInPrez,
        });
        this.setPrezentationData({
          prezentation: prezentationData,
          currentUser: this.currentUser,
          noOriginalUpdate: true,
        });
        this.setLoadingAnimations({
          isLoading: false,
          animationsData: {},
        });
      } else {
        this.savedStatus = 'Saving...';
        const presentation = JSON.parse(JSON.stringify(prezentationData));
        if (!isPartialData) {
          this.setPrezentationData({
            prezentation: prezentationData,
            currentUser: this.currentUser,
          });
        }
        if (prezentationData?.sections?.list?.length) {
          presentation.sections.list = prezentationData?.sections.list.map(
            (section) => {
              section.slides = section.slides
                .map((slide) => ({
                  ...slide,
                  thumbnail: '',
                }))
                .filter((slide) => slide.assetId);
              return section;
            },
          );
        }
        updatePrezentationFunction(presentation)
          .then((resp) => {
            // Added to else to avoid updating slides and loader twice in change audience
            // eslint-disable-next-line no-lonely-if
            if (isPartialData) {
              this.setPrezentationData({
                prezentation: resp.prezentationData,
                currentUser: this.currentUser,
              });
            }

            this.updatePrezentationWithAction({
              prezentation: resp.prezentationData,
              action: 'updated',
              origin: this.origin,
            });

            this.savedStatus = 'Saved';
            setTimeout(() => {
              this.savedStatus = '';
            }, 1000);

            if (callback) callback('success', resp);
          })
          .catch((error) => {
            console.log(error);
            this.savedStatus = '';
            if (callback) callback('error', error);
          });
      }
    },
    setDefaultAudience(prezentation) {
      /**
       * if there is an audienceOwnerID exist for a prezentation
       * then match both id and ownerid of the audience else then pick the audience which is of not a type of shared and private
       */
      let currentAudienceID = '';
      if (prezentation?.audienceOwnerID) {
        currentAudienceID = {
          defaultAudience: `${prezentation?.audienceID}-private_audience`,
        };
      } else {
        currentAudienceID = {
          defaultAudience: `${prezentation?.audienceID}-user:audience:standard`,
        };
      }
      this.handleDefaultAudienceData(currentAudienceID);
    },
    handleDefaultAudienceData(val) {
      const lastDashIndex = val.defaultAudience.lastIndexOf('-');
      const audienceTypes = val.defaultAudience
        .slice(lastDashIndex + 1)
        .split(':');
      const email = val.defaultAudience.slice(0, lastDashIndex);
      const searchAudienceTypes = audienceTypes;
      const audienceTypeFilter =
        this.origin === 'generate'
          ? (searchAudienceTypes &&
              searchAudienceTypes.filter((i) => i !== 'standard')) || [
              'audience',
              'private_audience',
              'user',
            ]
          : searchAudienceTypes;
      const realtimeAudiencesMap = {};
      this.originalRealtimeAudiences.forEach((a) => {
        realtimeAudiencesMap[a.audienceId] = a;
      });
      audienceApi.methods
        .audienceSearch({
          query: email,
          companyId: this.currentUser.user.companyID,
          userId: this.currentUser.user.id,
          realtimeAudiences: realtimeAudiencesMap,
          searchAudienceTypes: audienceTypeFilter,
          filterByCollection: {
            private_audiences: 'hasFingerPrint:=1',
          },
        })
        .then((audiences) => {
          audiences.forEach((audience) => {
            audience.isPublicLimited = !(
              audience.accessible_to.includes('a__0') ||
              audience.accessible_to.includes(
                `c__${this.currentUser.company.num_id}`,
              ) ||
              audience.accessible_to.includes(
                `u__${this.currentUser.user.num_id}`,
              )
            );
          });
          // When default audience is being set programtically.
          if (audiences.length) {
            // eslint-disable-next-line prefer-destructuring
            this.currentAudienceID = audiences[0];
            this.setSelectedAudienceData(this.currentAudienceID);
          }
        });
    },

    handleUpdateSlideInPrezentation(action, slideData, callback) {
      let slideDetails = { ...slideData };
      if (action.type === 'node_change' || action.type === 'layout_change') {
        slideDetails = {
          ...slideData.asset,
          parentID: slideData.parent
            ? slideData.parent.unique_id
            : slideData.unique_id,
          assetId: slideData.landscape,
          category: slideData.categories[0],
          filename: slideData.filename || slideData.title,
          outline: slideData.title || slideData.categories[0],
          thumbnail: slideData.landscapePath,
          uniqueID: slideData.unique_id,
          replacedInfo: slideData.replacedInfo,
          isReplaced: slideData.isReplaced,
          originalAssetID: this.selectedSlide.assetId,
          originalFileName: this.selectedSlide.filename,
          originalUniqueID: this.selectedSlide.uniqueID,
          originalThumbnail: this.selectedSlide.assetId,
        };
        if (action.type === 'node_change') {
          if (this.origin.toLowerCase() === BUILD) {
            const buildData = getBuildPrezentationData(this.prezentationData);
            trackBuildEvents.buildNodeCountChange(
              slideData,
              this.currentUser,
              buildData,
            );
          } else {
            const trackingData = getPrezData(this.prezentationData);
            trackPrezentationEvents.prezentationNodeCountChange(
              slideData,
              this.currentUser,
              trackingData,
            );
          }
        }
      } else if (action.type === 'replace_image') {
        const uniqueID =
          this.selectedSlide.uniqueID || this.selectedSlide.unique_id;
        slideDetails = {
          ...this.selectedSlide,
          uniqueID: `replaced-image/${uniqueID}`,
          assetId: slideData.pdfPath,
          filename: slideData.pptxPath,
          thumbnail: slideData.convertedImage,
          replacedInfo: slideData.replacedInfo,
        };
        if (!this.selectedSlide.isReplaced) {
          slideDetails = {
            ...slideDetails,
            isReplaced: true,
            thumbnail: slideData.convertedImage,
            originalAssetID: this.selectedSlide.assetId,
            originalFileName: this.selectedSlide.filename,
            originalUniqueID: uniqueID,
            originalThumbnail: null,
          };
        }
        const otherData = {
          [TD_IDEA]: slideDetails?.category,
          [TD_CONSTRUCT]:
            slideDetails?.constructs && slideDetails?.constructs[0]
              ? slideDetails?.constructs[0]
              : '',
          [TD_SLIDE_SOURCE]: this.getSlideSource(slideDetails),
          [TD_SLIDE_NAME]: slideDetails?.name,
          [TD_SLIDEID]: this.getSlideId(slideDetails),
          [TD_DECK_ID]: this.getDeckId(slideDetails),
          [TD_HASCARTOON]: slideDetails?.prefs?.has_cartoon === 'yes' ? 1 : 0,
          [TD_HASICONS]: slideDetails?.prefs?.has_icons === 'yes' ? 1 : 0,
          [TD_HASIMAGES]: slideDetails?.prefs?.has_image === 'yes' ? 1 : 0,
          [TD_IS3D]: slideDetails?.prefs?.has_three_d === 'yes' ? 1 : 0,
          [TD_PREZNAME]: this.prezentationData?.name,
          [TD_PREZENTATION_CATEGORY]: this.prezentationData?.prezentationType,
          [TD_PREZTYPE]: this.prezentationData?.type,
          [TD_NODECOUNT]: slideDetails?.prefs?.node,
          [TD_AUD]: this.prezentationData?.audienceID,
          [TD_REPLACE_IMAGE_SOURCE]: this.getReplaceImageSource(
            slideDetails?.replacedInfo?.newImage?.source,
          ),
          [TD_SLIDE_SOURCE]: this.getSlideSource(slideDetails),
        };

        if (this.origin.toLowerCase() === BUILD) {
          trackBuildEvents.buildImagereplacementAccept(
            slideDetails,
            this.currentUser,
            otherData,
          );
        } else {
          trackPrezentationEvents.prezentationsSlidesImageReplacementAccept(
            this.currentUser,
            this.prezentationData,
            otherData,
          );
        }
      } else if (action.type === 'replace_icon') {
        const uniqueID =
          this.selectedSlide.uniqueID || this.selectedSlide.unique_id;
        slideDetails = {
          ...this.selectedSlide,
          uniqueID: `replaced-image/${uniqueID}`,
          assetId: slideData.pdfPath,
          filename: slideData.pptxPath,
          thumbnail: slideData.convertedIcon,
          replacedInfo: slideData.replacedInfo,
          isIconReplaced: true,
        };
        if (!this.selectedSlide.isReplaced) {
          slideDetails = {
            ...slideDetails,
            isIconReplaced: true,
            thumbnail: slideData.convertedIcon,
            originalAssetID: this.selectedSlide.assetId,
            originalFileName: this.selectedSlide.filename,
            originalUniqueID: uniqueID,
            originalThumbnail: null,
          };
        }
        const eventStart = this.eventStart ? this.eventStart : new Date();
        const imageReplaceTrackingData = {
          [TD_IDEA]: slideDetails?.category,
          [TD_CONSTRUCT]:
            slideDetails?.constructs && slideDetails?.constructs[0]
              ? slideDetails?.constructs[0]
              : '',
          [TD_SLIDE_SOURCE]: this.getSlideSource(slideDetails),
          [TD_SLIDE_NAME]: slideDetails?.name,
          [TD_SLIDEID]: this.getSlideId(slideDetails),
          [TD_DECK_ID]: this.getDeckId(slideDetails),
          [TD_HASCARTOON]: slideDetails?.prefs?.has_cartoon === 'yes' ? 1 : 0,
          [TD_HASICONS]: slideDetails?.prefs?.has_icons === 'yes' ? 1 : 0,
          [TD_HASIMAGES]: slideDetails?.prefs?.has_image === 'yes' ? 1 : 0,
          [TD_IS3D]: slideDetails?.prefs?.has_three_d === 'yes' ? 1 : 0,
          [TD_PREZENTATION_CATEGORY]: this.prezentationData?.prezentationType,
          [TD_PREZTYPE]: this.prezentationData?.type,
          [TD_PREZNAME]: this.prezentationData?.name,
          [TD_NODECOUNT]: slideDetails?.prefs?.node,
          [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStart) / 1000,
          [TD_REPLACE_IMAGE_SOURCE]: this.getSourceIcon(
            slideDetails?.replacedInfo?.newImage?.source,
          ),
          [TD_PLACEMENT]:
            this.origin === BUILD ? BUILD : PREZENTATION_PLACEMENT,
        };
        trackIconReplacementEvents.iconReplacementSuccess(
          { ...this.selectedSlide, ...slideData },
          this.currentUser,
          imageReplaceTrackingData,
        );
      } else if (action.type === 'edit_tags') {
        let outline = slideData?.outline;
        let category = slideData?.category;
        if (!outline && !category && slideData.ideas.length) {
          outline = slideData.ideas[0]?.display_name;
          category = slideData.ideas[0]?.category;
        }
        slideDetails = {
          ...slideData,
          name: slideData.name,
          businessIdeas: slideData.ideas.map((c) => c.category),
          nodeCount: slideData.nodeCount,
          template: slideData.template,
          description: slideData.speaker_notes,
          outline,
          category,
          // isUserUploaded: true,
          mlTags: {
            ...slideData.ml_detected_tags,
            node: slideData.nodeCount,
          },
          ml_detected_tags: undefined,
          maxNodeCount: undefined,
          selected: undefined,
          speaker_notes: undefined,
          theme_code: undefined,
          keytype: undefined,
          ideas: undefined,
        };
      }
      this.prezentationData.sections.list[this.selectedSectionIndex].slides[
        this.selectedSlideIndex
      ] = slideDetails;
      if (this.isPrezentSourceBestPractice) {
        this.setPrezentationData({
          prezentation: this.prezentationData,
          currentUser: this.currentUser,
          noOriginalUpdate: true,
        });
      } else {
        this.updatePrezentationData(this.prezentationData);
      }
      this.setSelectedSlideData({
        data: slideDetails,
        sectionIndex: this.selectedSectionIndex,
        slideIndex: this.selectedSlideIndex,
      });
      if (!this.isPrezentSourceBestPractice) {
        this.handleUpdatePrezentation(
          this.prezentationData,
          callback,
          null,
          'Slide',
        );
      } else if (callback) callback('success');
    },
    getDeckId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }

      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (!uniqueID?.includes('deck')) {
        return 'Deck ID not available';
      }
      return uniqueID?.substring(0, uniqueID.indexOf('deck') + 5);
    },
    getSlideId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }
      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (['redesign', 'generated', 'uploaded'].includes(slideData?.source))
        return uniqueID;
      return uniqueID?.split('deck1_')[1]?.split('_')[0];
    },
    getSlideSource(slideData) {
      if (!slideData) {
        return NA;
      }
      if (slideData.source !== undefined) {
        return slideData.source;
      }
      if (slideData?.prefs?.source) {
        return slideData?.prefs?.source;
      }
      return 'Source not available';
    },
    getReplaceImageSource(replaceSource) {
      let source = '';
      switch (replaceSource) {
        case 'adobe':
        case 'freepik':
          source = 'Library';
          break;
        case 'brand-images':
          source = this.currentUser?.company?.displayName;
          break;
        default:
          source = 'Upload';
      }
      return source;
    },
    getSourceIcon(source) {
      switch (source) {
        case 'flat-icons':
          return 'Library';
        case 'brand-icons':
          return this.currentUser.company.displayName;
        default:
          return 'Upload';
      }
    },
    handleRightPanelOpen(isOpen = true) {
      this.showRightPanel = isOpen;
      this.setIsRightPanelOpen(true);
      this.prezentationDetailActionsClass = 'right-panel-open';
    },
    handleEditOutlineClick() {
      if (this.isNew) {
        this.onEditStoryLine(4);
        this.pushTrackingDataToMatomo();
      } else {
        this.pushTrackingDataToMatomo();
        this.$router.push(
          `/home/build?id=${this.prezentationData.id}&origin=prezentation`,
          () => {},
        );
      }
    },
    pushTrackingDataToMatomo() {
      if (this.origin.toLowerCase() === BUILD) {
        const otherData = getBuildPrezentationData(this.prezentationData);
        trackBuildEvents.buildEditStoryLine(this.currentUser, otherData);
      }
      if (this.origin.toLowerCase() === MY_PREZENTATION) {
        const otherData = getBuildPrezentationData(this.prezentationData);
        trackPrezentationEvents.prezentationsEditStoryline(
          this.currentUser,
          otherData,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prezentation-details {
  .loading-container {
    padding: 15%;
    margin: auto;
  }
  .prezLockedTopBanner {
    .templateRemovedAlertBanner {
      background-color: rgb(224 246 254 / 1);
      width: 100%;
      .lockAlertMessage {
        display: flex;
        padding: 16px 16px 8px 16px;
        border-radius: 8px;
        width: 100%;
        .alertText {
          width: 100%;
          text-align: center;
          font-size: 18px;
          i {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
  .prezentation-details-wrapper {
    display: flex;
    width: 100%;

    .prez-detail-middle {
      max-width: 89%;
      padding: 20px;
      margin: 0 auto;
      position: relative;

      .title-row {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        width: 58%;

        .title-wrapper {
          display: flex;
          align-items: center;

          .prez-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .saved-status {
          background-color: #1b9ad01f;
          padding: 5px 15px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .banner-row,
      .bannerRowForBp {
        margin-top: 10px;
      }
      .bannerRowForBp {
        // margin-bottom: 36px;
      }

      .sub-actions-row {
        margin-top: 10px;
      }

      &.full-width {
        width: 100% !important;
      }
      .main-action-container {
        display: flex;
        justify-content: space-between;
        transform: translate(-5px, -5px);
        position: relative;
        z-index: 2;

        .title-wrapper {
          display: flex;
          align-items: center;
        }

        .saved-status {
          background-color: #1b9ad01f;
          padding: 5px 15px;
          border-radius: 20px;
          margin-right: 15px;
          transform: translate(-200px, 5px);
        }
      }
    }
    .prez-detail-right {
      position: relative;
      width: 30%;
      height: calc(100vh - 68px);
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      // border-left: 1px solid lightgray;
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;
      // gap: 20px;

      .smart-tools-icon-panel {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-left: 10px;
      }
    }

    .prez-detail-build {
      position: absolute !important;
      right: 0;
      top: 0;

      // &.banner-active {
      //   // top: 48px;
      // }
    }

    .right-panel-component {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding-left: 10px;

      .right-panel-context {
        height: 100%;
      }
    }
  }
  .error-page-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80vh;
    .error-message {
      color: #000000;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 24px;
    }
    .primary-btn {
      font-family: 'Lato', sans-serif;
      font-size: 1.3vw;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      height: 40px !important;
      line-height: normal;
      letter-spacing: normal;
      min-width: 64px;
      text-transform: none !important;
    }
    .action-container {
      display: flex;
      margin-top: 10px;
    }
    .goToSlidesBtnCntr {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .goToSlidesBtn {
        box-shadow: none;
        font-size: 1.3vw;
        letter-spacing: normal;
        text-transform: none;
        border-radius: 25px;
        color: #ffffff;
        background-color: #21a7e0;
        width: auto !important;
        min-width: 125px;
      }
    }
    .send-assessment {
      background-color: #ffffff;
      border: 1px solid #21a7e0;
      font-weight: bold;
      color: #21a7e0;
      font-size: 1.3vw;
      // margin-top: 10px;
      box-shadow: none;
      letter-spacing: normal;
      text-transform: none;
      border-radius: 25px;
      margin-left: 15px;
      min-width: 125px;
      width: auto !important;
    }
  }
}
.backToSlidesContainer {
  .v-btn {
    border: none;
    text-transform: none;
    background-color: #ffffff;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0px 0px 0px 0px;
    margin-left: -10px;
  }
  .last-modified {
    font-size: 14px;
    float: right;
    color: #828382;
    font-weight: normal;
    line-height: 2.6;
  }
}
.prez-detail-actions-wrapper {
  align-items: center;
  bottom: -14px;
  display: flex;
  justify-content: center;
  height: 82px;
  position: fixed;
  transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;
  pointer-events: none;
  & > * {
    pointer-events: all;
  }

  .action-btn {
    background-color: $white-default;
    color: $zen-blue-default;
    height: 40px;
    font-size: 18px;
    text-transform: none;
    letter-spacing: normal;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  &.menu-expanded {
    width: calc(100% - 280px);
  }
  &.menu-minimized {
    width: 100%;
  }
  &.right-panel-close {
    width: 100%;
  }
  &.right-panel-open {
    width: 70%;
  }
  .revertBtn,
  .disabledRevertBtn {
    background-color: #ffffff !important;
    letter-spacing: normal;
    text-transform: none;
    font-size: 18px;
    border-radius: 20px;
    margin: 8px !important;
    height: 40px;
    margin: 0px 0px 0px 16px;
    border: white;
  }
  .revertBtn {
    color: #21a7e0;
    border: 1px solid #21a7e0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  ::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #ffffff !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.overlay {
  position: fixed;
}
</style>
