<template>
  <div
    v-cloak
    class="slide-detail"
    :style="{ overflow: `${allowContainerOverflow ? 'auto' : 'hidden'}` }"
  >
    <div
      class="slideView"
      :style="
        getSidePanelOpen
          ? { width: 'calc(100% - 550px)', transition: 'all 0.5s ease-in' }
          : { width: '70%', margin: '0 auto', transition: 'all 0.5s ease-in' }
      "
    >
      <div class="backToSlidesContainer">
        <v-btn
          @click="handleBackFromSlideDetailInitialView"
          data-pendo-id="back-btn"
          :style="{
            margin: `0px 0px 0px -${
              getBackButtonText() === 'Back' ? '12' : '8'
            }px`,
          }"
          :disabled="getShowLoadingOverlay"
        >
          <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
          {{ getBackButtonText() }}
        </v-btn>
      </div>
      <div class="content-wrapper">
        <v-row
          :class="
            [
              'userUploadedSlide',
              'userGeneratedSlide',
              'userRedesignSlide',
              'userComplySlide',
              'synthesisSlide',
            ].includes(slideType)
              ? 'mb-2'
              : ''
          "
          no-gutters
          style="flex-wrap: nowrap; width: 100%"
        >
          <v-col cols="12">
            <div v-if="result" class="main-wrapper pa-0">
              <div
                class="header text-h6 text-left font-weight-bold slide-view-header"
              >
                <div class="nameAndLogo">
                  <div class="nameOrIdeaName">
                    <v-tooltip top content-class="tooltip-content">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="slide-name"
                          v-if="
                            slideType === 'prezentSourcedSlide' ||
                            slideType === 'companyBrandedSlide'
                          "
                        >
                          {{ getTitle }}
                        </div>
                        <div v-bind="attrs" v-on="on" class="slide-name" v-else>
                          {{ result.name }}
                        </div>
                      </template>
                      <span
                        v-if="
                          slideType === 'prezentSourcedSlide' ||
                          slideType === 'companyBrandedSlide'
                        "
                      >
                        {{ getTitle }}
                      </span>
                      <span v-else>{{ result.name }}</span>
                    </v-tooltip>
                  </div>
                  <div
                    class="prezentLogo"
                    v-if="slideType === 'prezentSourcedSlide'"
                  >
                    <img
                      src="/assets/img/prezent_avatar.svg"
                      alt
                      width="22px"
                    />
                  </div>
                </div>
                <SlideQuickActions
                  v-if="result"
                  :slideType="slideType"
                  :handleCollection="handleCollection"
                  :processingCollection="processingCollection"
                  :trackCollectionMaxLimitEvent="trackCollectionMaxLimitEvent"
                  :copyLink="copyToClipBoard"
                  :isFav="isFav"
                  :isCollectionLimitReached="isCollectionLimitReached"
                  :isAddedToCollection="isAddedToCollection"
                  :processingFavSlide="processingFavSlide"
                  :handleFavorite="handleFavorite"
                  :result="result"
                  :downloadingSlide="downloadingSlide"
                  :logDownload="logDownload"
                  :handleToggleButton="handleToggleButton"
                  :handleSlideDownload="
                    () => {
                      handleDownload(
                        false,
                        result,
                        true /** this boolean indicates download from expanded view*/,
                      );
                    }
                  "
                  :shareSlide="openShareModal"
                  :showCopied="showCopied"
                  :downloadLocalForReplaceVisuals="
                    downloadLocalForReplaceVisuals
                  "
                  :logDownloadForReplaceVisuals="logDownload"
                  :downloadingSlideInReplaceVisuals="
                    downloadingFromReplaceVisuals
                  "
                  :handleCollectionsForReplaceVisuals="
                    handleCollectionsForReplaceVisuals
                  "
                  :processingCollectionForReplaceVisuals="
                    processingCollectionForReplaceVisuals
                  "
                  :handleCollectionForSlideAnonymize="
                    handleCollectionForSlideAnonymize
                  "
                  :processingCollectionForSlideAnonymize="
                    processingCollectionForSlideAnonymize
                  "
                />
              </div>
              <div class="header text-subtitle-1 text-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="source" v-bind="attrs" v-on="on">
                      {{
                        $t('slideDetails.sourceLabel', {
                          sourceName: capitalizeFirstLetter(
                            getSourceForSlide(),
                          ),
                        })
                      }}
                    </div>
                  </template>
                  <span>
                    {{
                      $t('slideDetails.sourceLabel', {
                        sourceName: capitalizeFirstLetter(getSourceForSlide()),
                      })
                    }}
                  </span>
                </v-tooltip>
                <div class="separator">&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="author"
                      v-if="
                        [
                          'companyBrandedSlide',
                          'userUploadedSlide',
                          'userGeneratedSlide',
                          'userRedesignSlide',
                          'synthesisSlide',
                          'userComplySlide',
                        ].includes(slideType)
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        $t('slideDetails.authorLabel', {
                          authorName: getAuthor(),
                        })
                      }}
                    </div>
                  </template>
                  <span>{{
                    $t('slideDetails.authorLabel', {
                      authorName: getAuthor(),
                    })
                  }}</span>
                </v-tooltip>
                <div
                  v-if="
                    [
                      'companyBrandedSlide',
                      'userUploadedSlide',
                      'userGeneratedSlide',
                      // 'userRedesignSlide',
                      'synthesisSlide',
                      'userComplySlide',
                    ].includes(slideType)
                  "
                  class="separator"
                >
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="description"
                      :style="{
                        // 'max-width': `${getDescriptionWidthBasedOnSlide()}%`,
                      }"
                      v-bind="attrs"
                      v-on="on"
                      v-if="
                        slideType === 'prezentSourcedSlide' ||
                        slideType === 'companyBrandedSlide'
                      "
                    >
                      {{
                        $t('slideDetails.descriptionLabel', {
                          descriptionText:
                            getDescription(getTitle) ||
                            $t('slideDetails.addDescriptionLabel'),
                        })
                      }}
                    </div>
                    <div
                      class="description"
                      :style="{
                        // 'max-width': `${getDescriptionWidthBasedOnSlide()}%`,
                      }"
                      v-bind="attrs"
                      v-on="on"
                      v-else-if="slideType !== 'userRedesignSlide'"
                    >
                      {{ $t('slideDetails.description') }}:
                      <span
                        :class="
                          result.summary && result.summary !== ''
                            ? 'availableDesc'
                            : 'unavailableDesc'
                        "
                        >{{
                          result.summary && result.summary !== ''
                            ? result.summary
                            : $t('slideDetails.addDescription')
                        }}</span
                      >
                    </div>
                  </template>
                  <span
                    v-if="
                      slideType === 'prezentSourcedSlide' ||
                      slideType === 'companyBrandedSlide'
                    "
                  >
                    {{
                      `${
                        getDescription(result.title) ||
                        getDescription(result.asset.title) ||
                        $t('slideDetails.addDescription')
                      }`
                    }}
                  </span>
                  <span v-else-if="slideType !== 'userRedesignSlide'">
                    {{
                      result.summary && result.summary !== ''
                        ? result.summary
                        : $t('slideDetails.addDescription')
                    }}</span
                  >
                </v-tooltip>
                <template v-if="isBPLSlideView && relatedWord.length > 0">
                  <div class="separator">&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                  <v-tooltip bottom max-width="400" fixed>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="description" v-bind="attrs" v-on="on">
                        {{
                          $t('bestPracticesGuide.knowContainer.alsoReleventTo')
                        }}
                        {{ relatedWord }}
                      </div>
                    </template>
                    <span>
                      {{ relatedWord }}
                    </span>
                  </v-tooltip>
                </template>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters style="flex-wrap: nowrap; width: 100%">
          <v-col cols="12" v-if="result">
            <KnowSlideActions
              v-if="isBPLSlideView"
              :slideType="slideType"
              :result="result"
            />
            <SlideActions v-else :slideType="slideType" :result="result" />
          </v-col>
        </v-row>
        <v-row no-gutters style="flex-wrap: nowrap; width: 100%">
          <v-col cols="12">
            <div v-if="result" class="main-wrapper">
              <div
                class="marker-switch"
                v-if="
                  getSidePanelAction === 'convertTemplate' && selectedTemplate
                "
              >
                <v-switch
                  v-model="showMarkers"
                  label="Show markers"
                  hide-details
                  dense
                  :disabled="isSuggestionsLoaded"
                ></v-switch>
              </div>
              <div
                :id="
                  isImageReplaceWalkThrough
                    ? 'walkthough-slide-id'
                    : 'main-slide-id'
                "
                class="image-wrapper"
                ref="slideDetailImage"
              >
                <div
                  class="replacementCont"
                  v-if="
                    getSidePanelAction === 'replaceVisuals' ||
                    (getSidePanelAction === 'convertTemplate' &&
                      prezLoader.isIdle())
                  "
                  :id="
                    getFlowActive === 'replaceImage'
                      ? 'replace-main-image'
                      : 'replace-main-icon'
                  "
                >
                  <LoadingAnimation
                    v-if="getShowLoadingOverlay"
                    :animationImages="getCurrAnimationImages"
                    :customContainerHeight="`${slideThumbnailRef.height}px`"
                  />
                  <v-img
                    v-else
                    :aspect-ratio="16 / 9"
                    :src="getReplaceStackData.convertedImage || landscapeAsset"
                    lazy-src="/assets/img/slides/placeholder-slide.svg"
                    alt="image"
                    contain
                  />
                </div>

                <!-- <div
                  v-if="
                    animationsData.type === 'acceptSuggestionLoader' ||
                    animationsData.type === 'reviewCheckLoader' ||
                    animationsData.type === 'synthesizeVideoLoading'
                  "
                >
                  <v-img
                    alt
                    lazy-src="/assets/img/slides/placeholder-slide.svg"
                    :aspect-ratio="16 / 9"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <clip-loader
                          :color="`#21a7e0`"
                          :width="20"
                          :height="20"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <div
                  class="image illustration-card"
                  v-else-if="animationsData.type === 'complyCheckLoader'"
                >
                  <v-img
                    class="main-image comply-check-loader"
                    src=""
                    alt
                    min-width="300"
                    :aspect-ratio="16 / 9"
                  >
                    <template v-slot:placeholder>
                      <ComplyCheckingAnimationLoader
                        :showBlast="animationsData.showBlast"
                      ></ComplyCheckingAnimationLoader>
                    </template>
                  </v-img>
                </div> -->
                <div v-if="loadingIllustrations" class="current-item-panel">
                  <div class="image illustration-card">
                    <v-img
                      class="main-image"
                      src=""
                      alt
                      min-width="300"
                      :aspect-ratio="16 / 9"
                    >
                      <template v-slot:placeholder>
                        <div
                          v-if="illustrationData"
                          class="illustration-container"
                        >
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <clip-loader
                              :color="`#21a7e0`"
                              :width="20"
                              :height="20"
                            />
                          </v-row>
                          <div
                            class="illustration-text"
                            v-if="illustrationData.text"
                          >
                            {{ illustrationData.text }}
                          </div>
                          <v-img
                            v-if="illustrationData.image"
                            :src="illustrationData.image"
                            alt
                            max-width="250"
                          >
                          </v-img>
                        </div>
                      </template>
                    </v-img>
                  </div>
                </div>
                <div
                  v-else-if="!prezLoader.isIdle()"
                  class="current-item-panel"
                >
                  <div class="image illustration-card">
                    <v-img
                      class="main-image"
                      src=""
                      alt
                      min-width="300"
                      :aspect-ratio="16 / 9"
                    >
                      <template v-slot:placeholder>
                        <VideoLoader
                          v-model="prezLoader"
                          v-if="!prezLoader.isIdle()"
                        />
                      </template>
                    </v-img>
                  </div>
                </div>
                <v-img
                  v-else
                  :aspect-ratio="16 / 9"
                  :src="landscapeAsset"
                  alt="image"
                  contain
                >
                  <div
                    v-if="
                      getSidePanelAction === 'convertTemplate' &&
                      getMarkers.length &&
                      showMarkers
                    "
                  >
                    <div
                      v-for="marker in getMarkers"
                      class="marker"
                      :class="{
                        selected:
                          selectedSuggestion &&
                          selectedSuggestion.index === marker.index &&
                          selectedSuggestion.categoryIndex ===
                            marker.categoryIndex,
                      }"
                      :style="`left: ${marker.x}%; top:${marker.y}%; position: absolute; background-color:${marker.avatarColor}`"
                      :key="`${marker.index}-${marker.avatarColor}`"
                      @click.stop="handleMarkerClick(marker)"
                    >
                      <v-icon color="white" v-if="marker.status === 'accepted'">
                        mdi-check
                      </v-icon>
                      <v-icon
                        color="white"
                        v-else-if="marker.status === 'rejected'"
                      >
                        mdi-close
                      </v-icon>
                      <template v-else>{{ marker.index }}</template>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      getToggleBestPracticesSwitch &&
                      getBestPractisesOnSlide &&
                      getBestPractisesOnSlide.length &&
                      !isSuggestionsLoaded &&
                      !isTcCompleted
                    "
                    class="best-practices-container"
                    :key="pointsKey"
                  >
                    <div v-if="getSidePanelAction === 'editBestPractices'">
                      <span
                        class="coordinate-point"
                        v-for="(point, index) in [...getBestPractisesOnSlide]"
                        :key="index"
                      >
                        <BestPractisesDraggable
                          :slideThumbnailRef="slideThumbnailRef"
                          :draggableLeft="draggableLeft"
                          :bestPractisePoint="point"
                          v-if="point && (result.isOwner || point.isNew)"
                        />
                        <v-tooltip top max-width="400" v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              :style="{
                                width: '28px',
                                height: '28px',
                                color: '#ffffff',
                                'background-color': '#ec9f0b',
                                border: '2px solid #f5cf95',
                                'border-radius': '50%',
                                'text-align': 'center',
                                margin: '0px 4px 0px 0px',
                                cursor: 'pointer',
                                left: `${point.x}%`,
                                top: `${point.y}%`,
                                position: 'absolute',
                              }"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ point.name.replace(/#/g, '') }}
                            </div>
                          </template>
                          <span>{{ point.tooltip }}</span>
                        </v-tooltip>
                      </span>
                    </div>
                    <div v-else>
                      <span
                        class="coordinate-point"
                        v-for="(point, index) in getBestPractisesOnSlide"
                        :style="`left: ${point.x}%; top: ${point.y}%; position: absolute;`"
                        :key="index"
                      >
                        <v-tooltip
                          :left="movingInRightHalf(point)"
                          :right="!movingInRightHalf(point)"
                          max-width="400"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              :src="'/assets/icons/bulb.svg'"
                              width="37.5px"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span>{{ point.tooltip }}</span>
                        </v-tooltip>
                      </span>
                    </div>
                  </div>
                </v-img>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="ctaActionsFooter" :style="{ width: `${ctaFooterWidth}px` }">
          <div class="slide-download">
            <!-- reset to default btn for initial view for prezent and brand slides !-->
            <v-btn
              v-if="
                (slideType === 'prezentSourcedSlide' ||
                  slideType === 'companyBrandedSlide') &&
                getSidePanelAction !== 'replaceVisuals' &&
                getSidePanelAction !== 'anonymizeSlide' &&
                getSidePanelAction !== 'convertTemplate'
              "
              :class="
                disableRevertToOriginal ? 'disabledRevertBtn' : 'revertBtn'
              "
              :disabled="disableRevertToOriginal"
              @click="handleRevertSlide"
              data-pendo-id="revert-to-original-btn"
            >
              {{ $t('slideDetails.revertToOriginal') }}
            </v-btn>

            <!-- reset to default btn exclusively for replacement flows !-->
            <v-btn
              v-if="
                getSidePanelAction === 'replaceVisuals' &&
                getSidePanelAction !== 'anonymizeSlide'
              "
              :class="
                !getReplaceStackData.convertedImage || getShowLoadingOverlay
                  ? 'disabledRevertBtn'
                  : 'revertBtn'
              "
              :disabled="
                !getReplaceStackData.convertedImage || getShowLoadingOverlay
              "
              @click="showRevertConfirmation"
              data-pendo-id="revert-to-original-btn"
            >
              {{ $t('slideDetails.revertToOriginal') }}
            </v-btn>
            <!-- Revert to original action for anonymize slide & template converter in the slide library -->
            <v-btn
              v-if="
                getSidePanelAction === 'anonymizeSlide' ||
                getSidePanelAction === 'convertTemplate'
              "
              :class="
                disablePremiumFeatureRevertBtn
                  ? 'disabledRevertBtn'
                  : 'revertBtn'
              "
              :disabled="disablePremiumFeatureRevertBtn"
              @click="showRevertPremiumFeatureDailog"
              data-pendo-id="revert-to-original-btn"
            >
              {{ $t('slideDetails.revertToOriginal') }}
            </v-btn>

            <!-- download for before replace visual -->
            <Download
              v-if="!getReplaceStackData.convertedImage"
              :file-name="getFilename(result)"
              :item="result"
              :downloading="downloadingSlide"
              :log-download="logDownload"
              :count-first-time="1"
              :on-download="
                () =>
                  handleDownload(
                    false,
                    result,
                    true /** this boolean indicates download from expanded view*/,
                  )
              "
              :speedDialPosRight="'10'"
              :isDisabled="
                getShowLoadingOverlay ||
                downloadingFromReplaceVisuals ||
                loadingIllustrations ||
                animationsData.type === 'complyCheckLoader'
              "
              :origin="getLimitsKey"
              :limitsKey="getLimitsKey"
            />

            <!-- download btn exclusively for replacement flows !-->
            <Download
              v-else-if="getSidePanelAction === 'replaceVisuals'"
              :origin="getLimitsKey"
              :limitsKey="getLimitsKey"
              :file-name="`${uuidv4()}.pptx`"
              :item="result"
              :count-first-time="1"
              :downloading="downloadingFromReplaceVisuals"
              :on-download="downloadLocalForReplaceVisuals"
              :downloadUrl="getReplaceStackData.downloadUrl"
              :log-download="logDownload"
              :downloadType="
                getReplaceStackData.isImageReplacementComplete
                  ? 'SlideReplacement'
                  : ''
              "
              :speedDialPosRight="'10'"
              :downloadIconType="
                getReplaceStackData.isIconReplacementComplete
                  ? 'SlideIconReplacement'
                  : ''
              "
              :isDisabled="
                !getReplaceStackData.convertedImage ||
                getShowLoadingOverlay ||
                downloadingFromReplaceVisuals
              "
            />

            <!-- download btn for initial view for all kinds of slides !-->
            <!--
            <Download
              v-else
              :file-name="getFilename(result)"
              :item="result"
              :downloading="downloadingSlide"
              :log-download="logDownload"
              :count-first-time="1"
              :on-download="
                () =>
                  handleDownload(
                    false,
                    result,
                    true /** this boolean indicates download from expanded view*/,
                  )
              "
              :speedDialPosRight="'10'"
              origin="slide"
              limitsKey="slide"
            />
            !-->
          </div>
        </div>
      </div>
    </div>
    <div
      class="sidePanel"
      :class="{ open: getSidePanelOpen, close: !getSidePanelOpen }"
    >
      <div class="sidePanelComponents">
        <!-- put the side panel components here !-->
        <v-row no-gutters style="width: 100%">
          <v-col cols="12">
            <SlideChangeLayout
              :dialogContent="dialogContent"
              :audience="audience"
              :result="result"
              :handleRelatedProductClick="handleSaveSlideByAction"
              v-if="getSidePanelAction === 'changeLayout'"
              :key="compKeyChangeLayout"
            />
            <SimilarSlides
              :dialogContent="dialogContent"
              :audience="audience"
              :result="result"
              :handleRelatedProductClick="handleSaveSlideByAction"
              v-if="getSidePanelAction === 'similarSlides'"
              :key="compKeySimilarSlides"
            />

            <SlideReplaceVisuals
              v-if="getSidePanelAction === 'replaceVisuals'"
              :slideType="slideType"
              :result="result"
              :origin="matomoOrigin"
              :selectedAudience="audience"
              :key="compKeyReplaceVisuals"
            />

            <EditUserSlideType
              v-if="getSidePanelAction === 'editSlideDetails'"
              ref="editUserSlideType"
              :slideDataForEdit="result"
              :slideType="slideType"
              :permission="result.permission"
              :isOwner="result.isOwner"
              :getDataForSlideUpdate="getDataForSlideUpdate"
              :sidePanelHeader="getSidePanelHeader"
              :sidePanelSubHeader="getSidePanelSubHeader"
              :handleBpPointsKey="handleBpPointsKey"
              :handleSave="handleSaveSlideByAction"
              :saving="editing"
              :editMode="!hideEditSlideDetailsOption"
              :ctaSaveText="ctaSaveText"
              :key="compKeyEditSlideDetails"
            />
            <SlideEditNodeCount
              v-if="getSidePanelAction === 'nodeCountChange'"
              :updateSlide="handleSaveSlideByAction"
              :selectedSlide="result"
              :key="compKeyNodeCount"
            />
            <BestPractises
              v-if="getSidePanelAction === 'editBestPractices'"
              :sidePanelHeader="getSidePanelHeader"
              :sidePanelSubHeader="getSidePanelSubHeader"
              :isOwner="result.isOwner"
              :handleBpPointsKey="handleBpPointsKey"
              :handleSave="handleSaveSlideByAction"
              :saving="editing"
              :ctaSaveText="ctaSaveText"
              :key="compKeyBp"
            />
            <ConvertTemplate
              v-if="getSidePanelAction === 'convertTemplate'"
              :origin="'slides'"
              :showAstridTitle="true"
            />
            <Anonymize
              v-if="getSidePanelAction === 'anonymizeSlide'"
              :origin="'slides'"
              :showAstridTitle="true"
              :getLandscape="getLandscape"
            />
          </v-col>
        </v-row>
      </div>
      <!-- <SlideSmartTools :slideType="slideType" :result="result" /> -->
    </div>
    <ConfirmationDialog
      :show="showResetConfirmDailog"
      :on-primary-click="onRevertVisualsConfirm"
      :on-secondary-click="onRevertVisualsCancel"
      :on-close="onRevertVisualsCancel"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      :title="
        getReplacementViewSelected === 'imagesView'
          ? `${$t('replaceImage.confirmationDialogTitle')}`
          : `${$t('replaceIcon.confirmationDialogTitle')}`
      "
      :text="
        getReplacementViewSelected === 'imagesView'
          ? `${$t('replaceImage.confirmationDialogText')}`
          : `${$t('replaceIcon.confirmationDialogText')}`
      "
    />
    <ConfirmationDialog
      :show="showResetPremiumFeatureDialogue"
      :on-primary-click="handleRevertPremiumFeatureSlide"
      :on-secondary-click="onCancelRevertPremiumFeature"
      :on-close="onCancelRevertPremiumFeature"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      :title="`${$t('build.step3.confirmationResetDialogTitle')}`"
      :text="`${$t('build.step3.confirmationDialogText')}`"
    />
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import EventBus from '../../../common/event-bus';
import DialogService from '../../../../services/DialogService';
import { getTooltipForKeyword } from '@/components/common/KeywordTooltipMapping';
import Download from '../../../common/Download.vue';
import { AnalyticsHandler } from '../../../common/Analytics/AnalyticsHandler';
import { getFileURL } from '@/utils/calyrex';
import {
  getSlideDetail,
  updateSharePermissionsForSlide,
  getCopyDetails,
} from '@/utils/api-helper';
import { MatomoAnalyticsHandler } from '../../../common/Analytics/MatomoAnalyticsHandler';
import utils from '../../../utils';
import usersApi from '../../../../API/users-api';
import {
  NA,
  TD_AUD,
  TD_DIAGRAM,
  TD_IDEA,
  TD_SIMILARSLIDETYPE,
  TD_PLACEMENT,
  TD_ISFAVORITED,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  getAudienceCompanyName,
  TD_EXPANDED_VIEW,
  TD_ISVIEWER,
  TD_ISEDITOR,
  TD_ISRESTRICTED,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_REPLACE_IMAGE_SOURCE,
  SLIDES,
  TD_EDIT_NODE_COUNT,
  TD_REPLACE_VISUALS,
  TD_CHANGE_LAYOUT,
  TD_KNOWBESTPRACTOGGLE,
  getSlideId,
  TD_SLIDEID,
  TD_ISDOWNLOAD,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackSlideEvents } from '../../../common/Analytics/SlideEvents';
import { getThumbnail } from '../../../../store/modules/helper';
import {
  deleteCollection,
  updateCollection,
  updateSlide,
  getBestPractices,
} from '../../../../utils/api-helper';
import EditUserSlideType from './EditUserSlideTypeV2.vue';
// import ShareSlidePermissions from '../ShareSlidePermissions.vue';
import ShareSlidePermissions from '../ShareSlidePermissionsV2.vue';
import BestPractises from '../BestPractisesV2.vue';
import BestPractisesDraggable from '../BestPractisesDraggable.vue';
import { convertPdfToImage } from '../../../../utils/pdf-to-png';
// import { downloadFileWithCustomName } from '../../../../utils/common';

// import SlideSmartTools from './SlideSmartTools.vue';
import SlideQuickActions from './SlideQuickActions.vue';
import SlideActions from './SlideActions.vue';
import SlideChangeLayout from './SlideChangeLayout.vue';
import SlideEditNodeCount from './SlideEditNodeCount.vue';
import SlideReplaceVisuals from './SlideReplaceVisuals.vue';
import SlideViewConfirmationModal from '../SlideViewConfirmationModal.vue';
import LoadingAnimation from '../../../common/LoadingAnimation.vue';
import ConfirmationDialog from '../../../common/ConfirmationDialog.vue';
import SimilarSlides from './SimilarSlides.vue';
import KnowSlideActions from './KnowSlideActions.vue';
import ConvertTemplate from '../../PrezentationDetails/components/ConvertTemplate/ConvertTemplate.vue';
// import ComplyCheckingAnimationLoader from '../../ComplianceChecker/ComplyCheckingAnimationLoader.vue';
import { getTotalSuggestionsInSlide } from '../../../../utils/common';
import Anonymize from '../../PrezentationDetails/components/Anonymize.vue';
import { trackKnowEvents } from '../../../common/Analytics/KnowEvents';
import { Loader, prezMapper } from '../../../../utils/loader-helper';
import VideoLoader from '../../../common/VideoLoader.vue';

export default {
  name: 'SlideDetail',
  inject: {
    isImageReplaceWalkThrough: { default: false },
    query: { default: '' },
  },
  mixins: [DialogService],
  components: {
    Download,
    EditUserSlideType,
    BestPractises,
    BestPractisesDraggable,

    // SlideSmartTools,
    SlideQuickActions,
    SlideActions,
    SlideChangeLayout,
    SlideEditNodeCount,
    SlideReplaceVisuals,
    LoadingAnimation,
    ConfirmationDialog,
    SimilarSlides,
    KnowSlideActions,
    ConvertTemplate,

    // ComplyCheckingAnimationLoader,
    ClipLoader,
    Anonymize,
    VideoLoader,
  },
  watch: {
    loadingAnimations(val) {
      if (val) {
        this.prezLoader = new Loader(prezMapper[this.animationsData.type], 1);
      } else {
        this.prezLoader.nextState(2);
      }
    },
    getSlideViewConfirmationModalState(val) {
      if (val) {
        this.$modal.show(
          SlideViewConfirmationModal,
          {
            onPrimaryClick: this.onConfirmationModalConfirm,
            onSecondaryClick: this.onConfirmationModalCancel,
            primaryBtnText: 'common.yes',
            secondaryBtnText: 'common.no',
            title: this.getConfirmationModalTitle,
            text: this.getConfirmationModalMessage,
          },
          {
            name: 'SlideViewConfirmationModal',
            width: '550px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
          },
          {
            closed: () => {
              this.setSlideViewConfirmationModalState(false);
              this.setRouteToNavigate({
                content: '',
                type: '',
                action: '',
                pushAction: false,
              });
              this.setIsDetailViewInPageAction(false);
              this.setDetailViewInPageAction('');

              /* resetting vuex store flags which identify navigation from download snackbar
      when slide detail flow is dirty and no is clicked on confirmation popup */
              this.setNavFromDownloadSnackbarOP({ navigated: false, type: '' });
              this.setNavFromDownloadSnackbarSlides({
                navigated: false,
                type: '',
              });
              this.setNavFromDownloadSnackbarBPSld({
                navigated: false,
                type: '',
              });
              this.setNavFromDownloadSnackbarBPDck({
                navigated: false,
                type: '',
              });
              this.setNavFromDownloadSnackbarPrezs({
                navigated: false,
                type: '',
              });

              /* resetting vuex store flags which identify navigation from favorite snackbar
      when slide detail flow is dirty and no is clicked on confirmation popup */
              this.setNavFromAddToLibOrFavSnackbar({
                navigated: false,
                type: '',
                origin: '',
              });
            },
          },
        );
      } else {
        this.$modal.hide('SlideViewConfirmationModal');
      }
    },
    async activeSlide(val) {
      this.result = val;
      await this.getLandscape({ ...val });
    },

    async anonymizedData(val) {
      if (Object.keys(this.getAnonymizedData).length !== 0) {
        this.setShowLoadingOverlay(true);
        this.result = val;
        this.setShowLoadingOverlay(false);
      } else {
        this.result = { ...this.getActiveSlide };
        await this.getLandscape({ ...this.getActiveSlide });
      }
    },

    getSidePanelAction(val) {
      if (val === 'convertTemplate') {
        this.showMarkers = true;

        if (Object.keys(this.getAnonymizedData).length !== 0) {
          this.handleRevertPremiumFeatureSlide();
        }
      } else {
        this.showMarkers = false;
      }
    },
    dialogContent() {
      this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
    },
    favoriteSlides() {
      this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
    },
    loadingIllustrations(val) {
      if (val) {
        let timeout = 0;
        this.illustrationList.forEach((illustration, i) => {
          timeout += illustration.timeout || i * 2000;
          setTimeout(() => {
            this.illustrationData = illustration;
          }, timeout);
        });
      } else {
        this.illustrationData = null;
      }
    },
  },
  props: {
    favoriteSlides: {
      type: Array,
    },
    dialogContent: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    audience: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    savedQueryPayload: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    handleFavorite: {
      type: Function,
      required: true,
    },
    handleDownload: {
      type: Function,
      required: true,
    },
    handleUpdateActiveSlide: {
      type: Function,
      required: false,
    },
    logDownload: {
      type: Function,
      required: true,
    },
    from: {
      type: String,
      required: false,
    },
    slideType: {
      type: String,
      default: '',
    },
    processingFavSlide: {
      type: Boolean,
      default: false,
    },
    downloadingSlide: {
      type: Boolean,
      default: false,
    },
    slideViewOrigin: {
      type: String,
      default: '',
    },
    relatedWord: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      landscapeAsset: null,
      isFav: false,
      // downloadingFromReplaceVisuals: false,
      result: {},
      matomoTypeMap: {
        diagram: TD_DIAGRAM,
        idea: TD_IDEA,
      },
      showCopied: false,
      // headingsWidth: 70,
      slideDetailUpdateData: {},
      sharePermissionsUpdateData: {},
      editing: false,
      pointsKey: 0,
      slideThumbnailRef: null,
      draggableLeft: 0,
      processingCollection: false,
      isThemeUpdated: false,
      showResetConfirmDailog: false,
      processingCollectionForReplaceVisuals: false,
      ctaFooterWidth: 0,
      resizeObserver: null,
      compKeyChangeLayout: 0,
      compKeyReplaceVisuals: 0,
      compKeyBp: 0,
      compKeyNodeCount: 0,
      compKeyEditSlideDetails: 0,
      compKeySimilarSlides: 0,
      resizeObserverMainSlideId: null,
      allowContainerOverflow: false,
      ctaSaveText: 'slideDetails.save',
      showMarkers: false,
      illustrationData: null,
      showResetPremiumFeatureDialogue: false,
      processingCollectionForSlideAnonymize: false,
      matomoOrigin: SLIDES,
      nextNav: null,
      prezLoader: new Loader('synthesize'),
    };
  },
  computed: {
    ...mapState('users', [
      'currentTheme',
      'themes',
      'currentUser',
      'startLoadingSlidesFlag',
      'sessionKey',
      'leftSideBarStatus',
    ]),
    ...mapState('slidesStore', [
      'activeSlide',
      'anonymizedData',
      'activeSlideOriginalData',
    ]),
    ...mapState('prezentationDetails', [
      'illustrationList',
      'animationsData',
      'selectedTemplate',
      'complyDetails',
      'selectedSuggestion',
      'actionCompletedInfo',
      'loadingIllustrations',
      'loadingAnimations',
    ]),
    ...mapState('slidesCollection', [
      'tailorID',
      'nodeCountChanged',
      'collections',
    ]),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
    ]),
    ...mapGetters('slidesCollection', [
      'getAddedCollections',
      // 'isCollectionLimitReached',
      'getAllAddedCollections',
    ]),
    ...mapGetters('slidesStore', [
      'getFavoriteSlides',
      'getBestPractisesOnSlide',
      'getActiveSlide',
      'getSlidesSnapshot',
      'getSlideViewDialogContentOnLoad',
      'getIsIconNotFound',
      'getSidePanelAction',
      'getToggleBestPracticesSwitch',
      'getToggleBestPracticesSwitchInitialState',
      'getSidePanelOpen',
      'getFlowActive',
      'getSlideViewConfirmationModalState',
      'getSlideViewOrigin',
      'getRouteToNavigate',
      'getIsSlideACLDirty',
      'getIsSlideDetailsDirty',
      'getIsSlideBestPractisesDirty',
      'getIsReplaceImageActionClicked',
      'getReplaceStackData',
      'getCurrAnimationImages',
      'getShowLoadingOverlay',
      'getReplacementViewSelected',
      'getIsDetailViewInPageAction',
      'getDetailViewInPageAction',
      'getAnonymizedData',
      'getIsSimilarSlidesSelected',
    ]),
    ...mapGetters('replaceImageStore', ['getSearchTerm', 'getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapState('search', ['snapshot']),

    ...mapGetters('commonDownloads', ['getDownloadLoaders']),

    /*
      computed property for determining
      is collection limit is reached for a collection
      belonging to this slide's template
    */
    isCollectionLimitReached() {
      let slidesInCollectionUnderSlideTemplate = [];
      const slideThemeObj = this.getTemplateForSlideLoaded;
      const currentCollection = this.getAllAddedCollections.find(
        (i) => i.templateID === slideThemeObj?.id,
      );
      if (currentCollection) {
        slidesInCollectionUnderSlideTemplate = currentCollection.slides.map(
          (i) => i.slideID,
        );
      } else {
        slidesInCollectionUnderSlideTemplate = [];
      }

      return slidesInCollectionUnderSlideTemplate.length >= 5;
    },

    isTcCompleted() {
      return this.actionCompletedInfo?.isTcCompleted;
    },
    disableRevertToOriginal() {
      return _.isEqual(
        this.dialogContent,
        this.getSlideViewDialogContentOnLoad,
      );
    },
    disablePremiumFeatureRevertBtn() {
      if (this.getSidePanelAction === 'convertTemplate') {
        return (
          !this.actionCompletedInfo?.isTcCompleted ||
          this.loadingIllustrations ||
          this.loadingAnimations
        );
      }

      if (
        Object.keys(this.getAnonymizedData).length === 0 ||
        this.getShowLoadingOverlay ||
        this.loadingIllustrations
      ) {
        return true;
      }

      const tempAnonymizedData = { ...this.getAnonymizedData };
      delete tempAnonymizedData.isAddedToCollection;
      return _.isEqual(this.getActiveSlide, tempAnonymizedData);
    },
    isSuggestionsLoaded() {
      return this.complyDetails?.step === 1;
    },

    getMarkers() {
      return this.getCurrentSlideSuggestions
        ?.map((category) => category.suggestions)
        .flat()
        .filter((i) => i.x && i.y);
    },

    getCurrentSlideSuggestions() {
      if (!this.complyDetails?.slide_images) {
        return [];
      }

      const slide = this.complyDetails.slide_images[0];

      return getTotalSuggestionsInSlide(slide);
    },

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },

    /*
      computed property for determining
      if the this slide belongs to a collection
      based on slide's template
    */
    isAddedToCollection() {
      let slidesInCollectionUnderSlideTemplate = [];
      const slideThemeObj = this.getTemplateForSlideLoaded;
      const currentCollection = this.getAddedCollections.find(
        (i) => i.templateID === slideThemeObj?.id,
      );
      if (currentCollection) {
        slidesInCollectionUnderSlideTemplate = currentCollection.slides
          .filter(
            (i) =>
              !i.isImageReplaced &&
              !i.isIconReplaced &&
              !['partial', 'full'].includes(
                i.sanitizedLevel && i.sanitizedLevel.toLowerCase(),
              ),
          )
          .map((i) => i.slideID);
      } else {
        slidesInCollectionUnderSlideTemplate = [];
      }

      if (this.actionCompletedInfo?.isTcCompleted) {
        return slidesInCollectionUnderSlideTemplate.includes(
          this.getActiveSlide.unique_id_tc,
        );
      }
      return slidesInCollectionUnderSlideTemplate.includes(
        this.result.unique_id,
      );
    },

    getTitle() {
      return this.result.title || this.result.asset?.title || '';
    },

    getLimitsKey() {
      return this.isBPLSlideView ? 'bpe-slide' : 'slide';
    },

    getSidePanelHeader() {
      let msg = '';
      if (this.getSidePanelAction === 'editSlideDetails') {
        msg = this.hideEditSlideDetailsOption
          ? this.$t('slideDetails.slideDetails')
          : this.$t('slideDetails.editSlideDetailsHeader');
      } else if (this.getSidePanelAction === 'editBestPractices') {
        msg = this.$t('slideDetails.bestPractices');
      }
      return msg;
    },

    getSidePanelSubHeader() {
      let msg = '';
      if (this.getSidePanelAction === 'editSlideDetails') {
        msg = this.hideEditSlideDetailsOption
          ? this.$t('slideDetails.slideDetailsSubtextForViewer')
          : this.$t('slideDetails.slideDetailsSubtext');
      } else if (this.getSidePanelAction === 'editBestPractices') {
        msg = this.$t('slideDetails.addUptoFiveBestPractices');
      }
      return msg;
    },

    getConfirmationModalMessage() {
      if (this.getSidePanelAction === 'convertTemplate') {
        return this.$t('build.step3.confirmationDialogText');
      }
      if (this.getFlowActive === 'editSlideDetails') {
        return this.$t('slides.changesToSlideDetailsWillBeLost');
      }
      if (this.getFlowActive === 'bestPractises') {
        return this.$t('slides.changesToBestPracticesWillBeLost');
      }
      if (this.getFlowActive === 'shareACL') {
        return this.$t('slides.updatedShareSettingsWillBeDiscarded');
      }
      if (this.getFlowActive === 'replaceImage') {
        return this.$t('build.step3.confirmationDialogText');
      }
      if (this.getFlowActive === 'anonymizeSlide') {
        return this.$t('build.step3.confirmationDialogText');
      }
      return this.$t('build.step3.confirmationDialogTextIcon');
    },

    getConfirmationModalTitle() {
      if (this.getSidePanelAction === 'convertTemplate') {
        return this.$t('slides.exitConvertTemplateSlideDetails');
      }
      if (this.getFlowActive === 'editSlideDetails') {
        return this.$t('slides.exitEditSlideDetails');
      }
      if (this.getFlowActive === 'bestPractises') {
        return this.$t('slides.exitEditBestPractices');
      }
      if (this.getFlowActive === 'shareACL') {
        return this.$t('slides.unsavedShareSettings');
      }
      if (this.getFlowActive === 'replaceImage') {
        return this.$t('build.step3.confirmationDialogTitle');
      }
      if (this.getFlowActive === 'anonymizeSlide') {
        return this.$t('anonymize.confirmExitAnonymizeTitle');
      }
      return this.$t('build.step3.confirmationDialogTitleIcon');
    },

    hideEditSlideDetailsOption() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    downloadingFromReplaceVisuals() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.$route?.params?.id,
      );
      return ldr && ldr.downloading;
    },

    /*
      Computed property to find template object
      based on current slide associated template code
    */
    getTemplateForSlideLoaded() {
      const template = this.themes.find(
        (thm) => thm.code === this.getTemplateCodeFromSlideLoaded,
      );
      return template;
    },

    /*
      Computed property to return the slide object related theme code
      based on type of slide
    */
    getTemplateCodeFromSlideLoaded() {
      if (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide'
      ) {
        return (
          this.getActiveSlide?.theme || this.getActiveSlide?.prefs?.theme || ''
        );
      }
      return (
        this.getActiveSlide?.theme || this.getActiveSlide?.templateCode || ''
      );
    },
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'updateActionCompletedInfo',
      'resetComplyData',
    ]),
    uuidv4,
    ...mapActions('slidesStore', [
      'setActiveSlide',
      'setFlowActive',
      'saveSlidesStateSnapshot',
      'setIsSlideBestPractisesDirty',
      'setIsSlideDetailsDirty',
      'setSlideDetailViewChangedAudienceAndTemplate',
      'setSlideViewDialogContentOnLoad',
      'setSidePanelAction',
      'setToggleBestPracticesSwitch',
      'setSidePanelOpen',
      'setSlideViewConfirmationModalState',
      'setRouteToNavigate',
      'setReplaceStackData',
      'setSlideBackClicked',
      'setReplaceImageActionClicked',
      'setShowLoadingOverlay',
      'setReplacementViewSelected',
      'setIsDetailViewInPageAction',
      'setDetailViewInPageAction',
      'setBestPractisesOnSlide',
      'setAnonymizedData',
      'setIsSimilarSlidesSelected',
    ]),
    ...mapActions('slidesCollection', [
      'setCollectionData',
      'setNodeCountChange',
    ]),
    ...mapActions('users', ['setCurrentUser', 'setCurrentTheme']),
    ...mapActions('replaceImageStore', ['reset']),
    ...mapActions('replaceIconStore', ['resetIcon']),

    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      'setNavFromDownloadSnackbarOP',
      'setNavFromDownloadSnackbarSlides',
      'setNavFromDownloadSnackbarBPSld',
      'setNavFromDownloadSnackbarBPDck',
      'setNavFromDownloadSnackbarPrezs',
      'setNavFromAddToLibOrFavSnackbar',
    ]),
    ...mapActions('prezentationDetails', ['setSelectedSuggestion']),
    handleMarkerClick(marker) {
      this.setSelectedSuggestion({
        index: marker.index,
        categoryIndex: marker.categoryIndex,
        category: marker.category,
      });
    },

    async handleCollectionsForReplaceVisuals() {
      if (
        this.isCollectionLimitReached &&
        !this.getReplaceStackData.isAddedToCollection
      )
        return;

      if (this.getReplaceStackData.isAddedToCollection) {
        const params = {
          type: 'slide',
          slideID: String(this.result.unique_id),
          templateID: this.getTemplateForSlideLoaded?.id,
          slideFileName: this.getReplaceStackData.savedSlidePath,
        };
        this.processingCollectionForReplaceVisuals = true;
        await deleteCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            this.setReplaceStackData({
              ...this.getReplaceStackData,
              isAddedToCollection: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollectionForReplaceVisuals = false;
      } else {
        const params = {
          templateID: this.getTemplateForSlideLoaded?.id,
          templateCode: this.getTemplateCodeFromSlideLoaded,
          templateName: this.getTemplateForSlideLoaded?.name,
          slideFileName: this.getReplaceStackData.savedSlidePath,
          slideThumbnail: this.getReplaceStackData.savedImagePath,
          slideID: String(this.result.unique_id),
          idea: this.result.title,
          tailorID: this.tailorID || this.currentUser.user.id,
          construct: this.result.prefs.construct,
          isImageReplaced:
            !!this.getReplaceStackData.isImageReplacementComplete,
          isIconReplaced: !!this.getReplaceStackData.isIconReplacementComplete,
          source: this.result.prefs.source,
          sanitizedLevel: this.result.sanitizedLevel,
          acceptedRejectedCount: this.result.acceptedRejectedCount,
        };
        this.processingCollectionForReplaceVisuals = true;
        await updateCollection(params)
          .then((resp) => {
            this.setCollectionData(resp.data.collections);
            this.setReplaceStackData({
              ...this.getReplaceStackData,
              isAddedToCollection: true,
            });
            trackSlideEvents.slidesImageReplacementAddToCollection(
              this.result,
              this.currentUser,
              {
                [TD_AUD]: this.tailorID || this.currentUser.user.id,
                [TD_ISFAVORITED]: this.getFavoriteSlides.includes(
                  this.getActiveSlide?.unique_id,
                )
                  ? '1'
                  : NA,
                [TD_REPLACE_IMAGE_SOURCE]: this.getSearchTerm?.source,
                [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                  this.audience,
                  this.currentUser,
                ),
              },
            );
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollectionForReplaceVisuals = false;
      }
    },

    onRevertVisualsCancel() {
      this.showResetConfirmDailog = false;
    },

    onRevertVisualsConfirm() {
      const action = TD_REPLACE_VISUALS;
      trackSlideEvents.slideRevertToOriginal(this.result, this.currentUser, {
        [TD_PLACEMENT]: action,
      });
      this.setReplaceStackData({});
      this.reset(true);
      this.resetIcon(true);
      this.showResetConfirmDailog = false;
      this.compKeyReplaceVisuals++;
    },

    showRevertConfirmation() {
      this.showResetConfirmDailog = true;
    },

    showRevertPremiumFeatureDailog() {
      this.showResetPremiumFeatureDialogue = true;
    },

    onCancelRevertPremiumFeature() {
      this.showResetPremiumFeatureDialogue = false;
    },

    async handleRevertPremiumFeatureSlide() {
      this.setAnonymizedData({});
      this.result = { ...this.getActiveSlide };
      this.setIsSlideDetailsDirty(false);
      if (this.actionCompletedInfo?.isTcCompleted) {
        this.setActiveSlide(this.activeSlideOriginalData);
        this.updateActionCompletedInfo({});
        this.resetComplyData();
        EventBus.$emit('TC_RESET_COMPLY_DATA');
      }
      this.showResetPremiumFeatureDialogue = false;
    },

    getSlideBasedTemplateForDownload(code) {
      const template = this.themes.find((thm) => thm.code === code);
      if (template) {
        return template.name;
      }
      return code;
    },

    async downloadLocalForReplaceVisuals() {
      let fileName = '';
      if (
        (this.result.prefs.source === 'generated' ||
          this.result.prefs.source === 'synthesis' ||
          this.result.prefs.source === 'redesign' ||
          this.result.prefs.source === 'uploaded' ||
          this.result.prefs.source === 'comply') &&
        this.result.name
      ) {
        fileName = this.result.name.replace(/.pptx/g, '');
      } else if (this.result.asset) {
        fileName = `${this.capitalizeFirstLetter(
          this.result.asset.categories[0],
        )} - ${this.getSlideBasedTemplateForDownload(
          this.result.asset.prefs.theme,
        )}`;
      } else {
        fileName = `${this.capitalizeFirstLetter(
          this.result.categories[0],
        )} - ${this.getSlideBasedTemplateForDownload(this.result.prefs.theme)}`;
      }

      // downloading slides with common download snackbar
      this.setShowDownloadSnackbar(true);
      this.setDownloadLoaders({
        loaderId: this.$route?.params?.id,
        downloading: true,
      });
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'slideLibDetailReplaceVisuals',
        logDownloadCallback: async () => {
          await this.logDownload(
            this.result,
            !!this.getReplaceStackData.isImageReplacementComplete,
            false,
            !!this.getReplaceStackData.isIconReplacementComplete,
          );
        },
        downloadStatusCallback: (payload) => {
          this.setDownloadLoaders({
            loaderId: payload.slideId,
            downloading: false,
          });
        },
        metaData: {
          item: { ...this.result },
          filePath: this.getReplaceStackData.downloadUrl,
          fileName,
          limitsKey: this.getLimitsKey,
          origin: this.getLimitsKey,
          downloadStatusCallbackPayload: {
            slideId: this.$route?.params?.id,
          },
        },
      });

      // this.downloadingFromReplaceVisuals = true;

      // await downloadFileWithCustomName(
      //   this.getReplaceStackData.downloadUrl,
      //   fileName,
      // );
      // await this.logDownload(
      //   this.result,
      //   !!this.getReplaceStackData.isImageReplacementComplete,
      //   false,
      //   !!this.getReplaceStackData.isIconReplacementComplete,
      // );
      // this.downloadingFromReplaceVisuals = false;
    },
    leaveComponent(nextNav) {
      this.nextNav = nextNav;
      this.setSlideViewConfirmationModalState(true);
    },

    handleBackFromSlideDetailInitialView() {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        /* setting slide origin page to next route to be navigated on
         click of back button in slide detail initial view */
        const origin = this.getSlideViewOrigin || 'Slides';
        if (origin === 'hybrid_search') {
          this.setRouteToNavigate({
            content: `/home/search?s=${this.snapshot?.Query}`,
            type: 'to_search_page',
            action: 'push',
            pushAction: false,
          });
        } else if (this.isBPLSlideView) {
          this.setRouteToNavigate({
            content: `/home/best-practice-library`,
            type: 'to_bpl_page',
            action: 'push',
            pushAction: false,
          });
        } else {
          this.setRouteToNavigate({
            content: `/home/slides`,
            type: 'to_slides_page',
            action: 'push',
            pushAction: false,
          });
        }
        this.setSlideViewConfirmationModalState(true);
      } else {
        const origin = this.getSlideViewOrigin;
        if (!this.isThemeUpdated) {
          this.setSlideBackClicked(true);
        }
        if (
          this.isBPLSlideView &&
          this.getSlideViewOrigin !== 'hybrid_search'
        ) {
          this.$router.push(`/home/best-practice-library`).catch(() => {});
        } else if (
          origin === 'Slides' ||
          this.getSlideViewOrigin === 'slidesListPage'
        ) {
          this.$router.push('/home/slides').catch(() => {});
          EventBus.$emit('CLEAR_SEARCH', '');
        } else if (this.getSlideViewOrigin === 'hybrid_search') {
          EventBus.$emit('CLEAR_SEARCH_COUNT');
          this.$router
            .push(`/home/search?s=${this.snapshot?.Query}`)
            .catch(() => {});
        } else {
          this.$router.push(`/home/slides`).catch(() => {});
        }
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
      }
      trackSlideEvents.slidesBackFromDetailedView(
        this.getActiveSlide,
        this.currentUser,
        {
          [TD_ISFAVORITED]: this.getFavoriteSlides.includes(
            this.getActiveSlide?.unique_id,
          )
            ? '1'
            : NA,
        },
      );
    },

    onConfirmationModalCancel() {
      this.setSlideViewConfirmationModalState(false);
      this.nextNav = null;
      this.setRouteToNavigate({
        content: '',
        type: '',
        action: '',
        pushAction: false,
      });
      this.setIsDetailViewInPageAction(false);
      this.setDetailViewInPageAction('');

      /* resetting vuex store flags which identify navigation from download snackbar
      when slide detail flow is dirty and no is clicked on confirmation popup */
      this.setNavFromDownloadSnackbarOP({ navigated: false, type: '' });
      this.setNavFromDownloadSnackbarSlides({ navigated: false, type: '' });
      this.setNavFromDownloadSnackbarBPSld({ navigated: false, type: '' });
      this.setNavFromDownloadSnackbarBPDck({ navigated: false, type: '' });
      this.setNavFromDownloadSnackbarPrezs({ navigated: false, type: '' });

      /* resetting vuex store flags which identify navigation from favorite snackbar
      when slide detail flow is dirty and no is clicked on confirmation popup */
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: false,
        type: '',
        origin: '',
      });

      if (this.nextNav) this.nextNav(false);
      this.nextNav = null;
    },

    onConfirmationModalConfirm() {
      // hide help menu if open
      this.$modal.hide('HelpMenu');

      if (this.getIsDetailViewInPageAction) {
        // resetting cached replace data flow on back to slide click
        this.setReplaceStackData({});
        this.setAnonymizedData({ ...this.getActiveSlide });

        // resetting replacement flows dirty flags
        this.reset(true);
        this.resetIcon(true);
        this.setReplacementViewSelected('');

        // resetting other dirty flags if any
        this.setIsSlideDetailsDirty(false);
        this.setIsSlideBestPractisesDirty(false);
        this.setReplaceImageActionClicked(false);

        this.setSidePanelAction(this.getDetailViewInPageAction);
        this.setIsDetailViewInPageAction(false);
        this.setDetailViewInPageAction('');

        this.setSlideViewConfirmationModalState(false);
      } else {
        // resetting cached replace data flow on back to slide click
        this.setReplaceStackData({});

        this.setSidePanelAction('');
        this.setFlowActive('');

        // resetting replacement flows dirty flags
        this.reset(true);
        this.resetIcon(true);
        this.setReplacementViewSelected('');

        // resetting other dirty flags if any
        this.setIsSlideDetailsDirty(false);
        this.setIsSlideBestPractisesDirty(false);
        this.setReplaceImageActionClicked(false);

        this.setSlideViewConfirmationModalState(false);

        this.$modal.hide('SlideViewConfirmationModal');

        // resetting slides list landing page snapshot if any if origin is not slides landing page
        if (this.getSlideViewOrigin !== 'slidesListPage') {
          this.saveSlidesStateSnapshot({});
        }

        // setting some hybrid search falgs and events
        const origin = this.getSlideViewOrigin || 'Slides';
        if (!this.isThemeUpdated) {
          this.setSlideBackClicked(true);
        }
        if (origin === 'hybrid_search') {
          EventBus.$emit('CLEAR_SEARCH_COUNT');
        }

        if (
          this.getRouteToNavigate &&
          this.getRouteToNavigate.content &&
          this.getRouteToNavigate.content !== ''
        ) {
          if (this.getRouteToNavigate.action === 'push') {
            if (this.getRouteToNavigate.pushAction === true) {
              this.$router
                .push(this.getRouteToNavigate.content, () => this.$router.go(0))
                .catch(() => {});
            } else {
              this.$router
                .push(this.getRouteToNavigate.content)
                .catch(() => {});
            }
          } else if (this.getRouteToNavigate.action === 'replace') {
            this.$router
              .replace(this.getRouteToNavigate.content)
              .catch(() => {});
          }
        }
      }
      if (this.actionCompletedInfo?.isTcCompleted) {
        this.setActiveSlide(this.activeSlideOriginalData);
        this.updateActionCompletedInfo({});
        this.resetComplyData();
      }
      if (this.nextNav) this.nextNav();
      this.nextNav = null;
    },

    getBackButtonText() {
      if (this.slideViewOrigin === 'slidesListPage') {
        return this.$t('slideDetails.back');
      }
      if (this.slideViewOrigin === 'hybrid_search') {
        return this.$t('slideDetails.backToSearch');
      }
      if (this.isBPLSlideView) {
        return this.$t('common.back');
      }
      return this.$t('slideDetails.backToSlides');
    },

    isFeatureEnabled(key) {
      return this.currentUser?.features?.find((f) => f.feature_name === key)
        ?.enabled;
    },

    handleToggleButton() {
      if (this.$route.name === 'know.detail') {
        trackKnowEvents.knowBestPractice(this.result, this.currentUser, {
          [TD_KNOWBESTPRACTOGGLE]: this.getToggleBestPracticesSwitch
            ? 'ON'
            : 'OFF',
          [TD_PLACEMENT]: 'Slides',
        });
      } else {
        trackSlideEvents.slidesBestPracticeAddedToggleClick(
          this.result,
          this.currentUser,
          {
            [TD_ISFAVORITED]: this.favoriteSlides.includes(
              this.result?.unique_id,
            )
              ? '1'
              : NA,
            [TD_COMMON_COLUMN_NAME]: 'toggle changed to',
            [TD_COMMON_COLUMN_VALUE]: this.getToggleBestPracticesSwitch
              ? 'On'
              : 'Off',
          },
        );
      }
    },

    // getDescriptionWidthBasedOnSlide() {
    //   if (this.slideType === 'prezentSourcedSlide') {
    //     return 83;
    //   }
    //   if (this.slideType === 'companyBrandedSlide') {
    //     return 60;
    //   }
    //   return this.getActiveSlide && this.getActiveSlide.isOwner ? 60 : 54;
    // },

    getSourceForSlide() {
      if (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide'
      ) {
        return this.result.prefs.source;
      }
      return this.result?.companies[0].toLowerCase().trim();
    },

    handleBpPointsKey() {
      this.pointsKey++;
    },

    findSlideValuesChanged() {
      let valuesChanged = '';
      valuesChanged +=
        this.result?.name !== this.$refs.editUserSlideType?.slideName
          ? 'Name&'
          : 'NA&';
      valuesChanged +=
        this.result?.summary !== this.$refs.editUserSlideType?.description
          ? 'Description&'
          : 'NA&';
      valuesChanged +=
        this.result?.templateCode !==
        this.$refs.editUserSlideType?.template?.code
          ? 'Template&'
          : 'NA&';
      valuesChanged +=
        this.result?.audience !== this.$refs.editUserSlideType?.audience?.email
          ? 'Audience&'
          : 'NA&';
      valuesChanged +=
        this.result?.prefs?.node !== this.$refs.editUserSlideType?.nodeCount
          ? 'Node-count&'
          : 'NA&';
      valuesChanged +=
        JSON.stringify(this.slideDetailUpdateData?.categories) !==
        JSON.stringify(this.$refs.editUserSlideType?.businessIdeasInitial)
          ? 'Idea&'
          : 'NA&';
      valuesChanged +=
        JSON.stringify(this.slideDetailUpdateData?.keywords) !==
        JSON.stringify(this.$refs.editUserSlideType?.keywordsInitial)
          ? 'Keywords'
          : 'NA';
      return valuesChanged;
    },

    getDataForSlideUpdate(val) {
      this.slideDetailUpdateData = {
        assetID: this.result.unique_id,
        name: val.slideName,
        summary: val.description,
        categories: val.businessIdeas,
        keywords: val.keywords,
        templateCode: val.template,
        audience: val.audience,
        audienceType: val.audienceType,
        audienceNumId: val.audienceNumId,
        node: val.nodeCount,
      };
    },

    getDataForSharePermissionsUpdate(val) {
      this.sharePermissionsUpdateData = { ...val };
    },

    handleUpdateCurrentThemeForUser() {
      usersApi.methods
        .updateUserProfile({
          defaultTheme: this.slideDetailUpdateData.templateCode,
        })
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.isThemeUpdated = true;
          this.setCurrentUser(this.currentUser);
          this.setCurrentTheme(
            this.themes.find(
              (thm) => thm.code === this.slideDetailUpdateData.templateCode,
            ),
          );
        })
        .catch((err) =>
          console.log('error while updating theme for current user', err),
        );
    },

    openShareModal() {
      this.$modal.show(
        ShareSlidePermissions,
        {
          template: this.currentTheme,
          type: 'modal',
          updateSlide: this.updateSlideWithStatus,
          slideOpened: this.result,
          getDataForSharePermissionsUpdate:
            this.getDataForSharePermissionsUpdate,
          updateWithParentCallback: true,
          handleShareAccept: this.handleUpdateWithShareIcon,
        },
        {
          name: 'ShareSlidePermissions',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: false,
        },
      );
    },

    async handleUpdateWithShareIcon() {
      let updatePayload = {};
      const teamEntity = [];
      const companyEntity = [];
      let usersEntities = [];
      if (this.sharePermissionsUpdateData.shareOptionType === 'me') {
        updatePayload = {
          sharedEntities: [],
        };
      } else if (this.sharePermissionsUpdateData.shareOptionType === 'share') {
        if (this.sharePermissionsUpdateData.teamCheckPermission === 'team') {
          teamEntity.push({
            sharedEntityID: this.currentUser.team.id,
            sharedEntityType: 'team',
            permissionLevel: this.sharePermissionsUpdateData.teamPermission,
          });
        }
        if (
          this.sharePermissionsUpdateData.companyCheckPermission === 'company'
        ) {
          companyEntity.push({
            sharedEntityID: this.currentUser.company.id,
            sharedEntityType: 'company',
            permissionLevel: this.sharePermissionsUpdateData.companyPermission,
          });
        }
        if (this.sharePermissionsUpdateData.userCheckPermission === 'user') {
          usersEntities = [
            ...this.sharePermissionsUpdateData.usersWithPermissions.map(
              (usr) => ({
                sharedEntityID: usr.id,
                permissionLevel: usr.permissionLevel,
                sharedEntityType: 'user',
              }),
            ),
          ];
        }
        updatePayload = {
          sharedEntities: [...usersEntities, ...teamEntity, ...companyEntity],
        };
      }
      const sharedEntitiesData = this.getSharedEntitiesData(
        updatePayload?.sharedEntities,
      );
      trackSlideEvents.slidesShareUpdate(this.result, this.currentUser, {
        [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
          ? '1'
          : NA,
        [TD_PLACEMENT]: TD_EXPANDED_VIEW,
        ...sharedEntitiesData,
      });
      await updateSharePermissionsForSlide({
        ...updatePayload,
        slideID: this.result.unique_id,
        type: this.result.prefs.source,
      })
        .then(async () => {
          // updating is shared Property;
          await updateSlide({
            assetID: this.result.unique_id,
            isShared: !(updatePayload.sharedEntities.length <= 0),
          })
            .then((r) => {
              console.log(r);
            })
            .catch((e) => console.log(e));
          // update the slide in the snapshot
          if (
            this.getSlidesSnapshot &&
            Object.keys(this.getSlidesSnapshot).length > 0
          ) {
            const slidesListFromSnapShotUpdated =
              this.getSlidesSnapshot.slidesFilteredChipAdvanced.map((sld) => {
                if (sld.unique_id === this.result.unique_id) {
                  return {
                    ...sld,
                    isShared: !(updatePayload.sharedEntities.length <= 0),
                  };
                }
                return { ...sld };
              });
            this.saveSlidesStateSnapshot({
              ...this.getSlidesSnapshot,
              slidesFilteredChipAdvanced: slidesListFromSnapShotUpdated,
            });
          }
          this.handleUpdateActiveSlide({
            asset: {
              ...this.result,
              isShared: !(updatePayload.sharedEntities.length <= 0),
            },
          });
          this.result = {
            ...this.result,
            isShared: !(updatePayload.sharedEntities.length <= 0),
          };
          this.sharePermissionsUpdateData = {};
        })
        .catch((err) => console.log(err));
    },

    async handleSaveSlideByAction(params = {}) {
      this.editing = true;
      this.setShowLoadingOverlay(true);
      if (this.getSidePanelAction === 'editSlideDetails') {
        await updateSlide(this.slideDetailUpdateData)
          .then(() => {
            this.ctaSaveText = 'slideDetails.saved';
            setTimeout(() => {
              trackSlideEvents.slidesEditSlideDetailsUpdated(
                this.result,
                this.currentUser,
                {
                  [TD_ISFAVORITED]: this.favoriteSlides.includes(
                    this.result?.unique_id,
                  )
                    ? '1'
                    : NA,
                  [TD_COMMON_COLUMN_NAME]: 'Fields changed',
                  [TD_COMMON_COLUMN_VALUE]: this.findSlideValuesChanged(),
                },
              );
              if (
                this.getSlidesSnapshot &&
                Object.keys(this.getSlidesSnapshot).length > 0
              ) {
                if (
                  this.slideDetailUpdateData.templateCode !==
                  this.result.templateCode
                ) {
                  this.handleUpdateCurrentThemeForUser();
                }
                /* update the current theme, audience and flags
                in the snapshot that these two properties were edited. */
                // update the slide in the snapshot
                const slidesListFromSnapShotUpdated =
                  this.getSlidesSnapshot.slidesFilteredChipAdvanced.map(
                    (sld) => {
                      if (sld.unique_id === this.result.unique_id) {
                        return {
                          ...sld,
                          prefs: {
                            ...this.result.prefs,
                            node: this.slideDetailUpdateData.node,
                          },
                          name: this.slideDetailUpdateData.name,
                          summary: this.slideDetailUpdateData.summary,
                          categories: this.slideDetailUpdateData.categories,
                          keywords: this.slideDetailUpdateData.keywords,
                          templateCode: this.slideDetailUpdateData.templateCode,
                          audience: this.slideDetailUpdateData.audience,
                          audienceType: this.slideDetailUpdateData.audienceType,
                          audienceNumId:
                            this.slideDetailUpdateData.audienceNumId,
                        };
                      }
                      return { ...sld };
                    },
                  );
                this.saveSlidesStateSnapshot({
                  ...this.getSlidesSnapshot,
                  slidesFilteredChipAdvanced: slidesListFromSnapShotUpdated,
                  isAudienceChangedWhileEdit:
                    this.slideDetailUpdateData.audience !==
                    this.result.audience,
                  isTemplateChangedWhileEdit:
                    this.slideDetailUpdateData.templateCode !==
                    this.result.templateCode,
                  filterTailor: this.getSlidesSnapshot.filterTailor,
                  templateSelected:
                    this.slideDetailUpdateData.templateCode !==
                    this.result.templateCode
                      ? this.themes.find(
                          (thm) =>
                            thm.code ===
                            this.slideDetailUpdateData.templateCode,
                        )
                      : this.getSlidesSnapshot.templateSelected,
                });
              } else if (
                this.slideDetailUpdateData.templateCode !==
                  this.result.templateCode ||
                this.slideDetailUpdateData.audience !== this.result.audience
              ) {
                if (
                  this.slideDetailUpdateData.templateCode !==
                  this.result.templateCode
                ) {
                  this.handleUpdateCurrentThemeForUser();
                }
                this.setSlideDetailViewChangedAudienceAndTemplate({
                  templateToApply:
                    this.slideDetailUpdateData.templateCode !==
                    this.result.templateCode
                      ? this.themes.find(
                          (thm) =>
                            thm.code ===
                            this.slideDetailUpdateData.templateCode,
                        )
                      : null,
                  audienceToApply: this.slideDetailUpdateData.audience,
                });
              }
              this.handleUpdateActiveSlide({
                asset: {
                  ...this.result,
                  prefs: {
                    ...this.result.prefs,
                    node: this.slideDetailUpdateData.node,
                  },
                  name: this.slideDetailUpdateData.name,
                  summary: this.slideDetailUpdateData.summary,
                  categories: this.slideDetailUpdateData.categories,
                  keywords: this.slideDetailUpdateData.keywords,
                  templateCode: this.slideDetailUpdateData.templateCode,
                  audience: this.slideDetailUpdateData.audience,
                  audienceType: this.slideDetailUpdateData.audienceType,
                  audienceNumId: this.slideDetailUpdateData.audienceNumId,
                },
              });
              this.result = {
                ...this.result,
                prefs: {
                  ...this.result.prefs,
                  node: this.slideDetailUpdateData.node,
                },
                name: this.slideDetailUpdateData.name,
                summary: this.slideDetailUpdateData.summary,
                categories: this.slideDetailUpdateData.categories,
                keywords: this.slideDetailUpdateData.keywords,
                templateCode: this.slideDetailUpdateData.templateCode,
                audience: this.slideDetailUpdateData.audience,
                audienceType: this.slideDetailUpdateData.audienceType,
                audienceNumId: this.slideDetailUpdateData.audienceNumId,
              };
              this.slideDetailUpdateData = {};

              this.compKeyEditSlideDetails++;
              this.setShowLoadingOverlay(false);

              this.ctaSaveText = 'slideDetails.save';
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
        this.editing = false;
      } else if (this.getSidePanelAction === 'editBestPractices') {
        await updateSlide({
          assetID: this.result.unique_id,
          bestPractices: this.getBestPractisesOnSlide,
        })
          .then(() => {
            this.ctaSaveText = 'slideDetails.saved';
            setTimeout(() => {
              if (
                this.getSlidesSnapshot &&
                Object.keys(this.getSlidesSnapshot).length > 0
              ) {
                const slidesListFromSnapShotUpdated =
                  this.getSlidesSnapshot.slidesFilteredChipAdvanced.map(
                    (sld) => {
                      if (sld.unique_id === this.result.unique_id) {
                        return {
                          ...sld,
                          bestPractices: this.getBestPractisesOnSlide.map(
                            (item) => ({
                              ...item,
                              isAdded: true,
                              isNew: false,
                            }),
                          ),
                        };
                      }
                      return { ...sld };
                    },
                  );
                this.saveSlidesStateSnapshot({
                  ...this.getSlidesSnapshot,
                  slidesFilteredChipAdvanced: slidesListFromSnapShotUpdated,
                });
              }
              this.handleUpdateActiveSlide({
                asset: {
                  ...this.result,
                  bestPractices: this.getBestPractisesOnSlide.map((item) => ({
                    ...item,
                    isAdded: true,
                    isNew: false,
                  })),
                },
              });
              this.result = {
                ...this.result,
                bestPractices: this.getBestPractisesOnSlide.map((item) => ({
                  ...item,
                  isAdded: true,
                  isNew: false,
                })),
              };
              this.setToggleBestPracticesSwitch(
                this.getToggleBestPracticesSwitchInitialState,
              );
              this.compKeyBp++;
              this.setShowLoadingOverlay(false);

              this.ctaSaveText = 'slideDetails.save';
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
        this.editing = false;
      } else if (this.getSidePanelAction === 'nodeCountChange') {
        console.log('updating node count', params);

        this.result = { ...params };
        if (this.isBPLSlideView) {
          this.getBestPracticesCordinates(this.result);
          trackKnowEvents.knowSlidesNodeCountChanged(
            this.result,
            this.currentUser,
            {
              [TD_ISDOWNLOAD]: 0,
            },
          );
        }
        if (this.handleUpdateActiveSlide) {
          this.isFav = this.favoriteSlides.includes(
            this.dialogContent.unique_id,
          );
          this.handleUpdateActiveSlide({ asset: { ...params } });
          this.setNodeCountChange(true);
        }

        await this.getLandscape({ ...params });

        // this.setSidePanelAction('changeLayout');
        // this.compKeyNodeCount++;

        this.setShowLoadingOverlay(false);
      } else if (
        this.getSidePanelAction === 'changeLayout' ||
        this.getSidePanelAction === 'similarSlides'
      ) {
        await this.handleRelatedProductClick(params.product);
        // this.compKeyChangeLayout++;
        this.setShowLoadingOverlay(false);
      }
      this.setIsSlideBestPractisesDirty(false);
      this.setIsSlideDetailsDirty(false);
    },

    getBestPracticesCordinates(slide) {
      const slideData = slide;
      const formattedSlideID = this.formatNumberAs4Digits(slideData.prefs?.sid);
      getBestPractices(
        formattedSlideID,
        slideData && slideData.categories ? slideData.categories[0] : '',
      )
        .then((res) => {
          console.log(res);
          if (res.status === 'success') {
            const bestPracticeCoordinates = res.data.tips || [];
            this.setBestPractisesOnSlide(
              res.data.tips
                ? res.data.tips.map((item) => ({
                    ...item,
                    isAdded: true,
                    isNew: false,
                  }))
                : [],
            );
            if (bestPracticeCoordinates.length) {
              this.setToggleBestPracticesSwitch(true);
            }
          }
        })
        .catch((err) => console.log(err));
    },

    formatNumberAs4Digits(number) {
      if (number) {
        const numberString = number.toString();
        const formattedNumber = numberString.padStart(4, '0');
        return formattedNumber;
      }
      return 0;
    },

    getAuthor() {
      let authorName = '';
      if (this.slideType === 'companyBrandedSlide') {
        authorName = 'Brand Team';
      } else {
        authorName =
          this.getActiveSlide && this.getActiveSlide.isOwner
            ? 'Me'
            : this.getActiveSlide.ownerName;
      }
      return authorName;
    },

    copyToClipBoard() {
      if (this.isBPLSlideView) {
        this.showCopied = true;
        navigator.clipboard.writeText(
          `${window.location.origin}/home/best-practice-library/${this.result?.unique_id}`,
        );
        trackKnowEvents.knowCopyLink(this.result, this.currentUser, {
          [TD_ISDOWNLOAD]: 0,
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result.unique_id)
            ? '1'
            : NA,
          [TD_PLACEMENT]: TD_EXPANDED_VIEW,
          [TD_SLIDEID]: getSlideId(this.result),
        });
        setTimeout(() => {
          this.showCopied = false;
        }, 1000);
      } else if (
        ['prezentSourcedSlide', 'companyBrandedSlide'].includes(this.slideType)
      ) {
        this.showCopied = true;
        navigator.clipboard.writeText(
          `${window.location.origin}/home/slides/${this.result.unique_id}`,
        );
        trackSlideEvents.slidesElipsisCopyLink(this.result, this.currentUser, {
          [TD_PLACEMENT]: TD_EXPANDED_VIEW,
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
            ? '1'
            : NA,
        });
        setTimeout(() => {
          this.showCopied = false;
        }, 1000);
      } else {
        this.showCopied = true;
        navigator.clipboard.writeText(
          `${window.location.origin}/home/slides/${this.result.id}`,
        );
        trackSlideEvents.slidesElipsisCopyLink(this.result, this.currentUser, {
          [TD_PLACEMENT]: TD_EXPANDED_VIEW,
          [TD_ISFAVORITED]: this.favoriteSlides.includes(this.result?.unique_id)
            ? '1'
            : NA,
        });
        setTimeout(() => {
          this.showCopied = false;
        }, 1000);
      }
    },

    getSharedEntitiesData(sharedEntities) {
      const sharedEntitiesTrackingData = {};
      if (!sharedEntities.length) {
        return sharedEntitiesTrackingData;
      }

      const viewers = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Viewer',
      );
      const isViewerList = this.getEntityList(viewers);
      if (isViewerList) {
        sharedEntitiesTrackingData[TD_ISVIEWER] = isViewerList;
      }
      const editors = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Editor',
      );
      const isEditorsList = this.getEntityList(editors);
      if (isEditorsList) {
        sharedEntitiesTrackingData[TD_ISEDITOR] = isEditorsList;
      }
      const restricted = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permissionLevel === 'Restricted',
      );
      const isRestrictedList = this.getEntityList(restricted);
      if (isRestrictedList) {
        sharedEntitiesTrackingData[TD_ISRESTRICTED] = isRestrictedList;
      }

      return sharedEntitiesTrackingData;
    },

    getEntityList(entities) {
      let eList = '';
      entities.forEach((sEntity) => {
        const entityType = sEntity.sharedEntityType;
        console.log('entityType', entityType);
        if (entityType === 'company') {
          eList += 'My Company$';
        }
        if (entityType === 'team') {
          eList += 'My Team$';
        }
        if (entityType === 'user') {
          eList += `${sEntity.shared_entity_id}$`;
        }
      });
      return eList;
    },

    capitalizeFirstLetter(str) {
      return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
    },

    async handleRevertSlide() {
      this.handleUpdateActiveSlide({
        asset: this.getSlideViewDialogContentOnLoad,
      });
      await this.getLandscape(this.getSlideViewDialogContentOnLoad);
      this.result = { ...this.getSlideViewDialogContentOnLoad };
      if (this.isBPLSlideView) {
        this.getBestPracticesCordinates(this.result);
      }
      this.setSlideViewDialogContentOnLoad({
        ...this.getSlideViewDialogContentOnLoad,
      });

      this.updateViewCount();
      this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);

      let action = NA;
      if (this.getSidePanelAction === 'changeLayout') {
        action = TD_CHANGE_LAYOUT;
      } else if (this.getSidePanelAction === 'nodeCountChange') {
        action = TD_EDIT_NODE_COUNT;
      } else if (this.getSidePanelAction === 'replaceVisuals') {
        action = TD_REPLACE_VISUALS;
      }
      trackSlideEvents.slideRevertToOriginal(this.result, this.currentUser, {
        [TD_PLACEMENT]: action,
      });

      // this.setSidePanelAction('changeLayout');
      // this.compKeyChangeLayout++;
      this.compKeyNodeCount++;
    },

    trackCollectionMaxLimitEvent() {
      if (this.isCollectionLimitReached && !this.isAddedToCollection) {
        trackSlideEvents.slidesCollectionMaxLimit(
          this.result,
          this.currentUser,
          {
            [TD_AUD]: this.tailorID || this.currentUser?.user?.id,
            [TD_ISFAVORITED]: this.favoriteSlides.includes(
              this.result.unique_id,
            )
              ? '1'
              : NA,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.audience,
              this.currentUser,
            ),
          },
        );
      }
    },

    async handleCollection() {
      if (this.isCollectionLimitReached && !this.isAddedToCollection) return;

      if (this.isAddedToCollection) {
        let slideID = String(this.result.unique_id);
        if (this.actionCompletedInfo?.isTcCompleted) {
          slideID = this.result.unique_id_tc;
        }

        const params = {
          type: 'slide',
          slideID,
          templateID: this.getTemplateForSlideLoaded?.id,
          slideFileName: String(this.result.file || this.result.filename),
        };
        this.processingCollection = true;
        await deleteCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            // this.collections[indexOfSet].slides.splice(indexOfSlide, 1);
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
        trackSlideEvents.slidesRemoveFromCollection(
          this.result,
          this.currentUser,
          {
            [TD_ISFAVORITED]: this.favoriteSlides.includes(
              this.result.unique_id,
            )
              ? '1'
              : NA,
            [TD_AUD]: this.getAudienceDataToTrack()?.email,
            [TD_PLACEMENT]: TD_EXPANDED_VIEW,
            [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
              this.audience,
              this.currentUser,
            ),
          },
        );
      } else {
        this.processingCollection = true;
        let slideThumbnail = this.result.thumbnail;
        let slideID = String(this.result.unique_id);
        if (this.actionCompletedInfo?.isTcCompleted) {
          slideThumbnail = this.result.poster;
          slideID = this.result.unique_id_tc;
        }

        let slideFilePath;
        // Checking if the current slide is template converted. If converted then execute copy asset.
        if (this.actionCompletedInfo?.isTcCompleted) {
          const copyAssetData = [
            {
              source: this.result.file || this.result.filename,
              target: '/protected/premium-feature-slides/tc/',
            },
          ];
          const copyAssetResult = await this.handleCopyAsset(copyAssetData);
          slideFilePath = copyAssetResult[0].source;
        } else {
          slideFilePath = this.result.file || this.result.filename;
        }
        const params = {
          templateID: this.getTemplateForSlideLoaded?.id,
          templateCode: this.getTemplateCodeFromSlideLoaded,
          templateName: this.getTemplateForSlideLoaded?.name,
          slideFileName: slideFilePath,
          slideThumbnail,
          slideID,
          idea: this.result.title,
          tailorID: this.tailorID || this.currentUser.user.id,
          construct: this.result.prefs.construct,
          isImageReplaced: false,
          isIconReplaced: false,
          source: this.result.prefs.source,
          sanitizedLevel: this.result.sanitizedLevel,
          acceptedRejectedCount: this.result.acceptedRejectedCount,
        };

        await updateCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollection = false;
        if (this.nodeCountChanged) {
          trackSlideEvents.slidesNodeCountChangeAddToCollection(
            this.result,
            this.currentUser,
            {
              [TD_ISFAVORITED]: this.favoriteSlides.includes(
                this.result.unique_id,
              )
                ? '1'
                : NA,
              [TD_AUD]: this.getAudienceDataToTrack()?.email,
              [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                this.audience,
                this.currentUser,
              ),
            },
          );
        } else {
          trackSlideEvents.slidesAddToCollection(
            this.result,
            this.currentUser,
            {
              [TD_ISFAVORITED]: this.favoriteSlides.includes(
                this.result.unique_id,
              )
                ? '1'
                : NA,
              [TD_AUD]: this.getAudienceDataToTrack()?.email,
              [TD_PLACEMENT]: TD_EXPANDED_VIEW,
              [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                this.audience,
                this.currentUser,
              ),
            },
          );
        }
      }
    },

    // Add to collection for slide anonymize
    async handleCollectionForSlideAnonymize() {
      if (
        this.isCollectionLimitReached &&
        !this.getAnonymizedData.isAddedToCollection
      )
        return;
      if (this.getAnonymizedData.isAddedToCollection) {
        const params = {
          type: 'slide',
          slideID: String(this.result.unique_id),
          templateID: this.getTemplateForSlideLoaded?.id,
          slideFileName: this.anonymizedData.thumbnail,
        };
        this.processingCollectionForSlideAnonymize = true;
        await deleteCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            this.setAnonymizedData({
              ...this.getAnonymizedData,
              isAddedToCollection: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollectionForSlideAnonymize = false;
      } else {
        this.processingCollectionForSlideAnonymize = true;
        const copyAssetData = [
          {
            source: this.anonymizedData.thumbnail,
            target: '/protected/premium-feature-slides/anonymize/',
          },
        ];
        const copyAssetResult = await this.handleCopyAsset(copyAssetData);

        const params = {
          templateID: this.getTemplateForSlideLoaded?.id,
          templateCode: this.getTemplateCodeFromSlideLoaded,
          templateName: this.getTemplateForSlideLoaded?.name,
          slideFileName: copyAssetResult[0].source,
          slideThumbnail: this.anonymizedData.thumbnail,
          slideID: String(this.result.unique_id),
          idea: this.result.title,
          tailorID: this.tailorID || this.currentUser.user.id,
          construct: this.result.prefs.construct,
          isImageReplaced: false,
          isIconReplaced: false,
          source: this.result.prefs.source,
          sanitizedLevel: this.result.sanitizedLevel,
          acceptedRejectedCount: this.result.acceptedRejectedCount,
        };
        await updateCollection(params)
          .then((resp) => {
            console.log(resp);
            this.setCollectionData(resp.data.collections);
            this.setAnonymizedData({
              ...this.getAnonymizedData,
              isAddedToCollection: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
        this.processingCollectionForSlideAnonymize = false;
      }
    },

    // Copying the thumbnail from temp folder for anonymize & tc
    async handleCopyAsset(slideData) {
      let response;
      const slidesList = [];
      slideData.forEach((slide) => {
        slidesList.push({
          source: slide.source,
          target: slide.target,
        });
      });
      await getCopyDetails({
        slides: slideData,
      })
        .then((resp) => {
          response = resp.data;
        })
        .catch((err) => {
          console.log(err);
          this.processingCollectionForSlideAnonymize = false;
        });
      return response;
    },

    updateViewCount() {
      if (this.isBPLSlideView) return;
      const userData = {
        performIncrements: {
          countSlideView: 1,
        },
      };

      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(userInfo);
        })
        .catch((err) => console.log(err));
      AnalyticsHandler.logUserStats(this.currentUser);
    },

    async getLandscape(asset) {
      if (!asset.landscape.startsWith('https')) {
        if (asset.prefs.source === 'uploaded') {
          const uploadedLandscape = await getFileURL(
            this.currentUser.user.cognitoID,
            asset.landscape,
            this.currentUser.userIp,
            'IOCUpload',
          );
          this.landscapeAsset = await convertPdfToImage(uploadedLandscape);
        } else if (
          asset.prefs.source === 'generated' ||
          asset.prefs.source === 'synthesis' ||
          asset.prefs.source === 'redesign' ||
          asset.prefs.source === 'comply'
        ) {
          if (
            asset.landscape.startsWith('/newImageReplaceOutput/output/') ||
            asset.landscape.startsWith('/output/pptx/')
          ) {
            this.landscapeAsset = await getThumbnail(asset.landscape);
          } else {
            const newThumbnail = await getFileURL(
              this.currentUser.user.cognitoID,
              asset.landscape,
              this.currentUser.userIp,
              'IOCUpload',
            );

            if (asset.isAnonymized) {
              this.landscapeAsset = await convertPdfToImage(newThumbnail);
            } else {
              this.landscapeAsset = newThumbnail;
            }
          }
        } else {
          this.landscapeAsset = await getFileURL(
            this.currentUser.user.cognitoID,
            asset.landscape,
            this.currentUser.userIp,
          );
        }
      } else {
        this.landscapeAsset = asset.landscape;
      }
    },

    getFilename(item) {
      if (!item) return '';
      if (item.filename) {
        return item.filename.toString();
      }
      if (item.asset) {
        return item.asset.filename.toString();
      }
      return '';
    },

    getDescription(keyword) {
      return getTooltipForKeyword(keyword);
    },

    async handleRelatedProductClick(product) {
      const arr = [];
      arr.push(product.asset.unique_id);
      const params = {
        assetIds: arr,
        getFavourite: true,
      };
      await getSlideDetail(params)
        .then((slideData) => {
          const slideMetaData = slideData.data;
          for (const key in slideMetaData) {
            if (
              slideMetaData[key].isFavourite === true &&
              !this.favoriteSlides.includes(key)
            ) {
              this.favoriteSlides.push(key);
            }
          }
        })
        .catch((err) => console.log(err));
      if (this.handleUpdateActiveSlide) {
        this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
        if (this.isBPLSlideView) {
          this.getBestPracticesCordinates(product.asset);
        }
        this.handleUpdateActiveSlide(product);
      }
      this.result = {
        ...product.asset,
      };
      await this.getLandscape(product);

      this.updateViewCount();
      EventBus.$emit('SET_SIMILAR_SLIDE_SELECTED', true);
      if (this.isBPLSlideView) {
        trackKnowEvents.knowSimilarSlides(product.asset, this.currentUser, {
          [TD_SIMILARSLIDETYPE]: this.matomoTypeMap[this.type],
          [TD_ISDOWNLOAD]: 0,
        });
        this.setIsSimilarSlidesSelected(true);
      } else {
        trackSlideEvents.slidesSimilar(product.asset, this.currentUser, {
          [TD_SIMILARSLIDETYPE]: this.matomoTypeMap[this.type],
        });
      }
    },

    getAudienceDataToTrack() {
      try {
        return {
          email:
            this.audience?.id ||
            this.audience?.user?.id ||
            this.currentUser?.user?.id,
        };
      } catch (e) {
        return {
          email: '',
        };
      }
    },

    getHeadingWidths() {
      if (window.innerWidth <= 1020) {
        // this.headingsWidth = 43;
        this.draggableLeft = 47;
      } else if (window.innerWidth > 1020 && window.innerWidth <= 1110) {
        // this.headingsWidth = 46;
        this.draggableLeft = 44;
      } else if (window.innerWidth > 1110 && window.innerWidth <= 1200) {
        // this.headingsWidth = 49;
        this.draggableLeft = 40;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        // this.headingsWidth = 54;
        this.draggableLeft = 40;
      } else if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
        // this.headingsWidth = 57;
        this.draggableLeft = 37;
      } else if (window.innerWidth > 1400 && window.innerWidth <= 1440) {
        // this.headingsWidth = 60;
        this.draggableLeft = 33;
      } else if (window.innerWidth > 1440 && window.innerWidth <= 1700) {
        // this.headingsWidth = 63;
        this.draggableLeft = 30;
      } else if (window.innerWidth > 1700 && window.innerWidth <= 1900) {
        // this.headingsWidth = 69;
        this.draggableLeft = 26;
      } else {
        // this.headingsWidth = 72;
        this.draggableLeft = 26;
      }
    },

    movingInRightHalf(point) {
      const pointX = Number(point.x);
      const pointY = Number(point.y);
      if (pointX <= 50 && pointY <= 50) {
        return false;
      }
      if (pointX > 50 && pointY <= 50) {
        return true;
      }
      if (pointX <= 50 && pointY > 50) {
        return false;
      }
      return true;
    },

    getInitialPanelComponent() {
      if (this.getIsReplaceImageActionClicked) {
        this.setSidePanelAction('replaceVisuals');
        return;
      }
      if (this.isBPLSlideView) {
        this.setSidePanelAction('similarSlides');
      } else if (
        ['prezentSourcedSlide', 'companyBrandedSlide'].includes(this.slideType)
      ) {
        this.setSidePanelAction('changeLayout');
      } else {
        this.setSidePanelAction('editSlideDetails');
      }
    },
  },

  async beforeMount() {
    this.result = {
      ...this.dialogContent,
    };
    await this.getLandscape(this.result);
    this.setSlideViewDialogContentOnLoad(
      this.getSlideViewDialogContentOnLoad &&
        Object.keys(this.getSlideViewDialogContentOnLoad).length > 0
        ? { ...this.getSlideViewDialogContentOnLoad }
        : { ...this.dialogContent },
    );
  },

  async mounted() {
    this.getInitialPanelComponent();

    this.setSidePanelOpen(true);

    this.allowContainerOverflow = true;

    // setTimeout(() => {
    //   this.setSidePanelOpen(true);
    //   setTimeout(() => {
    //     this.allowContainerOverflow = true;
    //   }, 500);
    // }, 1000);

    const { slideTitle, slideId } = utils.getSlideTitleAndID(this.result);

    MatomoAnalyticsHandler.slidesViewed(slideTitle, slideId, this.currentUser, {
      query: this.savedQueryPayload.query,
      audience: this.getAudienceDataToTrack(),
      from: this.from,
    });

    this.isFav = this.favoriteSlides.includes(this.dialogContent.unique_id);
    this.getHeadingWidths();

    window.addEventListener('resize', this.getHeadingWidths);

    // adding another resize event listener for calculating width of page CTA containers
    const element = document.querySelector('.content-wrapper'); // Select the element

    // Create a new ResizeObserver
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        this.ctaFooterWidth = width;
      }
    });

    // Observe the selected element
    this.resizeObserver.observe(element);

    // adding another resize event listener for calculating width of best practices draggables work area
    const elementSlideMainId = document.querySelector('.image-wrapper'); // Select the element

    this.resizeObserverMainSlideId = new ResizeObserver(() => {
      this.slideThumbnailRef =
        this.$refs.slideDetailImage.getBoundingClientRect();
    });

    // Observe the selected element
    this.resizeObserverMainSlideId.observe(elementSlideMainId);
  },
  destroyed() {
    window.removeEventListener('resize', this.getHeadingWidths);

    this.resizeObserver.disconnect();
    this.resizeObserverMainSlideId.disconnect();

    this.setSidePanelAction('');
    this.setSidePanelOpen(false);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeadingWidths);

    this.resizeObserver.disconnect();
    this.resizeObserverMainSlideId.disconnect();

    this.setSidePanelAction('');
    this.setSidePanelOpen(false);
    this.updateActionCompletedInfo({});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.slide-detail {
  background: white;
  display: flex;
  height: 100%;
  width: 100%;
  // overflow: hidden;

  .slideView {
    position: relative;
    padding: 40px 32px;
    .backToSlidesContainer {
      margin: 0px 0px 14px 0px !important;
      .v-btn {
        border: none;
        text-transform: none;
        background-color: #ffffff;
        box-shadow: none;
        letter-spacing: normal;
        color: #21a7e0;
        padding: 0;
        background-color: #ffffff !important;
        height: 24px !important;
        &:before {
          background-color: #ffffff !important;
        }
      }
      .walkthrough-back {
        margin-left: 1%;
      }
    }
    .content-wrapper {
      height: 100%;
      position: relative;
      width: 100%;

      .main-wrapper {
        align-items: flex-start;
        display: inline-block;
        flex-direction: column;
        font-family: 'Lato';
        justify-content: flex-start;
        padding: 5px 3px 5px 3px;
        width: 100%;

        .marker-switch {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          ::v-deep .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;
          }
          ::v-deep .v-input--selection-controls__input + .v-label {
            color: #000000;
            font-size: 14px;
            top: -2px;
          }
        }

        .marker {
          display: flex;
          align-items: center;
          justify-content: center;
          // transform: translate(-50%, -50%);
          transform-origin: center center;
          border-radius: 50%;
          height: 24px;
          width: 24px;
          color: white;
          cursor: pointer;
          transition: all 0.2s ease-in;
          box-sizing: content-box;

          &.selected {
            z-index: 2;
            height: 26px;
            width: 26px;
            border: 3px solid currentColor;
            border-color: color-mix(in srgb, currentColor 30%, transparent);
            transition: all 0.2s ease-in;
            box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
              1px 1px 4px 0px rgba(0, 0, 0, 0.1);
          }

          &:hover {
            border: 3px solid currentColor;
            border-color: color-mix(in srgb, currentColor 30%, transparent);
            transition: all 0.2s ease-in;
            box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
              1px 1px 4px 0px rgba(0, 0, 0, 0.1);
          }
        }

        .slide-view-header {
          display: flex;
          justify-content: space-between;
          .nameAndLogo {
            display: flex;
            flex: 1;
            min-width: 0;
            .nameOrIdeaName {
              max-width: calc(100% - 46px);
              display: flex;
              .slide-name {
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
            .prezentLogo {
              img {
                margin: 0px 0px 2px 4px;
              }
            }
          }
        }

        .header {
          font-family: 'Lato' !important;
          display: flex;
          overflow-wrap: anywhere;
          height: 31px;

          .source,
          .author {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .source {
            max-width: 17%;
          }
          .author {
            max-width: 23%;
          }
          .description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .unavailableDesc {
              color: grey;
            }
          }
          .separator {
            min-width: 16px;
          }
        }
        .image-wrapper {
          border-radius: 8px;
          box-shadow: $item-box-shadow;
          overflow: hidden;
          position: relative;
          // max-height: 32rem;
          aspect-ratio: 16/9;

          .v-responsive {
            object-fit: contain;
          }

          img {
            height: 100%;
            width: 100%;
          }

          .already-download {
            position: absolute;
            right: 12px;
            top: 12px;
          }
        }
      }

      .ctaActionsFooter {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 16px;
        bottom: 40px;
        display: flex;
        justify-content: center;
        position: fixed;
        // transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;
        z-index: 31;
        .slide-download {
          display: flex;
          .revertBtn,
          .disabledRevertBtn {
            background-color: #ffffff !important;
            letter-spacing: normal;
            text-transform: none;
            font-size: 18px;
            border-radius: 20px;
            margin: 8px !important;
            height: 40px;
            margin: 0px 0px 0px 16px;
            border: 1px solid #b6b6b6;
          }
          .revertBtn {
            color: #21a7e0;
            border: 1px solid #21a7e0;
            // box-shadow: none;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }
          .disabledRevertBtn {
            // box-shadow: 0 4px 4px -2px #b6b6b6;
            color: #b6b6b6;
            box-shadow: 0 4px 4px -2px #b6b6b6 !important;
          }
          ::v-deep .download-actions {
            .save-to-gdrive-disabled {
              .v-btn {
                margin: 8px !important;
                border: none !important;
                letter-spacing: normal;
                text-transform: none;
                font-size: 18px;
                font-weight: 500;
              }
              .v-btn--disabled {
                background-color: #ffffff !important;
                color: #b6b6b6 !important;
                border: 1px solid #b6b6b6 !important;
                box-shadow: 0 4px 4px -2px #b6b6b6 !important;
              }
            }
            .downloadPreferences {
              .downloadEleWithPrefs {
                .v-btn {
                  margin: 8px !important;
                  border: none !important;
                  letter-spacing: normal;
                  text-transform: none;
                  font-size: 18px;
                  font-weight: 500;
                }
                .v-btn--disabled {
                  background-color: #ffffff !important;
                  color: #b6b6b6 !important;
                  border: 1px solid #b6b6b6 !important;
                  box-shadow: 0 4px 4px -2px #b6b6b6 !important;
                }
              }
              .alwaysAskPref {
                .new-slide-detail-speed {
                  .v-btn {
                    margin: 8px !important;
                    border: none !important;
                    letter-spacing: normal;
                    text-transform: none;
                    font-size: 18px;
                    font-weight: 500;
                  }
                  .v-btn--disabled {
                    background-color: #ffffff !important;
                    color: #b6b6b6 !important;
                    border: 1px solid #b6b6b6 !important;
                    box-shadow: 0 4px 4px -2px #b6b6b6 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sidePanel {
    display: flex;
    box-shadow: -2px 0px 2px rgba(182, 182, 182, 0.3);
    // width: 550px;
    // transition: all 4s ease-in-out;
    height: calc(100vh - 64px);
    &.open {
      width: 550px;
      opacity: 1;
      transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
        transform 0.5s ease-in;
    }
    &.close {
      width: 0px;
      opacity: 0;
      transform: translateX(550px);
      transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
        transform 0.5s ease-in;
    }
    .sidePanelComponents {
      // width: 500px;
      // width: 500px;
      width: 100%;
      padding: 32px 24px;
    }
  }
}

.text-h6 {
  font-family: 'Lato' !important;
}

.current-item-panel {
  flex-grow: 3.5;
  padding-bottom: 60px;
  // padding-left: 20px;
  // width: 80%;

  .marker-switch {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ::v-deep .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
    ::v-deep .v-input--selection-controls__input + .v-label {
      color: #000000;
      font-size: 14px;
      top: -2px;
    }
  }

  .marker {
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: translate(-50%, -50%);
    transform-origin: center center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in;
    box-sizing: content-box;

    &.selected {
      z-index: 2;
      height: 26px;
      width: 26px;
      border: 3px solid currentColor;
      border-color: color-mix(in srgb, currentColor 30%, transparent);
      transition: all 0.2s ease-in;
      box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
        1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    }

    &:hover {
      border: 3px solid currentColor;
      border-color: color-mix(in srgb, currentColor 30%, transparent);
      transition: all 0.2s ease-in;
      box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
        1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .image {
    border-radius: 8px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    // cursor: pointer;
    margin-bottom: 30px;

    &.default-cursor {
      cursor: default;
    }

    ::v-deep .v-image__image {
      border-radius: 8px;
    }
  }

  .illustration-card {
    z-index: 30;
    position: relative;
    background-color: #fff;
  }

  .illustration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7%;
  }

  .illustration-text {
    margin: 20px 0;
    font-size: 18px;
  }

  .description-wrapper {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 170px;
    margin-top: -20px;
  }

  .description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
}

.comply-check-loader {
  z-index: 30;
}
</style>
