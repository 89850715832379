var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comply-tabs-main"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.getReplaceImageSuggestionState.panelOpen &&
      !_vm.getReplaceIconSuggestionState.panelOpen &&
      !_vm.colorPickerState.panelOpen
    ),expression:"\n      !getReplaceImageSuggestionState.panelOpen &&\n      !getReplaceIconSuggestionState.panelOpen &&\n      !colorPickerState.panelOpen\n    "}],staticClass:"heading-block"},[_c('div',{staticClass:"heading-block__main"},[_vm._v("Brand Compliance suggestions")]),_c('div',{staticClass:"heading-block__sub"},[_vm._v("Powered by ASTRID™ AI")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.getReplaceImageSuggestionState.panelOpen &&
      !_vm.getReplaceIconSuggestionState.panelOpen &&
      !_vm.colorPickerState.panelOpen
    ),expression:"\n      !getReplaceImageSuggestionState.panelOpen &&\n      !getReplaceIconSuggestionState.panelOpen &&\n      !colorPickerState.panelOpen\n    "}],staticClass:"suggest-block"},[_c('p',{staticClass:"suggest-block__summary-text"},[_vm._v(" We have "+_vm._s(_vm.getSuggestionCount)+" "+_vm._s(_vm.getSuggestionCount === 1 ? 'suggestion' : 'suggestions')+" based on brand guidelines for your selected template. ")])]),_c('div',{staticClass:"deck-and-slide-tabs"},[_c('v-tabs',{class:("" + (_vm.getReplaceImageSuggestionState.panelOpen ||
        _vm.getReplaceIconSuggestionState.panelOpen ||
        _vm.colorPickerState.panelOpen
          ? 'slideDetailsTabsGroupForRpImg'
          : '')),attrs:{"color":"primary accent-4","fixed-tabs":"","hide-slider":true},model:{value:(_vm.currTab),callback:function ($$v) {_vm.currTab=$$v},expression:"currTab"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.getReplaceImageSuggestionState.panelOpen &&
          !_vm.colorPickerState.panelOpen
        ),expression:"\n          !getReplaceImageSuggestionState.panelOpen &&\n          !colorPickerState.panelOpen\n        "}],staticClass:"tab-item",style:(_vm.currTab === 0 ? { 'border-bottom': '2px solid #21a7e0' } : {}),attrs:{"hide-slider":true,"name--auto":("tc-tab-deck-view" + (_vm.currTab === 0 ? '--active' : ''))},on:{"reviewDetails":_vm.handleReviewDetails,"click":function($event){return _vm.handleTabChange(0)}}},[_vm._v(" Deck ")]),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.getReplaceImageSuggestionState.panelOpen &&
          !_vm.colorPickerState.panelOpen
        ),expression:"\n          !getReplaceImageSuggestionState.panelOpen &&\n          !colorPickerState.panelOpen\n        "}],staticClass:"tab-item",style:(_vm.currTab === 1 ? { 'border-bottom': '2px solid #21a7e0' } : {}),attrs:{"hide-slider":true,"data-pendo-id":!_vm.isActionLoading ? 'comply-current-slide-tab-button' : '',"name--auto":("tc-tab-slide-view" + (_vm.currTab === 1 ? '--active' : ''))},on:{"reviewDetails":_vm.handleReviewDetails,"click":function($event){return _vm.handleTabChange(1)}}},[_vm._v(" Current Slide ")]),_c('v-tabs-items',{model:{value:(_vm.currTab),callback:function ($$v) {_vm.currTab=$$v},expression:"currTab"}},[_c('v-tab-item',{attrs:{"value":0}},[(_vm.deckLoading)?_c('div',{staticClass:"loader"},[_c('ClipLoader',{staticClass:"loading-spinner",attrs:{"color":'#21a7e0',"width":40,"height":40}})],1):_c('deck-details-tab',{attrs:{"clearSlideLibraryFlags":_vm.clearSlideLibraryFlags,"isAddedToLibrary":_vm.isAddedToLibrary,"addToLibrary":_vm.addToLibrary,"handleClosePopAndSaveToLib":_vm.handleClosePopAndSaveToLib,"brandIconsListInLibrary":_vm.brandIconsListInLibrary}})],1),_c('v-tab-item',{attrs:{"value":1}},[(_vm.currTab === 1)?_c('current-slide',{attrs:{"clearSlideLibraryFlags":_vm.clearSlideLibraryFlags,"brandIconsListInLibrary":_vm.brandIconsListInLibrary},on:{"reviewDetails":function($event){return _vm.$emit('reviewDetails', $event)}}}):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }