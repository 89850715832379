<template>
  <div class="comply-tabs-main">
    <div
      class="heading-block"
      v-show="
        !getReplaceImageSuggestionState.panelOpen &&
        !getReplaceIconSuggestionState.panelOpen &&
        !colorPickerState.panelOpen
      "
    >
      <div class="heading-block__main">Brand Compliance suggestions</div>
      <div class="heading-block__sub">Powered by ASTRID™ AI</div>
    </div>
    <div
      class="suggest-block"
      v-show="
        !getReplaceImageSuggestionState.panelOpen &&
        !getReplaceIconSuggestionState.panelOpen &&
        !colorPickerState.panelOpen
      "
    >
      <p class="suggest-block__summary-text">
        We have {{ getSuggestionCount }}
        {{ getSuggestionCount === 1 ? 'suggestion' : 'suggestions' }} based on
        brand guidelines for your selected template.
      </p>
    </div>
    <div class="deck-and-slide-tabs">
      <v-tabs
        color="primary accent-4"
        fixed-tabs
        :class="`${
          getReplaceImageSuggestionState.panelOpen ||
          getReplaceIconSuggestionState.panelOpen ||
          colorPickerState.panelOpen
            ? 'slideDetailsTabsGroupForRpImg'
            : ''
        }`"
        :hide-slider="true"
        v-model="currTab"
      >
        <v-tab
          class="tab-item"
          @reviewDetails="handleReviewDetails"
          v-show="
            !getReplaceImageSuggestionState.panelOpen &&
            !colorPickerState.panelOpen
          "
          :hide-slider="true"
          :style="currTab === 0 ? { 'border-bottom': '2px solid #21a7e0' } : {}"
          :name--auto="`tc-tab-deck-view${currTab === 0 ? '--active' : ''}`"
          @click="handleTabChange(0)"
        >
          Deck
        </v-tab>
        <v-tab
          class="tab-item"
          @reviewDetails="handleReviewDetails"
          v-show="
            !getReplaceImageSuggestionState.panelOpen &&
            !colorPickerState.panelOpen
          "
          :hide-slider="true"
          :data-pendo-id="
            !isActionLoading ? 'comply-current-slide-tab-button' : ''
          "
          @click="handleTabChange(1)"
          :style="currTab === 1 ? { 'border-bottom': '2px solid #21a7e0' } : {}"
          :name--auto="`tc-tab-slide-view${currTab === 1 ? '--active' : ''}`"
        >
          Current Slide
        </v-tab>
        <v-tabs-items v-model="currTab">
          <v-tab-item :value="0">
            <div v-if="deckLoading" class="loader">
              <ClipLoader
                class="loading-spinner"
                :color="'#21a7e0'"
                :width="40"
                :height="40"
              />
            </div>
            <deck-details-tab
              :clearSlideLibraryFlags="clearSlideLibraryFlags"
              :isAddedToLibrary="isAddedToLibrary"
              :addToLibrary="addToLibrary"
              :handleClosePopAndSaveToLib="handleClosePopAndSaveToLib"
              :brandIconsListInLibrary="brandIconsListInLibrary"
              v-else
            ></deck-details-tab>
          </v-tab-item>
          <v-tab-item :value="1">
            <current-slide
              v-if="currTab === 1"
              @reviewDetails="$emit('reviewDetails', $event)"
              :clearSlideLibraryFlags="clearSlideLibraryFlags"
              :brandIconsListInLibrary="brandIconsListInLibrary"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import CurrentSlide from './CurrentSlide.vue';
import DeckDetailsTab from './DeckDetailsTab.vue';
import { getSearchBrandIconsSolr } from '../../../../utils/api-helper';

export default {
  computed: {
    ...mapState('comply', ['colorPickerState', 'selectedTemplate']),
    ...mapGetters('comply', [
      'getSuggestionCount',
      'getReplaceImageSuggestionState',
      'getReplaceIconSuggestionState',
      'isActionLoading',
    ]),
    ...mapGetters('users', ['isReplaceBrandIconsEnabled']),
  },
  components: {
    CurrentSlide,
    DeckDetailsTab,
    ClipLoader,
  },
  props: {
    clearSlideLibraryFlags: {
      type: Function,
    },
    isAddedToLibrary: {
      type: Boolean,
      default: false,
    },
    addToLibrary: {
      type: Function,
      default: () => ({}),
    },
    handleClosePopAndSaveToLib: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      displayDetails: true,
      currTab: 0,
      deckLoading: false,
      brandIconsListInLibrary: [],
    };
  },
  methods: {
    ...mapActions('comply', ['fetchScore', 'setActiveSuggestionView']),
    ...mapMutations('comply', ['setToggleColorPicker']),
    closeColorPicker() {
      this.setToggleColorPicker(false);
    },
    handleReviewDetails() {
      this.displayDetails = false;
    },

    async handleTabChange(tab) {
      this.currTab = tab;
      this.setActiveSuggestionView(tab === 0 ? 'deck' : 'currSlide');
      if (tab === 0) {
        this.deckLoading = true;
        await this.fetchScore();
        this.deckLoading = false;
      }
    },

    async searchBrandIconsFromSolr({ query, skip }) {
      const templates = this.selectedTemplate || '';
      const resp = await getSearchBrandIconsSolr({
        query,
        skip,
        templates,
      });
      if (resp?.data?.docs?.length) {
        return resp?.data?.docs.map((image) => ({
          filepath: image?.filepath,
        }));
      }
      return [];
    },
  },
  async mounted() {
    if (this.isReplaceBrandIconsEnabled) {
      this.brandIconsListInLibrary = await this.searchBrandIconsFromSolr({
        query: '*',
        skip: 0,
      });
    }
    this.setActiveSuggestionView('deck');
  },
};
</script>
<style lang="scss" scoped>
.tab-item {
  color: #4d5358;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 130%;
  text-transform: none;
}

.loader {
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

::v-deep .v-tab--active {
  color: #212121;
}

.heading-block {
  &__main {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__sub {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
  }
}

.suggest-block {
  align-self: stretch;
  &__summary-text {
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-bottom: 0;
  }
}
.slideDetailsTabsGroupForRpImg {
  ::v-deep .v-slide-group {
    display: none !important;
  }
}

.comply-tabs-main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.deck-and-slide-tabs {
  min-width: 455px;
  width: 100%;
  height: 96%;
  .v-tab {
    text-decoration: none;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-transform: none;
  }
  ::v-deep .v-tabs {
    display: flex;
    flex-direction: column;

    .v-item-group {
      padding-left: 1px;
    }
  }
}
</style>
