<template>
  <div class="redesign-outer-container">
    <!-- <div
      v-if="
        currentUser.user.redesignBanner === -1 &&
        currentUser.user.role.toLowerCase() !== 'trialuser' &&
        isRedesignFullAccessBannerCheck() &&
        doUserHavePremiumSubscription
      "
      class="generate-banner"
    >
      {{ $t('redesign.redesignBanner') }}
      <button @click="onDismissBetaBanner" class="dismiss-btn primary--text">
        {{ $t('generate.dismiss') }}
      </button>
    </div> -->
    <div class="redesign-container">
      <div
        class="main-content"
        :class="{ 'right-panel-open': rightPanelComponent }"
      >
        <div class="page-heading">
          <div class="main">{{ $t('redesign.heading') }}</div>
          <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
        </div>
        <div class="input-box fl-upldr-bx">
          <div>
            <div class="input-container">
              <div class="input-placeholder" v-if="fileError">
                {{ $t(fileError) }}
              </div>
              <div
                v-else-if="newFilesToUpload.length === 0"
                class="input-placeholder"
                @click="onUploadDivClick('')"
              >
                Upload your PowerPoint slides (.pptx)
              </div>
              <template v-else>
                <v-tooltip bottom fixed>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="input-file-name"
                      :class="{ disabled: step > 0 }"
                      v-bind="attrs"
                      v-on="on"
                      @click="onUploadDivClick('')"
                    >
                      {{ newFilesToUpload[0].name }}
                    </div>
                  </template>
                  <span>{{ newFilesToUpload[0].name }}</span>
                </v-tooltip>
              </template>
              <template v-if="step === 0">
                <v-tooltip bottom open-delay="500" close-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      small
                      icon
                      color="#21A7E0"
                      v-bind="attrs"
                      v-on="on"
                      data-pendo-id="redesign-upload-file"
                      @click="onUploadDivClick('cta')"
                    >
                      <v-icon> mdi-tray-arrow-up </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('redesign.uploadBtn') }}</span>
                </v-tooltip>
              </template>
              <v-tooltip bottom v-if="step === 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="display: none"
                  >
                    mdi-home
                  </v-icon>
                </template>
                <span>Tooltip</span>
              </v-tooltip>
              <input
                type="file"
                ref="hiddenInput"
                :accept="`${allowedUploadTypes}`"
                @change="handleOnDivClickFileSelection"
                style="display: none"
              />
            </div>
            <div class="d-flex justify-end">
              <v-btn
                v-if="step === 0 && !loading"
                elevation="2"
                :disabled="
                  (!newFilesToUpload.length || !!fileError.length) &&
                  !checkingUsage
                "
                :loading="checkingUsage"
                rounded
                color="primary"
                class="redesign-btn"
                data-pendo-id="redesign-btn"
                @click="uploadFile"
              >
                {{ $t('redesign.title') }}
              </v-btn>
              <div
                v-else-if="step > 1 && slidesCompleted"
                class="redesign-action-container"
              >
                <template v-if="!loading">
                  <v-tooltip bottom fixed>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="#41484D"
                        @click="cancelRequest"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-close
                      </v-icon>
                    </template>
                    <span>{{ $t('redesign.cancelBtn') }}</span>
                  </v-tooltip>
                </template>
                <v-btn
                  :disabled="!!loading || !slidesCompleted"
                  :loading="checkingUsage"
                  elevation="2"
                  rounded
                  color="primary"
                  class="redesign-btn"
                  data-pendo-id="redesign-btn"
                  @click="redesign"
                >
                  {{ $t('redesign.title') }}
                </v-btn>
              </div>
              <v-btn
                v-else
                elevation="2"
                rounded
                class="cancel-btn"
                @click="cancelRequest"
              >
                {{ $t('common.cancel') }}
              </v-btn>
            </div>
          </div>
          <div>
            <div v-if="step < 3 || !!error || !recordId">
              Redesign works best with decks having up to 20 slides and file
              size under 200MB. Image conversion to slide content is currently
              not supported.
            </div>
            <template v-else>
              <div class="panel-items">
                <template v-for="(action, index) in actions">
                  <template v-if="action.id === 'replaceVisual'">
                    <div
                      v-if="allowReplaceImageOverall && allowReplaceImageTabs"
                      :class="{
                        current: rightPanelComponent === action.id,
                      }"
                      :key="index"
                      :data-pendo-id="action.dataPendoId"
                      @click="openRightPanel(action.id, 'bar')"
                    >
                      <v-icon
                        :name="action.id + '-icon'"
                        color="#21A7E0"
                        v-if="action.icon"
                      >
                        {{ action.icon }}
                      </v-icon>
                      <img v-else :src="action.iconSvg[1]" />
                      <span>{{ $t(action.action) }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      :class="{
                        current: rightPanelComponent === action.id,
                      }"
                      :key="index"
                      :data-pendo-id="action.dataPendoId"
                      @click="openRightPanel(action.id, 'bar')"
                    >
                      <v-icon
                        :name="action.id + '-icon'"
                        color="#21A7E0"
                        v-if="action.icon"
                      >
                        {{ action.icon }}
                      </v-icon>
                      <img v-else :src="action.iconSvg[1]" />
                      <span>{{ $t(action.action) }}</span>
                    </div>
                  </template>
                </template>
                <div
                  @click="redesignSlide('bar')"
                  :class="{ disabled: !slidesCompleted }"
                  data-pendo-id="redesign-redesign-slide"
                >
                  <v-icon
                    name="redesign-icon"
                    :color="slidesCompleted ? '#21A7E0' : '#00000042'"
                  >
                    mdi-autorenew
                  </v-icon>
                  <span>{{ $t('redesign.actions.redesign') }}</span>
                </div>
              </div>
              <div class="d-flex justify-end align-self-end">
                <Ratings
                  v-model="form"
                  @change="handleChangeRating"
                  :disabled="!slidesCompleted"
                  :animationTooltipTxt="'redesign.ratings.animationTooltip'"
                  :likeTooltipTxt="'redesign.ratings.likeTooltip'"
                  :dislikeTooltipTxt="'redesign.ratings.dislikeTooltip'"
                />
              </div>
            </template>
          </div>
        </div>
        <div class="content-view">
          <template v-if="step === 1 && !error">
            <slides-listing
              :slidesList="slides"
              :origin="'redesign'"
              :loading="loading === 'templates-loading'"
              @handleOnNext="handleOnSlidesSelectionCta"
              class="slides-selection-container"
              v-if="step === 1 && loading !== 'file-upload'"
            ></slides-listing>
            <div class="slides-loader" v-else-if="loading === 'file-upload'">
              <div class="progress-spinner">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div class="video-container">
                <video autoplay loop muted playsinline ref="video">
                  <source
                    :src="'/assets/videos/loaders/redesignUpload.mp4'"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </template>
          <template v-else-if="step === 2 && !error">
            <templates-listing
              :templatesData="templates"
              :loading="loading === 'slides-loading'"
              :primaryBtnText="'Redesign'"
              :origin="'redesign'"
              :templateRequestFrom="'Redesign'"
              @onPrimaryBtnClick="handleTemplateSelection"
              class="slides-selection-container"
              v-if="step === 2"
            ></templates-listing>
            <div class="slides-loader" v-else-if="loading === 'file-upload'">
              <div class="progress-spinner">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div class="video-container">
                <video autoplay loop muted playsinline ref="video">
                  <source
                    :src="'/assets/img/redesign/processing-file.mp4'"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </template>
          <div
            v-else
            class="redesign-view"
            :class="{ 'right-panel-open': rightPanelComponent }"
          >
            <RedesignThumbnail
              :slides="selectedSlides"
              :error="error"
              :loading="!redesignLoader.isIdle()"
              :slideIndex="slideIndex"
              :redesignSlideIndex="redesignSlideIndex"
              @slideIndexChange="handleIndexChange"
            />
            <RedesignContent
              :slide="selectedSlide"
              :error="error"
              :buttonLoader="spinnerLoader"
              :loader="redesignLoader"
              @redesignError="handleSlideError"
              @keepOriginal="keepOriginal"
            />
            <!-- <RedesignContent
              :slide="selectedSlide"
              :error="error"
              :loadingSrc="loadingSrc"
              @redesignError="handleSlideError"
              @keepOriginal="keepOriginal"
            /> -->
          </div>
        </div>
        <div class="quick-access-toolbar">
          <v-tooltip
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  :color="hover || isAddedToLibrary ? '#21A7E0' : '#000'"
                  :disabled="
                    step < 3 || !!loading || !slidesCompleted || !deckValid
                  "
                  v-bind="attrs"
                  v-on="on"
                  @click="handleAddToSlideLibrary"
                >
                  <v-icon
                    name="add-to-library-icon"
                    data-pendo-id="redesign-add-to-library-btn"
                  >
                    {{
                      isAddedToLibrary
                        ? selectedSlides.length > 1
                          ? 'mdi-folder'
                          : 'mdi-folder-table'
                        : selectedSlides.length > 1
                        ? 'mdi-folder-outline'
                        : 'mdi-folder-table-outline'
                    }}
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t(addToLibraryTooltip) }}</span>
          </v-tooltip>
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
              v-if="!removingAsFavorite"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    small
                    icon
                    :ripple="false"
                    :color="hover || isAddedAsFavorite ? '#21A7E0' : '#000'"
                    :disabled="
                      step < 3 || !!loading || !slidesCompleted || !deckValid
                    "
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddToFavorites()"
                  >
                    <v-icon
                      name="favorite-icon"
                      data-pendo-id="redesign-favorite-btn"
                    >
                      {{
                        isAddedAsFavorite ? 'mdi-heart' : 'mdi-heart-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t(addToFavoriteTooltip) }}</span>
            </v-tooltip>
            <v-progress-circular
              indeterminate
              :color="'#21A7E0'"
              :size="25"
              v-if="isAddedAsFavorite && removingAsFavorite"
            />
          </div>
          <v-tooltip
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :disabled="
                    step < 3 || !!loading || !slidesCompleted || !deckValid
                  "
                  :ripple="false"
                  :color="hover ? '#21A7E0' : '#000'"
                  v-bind="attrs"
                  v-on="on"
                  @click="onDownload()"
                >
                  <v-icon
                    data-pendo-id="redesign-download-btn"
                    name="download-icon"
                  >
                    mdi-download-outline
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
          </v-tooltip>
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    small
                    icon
                    :ripple="false"
                    :color="showTutorialMenu || hover ? '#21A7E0' : '#000'"
                    v-bind="attrs"
                    v-on="on"
                    @click="showTutorial"
                  >
                    <v-icon
                      name="tutorials-icon"
                      data-pendo-id="generate-tutorials"
                    >
                      mdi-television-play
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t('generate.tutorials') }}</span>
            </v-tooltip>
            <v-menu
              v-model="showTutorialMenu"
              :attach="true"
              absolute
              offset-y
              :position-x="-420"
              :position-y="40"
              :nudge-width="460"
              :close-on-click="true"
              :close-on-content-click="false"
              content-class="tutorial-menu"
            >
              <TutorialMenu :category="'Redesign'"></TutorialMenu>
            </v-menu>
          </div>
          <v-tooltip
            v-if="!rightPanelComponent"
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  :disabled="step < 3 || !!loading || !initialRedesignCompleted"
                  @click="setSmartTools(true)"
                  :color="hover ? '#21A7E0' : '#000'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon name="right-panel-icon">
                    mdi-square-edit-outline
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.smartTools') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        class="right-panel"
        :class="{
          hide: !rightPanelComponent,
          fade:
            !!loading ||
            !selectedSlide ||
            !selectedSlide.completed ||
            selectedSlide.error ||
            error,
          // (['replaceVisual', 'changeLayout'].includes(rightPanelComponent) &&
          //   selectedSlide &&
          //   selectedSlide.error),
        }"
      >
        <!-- <AudienceMenu
          v-if="rightPanelComponent === 'audience'"
          :audience="audience"
          :origin="'redesign'"
          :fingerprintResponse="true"
          @change="handleAudienceChange"
        /> -->
        <ReplaceVisual
          v-if="rightPanelComponent === 'replaceVisual'"
          :slide="selectedSlideForVisuals"
          :origin="'Redesign'"
          ref="replaceVisual"
          @changePanel="openRightPanel('changeLayout')"
          @change="handleConfirmation"
          @toggleReplaceImage="onImageReplaced"
          @replaceStart="startReplaceVisuals"
        />
        <!-- <TemplateMenu
          v-if="rightPanelComponent === 'template-menu'"
          :template="template"
          class="template-selector-active"
          :isCloseBtn="false"
          :origin="'redesign'"
          @change="handleConfirmation"
        /> -->
        <template-selection
          v-if="rightPanelComponent === 'template-menu'"
          :templatesInfo="templates"
          :selectedTemplate="template"
          :templateRequestFrom="'Redesign'"
          @selectedTemplate="handleTemplateChange"
          class="template-menu"
        ></template-selection>
        <RedesignChangeLayout
          v-if="rightPanelComponent === 'changeLayout'"
          :selectedSlide="
            selectedSlide && selectedSlide.redesign
              ? selectedSlide.redesign
              : {}
          "
          :selectedSlideID="
            selectedSlide &&
            selectedSlide.redesign &&
            selectedSlide.redesign.meta
              ? selectedSlide.redesign.meta.id
              : ''
          "
          @change="handleConfirmation"
        >
        </RedesignChangeLayout>
      </div>
      <div v-if="isSmartToolsOpen" class="smart-tool-switch">
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn
                depressed
                small
                icon
                :ripple="false"
                @click="setSmartTools(false)"
                :color="hover ? '#21A7E0' : '#000'"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon name="close-icon"> mdi-close </v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span>{{ $t('generate.closeSmartTools') }}</span>
        </v-tooltip>
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
          v-for="(action, index) in actions"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <template>
                <template v-if="action.id === 'replaceVisual'">
                  <v-btn
                    v-if="allowReplaceImageOverall && allowReplaceImageTabs"
                    depressed
                    small
                    icon
                    :ripple="false"
                    @click="openRightPanel(action.id)"
                    :color="
                      rightPanelComponent === action.id || hover
                        ? '#21A7E0'
                        : '#000'
                    "
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!slidesCompleted"
                  >
                    <v-icon :name="action.id + '-icon'" v-if="action.icon">
                      {{ action.icon }}
                    </v-icon>
                    <img
                      v-else-if="rightPanelComponent === action.id || hover"
                      :src="action.iconSvg[1]"
                    />
                    <img v-else :src="action.iconSvg[0]" />
                  </v-btn>
                </template>
                <v-btn
                  v-else
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel(action.id)"
                  :color="
                    rightPanelComponent === action.id || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="action.id !== 'changeLayout' && !slidesCompleted"
                >
                  <v-icon :name="action.id + '-icon'" v-if="action.icon">
                    {{ action.icon }}
                  </v-icon>
                  <img
                    v-else-if="rightPanelComponent === action.id || hover"
                    :src="action.iconSvg[1]"
                  />
                  <img v-else :src="action.iconSvg[0]" />
                </v-btn>
              </template>
            </v-hover>
          </template>
          <span>{{ $t(action.text) }}</span>
        </v-tooltip>
      </div>
    </div>
    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="dialogSecondary"
      :secondary-btn-text="dialogPrimary"
      :title="dialogTitle"
      :text="dialogText"
    />
    <!-- <LibraryConfirmationPopup
      v-if="isAddedToLibraryDownload"
      :goToLibrary="goToLibrary"
      :message="
        selectedSlides.length > 1
          ? $t('premiumFeature.addedToPrezLibrary')
          : $t('premiumFeature.addedToSlideLibrary')
      "
      :btnText="$t('premiumFeature.openInLibrary')"
      :cancel="closePopup"
    ></LibraryConfirmationPopup> -->
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { RedesignOperation } from './RedesignUtils.js';
import EventBus from '../../common/event-bus';
import { removeSpecialCharacters } from '../../utils';
// import AudienceMenu from '../GenerateSlides/AudienceMenu.vue';
import ReplaceVisual from '../GenerateSlides/ReplaceVisual/ReplaceVisual.vue';
// import TemplateMenu from '../NewPrezentation/TemplateMenu.vue';
import TemplateSelection from '../ComplianceChecker/comply-settings/TemplateSelection.vue';
import {
  checkTaskStatus,
  uploadChunks,
  uploadOPFile,
  sendEmail,
  getFeatureUsage,
  postFeatureUsage,
  fetchRecomendedTemplateImages,
  cancelGSRequest,
  createSlide,
  deleteSlide,
  removeFromLibrary,
  createFavorite,
  addPrezentationAsFavorite,
  addPrezentationDeck,
  getSlideDetail,
  deleteFavorite,
  removePrezentationAsFavorite,
} from '../../../utils/api-helper';
import SlidesListing from '../ComplianceChecker/comply-slides-listing-page/SlidesListing.vue';
import { getFileURL } from '@/utils/calyrex';
// import usersApi from '../../../API/users-api';
import Ratings from '../Synthesize/Ratings.vue';
import GenerateActionModal from '../GenerateSlides/GenerateActionModal.vue';
// import AddToSlides from './AddToSlides.vue';
// import { downloadFileWithCustomName } from '../../../utils/common';
import { RedesignService } from './RedesignService';
import RedesignThumbnail from './RedesignThumbnail.vue';
import RedesignContent from './RedesignContent.vue';
import TutorialMenu from '../GenerateSlides/TutorialMenu.vue';
import { miscConfig } from '../../../runtimeConfig';
import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';
import ContactSoonModal from '../../common/ContactSoonModal.vue';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import TemplatesListing from '../ComplianceChecker/comply-templates-listing-page/TemplatesListing.vue';
import DirectDownloadInfo from '../../common/DirectDownloadInfo.vue';
import { trackRedesignEvents } from '../../common/Analytics/RedesignEvents';
// import LibraryConfirmationPopup from '../../common/LibraryConfimationPopup.vue';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_PLACEMENT,
  TD_NUMBEROFSLIDES,
  TD_COMMON_COLUMN_NAME,
  TD_ISDOWNLOAD,
  TD_ISDOWNLOAD_VALUE,
  TD_UPLOAD_SECTION,
  TD_UPLOAD_CTA,
  TD_COMMON_COLUMN_VALUE,
  TD_NUMBER_OF_SLIDES_UPLOADED,
  TD_AUD,
  TD_THUMB_SELECTED,
  TD_INSTRUCTIONS,
  NA,
  TD_THUMBS_UP,
  TD_THUMBS_DOWN,
  TD_RECOMMENDED_TEMPLATE,
  TD_TEMPLATE_SWITCHED,
  TD_DOWNLOADTYPE,
  TD_SMART_LAYOUT_PLACEMENT,
  TD_UPLOAD_BAR_PLACEMENT,
  TD_REPLACE_IMAGE_SOURCE,
  TD_SLIDE_NAME,
  TD_PREZENTATION_LIBRARY,
  TD_LIBRARY,
  TD_SLIDES_LIBRARY,
  TD_FILE_SIZE,
  TD_TIME_SPENT_IN_SECS,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { triggerReplaceImage } from '../../../utils/replace-image-api-helper';
import { Loader } from '../../../utils/loader-helper.js';
import RedesignChangeLayout from '../../common/RedesignChangeLayout.vue';

export default {
  name: 'RedesignContainer',
  components: {
    // AudienceMenu,
    ReplaceVisual,
    RedesignChangeLayout,
    // TemplateMenu,
    SlidesListing,
    Ratings,
    RedesignThumbnail,
    RedesignContent,
    TutorialMenu,
    ConfirmationDialog,
    TemplatesListing,
    TemplateSelection,
    // LibraryConfirmationPopup,
  },
  data() {
    return {
      checkingUsage: false,
      selectedTemplate: null,
      template: null,
      templates: null,
      templateInfo: null,
      redesignSlideIndex: null,
      initialRedesignCompleted: false,
      modifiedFileName: '',
      redesignTimeTaken: '',
      recordId: '',
      audience: null,
      allowedUploadTypes: ['.pptx'],
      loading: null,
      slideIndex: 0,
      form: {
        isLiked: null,
        feedback: '',
        shareDetails: true,
      },
      slides: [],
      showTutorialMenu: false,
      selectedSlides: [],
      rightPanelComponent: '',
      isSmartToolsOpen: false,
      newFilesToUpload: [],
      // isAddedToLibraryDownload: false,
      prezOrSlideId: null,
      fileDetails: null,
      error: null,
      step: 0,
      uploadID: null,
      changeReq: null,
      showConfirmation: false,
      dialogPrimary: '',
      dialogSecondary: '',
      dialogText: '',
      dialogTitle: '',
      spinnerLoader: false,
      actions: [
        // {
        //   id: 'audience',
        //   icon: 'mdi-account-group-outline',
        //   text: 'redesign.smartTools.audience',
        //   action: 'redesign.actions.audience',
        //   dataPendoId: 'redesign-change-audience',
        // },
        {
          id: 'template-menu',
          icon: 'mdi-compare',
          text: 'redesign.smartTools.template',
          action: 'redesign.actions.template',
          dataPendoId: 'redesign-switch-template',
        },
        {
          id: 'changeLayout',
          iconSvg: [
            '/assets/img/generate/smartLayout.svg',
            '/assets/img/generate/smartLayout-filled.svg',
            '/assets/img/generate/smartLayout-disabled.svg',
          ],
          text: 'redesign.smartTools.layout',
          action: 'redesign.actions.layout',
          dataPendoId: 'redesign-change-layout',
        },
        {
          id: 'replaceVisual',
          icon: 'mdi-image-outline',
          text: 'redesign.smartTools.visual',
          action: 'redesign.actions.visual',
          dataPendoId: 'redesign-replace-visuals',
        },
      ],
      redesignOperation: {
        loading: false,
        success: false,
      },
      leftNavExpanded: false,
      fileError: '',
      iconPlacement: '',
      isAddedToLibrary: false,
      isAddedAsFavorite: false,
      addedToLibraryData: null,
      removingFromLibrary: false,
      removingAsFavorite: false,
      processInProgress: false,
      startTime: '',
      redesignLoader: new Loader('redesign'),
    };
  },
  watch: {
    async slidesCompleted(val) {
      if (val && !this.deckValid) {
        this.redesignLoader.nextState(0);
      }
      if (val && !this.recordId) {
        await this.handleCreateRecord();
      }
      if (
        val &&
        this.changeReq &&
        this.changeReq.type === RedesignOperation.TemplateChange
      ) {
        await this.handleUpdateRecord();
      }
      if (val) {
        this.processInProgress = false;
      }
    },
    initialRedesignCompleted(val) {
      if (this.step < 3) {
        return;
      }
      if (val) {
        this.redesignLoader.nextState(2);
      } else {
        this.redesignSlideIndex = null;
        this.redesignLoader.nextState(1);
      }
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'typesenseSelfAudience',
      'limitsInfoObj',
      'themes',
    ]),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
    ]),
    selectedSlide() {
      return this.selectedSlides[this.slideIndex];
    },
    formImageKeywords() {
      const imageKeywords = [];
      this.selectedSlides?.forEach((item) => {
        if (item?.redesign?.meta?.image_keyword) {
          imageKeywords.push(item.redesign.meta.image_keyword);
        }
      });
      return imageKeywords;
    },
    // validSlidesCount() {
    //   return this.selectedSlides.filter((i) => i.completed && !i.error).length;
    // },
    addToLibraryTooltip() {
      if (this.isAddedToLibrary) {
        return this.selectedSlides.length <= 1
          ? 'generateToolBar.remove_from_slide_library'
          : 'generateToolBar.remove_from_prez_exchange';
      }
      return this.selectedSlides.length > 1
        ? 'generateToolBar.add_to_prez_exchange'
        : 'generateToolBar.add_to_slide_library';
    },
    addToFavoriteTooltip() {
      if (this.isAddedAsFavorite) {
        return this.selectedSlides.length <= 1
          ? 'premiumFeature.removeSlideFromFavourite'
          : 'premiumFeature.removePrezentationFromFavourite';
      }
      return this.selectedSlides.length > 1
        ? 'premiumFeature.addPrezentationToFavourites'
        : 'premiumFeature.addSlideToFavourites';
    },
    selectedSlideForVisuals() {
      if (!this.selectedSlide) {
        return {};
      }
      return {
        ...this.selectedSlide.redesign,
        id: this.selectedSlide.id,
      };
    },
    deckValid() {
      return (
        !this.error &&
        this.selectedSlides.length &&
        this.slidesCompleted &&
        this.selectedSlides.some((i) => !i.error)
      );
    },
    slidesCompleted() {
      return (
        this.selectedSlides.length &&
        this.selectedSlides.every((i) => i.completed)
      );
    },
    redesignService() {
      return new RedesignService();
    },
    getDefaultAudience() {
      return {
        id: this.typesenseSelfAudience.id,
        num_id: this.typesenseSelfAudience.num_id,
        type: this.typesenseSelfAudience.audienceType,
        ownerID: this.typesenseSelfAudience.ownerID,
        has_image: 1,
        color_type:
          this.typesenseSelfAudience.question2 === 'leftImage' ? 'high' : 'low',
        has_cartoon:
          this.typesenseSelfAudience.question3 === 'leftImage' ? 0 : 1,
        has_tables:
          this.typesenseSelfAudience.question6 === 'leftImage' ? 1 : 0,
        has_graphs:
          this.typesenseSelfAudience.question6 === 'rightImage' ? 1 : 0,
      };
    },
    isRedesignAccess(state) {
      const redesignFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'restricted_access')
      );
    },

    mapUserDownloadPreferenceToTooltip() {
      const userDownloadPreference =
        this.currentUser?.user?.userDownloadPreference?.toLowerCase();
      if (userDownloadPreference === 'local_download') {
        return this.$t('common.downloadPPTX');
      }
      if (userDownloadPreference === 'save_to_gdrive') {
        return this.$t('common.googleDownload');
      }
      return this.$t('common.download');
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setLimitData',
      'setUpgradePopup',
    ]),

    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      // 'resetAddedToLibrarySnackbarState',
      // 'setPremiumFtLibConfPopupData',
      // 'setIsAddedToLibraryDownload',
      'setAddedToLibOrFavSnackbar',
      'setNavFromAddToLibOrFavSnackbar',
    ]),

    async getMergedUrlForSaveToGdrive() {
      const s = {
        slides: [],
      };
      this.selectedSlides.forEach((slide) => {
        if (slide.completed && !slide.error) {
          s.slides.push({
            slideId:
              slide?.redesign?.slide_path?.charAt(0) === '/'
                ? slide?.redesign?.slide_path?.substring(1)
                : slide?.redesign?.slide_path,
            isReplaced:
              slide?.redesign?.slide_path?.startsWith(
                '/newImageReplaceOutput/output/',
              ) || slide?.redesign?.slide_path?.startsWith('/output/pptx/'),
            isUploaded: false,
            isGenerated: false,
            isSynthesis: false,
            isRedesign: true,
            isComply: false,
          });
        }
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
    },

    goToFavLibrary() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type:
          this.selectedSlides.length > 1 ? 'addToFavPrez' : 'addToFavSlides',
        origin: this.selectedSlides.length > 1 ? 'fav_prez' : 'fav_slides',
      });
      this.$modal.hide('GenerateActionModal');
      if (this.selectedSlides.length > 1) {
        this.$router.push('/home/prezentations').catch(() => {});
      } else {
        this.$router.push('/home/slides').catch(() => {});
      }
    },

    goToLibrary() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type:
          this.selectedSlides.length > 1 ? 'addToLibPrez' : 'addToLibSlides',
        origin:
          this.selectedSlides.length > 1
            ? 'redesign_lib_prez'
            : 'redesign_lib_slides',
      });
      this.$modal.hide('GenerateActionModal');
      if (this.selectedSlides.length > 1) {
        this.$router.push('/home/prezentations').catch(() => {});
      } else {
        this.$router.push('/home/slides').catch(() => {});
      }
    },
    pageChangeAction(navigationNext) {
      this.navigationNext = navigationNext;
      this.$modal.show(
        GenerateActionModal,
        {
          heading: this.$t('premiumFeature.unsavedChanges'),
          primaryBtnText: this.$t('common.save'),
          secondaryBtnText: this.$t('premiumFeature.continueWithoutSaving'),
          modalType: 'unsaved',
          primaryBtnAction: this.saveChanges,
          secondaryBtnAction: this.discardChanges,
          type: this.selectedSlides.length > 1 ? 'prezentation' : 'slide',
          operation: this.redesignOperation,
          filename:
            this.modifiedFileName ||
            `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
    },
    async saveChanges(name) {
      await this.updateAddedToSlideLibraryStatus(name);
      this.$modal.hide('GenerateActionModal');
      this.discardChanges();
    },
    discardChanges() {
      this.navigationNext();
    },
    // closePopup() {
    //   // this.isAddedToLibraryDownload = false;
    //   this.resetAddedToLibrarySnackbarState();
    // },
    // isRedesignFullAccessBannerCheck() {
    //   const redesignFeature = this.currentUser?.features?.find(
    //     (ft) => ft.feature_name === 'redesign',
    //   );
    //   return (
    //     redesignFeature &&
    //     redesignFeature.enabled &&
    //     redesignFeature.access_level &&
    //     redesignFeature.access_level === 'full_access'
    //   );
    // },
    handleChangeRating(change) {
      const otherData = {
        [TD_COMMON_COLUMN_NAME]: TD_THUMB_SELECTED,
        [TD_TEMPLATE]: NA,
        [TD_COMMON_COLUMN_VALUE]: change.isLiked
          ? TD_THUMBS_UP
          : TD_THUMBS_DOWN,
      };
      trackRedesignEvents.redesignFeedbackClick(this.currentUser, otherData);

      if (change?.type === 'feedback') {
        this.handleUpdateRecord({
          feedback: change.feedback,
          shareDetails: change.shareDetails,
        });
        trackRedesignEvents.redesignFeedbackSubmitted(this.currentUser, {
          ...otherData,
          [TD_PREZNAME]: this.newFilesToUpload[0].name,
          [TD_INSTRUCTIONS]: change.feedback,
        });
      } else {
        this.handleUpdateRecord({
          reaction: change.isLiked,
        });
      }
    },
    handleSlideError(errorData) {
      if (errorData.type === 'GET_IN_TOUCH') {
        this.getInTouch();
      } else if (errorData.type === 'OP_EN') {
        this.dialogTitle = this.$t('redesign.content.opPopupTitle');
        this.dialogText = this.$t('redesign.content.opPopupBody');
        this.dialogPrimary = this.$t('redesign.content.opConfirm');
        this.dialogSecondary = this.$t('redesign.content.opCancel');
        this.showConfirmation = true;
      } else if (errorData.type === 'RDSGN_SLIDE') {
        this.redesignSlide();
      } else {
        // redesign
      }
    },
    keepOriginal() {
      this.selectedSlides[this.slideIndex].keepOriginal = true;
    },
    onImageReplaced(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = 'Exit Replace Visuals';
      this.dialogText = `Any changes to the slide will not be saved. Would you like to leave anyway?`;
      this.dialogPrimary = 'Yes';
      this.dialogSecondary = 'No';
    },
    async getInTouch() {
      this.spinnerLoader = true;
      try {
        const paramsOp = {
          type: 'interest-in-op',
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            userEmail: this.currentUser.user.id,
          },
        };

        sendEmail(paramsOp)
          .then(() => {
            this.spinnerLoader = false;
          })
          .catch((err) => {
            this.spinnerLoader = false;
            console.log('sendEmail err ', err);
          });
      } catch (err) {
        this.spinnerLoader = false;
        console.log(err);
      }

      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    onDownload() {
      this.redesignOperation.loading = false;
      this.$modal.show(
        GenerateActionModal,
        {
          heading:
            this.selectedSlides.length > 1 ? `Download deck` : 'Download slide',
          primaryBtnText: 'Download',
          modalType: 'download',
          primaryBtnAction: this.download,
          type: this.selectedSlides.length > 1 ? 'prezentation' : 'slide',
          operation: this.redesignOperation,
          isAddedToLibrary: this.isAddedToLibrary,
          filename:
            this.modifiedFileName ||
            `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
          originPage: 'redesign',
          downloadItem:
            this.selectedSlides.length > 1
              ? {
                  prefs: {
                    source: 'redesign',
                  },
                  name:
                    this.modifiedFileName ||
                    `${this.fileDetails?.name.replace(/.pptx/g, '')}.pptx`,
                  audienceID: this.audience?.id,
                  downloadLoaderId:
                    this.selectedSlides[0]?.redesign?.slideDetails?.id,
                }
              : {
                  ...this.selectedSlides[0]?.redesign?.slideDetails,
                  name:
                    this.modifiedFileName ||
                    `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
                  prefs: {
                    ...this.selectedSlides[0]?.redesign?.slideDetails?.prefs,
                    source: 'redesign',
                  },
                  filename: this.selectedSlides[0]?.redesign?.meta?.s3_path,
                  noofslides: this.selectedSlides.length,
                  downloadLoaderId:
                    this.selectedSlides[0]?.redesign?.slideDetails?.id,
                },
          getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
          logDownload: () => {
            this.handleUpdateRecord({
              deckDownloaded: true,
            });
          },
          closePreviousPopups: this.handleClosePopAndSaveToLib,
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
    },
    async handleClosePopAndSaveToLib(name, addToLib) {
      if (addToLib === true && !this.isAddedToLibrary) {
        await this.updateAddedToSlideLibraryStatus(name);
        // this.isAddedToLibraryDownload = true;
        // this.setIsAddedToLibraryDownload(true);
        // this.setPremiumFtLibConfPopupData({
        //   message:
        //     this.selectedSlides.length > 1
        //       ? this.$t('premiumFeature.addedToPrezLibrary')
        //       : this.$t('premiumFeature.addedToSlideLibrary'),
        //   btnText: this.$t('premiumFeature.openInLibrary'),
        //   goToLibrary: this.goToLibrary,
        //   cancel: this.closePopup,
        // });
        // setTimeout(() => {
        //   // this.isAddedToLibraryDownload = false;
        //   this.resetAddedToLibrarySnackbarState();
        // }, 10000);
      }
      this.$modal.hide('GenerateActionModal');
    },
    async download(name, isFavorite, addToLibraryClick) {
      this.redesignOperation.loading = true;
      const s = {
        slides: [],
      };
      this.selectedSlides.forEach((slide) => {
        if (slide.keepOriginal || !slide.error) {
          const path = slide.keepOriginal
            ? slide.pptx.s3_path
            : slide.redesign.slide_path;
          s.slides.push({
            slideId: path.charAt(0) === '/' ? path.substring(1) : path,
            isReplaced:
              path.startsWith('/newImageReplaceOutput/output/') ||
              path.startsWith('/output/pptx/'),
            isUploaded: true,
            isGenerated: false,
          });
        }
      });
      //
      this.setDownloadLoaders({
        loaderId: this.selectedSlides[0]?.redesign?.slideDetails?.id,
        downloading: true,
      });
      this.setShowDownloadSnackbar(true);
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'redesignDownload',
        logDownloadCallback: async () => {
          this.handleUpdateRecord({
            deckDownloaded: true,
          });

          if (addToLibraryClick === true && !this.isAddedToLibrary) {
            await this.updateAddedToSlideLibraryStatus(name);
            // this.isAddedToLibraryDownload = true;
            // this.setIsAddedToLibraryDownload(true);
            // this.setPremiumFtLibConfPopupData({
            //   message:
            //     this.selectedSlides.length > 1
            //       ? this.$t('premiumFeature.addedToPrezLibrary')
            //       : this.$t('premiumFeature.addedToSlideLibrary'),
            //   btnText: this.$t('premiumFeature.openInLibrary'),
            //   goToLibrary: this.goToLibrary,
            //   cancel: this.closePopup,
            // });
            // setTimeout(() => {
            //   // this.isAddedToLibraryDownload = false;
            //   this.resetAddedToLibrarySnackbarState();
            // }, 10000);
          }

          const otherData = {
            [TD_PREZNAME]: this.fileDetails.name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
            [TD_ISDOWNLOAD]: TD_ISDOWNLOAD_VALUE,
            [TD_NUMBEROFSLIDES]: s.slides.length,
            [TD_AUD]: this.audience
              ? this.audience.id
              : this.getDefaultAudience.id,
          };
          trackRedesignEvents.redesignDownload(this.currentUser, otherData);
        },
        mergeSlidesCallback: () => {
          if (this.$route.name === 'redesign') {
            this.$modal.show(
              DirectDownloadInfo,
              {
                origin: 'redesign',
              },
              {
                name: 'DirectDownloadInfo',
                width: '500px',
                height: 'auto',
              },
            );
          }
        },
        downloadStatusCallback: (payload) => {
          this.redesignOperation.loading = false;
          this.setDownloadLoaders({
            loaderId: payload.slideId,
            downloading: false,
          });
          this.$modal.hide('GenerateActionModal');
        },
        metaData: {
          payload: s,
          fileName: name,
          limitsKey: this.selectedSlides.length > 1 ? 'prezentation' : 'slide',
          origin: this.selectedSlides.length > 1 ? 'prezentation' : 'slide',
          noofslides: this.selectedSlides.length,
          mergeSlidesCallbackPayload: {},
          downloadStatusCallbackPayload: {
            slideId: this.selectedSlides[0]?.redesign?.slideDetails?.id,
          },
        },
      });
      //

      // this.handleUpdateRecord({
      //   deckDownloaded: true,
      // });
      // this.redesignOperation.loading = true;
      // const s = {
      //   slides: [],
      // };
      // this.selectedSlides.forEach((slide) => {
      //   if (slide.keepOriginal || !slide.error) {
      //     const path = slide.keepOriginal
      //       ? slide.pptx.s3_path
      //       : slide.redesign.slide_path;
      //     s.slides.push({
      //       slideId: path.charAt(0) === '/' ? path.substring(1) : path,
      //       isReplaced:
      //         path.startsWith('/newImageReplaceOutput/output/') ||
      //         path.startsWith('/output/pptx/'),
      //       isUploaded: true,
      //       isGenerated: false,
      //     });
      //   }
      // });
      // const otherData = {
      //   [TD_PREZNAME]: this.fileDetails.name,
      //   [TD_TEMPLATE]: this.selectedTemplate.code,
      //   [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
      //   [TD_ISDOWNLOAD]: TD_ISDOWNLOAD_VALUE,
      //   [TD_NUMBEROFSLIDES]: s.slides.length,
      //   [TD_AUD]: this.audience ? this.audience.id : this.getDefaultAudience.id,
      // };
      // trackRedesignEvents.redesignDownload(this.currentUser, otherData);
      // if (addToLibraryClick === true && !this.isAddedToLibrary) {
      //   await this.updateAddedToSlideLibraryStatus(name);
      //   // this.isAddedToLibraryDownload = true;
      //   this.setIsAddedToLibraryDownload(true);
      //   this.setPremiumFtLibConfPopupData({
      //     message:
      //       this.selectedSlides.length > 1
      //         ? this.$t('premiumFeature.addedToPrezLibrary')
      //         : this.$t('premiumFeature.addedToSlideLibrary'),
      //     btnText: this.$t('premiumFeature.openInLibrary'),
      //     goToLibrary: this.goToLibrary,
      //     cancel: this.closePopup,
      //   });
      //   setTimeout(() => {
      //     // this.isAddedToLibraryDownload = false;
      //     this.resetAddedToLibrarySnackbarState();
      //   }, 10000);
      // }
      // callMergeSlidesApi(s)
      //   .then((res) => {
      //     const mergedslide = res.data;
      //     if (mergedslide.includes('.pptx')) {
      //       const mergedUrl = `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
      //       this.$modal.show(
      //         DirectDownloadInfo,
      //         {
      //           origin: 'redesign',
      //         },
      //         {
      //           name: 'DirectDownloadInfo',
      //           width: '500px',
      //           height: 'auto',
      //         },
      //       );
      //       downloadFileWithCustomName(mergedUrl, name);
      //       this.redesignOperation.loading = false;
      //       this.$modal.hide('GenerateActionModal');
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    async startReplaceVisuals(val) {
      this.changeReq = {
        type: 'replaceVisuals',
      };
      this.error = null;
      const redesignSlideIndex = this.slideIndex;
      this.selectedSlides[this.slideIndex].completed = false;
      const resp = await triggerReplaceImage(
        val.imageToReplace,
        // passing origin feature in replace image payload
        { ...val.currentSelectedSlideImageData, originFeature: 'Redesign' },
        this.selectedSlides[this.slideIndex].redesign,
        this.currentUser,
      );
      if (!resp.error) {
        let replaceSource = '';
        let matomoReplaceSource = '';
        replaceSource = val.imageToReplace?.source;
        if (replaceSource === 'adobe' || replaceSource === 'freepik')
          matomoReplaceSource = 'Library';
        if (replaceSource === 'brand-images')
          matomoReplaceSource = this.currentUser?.company?.displayName;
        if (replaceSource === 'upload') matomoReplaceSource = 'Upload';
        trackRedesignEvents.redesignReplaceVisual(this.currentUser, {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_REPLACE_IMAGE_SOURCE]: matomoReplaceSource,
          [TD_AUD]: this.audience
            ? this.audience.id
            : this.getDefaultAudience.id,
          [TD_PLACEMENT]: this.iconPlacement,
        });
        const reformattedIndex = this.selectedSlides[
          redesignSlideIndex
        ].redesign.reformattedSlides.slides.findIndex(
          (i) =>
            i.id === this.selectedSlides[redesignSlideIndex].redesign.meta.id,
        );
        const redesignedIndex = this.selectedSlides[
          redesignSlideIndex
        ].redesign.redesignedSlides.slides.findIndex(
          (i) =>
            i.id === this.selectedSlides[redesignSlideIndex].redesign.meta.id,
        );
        if (reformattedIndex >= 0) {
          this.selectedSlides[
            redesignSlideIndex
          ].redesign.reformattedSlides.slides[reformattedIndex] = {
            ...this.selectedSlides[redesignSlideIndex].redesign
              .reformattedSlides.slides[reformattedIndex],
            s3_path: resp.path,
            thumbnail: resp.thumbnail,
            thumbnailPath: resp.landscape,
            landscapePath: resp.landscape,
          };
        }
        if (redesignedIndex >= 0) {
          this.selectedSlides[
            redesignSlideIndex
          ].redesign.redesignedSlides.slides[redesignedIndex] = {
            ...this.selectedSlides[redesignSlideIndex].redesign.redesignedSlides
              .slides[redesignedIndex],
            s3_path: resp.path,
            thumbnail: resp.thumbnail,
            thumbnailPath: resp.landscape,
            landscapePath: resp.landscape,
          };
        }
        this.selectedSlides[redesignSlideIndex].redesign = {
          ...this.selectedSlides[redesignSlideIndex].redesign,
          slide_path: resp.path,
          thumbnail: resp.thumbnail,
          landscape: resp.landscape,
        };
        this.$refs.replaceVisual?.selectImage(
          val.imageToReplace,
          val.currentSelectedSlideImageData,
          this.selectedSlides[redesignSlideIndex].id,
        );
        this.resetSlideLibraryFlags();
        this.$set(this.selectedSlides, redesignSlideIndex, {
          ...this.selectedSlides[redesignSlideIndex],
          completed: true,
        });
      } else {
        this.$set(this.selectedSlides, redesignSlideIndex, {
          ...this.selectedSlides[redesignSlideIndex],
          redesign: {
            reformattedSlides: {
              slides: [],
              loading: false,
            },
            redesignedSlides: {
              slides: [],
              loading: false,
            },
          },
          completed: true,
          error: true,
        });
      }
    },
    handleTemplateChange(template) {
      this.templateInfo = template;
      this.dialogTitle = this.$t('redesign.content.templateChangeTitle');
      this.dialogText = this.$t('redesign.content.templateChangeBody');
      this.dialogPrimary = this.$t('redesign.content.templateConfirm');
      this.dialogSecondary = this.$t('redesign.content.templateCancel');
      this.showConfirmation = true;
    },
    // handleAudienceChange(event) {
    //   this.changeReq = event;
    //   this.dialogTitle = this.$t('redesign.content.audienceChangeTitle');
    //   this.dialogText = this.$t('redesign.content.audienceChangeBody');
    //   this.dialogPrimary = this.$t('redesign.content.audienceConfirm');
    //   this.dialogSecondary = this.$t('redesign.content.templateCancel');
    //   this.showConfirmation = true;
    // },
    async handleConfirmation(event) {
      let replaceSource = '';
      let matomoReplaceSource = '';
      this.changeReq = event;
      switch (event.type) {
        // case RedesignOperation.AudienceChange:
        //   trackRedesignEvents.redesignAudienceChange(this.currentUser, {
        //     [TD_AUD]: event.val.id,
        //     [TD_PREZNAME]: this.newFilesToUpload[0].name,
        //     [TD_TEMPLATE]: this.selectedTemplate.code,
        //     [TD_PLACEMENT]: this.iconPlacement,
        //     [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        //   });
        //   this.checkRedesignLimit(() => {
        //     this.audience = event.val;
        //     this.handleUpdateRecord({
        //       audience: event.val.id,
        //       changeAudienceStatus: true,
        //     });
        //     this.redesignDeck({ audience: event.val });
        //   });
        //   break;
        case RedesignOperation.ReplaceVisualChange:
          replaceSource = this.$refs.replaceVisual?.imageToReplace?.source;
          if (replaceSource === 'adobe' || replaceSource === 'freepik')
            matomoReplaceSource = 'Library';
          if (replaceSource === 'brand-images')
            matomoReplaceSource = this.currentUser?.company?.displayName;
          if (replaceSource === 'upload') matomoReplaceSource = 'Upload';
          trackRedesignEvents.redesignReplaceVisual(this.currentUser, {
            [TD_PREZNAME]: this.fileDetails.name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_REPLACE_IMAGE_SOURCE]: matomoReplaceSource,
            [TD_AUD]: this.audience
              ? this.audience.id
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.iconPlacement,
          });
          break;
        case RedesignOperation.LayoutChange:
          trackRedesignEvents.redesignLayoutChanged(this.currentUser, {
            [TD_PREZNAME]: this.fileDetails.name,
            [TD_TEMPLATE]: this.selectedTemplate.code,
            [TD_AUD]: this.audience
              ? this.audience.id
              : this.getDefaultAudience.id,
            [TD_PLACEMENT]: this.iconPlacement,
            [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          });

          this.resetSlideLibraryFlags();
          this.$set(this.selectedSlides, this.slideIndex, {
            ...this.selectedSlides[this.slideIndex],
            completed: true,
            error: false,
            redesign: {
              ...this.selectedSlides[this.slideIndex].redesign,
              slide_path: event.slide.s3_path,
              thumbnail: event.slide.thumbnail,
              confidenceScore: event.slide.confidence_score,
              landscape: event.slide.landscapePath,
              meta: { ...event.slide },
              slideDetails: { ...event.slide },
            },
            keepOriginal: false,
          });

          // this.redesignSlide({
          //   slide: event.slide,
          // });
          break;
        default:
      }
    },
    onDialogConfirm() {
      this.showConfirmation = false;
      // if (
      //   this.changeReq &&
      //   this.changeReq.type === RedesignOperation.AudienceChange
      // ) {
      //   this.handleConfirmation(this.changeReq);
      // }
      if (this.dialogPrimary === 'Change template') {
        const oldTemplate = this.template;
        this.template = this.templateInfo.code;
        this.changeReq = {
          type: RedesignOperation.TemplateChange,
          val: this.template,
        };
        const otherData = {
          [TD_AUD]: this.audience.id,
          [TD_PREZNAME]: this.newFilesToUpload[0].name,
          [TD_TEMPLATE]: oldTemplate,
          [TD_COMMON_COLUMN_NAME]: TD_TEMPLATE_SWITCHED,
          [TD_COMMON_COLUMN_VALUE]: this.template,
          [TD_PLACEMENT]: this.iconPlacement,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        };
        trackRedesignEvents.redesignTemplateSwitched(
          this.currentUser,
          otherData,
        );
        this.handleUpdateRecord({
          templateCode: this.template,
          changeTemplateStatus: true,
        });
        this.checkRedesignLimit(() => {
          this.redesignDeck({ template: this.template });
        });
      } else if (
        this.dialogPrimary === 'Yes' &&
        this.dialogTitle === 'Exit Replace Visuals'
      ) {
        this.handleConfirmation(this.changeReq);
      } else if (this.dialogPrimary === 'Yes') {
        this.$router.push({
          path: `/home/overnight-prezentations`,
        });
      }
    },
    showTutorial() {
      this.showTutorialMenu = !this.showTutorialMenu;
      trackRedesignEvents.redesignTutorial(this.currentUser, {
        [TD_TEMPLATE]: NA,
      });
    },
    onDialogCancel() {
      this.changeReq = null;
      this.showConfirmation = false;
    },
    handleOnDivClickFileSelection(event) {
      this.previewFiles(event.target.files);
    },
    onUploadDivClick(action) {
      if (this.step === 0 && !this.loading) {
        this.$refs.hiddenInput.click();
        const otherData = {
          [TD_PLACEMENT]: action === 'cta' ? TD_UPLOAD_CTA : TD_UPLOAD_SECTION,
          [TD_TEMPLATE]: NA,
        };
        trackRedesignEvents.redesignUpload(this.currentUser, otherData);
      }
    },
    // onDismissBetaBanner() {
    //   const userData = {
    //     performIncrements: {
    //       redesignBanner: 1,
    //     },
    //   };
    //   usersApi.methods
    //     .updateUserProfile(userData)
    //     .then((updatedUser) => {
    //       const userInfo = this.currentUser;
    //       userInfo.user = updatedUser;
    //       this.setCurrentUser(userInfo);
    //     })
    //     .catch((err) => console.log(err));
    // },
    async uploadFile() {
      this.checkingUsage = true;
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
          this.checkingUsage = false;
        })
        .catch((error) => {
          this.checkingUsage = false;
          console.log(error);
        });
      if (
        !(
          this.limitsInfoObj.redesign &&
          this.limitsInfoObj?.redesign?.isLimitCrossed
        ) ||
        this.limitsInfoObj?.redesign?.isFullAccess
      ) {
        EventBus.$emit('MINIMIZE_LEFTNAV');
        this.changeReq = null;
        this.error = null;
        this.step = 1;
        this.loading = 'file-upload';
        if (this.newFilesToUpload.length > 0) {
          try {
            if (this.newFilesToUpload[0].file.size / 1024 / 1024 > 200) {
              if (this.step === 1) {
                const otherDatas = {
                  [TD_NUMBEROFSLIDES]: this.slides.length,
                  [TD_PREZNAME]: this.newFilesToUpload[0]?.name,
                  [TD_FILE_SIZE]: `${
                    this.newFilesToUpload[0]?.file?.size
                      ? this.newFilesToUpload[0].file.size / 1024 / 1024
                      : 0
                  } MB`,
                };
                trackRedesignEvents.redesignUploadRedesignFailed(
                  this.currentUser,
                  otherDatas,
                );
                this.cancelRequest();
                this.error = 'SIZE_ERR';
              }
            } else {
              const otherData = {
                [TD_PREZNAME]: this.newFilesToUpload[0].name,
                [TD_TEMPLATE]: NA,
              };
              trackRedesignEvents.redesignUploadStart(
                this.currentUser,
                otherData,
              );
              const preProcessResp = await uploadChunks(
                this.newFilesToUpload[0],
                'redesign',
              );
              if (preProcessResp && this.step === 1) {
                const postProcessUpload = await uploadOPFile(
                  this.newFilesToUpload[0].requestIdentifier,
                  this.newFilesToUpload[0].name,
                  'redesign',
                );
                if (postProcessUpload && this.step === 1) {
                  this.newFilesToUpload[0] = {
                    ...this.newFilesToUpload[0],
                    ...postProcessUpload.data,
                    processingCompleted: true,
                  };
                  const context = {
                    s3_path: this.newFilesToUpload[0].s3Prefix,
                    s3_bucket: this.newFilesToUpload[0].s3Bucket,
                    format: 'png',
                  };

                  const { jobId, output } =
                    await this.redesignService.startUpload(
                      context,
                      'U-Thumbnails',
                    );
                  this.newFilesToUpload[0] = {
                    ...this.newFilesToUpload[0],
                    uploadId: jobId,
                  };
                  this.uploadID = jobId;
                  // eslint-disable-next-line prefer-destructuring
                  this.fileDetails = this.newFilesToUpload[0];
                  this.slides = await this.getImages(output);
                  this.loading = null;
                } else if (this.step === 1) {
                  const otherDatas = {
                    [TD_NUMBEROFSLIDES]: this.slides.length,
                    [TD_PREZNAME]: this.newFilesToUpload[0]?.name,
                    [TD_FILE_SIZE]: `${
                      this.newFilesToUpload[0]?.file?.size
                        ? this.newFilesToUpload[0].file.size / 1024 / 1024
                        : 0
                    } MB`,
                  };
                  trackRedesignEvents.redesignUploadRedesignFailed(
                    this.currentUser,
                    otherDatas,
                  );
                  this.cancelRequest();
                  this.error = 'UPL_ERR';
                }
              }
            }
          } catch (err) {
            console.log(err);
            let numOfPages = 0;
            if (err?.response?.data?.data) {
              numOfPages = err.response.data.data.numOfPages;
            }
            if (this.step === 1) {
              const otherData = {
                [TD_NUMBEROFSLIDES]: numOfPages,
                [TD_PREZNAME]: this.newFilesToUpload[0]?.name,
                [TD_FILE_SIZE]: `${
                  this.newFilesToUpload[0]?.file?.size
                    ? this.newFilesToUpload[0].file.size / 1024 / 1024
                    : 0
                } MB`,
              };
              trackRedesignEvents.redesignUploadRedesignFailed(
                this.currentUser,
                otherData,
              );
              this.cancelRequest();
              if (numOfPages > 30) {
                this.error = 'UPL_FILE_ERR';
              } else {
                this.error = 'UPL_ERR';
              }
              this.loading = null;
            }
          }
        }
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'redesign' });
      }
    },
    handleAddToSlideLibrary() {
      if (this.isAddedToLibrary) {
        // this.isAddedToLibraryDownload = false;
        // this.resetAddedToLibrarySnackbarState();
        if (this.addedToLibraryData.type === 'slide') {
          this.removingFromLibrary = true;
          deleteSlide(this.addedToLibraryData.info.unique_id)
            .then(() => {
              // this.postCompleteLog('added_to_library', false);
              this.resetSlideLibraryFlags();
              this.removingFromLibrary = false;
              // this.$modal.hide('GenerateActionModal');
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.removingFromLibrary = true;
          removeFromLibrary(this.addedToLibraryData.info.prezentation.id).then(
            () => {
              // this.postCompleteLog('added_to_library', false);
              this.resetSlideLibraryFlags();
              this.removingFromLibrary = false;
              // this.$modal.hide('GenerateActionModal');
            },
          );
        }
      } else {
        this.redesignOperation.loading = false;
        this.$modal.show(
          GenerateActionModal,
          {
            heading:
              this.selectedSlides.length > 1
                ? this.$t('generateToolBar.add_to_prez_exchange')
                : this.$t('generateToolBar.add_to_slide_library'),
            primaryBtnText: this.$t('premiumFeature.addToLibrary'),
            modalType: 'slidesLibrary',
            type: this.selectedSlides.length > 1 ? 'prezentation' : 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            operation: this.redesignOperation,
            filename:
              this.modifiedFileName ||
              `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
          },
          {
            name: 'GenerateActionModal',
            width: '500px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },
    async updateAddedToSlideLibraryStatus(name, addFavorite = false) {
      if (addFavorite === true) {
        const otherData = {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_COMMON_COLUMN_NAME]: TD_LIBRARY,
          [TD_COMMON_COLUMN_VALUE]:
            this.selectedSlides.length > 1
              ? TD_PREZENTATION_LIBRARY
              : TD_SLIDES_LIBRARY,
          [TD_SLIDE_NAME]:
            this.modifiedFileName ||
            `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
        };
        trackRedesignEvents.redesignAddToFavourite(this.currentUser, otherData);
      } else {
        const otherData = {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_COMMON_COLUMN_NAME]: TD_LIBRARY,
          [TD_COMMON_COLUMN_VALUE]:
            this.selectedSlides.length > 1
              ? TD_PREZENTATION_LIBRARY
              : TD_SLIDES_LIBRARY,
          [TD_SLIDE_NAME]:
            this.modifiedFileName ||
            `${this.fileDetails?.name.replace(/.pptx/g, '')}`,
        };
        trackRedesignEvents.redesignAddToLibrary(this.currentUser, otherData);
      }
      if (this.isAddedToLibrary && addFavorite) {
        await this.addToFavorite();
        this.setAddedToLibOrFavSnackbar({
          show: true,
          ctaText: 'snackBarItems.goToFavorites',
          snackbarText:
            this.selectedSlides.length > 1
              ? 'snackBarItems.prezAddedToFav'
              : 'snackBarItems.slideAddedToFav',
          navFn: this.goToFavLibrary,
          snackbarType: 'goToFav',
        });
        return;
      }
      this.modifiedFileName = name;
      if (this.selectedSlides.length > 1) {
        const nonErrSlides = this.selectedSlides.filter(
          (i) => !i.error && i.completed,
        );
        const payload = {
          source: 'redesign',
          prezentation_name: name,
          audience_id:
            this.audience?.type === 'private_audience'
              ? this.audience.email
              : this.audience.id,
          audience_owner_id: this.audience.ownerID,
          audience_type: this.audience.type || 'Me',
          audience_num_id: this.audience.num_id,
          theme_code: this.template,
          add_to_prezentations: true,
          slides: nonErrSlides.map((i, idx) => {
            const result = {
              pptx_file: this.formatProperty(i.redesign.slide_path),
              pdf_file: this.formatProperty(i.redesign.thumbnail), // img location
              slide_name: `${name}_Slide_${idx}`, // slide sequence  - ppt name + _Slide_idx
              business_ideas: i.redesign.slideDetails?.prefs?.category
                ? [i.redesign.slideDetails?.prefs?.category]
                : [],
              node_count: i.redesign.meta?.prefs?.node
                ? `${i.redesign.meta?.prefs?.node}`
                : '',
              ml_detected_tags: { ...i.redesign.slideDetails?.prefs },
              theme_code: this.template,
              category: i.redesign.slideDetails?.prefs?.category || '',
              constructs: i.redesign.slideDetails?.prefs?.construct || '',
            };
            return result;
          }),
        };
        this.redesignOperation.loading = true;
        await addPrezentationDeck(payload)
          .then(async (resp) => {
            // this.postCompleteLog('added_to_library', true);
            this.addedToLibraryData = {
              info: resp.data,
              type: 'prezentation',
            };
            this.isAddedToLibrary = true;
            this.redesignOperation.loading = false;
            this.prezOrSlideId = resp.data.prezentation.id;
            this.redesignOperation.success = true;
            if (addFavorite) {
              await this.addToFavorite();
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToFavorites',
                snackbarText: 'snackBarItems.prezAddedToFav',
                navFn: this.goToFavLibrary,
                snackbarType: 'goToFav',
              });
            } else {
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToLibrary',
                snackbarText: 'snackBarItems.prezAddedToLib',
                navFn: this.goToLibrary,
                snackbarType: 'goToLib',
              });
            }
            this.redesignOperation.loading = false;
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.redesignOperation.loading = false;
            this.redesignOperation.success = false;
          });
      } else {
        this.redesignOperation.loading = true;
        const payload = {
          source: 'redesign',
          isShare: false,
          sharedEntities: [],
          slidesData: [
            {
              tags: {
                uniqueID:
                  this.selectedSlides[0].redesign.slideDetails?.id || '',
                node: this.selectedSlides[0].redesign.meta?.node || '',
                construct:
                  this.selectedSlides[0].redesign.slideDetails?.construct || '',
                ...this.selectedSlides[0].redesign.slideDetails?.prefs,
                source: 'redesign',
              },
              categories: this.selectedSlides[0].redesign.slideDetails?.prefs
                ?.category
                ? [
                    this.selectedSlides[0].redesign.slideDetails?.prefs
                      ?.category,
                  ]
                : [],
              constructs:
                this.selectedSlides[0].redesign.slideDetails?.prefs
                  ?.construct || '',
              title: name,
              summary: '',
              filename: this.selectedSlides[0].redesign.slide_path,
              landscape: this.formatProperty(
                this.selectedSlides[0].redesign.thumbnail,
              ),
              thumbnail: this.formatProperty(
                this.selectedSlides[0].redesign.thumbnail,
              ),
              type: 'redesign',
              templateCode: this.template || '',
              audience: this.audience.id,
              audienceNumId: Number(this.audience.num_id),
              audienceType: this.audience.type,
            },
          ],
        };
        await createSlide(payload)
          .then(async (resp) => {
            // this.postCompleteLog('added_to_library', true);
            this.addedToLibraryData = {
              info: resp.data[0],
              type: 'slide',
            };
            this.isAddedToLibrary = true;
            this.redesignOperation.loading = false;
            this.prezOrSlideId = resp.data[0].id;
            this.redesignOperation.success = true;
            if (addFavorite) {
              await this.addToFavorite();
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToFavorites',
                snackbarText: 'snackBarItems.slideAddedToFav',
                navFn: this.goToFavLibrary,
                snackbarType: 'goToFav',
              });
            } else {
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToLibrary',
                snackbarText: 'snackBarItems.slideAddedToLib',
                navFn: this.goToLibrary,
                snackbarType: 'goToLib',
              });
            }
            this.redesignOperation.loading = false;
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.redesignOperation.loading = false;
            this.redesignOperation.success = false;
          });
      }
    },
    async handleAddToFavorites() {
      if (this.isAddedAsFavorite) {
        if (this.addedToLibraryData.type === 'slide') {
          this.removingAsFavorite = true;
          const params = {
            assetIds: [this.addedToLibraryData.info.unique_id],
            getFavourite: true,
          };
          const resp = await getSlideDetail(params);
          const favSlideId =
            resp.data[this.addedToLibraryData.info.unique_id].favouriteId;
          await deleteFavorite(favSlideId)
            .then(() => {
              // this.postCompleteLog('favorite', false);
              this.isAddedAsFavorite = false;
              this.removingAsFavorite = false;
              this.handleUpdateRecord({
                addedToFavorite: false,
              });
              // this.$modal.hide('GenerateActionModal');
            })
            .catch((err) => console.log(err));
        } else {
          this.removingAsFavorite = true;
          removePrezentationAsFavorite(
            this.addedToLibraryData.info.prezentation.id,
          ).then(() => {
            // this.postCompleteLog('favorite', false);
            this.isAddedAsFavorite = false;
            this.removingAsFavorite = false;
            this.handleUpdateRecord({
              addedToFavorite: false,
            });
            // this.$modal.hide('GenerateActionModal');
          });
        }
      } else {
        this.$modal.show(
          GenerateActionModal,
          {
            heading:
              this.selectedSlides.length > 1
                ? this.$t('premiumFeature.addPrezentationToFavourites')
                : this.$t('premiumFeature.addSlideToFavourites'),
            primaryBtnText: this.$t('slideDetails.addFavorite'),
            modalType: 'favorites',
            type: this.selectedSlides.length > 1 ? 'prezentation' : 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            isAddedToLibrary: this.isAddedToLibrary,
            operation: this.redesignOperation,
            filename: `${this.fileDetails.name.replace(/.pptx/g, '')}-redesign`,
          },
          {
            name: 'GenerateActionModal',
            width: '500px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },
    resetSlideLibraryFlags() {
      this.isAddedToLibrary = false;
      this.isAddedAsFavorite = false;
      this.addedToLibraryData = null;
    },
    async addToFavorite() {
      if (this.addedToLibraryData.type === 'slide') {
        const favoriteData = {
          id: uuidv4(),
          assetID: this.addedToLibraryData.info.unique_id,
          source: this.addedToLibraryData.info.tags.source,
          themeID: this.template,
          tailorID: this.audience?.id,
          audienceType: this.audience?.type,
        };
        await createFavorite(favoriteData)
          .then(() => {
            // this.postCompleteLog('favorite', true);
            this.isAddedAsFavorite = true;
            this.handleUpdateRecord({
              addedToFavorite: true,
            });
            this.redesignOperation.loading = false;
            this.redesignOperation.success = true;
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.redesignOperation.loading = false;
            this.redesignOperation.success = false;
          });
      } else {
        await addPrezentationAsFavorite({
          prezentationId: this.addedToLibraryData.info.prezentation.id,
        }).then(() => {
          // this.postCompleteLog('favorite', true);
          this.isAddedAsFavorite = true;
          this.redesignOperation.loading = false;
          this.redesignOperation.success = true;
          this.handleUpdateRecord({
            addedToFavorite: true,
          }).catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.redesignOperation.loading = false;
            this.redesignOperation.success = false;
          });
          // this.$modal.hide('GenerateActionModal');
        });
      }
    },
    formatProperty(str) {
      return str.charAt(0) === '/' ? str : `/${str}`;
    },
    getImages(slides) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const slideList = [];
          for (const slide of slides) {
            const slideObj = {
              ...slide,
              key: slide.index,
              processing: false,
              completed: false,
              error: false,
              redesign: {},
              keepOriginal: false,
              id: null,
            };
            slideObj.thumbnail_png.thumbnail = '';
            slideObj.thumbnail_png.thumbnail = await getFileURL(
              this.currentUser.user.id,
              slide.thumbnail_png.s3_path,
              this.currentUser.userIp,
              'IOCUpload',
            );
            slideList.push(slideObj);
          }
          resolve(slideList);
        } catch (err) {
          reject(err);
        }
      });
    },
    async handleOnSlidesSelectionCta(slides) {
      try {
        this.selectedSlides = [...slides];
        this.loading = 'templates-loading';
        const otherData = {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_COMMON_COLUMN_NAME]: TD_NUMBER_OF_SLIDES_UPLOADED,
          [TD_COMMON_COLUMN_VALUE]: this.slides.length,
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: NA,
        };
        trackRedesignEvents.redesignUploadNext(this.currentUser, otherData);
        const resp = await checkTaskStatus(
          this.uploadID,
          (interval) => {
            this.redesignService.intervals.push(interval);
          },
          'Recommended-Templates',
        );
        this.templates = await fetchRecomendedTemplateImages(
          resp[0],
          [...this.themes],
          'redesign',
        );
        this.step = 2;
        this.loading = null;
      } catch (err) {
        console.log(err);
        this.cancelRequest();
        this.error = 'RDSN_ERR';
        this.loading = null;
      }
    },
    handleTemplateSelection(template) {
      this.selectedTemplate = template;
      this.template = template.code;
      const otherData = {
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
        [TD_TEMPLATE]: this.selectedTemplate.code,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        [TD_AUD]: this.audience ? this.audience.id : this.getDefaultAudience.id,
        [TD_RECOMMENDED_TEMPLATE]: this.templates.recommended_templates
          .map((templates) => templates.code)
          .join('/'),
      };
      trackRedesignEvents.redesignUploadRedesignFinal(
        this.currentUser,
        otherData,
      );
      postFeatureUsage({ feature: 'redesign' })
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));

      this.startInitalRedesign(true);
    },
    async checkRedesignLimit(callback) {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => {
          this.checkingUsage = false;
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.redesign?.isLimitCrossed ||
        this.limitsInfoObj?.redesign?.isFullAccess
      ) {
        postFeatureUsage({ feature: 'redesign' })
          .then((response) => {
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));
        callback();
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'redesign' });
      }
    },
    async redesign() {
      const otherData = {
        [TD_TEMPLATE]: this.selectedTemplate.code,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
      };
      trackRedesignEvents.redesignCTA(this.currentUser, otherData);
      this.checkingUsage = true;
      this.checkRedesignLimit(() => {
        this.recordId = null;
        this.changeReq = null;
        this.error = null;
        this.audience = this.getDefaultAudience;
        this.template = this.selectedTemplate.code;
        this.checkingUsage = false;
        this.setSmartTools(false);
        this.redesignDeck();
      });
    },
    handleIndexChange(index) {
      this.slideIndex = index;
    },
    async startInitalRedesign(processStarted = false) {
      if (processStarted) {
        this.processInProgress = processStarted;
        this.startTime = performance.now();
      }
      this.audience = this.getDefaultAudience;
      this.slideIndex = this.selectedSlides[0].index;
      this.initialRedesignCompleted = false;
      this.redesignLoader.nextState(1);
      // this.redesignSlideIndex = this.selectedSlides[0].index;
      this.step = 3;
      this.redesignStartTime = Date.now();
      try {
        let slidesProcessed = 0;
        checkTaskStatus(
          this.uploadID,
          (interval) => {
            this.redesignService.intervals.push(interval);
          },
          'U-SplitPPTX',
          (res, interval) => {
            const pptxOutput = res.outputs['U-SplitPPTX'].data;
            if (pptxOutput && pptxOutput.length) {
              pptxOutput.forEach(async (sld) => {
                const slideIndex = this.selectedSlides.findIndex(
                  (i) => i.index === sld.index,
                );
                if (
                  slideIndex >= 0 &&
                  !this.selectedSlides[slideIndex].id &&
                  !this.selectedSlides[slideIndex].processing &&
                  !this.selectedSlides[slideIndex].error
                ) {
                  this.$set(this.selectedSlides, slideIndex, {
                    ...this.selectedSlides[slideIndex],
                    pptx: sld.pptx,
                    processing: true,
                    redesign: {
                      redesignedSlides: {
                        slides: [],
                        loading: true,
                      },
                      reformattedSlides: {
                        slides: [],
                        loading: true,
                      },
                    },
                  });
                  const context = {
                    img_filepath:
                      this.selectedSlides[slideIndex].thumbnail_png.s3_path,
                    pptx_filepath: sld.pptx.s3_path,
                    company: this.currentUser.company.name,
                    template: this.template,
                  };
                  const { jobId } = await this.redesignService.startRedesignJob(
                    context,
                  );
                  this.$set(this.selectedSlides, slideIndex, {
                    ...this.selectedSlides[slideIndex],
                    id: jobId,
                  });
                  slidesProcessed++;
                  if (this.selectedSlides.length === slidesProcessed) {
                    this.redesignService
                      .checkSlidesStatus((slidesResp) => {
                        slidesResp.forEach((slide) => {
                          const idx = this.selectedSlides.findIndex(
                            (i) => i.id === slide.id,
                          );
                          this.handleDeckSlides(slide, idx);
                        });
                      })
                      .then(() => {});
                    clearInterval(interval);
                    cancelGSRequest(this.uploadID).then(() => {});
                  }
                }
              });
            }
          },
        ).then((originalPptx) => {
          console.log(originalPptx);
        });
      } catch (err) {
        console.log(err);
        this.processInProgress = false;
        this.error = 'RDSGN_ERR';
      }
    },
    async handleDeckSlides(slide, index) {
      if (slide.error) {
        console.log('error', slide, index);
        this.$set(this.selectedSlides, index, {
          ...this.selectedSlides[index],
          redesign: {
            reformattedSlides: {
              slides: [],
              loading: false,
            },
            redesignedSlides: {
              slides: [],
              loading: false,
            },
          },
          id: slide.id || null,
          completed: true,
          error: true,
          processing: false,
        });
      } else {
        if (this.step < 3) {
          return;
        }
        try {
          const { slideInfo, callback } =
            await this.redesignService.fetchSlideMetadata(
              slide.resp,
              this.currentUser,
              this.changeReq,
            );
          if (this.step < 3) {
            return;
          }
          if (!this.initialRedesignCompleted) {
            this.initialRedesignCompleted = true;
            this.slideIndex = index;
            this.redesignSlideIndex = index;
            this.openRightPanel('changeLayout');
          }
          console.log(slideInfo, index, 'success');
          this.$set(this.selectedSlides, index, {
            ...this.selectedSlides[index],
            ...slideInfo,
            id: slide.id,
            completed: true,
            processing: false,
          });
          console.log(
            'completed',
            index,
            this.selectedSlides[index],
            !!callback,
          );
          if (callback && this.step === 3) {
            try {
              const { reccos, confidenceScore } =
                await this.redesignService.fetchOtherRecommendation(
                  callback,
                  this.currentUser,
                );
              if (
                this.selectedSlides[index].completed &&
                !this.selectedSlides[index].error
              ) {
                this.selectedSlides[index].redesign.confidenceScore =
                  confidenceScore;
                this.selectedSlides[index].redesign.reformattedSlides = {
                  slides: [
                    ...this.selectedSlides[index].redesign.reformattedSlides
                      .slides,
                    ...reccos,
                  ],
                  loading: false,
                };
                if (this.slidesCompleted) {
                  this.handleUpdateRecord();
                }
              }
            } catch (err) {
              console.log(err);
              console.log(
                index,
                this.selectedSlides[index],
                'failed to get confidence score and option 0 recommendations',
              );
              this.selectedSlides[index].redesign.reformattedSlides = {
                slides: [
                  ...this.selectedSlides[index].redesign.reformattedSlides
                    .slides,
                ],
                loading: false,
              };
            }
          }
        } catch (err) {
          console.log(err);
          this.$set(this.selectedSlides, index, {
            ...this.selectedSlides[index],
            redesign: {
              reformattedSlides: {
                slides: [],
                loading: false,
              },
              redesignedSlides: {
                slides: [],
                loading: false,
              },
            },
            completed: true,
            error: true,
            processing: false,
          });
        }
      }
    },
    async getSlideInfo(context, index) {
      try {
        const { slideOutput, jobId } = await this.redesignService.getSlideInfo(
          context,
        );
        this.$set(this.selectedSlides, index, {
          ...this.selectedSlides[index],
          id: jobId,
        });
        const { slideInfo, callback } =
          await this.redesignService.fetchSlideMetadata(
            slideOutput,
            this.currentUser,
            this.changeReq,
          );
        if (callback) {
          try {
            const { reccos, confidenceScore } =
              await this.redesignService.fetchOtherRecommendation(
                callback,
                this.currentUser,
              );
            if (
              this.selectedSlides[index].completed &&
              !this.selectedSlides[index].error
            ) {
              this.selectedSlides[index].redesign.confidenceScore =
                confidenceScore;
              this.selectedSlides[index].redesign.reformattedSlides = {
                slides: [
                  ...this.selectedSlides[index].redesign.reformattedSlides
                    .slides,
                  ...reccos,
                ],
                loading: false,
              };
              if (this.slidesCompleted) {
                this.handleUpdateRecord();
              }
            }
          } catch (err) {
            console.log(err);
            console.log(
              index,
              this.selectedSlides[index],
              'failed to get confidence score and option 0 recommendations',
            );
            this.selectedSlides[index].redesign.reformattedSlides = {
              slides: [
                ...this.selectedSlides[index].redesign.reformattedSlides.slides,
              ],
              loading: false,
            };
          }
        }
        console.log(slideInfo, index);
        this.$set(this.selectedSlides, index, {
          ...this.selectedSlides[index],
          ...slideInfo,
          id: jobId,
          completed: true,
          processing: false,
        });
      } catch (err) {
        console.log(err);
        this.$set(this.selectedSlides, index, {
          ...this.selectedSlides[index],
          redesign: {
            reformattedSlides: {
              slides: [],
              loading: false,
            },
            redesignedSlides: {
              slides: [],
              loading: false,
            },
          },
          completed: true,
          error: true,
          processing: false,
        });
      }
    },
    async redesignDeck(contextOptions = {}) {
      this.resetSlideLibraryFlags();
      this.slideIndex = this.selectedSlides[0].index;
      this.initialRedesignCompleted = false;
      this.redesignLoader.nextState(1);

      // this.redesignSlideIndex = this.selectedSlides[0].index;
      for (const slide of this.selectedSlides) {
        slide.completed = false;
        slide.error = false;
        slide.redesign = {};
        slide.keepOriginal = false;
      }
      for (const [index, slide] of this.selectedSlides.entries()) {
        const context = {
          img_filepath: this.selectedSlides[index].thumbnail_png.s3_path,
          pptx_filepath: slide.pptx.s3_path,
          company: this.currentUser.company.name,
          // template: this.currentTheme.code,
          operation: this.changeReq ? this.changeReq.type : null,
          template: contextOptions.template || this.template,
          ...(this.changeReq && {
            override: {
              workflow: slide.id,
            },
          }),
        };
        const { jobId } = await this.redesignService.startRedesignJob(context);
        this.$set(this.selectedSlides, index, {
          ...this.selectedSlides[index],
          id: jobId,
        });
      }
      // this.selectedSlides.forEach(async (slide) => {});
      this.redesignService
        .checkSlidesStatus((slidesResp) => {
          slidesResp.forEach((slide) => {
            if (this.step < 3) {
              return;
            }
            const idx = this.selectedSlides.findIndex((i) => i.id === slide.id);
            this.handleDeckSlides(slide, idx);
          });
        })
        .then(() => {});
    },
    async redesignSlide(placement = '') {
      if (placement === 'bar') {
        const otherData = {
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_NUMBEROFSLIDES]: 1,
        };
        trackRedesignEvents.redesignSlideRetry(this.currentUser, otherData);
      }

      this.checkRedesignLimit(() => {
        this.resetSlideLibraryFlags();
        this.openRightPanel(null);
        const redesignSlideIndex = this.slideIndex;
        this.$set(this.selectedSlides, redesignSlideIndex, {
          ...this.selectedSlides[redesignSlideIndex],
          processing: false,
          completed: false,
          error: false,
          redesign: {},
          keepOriginal: false,
        });
        const context = {
          img_filepath: this.selectedSlide.thumbnail_png.s3_path,
          pptx_filepath: this.selectedSlide.pptx.s3_path,
          company: this.currentUser.company.name,
          template: this.template,
          operation: RedesignOperation.AudienceChange,
          override: {
            workflow: this.selectedSlide.id,
          },
        };
        this.getSlideInfo(context, redesignSlideIndex);
      });
    },
    async cancelRequest() {
      this.checkingUsage = false;
      const otherData = {
        [TD_TEMPLATE]: NA,
      };
      if (this.step === 1) {
        trackRedesignEvents.redesignUploadCancel(this.currentUser, otherData);
      } else if (this.step === 2) {
        trackRedesignEvents.redesignUploadTemplatePageCancel(
          this.currentUser,
          otherData,
        );
      } else if (this.step === 3) {
        this.processInProgress = false;
        trackRedesignEvents.redesignUploadFinalCancel(
          this.currentUser,
          otherData,
        );
      }
      this.redesignLoader.nextState(0);
      this.redesignService.cancel();
      this.setSmartTools(false);
      this.recordId = null;
      this.step = 0;
      this.selectedSlides = [];
      this.fileDetails = null;
      this.$refs.hiddenInput.value = null;
      this.changeReq = null;
      this.error = null;
      this.newFilesToUpload = [];
      this.loading = null;
      this.slides = [];
      this.audience = null;
      this.template = null;
      this.resetSlideLibraryFlags();
      this.modifiedFileName = '';
    },
    async handleCreateRecord(data = {}) {
      this.form = {
        isLiked: null,
        feedback: '',
        shareDetails: true,
      };
      const noOfTransformedSlides = this.selectedSlides.filter(
        (slide) => !slide.error,
      ).length;
      const noOfErrorSlides = this.selectedSlides.filter(
        (slide) => slide.error,
      ).length;
      let status = 'Success';
      if (noOfErrorSlides === this.selectedSlides.length) {
        status = 'Failed';
      } else if (noOfErrorSlides && noOfTransformedSlides) {
        status = 'Partial';
      }
      let statusReason = status;

      if (this.error === 'UPL_ERR') {
        statusReason = 'Failed to Upload';
      } else if (status === 'Failed') {
        statusReason = 'Redesign Failed';
      }
      const failureDeck = [];
      const failureIndices = [];
      const redesignedDeck = [];
      const slidesInfo = [];

      this.selectedSlides.forEach((slide, index) => {
        if (!slide.error) {
          const path = slide.keepOriginal
            ? slide.pptx.s3_path
            : slide.redesign.slide_path;
          redesignedDeck.push(path);
          slidesInfo.push({
            index,
            assetID: slide?.redesign?.meta?.id,
            confidenceScore: slide.redesign.confidenceScore,
          });
        }
        if (slide.error) {
          const path = slide.pptx.s3_path;
          failureDeck.push(path);
          failureIndices.push(index);
          slidesInfo.push({
            index,
            assetID: '',
            confidenceScore: '',
          });
        }
      });
      const payload = {
        noOfSlideTransformed: noOfTransformedSlides,
        noOfSlideRedesignFailed: noOfErrorSlides,
        timeTakenSeconds: Math.floor(
          (Date.now() - this.redesignStartTime) / 1000,
        ),
        imageKeywords: this.formImageKeywords, // adding image keywords
        redesignStatus: status,
        originalDeck: this.fileDetails?.name,
        totalNumberOfSlides: this.selectedSlides.length || 0,
        redesignedDeck,
        originalPrezentation: this.fileDetails?.s3Prefix,
        statusReason,
        failedSlides: failureDeck,
        failedSlidesIndex: failureIndices,
        templateCode: this.selectedTemplate.template_internal_name,
        audience: this.currentUser.user.id,
        fileSizeKb: this.fileDetails.sizeKb,
        noOfSlidesDeck: this.slides.length || 0,
        slidesInfo,
        ...data,
      };
      const id = await this.redesignService.createStats(payload);
      this.recordId = id;
    },
    async handleUpdateRecord(updates) {
      const redesignedDeck = [];
      const slidesInfo = [];

      this.selectedSlides.forEach((slide, index) => {
        if (!slide.error) {
          console.log(slide.redesign);
          const path = slide.keepOriginal
            ? slide.pptx.s3_path
            : slide.redesign.slide_path;
          redesignedDeck.push(path);
          slidesInfo.push({
            index,
            assetID: slide.redesign.meta.id,
            confidenceScore: slide.redesign.confidenceScore,
          });
          if (slide.error) {
            slidesInfo.push({
              index,
              assetID: '',
              confidenceScore: '',
            });
          }
        }
      });
      await this.redesignService.updateStats(this.recordId, {
        ...updates,
        slidesInfo,
        redesignedDeck,
        imageKeywords: this.formImageKeywords, // updating image keywords
      });
    },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/redesign/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },
    async previewFiles(event) {
      // Check file type
      const file = event[0];
      if (!['pptx'].includes(file.name.split('.').pop())) {
        this.fileError = 'userUploadPrezentation.invalidFileFormat';
        return;
      }
      this.fileError = '';
      this.newFilesToUpload = [...event];
      this.newFilesToUpload = this.newFilesToUpload.map((i) => ({
        file: i,
        name: i.name,
        status: 'Processing',
        progress: 0,
        path: `${this.getOutputPath()}/${removeSpecialCharacters(i.name)}`,
        processingCompleted: false,
        requestIdentifier: uuidv4(),
      }));
    },
    setSmartTools(val) {
      const otherData = {
        [TD_TEMPLATE]: NA,
      };
      this.isSmartToolsOpen = val;
      if (val) {
        trackRedesignEvents.redesignSmartToolsClick(
          this.currentUser,
          otherData,
        );
      } else if (this.rightPanelComponent) {
        trackRedesignEvents.redesignSmartToolsClose(
          this.currentUser,
          otherData,
        );
      }
      if (!val) {
        this.rightPanelComponent = null;
      }
    },
    openRightPanel(component, placement = TD_SMART_LAYOUT_PLACEMENT) {
      this.iconPlacement =
        placement === 'bar' ? TD_UPLOAD_BAR_PLACEMENT : placement;
      if (this.rightPanelComponent === component) {
        this.rightPanelComponent = null;
      } else {
        EventBus.$emit('MINIMIZE_LEFTNAV');
        this.isSmartToolsOpen = true;
        this.rightPanelComponent = component;
      }
    },
    handleVisibility() {
      if (this.processInProgress && document.visibilityState === 'hidden') {
        const endTime = performance.now();
        const timeSpent = Math.round((endTime - this.startTime) / 1000);
        const otherData = {
          [TD_PREZNAME]: this.newFilesToUpload[0].name,
          [TD_TEMPLATE]: this.selectedTemplate.code,
          [TD_TIME_SPENT_IN_SECS]: timeSpent,
        };
        trackRedesignEvents.redesignUserTabSwitch(this.currentUser, otherData);
      }
    },
  },
  mounted() {
    if (!this.isRedesignAccess) {
      this.$router.push('/home', () => {});
    }
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
      this.setSmartTools(false);
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });
    document.addEventListener('visibilitychange', this.handleVisibility, false);
  },
  beforeDestroy() {
    this.redesignService.cancel();
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibility,
      false,
    );
    // this.resetAddedToLibrarySnackbarState();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isAddedToLibrary && this.slidesCompleted && this.deckValid) {
      this.pageChangeAction(next);
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.redesign-outer-container {
  display: flex;
  height: calc(100vh - 68px);
  flex-direction: column;

  // .generate-banner {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 14px 8px 14px 16px;
  //   gap: 4px;
  //   height: 48px;
  //   background: #e1f5fb;
  //   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  //   border-radius: 4px;

  //   .dismiss-btn {
  //     cursor: pointer;
  //   }
  // }

  .redesign-container {
    display: flex;
    flex: 1;
    width: 100%;
    position: relative;
    min-height: 0;

    .main-content {
      padding: 20px;
      max-width: 1434px;
      width: 93%;
      margin: 0 auto;
      transition: width 0.5s ease-in;
      display: flex;
      flex-direction: column;

      &.right-panel-open {
        position: relative;
        width: calc(100% - 550px);
        transition: width 0.5s ease-in;
      }

      // .heading {
      //   font-family: 'Lato';
      //   font-style: normal;
      //   font-weight: 600;
      //   font-size: 24px;
      //   line-height: 29px;
      //   color: #000000;

      //   .sub {
      //     font-family: 'Lato';
      //     font-style: normal;
      //     font-weight: 400;
      //     font-size: 12px;
      //     line-height: 14px;
      //     letter-spacing: 0.02em;
      //     color: #697077;
      //     margin-top: 4px;
      //   }
      // }

      .input-box {
        // margin-top: 8px;
        // margin-bottom: 16px;
        // padding: 10px 15px;
        // box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
        // border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        > div {
          gap: 10px;
          display: flex;
          align-items: center;

          .input-container {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 10px;
            width: calc(100% - 174px);

            .input-placeholder {
              color: #697077;
            }

            .input-placeholder,
            .input-file-name {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;

              &.disabled {
                cursor: default;
                pointer-events: none;
              }
            }
          }

          .redesign-action-container {
            display: flex;
            gap: 10px;
          }
        }

        .cancel-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 16px;
          gap: 8px;
          height: 32px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-transform: capitalize;
          letter-spacing: normal;
          line-height: 19px;

          /* Color Styles (Old)/White */

          color: #21a7e0;

          background: #ffffff;
          border: 1px solid #21a7e0;
          border-radius: 25px;

          &.v-btn--disabled {
            border: none;
          }
        }

        .panel-items {
          display: flex;
          flex-wrap: wrap;
          column-gap: 14px;
          row-gap: 5px;
          flex: 1;

          > div {
            display: flex;
            gap: 4px;
            align-items: center;
            color: #21a7e0;
            cursor: pointer;
            padding: 8px 4px;

            &:hover {
              background-color: #41484d14;
              border-radius: 8px;
            }

            &.current {
              background: #e1f5fb;
              border-radius: 8px;
            }

            &.disabled {
              color: rgba(0, 0, 0, 0.26) !important;
              pointer-events: none;
              cursor: default;
            }
          }
        }
      }

      .content-view {
        flex: 1;
        min-height: 0;

        .redesign-view {
          display: grid;
          grid-template-columns: 364px 1fr;
          height: 100%;
          gap: 20px;
          // overflow: hidden;
          transition: 0.4s;

          &.right-panel-open {
            grid-template-columns: 140px 1fr;
            gap: 10px;
          }
        }
      }

      .slides-selection-container {
        height: 100%;
      }

      .slides-loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .progress-spinner {
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
        .video-container {
          height: calc(100% - 42px);
          display: flex;
          align-items: center;
          justify-content: center;
          video {
            height: 100%;
            aspect-ratio: 16/9;
          }
        }
      }

      .quick-access-toolbar {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
    .smart-tool-switch {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 4;
      background: white;

      .divider {
        border: 0.75px solid #b6b6b6;
      }
    }
    .right-panel {
      box-shadow: -2px 0px 2px rgba(182, 182, 182, 0.3);
      width: 550px;
      padding-right: 55px;
      transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
        transform 0.5s ease-in;

      .template-menu {
        padding: 24px 16px;
        height: 100%;

        ::v-deep .tmpLists {
          flex: 1;
          min-height: 0;
        }
      }

      &.fade {
        opacity: 0.4;
        pointer-events: none;
      }
      &.hide {
        width: 0;
        transform: translateX(550px);
        padding-right: 0;
        opacity: 0;
        transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
          transform 0.5s ease-in;
      }
    }
  }
}

.redesign-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;

  width: auto;
  height: 32px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: normal;
  line-height: 19px;

  /* Color Styles (Old)/White */

  color: #ffffff;

  background: #21a7e0;
  border-radius: 25px;
}
.tutorial-menu {
  border-radius: 12px;
  width: 520px;
  background: white;
}
.template-selector-active {
  background: $white-default;
  box-shadow: -2px 0px 2px rgba(182, 182, 182, 0.3) !important;
  overflow: unset;
  .templates-list {
    max-height: 80vh !important;
    overflow: scroll;
  }
}
.error-layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .no-layout-text {
    text-align: center;
    margin-top: 16px;
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
}
</style>
