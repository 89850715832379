import { render, staticRenderFns } from "./PrezentationDetails.vue?vue&type=template&id=64b00056&scoped=true&"
import script from "./PrezentationDetails.vue?vue&type=script&lang=js&"
export * from "./PrezentationDetails.vue?vue&type=script&lang=js&"
import style0 from "./PrezentationDetails.vue?vue&type=style&index=0&id=64b00056&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b00056",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VHover,VIcon,VList,VListItem,VListItemTitle,VMenu,VOverlay,VTooltip})
