<template>
  <v-app class="application-main">
    <v-main>
      <div v-if="!isExistingUser" class="registration-wrapper">
        <div
          :class="`registration-content  ${
            innerWidth < 500 ? 'mobile-content' : ''
          }`"
        >
          <div
            class="registration-content-left"
            v-if="innerWidth > 500 && !isUserRegistered"
          >
            <TrialSignUpAdvantages></TrialSignUpAdvantages>
          </div>
          <div
            v-if="!isUserRegistered"
            :class="`registration-content-right ${
              innerWidth < 500 ? 'mobile-right' : ''
            }`"
          >
            <div class="registration-heading">
              Sign up to Prezent and supercharge your presentation<br />
              productivity while streamlining agency costs
            </div>
            <v-form
              class="registration-form"
              ref="registrationForm"
              v-model="isNotValid"
              lazy-validation
            >
              <v-text-field
                v-model="firstName"
                label="First name"
                :rules="formRules.firstName"
                required
                color="#fff"
                class="mb-4"
                id="first"
                :loading="loadingWithExternalFpTestId"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="lastName"
                label="Last name"
                :rules="formRules.lastName"
                required
                class="mb-4"
                id="last"
                :loading="loadingWithExternalFpTestId"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="email"
                :label="
                  isFocused || email
                    ? 'Work email'
                    : 'Work email (Ex. john.doe@company.com)'
                "
                :rules="formRules.email"
                required
                class="mb-4"
                id="email"
                @focus="isFocused = true"
                @blur="handleAudienceEmailChange"
                :loading="loadingWithExternalFpTestId"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-text-field
                v-model="companyName"
                label="Company name"
                :rules="formRules.companyName"
                required
                class="mb-4"
                id="companyName"
                :loading="loadingWithExternalFpTestId"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-autocomplete
                v-model="companySize"
                :items="companySizeOptions"
                label="Company size"
                :rules="formRules.companySize"
                required
                class="mb-4"
                id="companySize"
                :loading="loadingWithExternalFpTestId"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-autocomplete
              >
              <v-text-field
                v-model="phone"
                label="Phone"
                :rules="formRules.phone"
                required
                class="mb-4"
                id="phone"
                :loading="loadingWithExternalFpTestId"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-text-field
              >
              <v-autocomplete
                v-model="country"
                label="Country"
                :rules="formRules.country"
                required
                class="mb-4"
                id="country"
                item-text="name"
                :items="countries"
                return-object
                :loading="loadingWithExternalFpTestId"
              >
                <template v-slot:message="{ message }">
                  <v-icon style="font-size: 14px" color="#fff" class="mr-1"
                    >mdi-alert-circle</v-icon
                  ><span> {{ message }}</span>
                </template></v-autocomplete
              >
              <div class="terms">
                <p>
                  By clicking below, you agree to our
                  <a href="https://prezent.ai/terms-of-service/" target="_blank"
                    >Terms of Service</a
                  >
                  and
                  <a href="https://prezent.ai/privacy-policy/" target="_blank"
                    >Privacy Policy</a
                  >.
                </p>
              </div>
              <div style="text-align: left">
                <v-btn
                  @click="submit"
                  :disabled="
                    !isNotValid ||
                    !firstName ||
                    !lastName ||
                    !email ||
                    !companyName ||
                    !companySize ||
                    !phone ||
                    !country ||
                    country === ''
                  "
                  color="primary"
                  rounded
                  min-width="144"
                  height="50"
                  id="submit"
                  :loading="isLoading"
                >
                  Continue
                </v-btn>
                <p>No credit card required</p>
                <div v-if="error !== ''" class="error">
                  {{ error }}
                </div>
              </div>
            </v-form>
          </div>
          <div v-else class="confirmation-wrapper">
            <div class="confirmation">
              <v-img
                src="/assets/img/feather_check-circle.svg"
                max-width="60px"
                max-height="60px"
                alt
              />
              <div v-html="getMessage"></div>
              <template v-if="isWaitListCompany">
                <clip-loader
                  v-if="loginLoading"
                  :loading="loginLoading"
                  :color="`#21a7e0`"
                  :width="'60'"
                  :height="'60'"
                  :size="'50px'"
                  class="cliploader"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isExistingUser" class="existing-user">
        <div>
          <v-img
            src="/assets/img/account-already-exists.svg"
            alt
            max-width="300px"
            max-height="300px"
          />
          <p>Account already exists</p>
          <p>Redirecting you to login...</p>
          <clip-loader
            v-if="loginLoading"
            :loading="loginLoading"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'50px'"
            class="cliploader"
          />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import TrialApi from '../API/trial-api';
import { getExternalFingerprintResultByTestId } from '../utils/api-helper';
import { isEmojiExist, isEmailValid } from '../components/utils';
import { capitalizeFirstLetter } from '../utils/common';
import { CountriesNowService } from '../services/CountriesNowService';
import TrialSignUpAdvantages from '../components/common/TrialSignUpAdvantages.vue';

export default {
  name: 'TrialRegistrationNew',
  components: {
    ClipLoader,
    TrialSignUpAdvantages,
  },
  data() {
    return {
      isWaitListCompany: false,
      isUserRegistered: false,
      isExistingUser: false,
      isFocused: false,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      companySize: '',
      jobTitle: '',
      country: '',
      state: '',
      prezentationSoftware: '',
      isNotValid: true,
      formRules: {
        firstName: [
          (v) => !!v || this.$t('warnings.firstNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        lastName: [
          (v) => !!v || this.$t('warnings.lastNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        email: [
          (v) => !!v || this.$t('warnings.emailRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => (v && isEmailValid(v)) || this.$t('warnings.emailMustBeValid'),
        ],
        companyName: [
          (v) => !!v || this.$t('warnings.companyNameRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        companySize: [(v) => !!v || this.$t('warnings.companySizeRequired')],
        phone: [
          (v) => !!v || this.$t('warnings.phoneNumberRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        jobTitle: [
          (v) => !!v || this.$t('warnings.jobTitleRequired'),
          (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
          (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        ],
        prezentationSoftware: [
          (v) => !!v || this.$t('warnings.presentationSoftwareRequired'),
        ],
        country: [(v) => !!v || this.$t('warnings.countryRequired')],
        state: [(v) => !!v || this.$t('warnings.stateRequired')],
      },
      prezentationSoftwares: [
        'Microsoft Powerpoint',
        'Google Slides',
        'Microsoft 365',
        'Keynote',
        'Other',
      ],
      valid: true,
      isLoading: false,
      error: '',
      loginLoading: true,
      countries: [],
      states: [],
      countDown: 0,
      innerWidth: 700,
      loadingWithExternalFpTestId: false,
      queryString: '',
      companySizeOptions: [
        'Less than 50',
        'Between 50 - 1000',
        'More than 1000',
      ],
    };
  },
  mixins: [TrialApi],
  computed: {
    firstNameIsValid() {
      return false;
    },
    ...mapGetters('users', ['getCompanyDomain']),
    ...mapState('users', ['currentUser']),
    getMessage() {
      if (!this.isWaitListCompany) {
        return `<p>Thank you for your interest!<br /></p>
                <p>
                  We have emailed you a link.<br />Please use it to complete the<br />sign-up process and begin<br />using Prezent
                </p>`;
      }
      if (this.isWaitListCompany) {
        return `
            <p>Thank you for your interest in Prezent!</p><p>We have added you to our waitlist<br />and will get back to you soon.</p>
            <p>Redirecting you to our website in ${this.countDown}s...</p>
          `;
      }
      return '';
    },
  },
  methods: {
    loadCountries() {
      CountriesNowService.getCountries().then((countries) => {
        this.countries = countries;
      });
    },
    loadStates(iso2) {
      CountriesNowService.getStates(iso2).then((states) => {
        if (states.length) {
          this.states = states;
        } else {
          this.states = [{ name: 'NA' }];
          this.state = 'NA';
        }
      });
    },
    isEmailDomainSame(v) {
      if (v) {
        const domain = this.currentUser.user.id.split('@')[1];
        const colleagueDomain = v.split('@')[1];
        return domain === colleagueDomain;
      }
      return true;
    },
    handleAudienceEmailChange() {
      this.isFocused = false;
      if (this.email.includes('@') && this.email.includes('.')) {
        const companyDomain = this.email.split('@')[1];
        const cNameArray = companyDomain.split('.');
        const cName = cNameArray && cNameArray.length ? cNameArray[0] : '';
        this.companyName = capitalizeFirstLetter(cName);
        this.email = this.email.toLowerCase();
      } else {
        this.companyName = '';
      }
    },
    async submit() {
      try {
        console.log('in submit method');
        this.isLoading = true;
        const payload = [
          {
            email: this.email.toLowerCase(),
            firstname: this.firstName,
            lastname: this.lastName,
            companyname: this.companyName,
            companySize: this.companySize,
            phone: this.phone,
            jobTitle: this.jobTitle,
            country: this.country.name,
            state: this.state.name,
            prezentationSoftware: this.prezentationSoftware,
            queryString: this.queryString,
          },
        ];
        if (this.$route.query.utm_campaign) {
          payload[0].source = this.$route.query.utm_campaign;
        }

        console.log('payload', payload);
        await TrialApi.methods
          .trialRegistration(payload)
          .then((resp) => {
            console.log('resp', resp);
            const isWaitListCompany = resp?.data[0]?.isWaitListCompany;

            this.isUserRegistered = true;
            this.isExistingUser = resp?.data[0]?.userAlreadyExist;
            this.isLoading = false;
            if (this.isExistingUser) {
              setTimeout(() => {
                this.queryString = '';
                this.$router.push('/signin', () => {});
              }, 3000);
            }
            if (isWaitListCompany) {
              this.queryString = '';
              this.startWebsiteRedirectCounter();
            }
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            if (err?.response?.status === 422) {
              this.isExistingUser =
                err?.response?.data?.data[0]?.userAlreadyExist;
              const isWaitListCompany =
                err?.response?.data?.data[0]?.isWaitListCompany;
              this.isUserRegistered = true;
              if (this.isExistingUser) {
                setTimeout(() => {
                  this.queryString = '';
                  this.$router.push('/signin', () => {});
                }, 3000);
              }
              if (isWaitListCompany) {
                this.queryString = '';
                this.startWebsiteRedirectCounter();
              }
            } else if (err?.response?.status === 400) {
              console.log(err);
              this.error = 'Email must be valid';
            }
          });
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    startWebsiteRedirectCounter() {
      this.countDown = 5;
      this.isWaitListCompany = true;

      const countDownTimer = setInterval(() => {
        if (this.countDown <= 1) {
          clearInterval(countDownTimer);
          window.location.href = 'https://www.prezent.ai/thank-you';
        } else {
          this.countDown -= 1;
        }
      }, 1000);
    },
  },
  async mounted() {
    console.log(window.innerWidth);
    this.innerWidth = window.innerWidth;
    if (this.$route.query.waitList) {
      this.isUserRegistered = this.$route.query.waitList;
      this.isWaitListCompany = this.$route.query.waitList;
      this.startWebsiteRedirectCounter();
    }
    if (
      this.$route.query.type &&
      this.$route.query.type === 'external-fingerprints'
    ) {
      // const { fname, lname, phone, email, company, country } =
      //   this.$route.query;
      this.loadingWithExternalFpTestId = true;
      await getExternalFingerprintResultByTestId(this.$route.query.test_id)
        .then((resp) => {
          this.firstName = resp?.data?.firstName || '';
          this.lastName = resp?.data?.lastName || '';
          this.phone = resp?.data?.phone || '';
          this.email = resp?.data?.email || '';
          this.country =
            resp?.data?.isd && resp?.data?.isd !== ''
              ? `${resp.data.isd.split('__')[0]}`
              : '';
          this.companyName = resp?.data?.company || '';
        })
        .catch((err) => {
          console.log(err);
        });
      this.loadingWithExternalFpTestId = false;
    }
    this.loadCountries();

    // getting query params for each trial registration
    const queries = this.$route?.query || {};
    const queriesMap = Object.keys(queries);
    if (queriesMap.length > 0) {
      let queryStr = '?';
      queriesMap.forEach((item, i) => {
        queryStr += `${i === 0 ? '' : '&'}${item}=${queries[item]}`;
      });
      this.queryString = queryStr;
    }
  },
  /* watch: {
      country(country) {
        this.loadStates(country.Iso2);
      },
    }, */
};
</script>

<style lang="scss" scoped>
.registration-wrapper {
  margin: auto;
  // padding: 2rem;
  width: 100%;

  p {
    font-size: 14px;
  }
  .registration-content {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .registration-content-right {
      width: 50%;
      color: #fff !important;
      background-color: #1075ab;
      .registration-heading {
        font-weight: 400px;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 25px;
        padding-top: 50px;
        padding-left: 100px;
      }
      .registration-form {
        width: 60%;
        padding: 10px 30px 0px 100px;
        ::v-deep .v-input input {
          color: #fff !important;
        }
        ::v-deep .v-input__slot {
          margin-bottom: 0px !important;
        }
        ::v-deep .v-input .v-label {
          color: #fff !important;
        }
        ::v-deep .v-input__slot:before {
          border-color: #fff !important;
        }
        ::v-deep .v-input__slot::after {
          border-color: #fff !important;
        }
        ::v-deep.error--text .v-input__slot:before {
          border-color: #f70000 !important;
        }
        ::v-deep.error--text .v-input__slot::after {
          border-color: #f70000 !important;
        }
        ::v-deep.v-messages__wrapper {
          height: 25px;
        }
        ::v-deep.v-messages__message {
          position: absolute;
          color: #fff; /* Change the text color */
          font-size: 12px; /* Adjust the font size */
          font-weight: 400; /* Adjust the font size */
          background-color: #f70000; /* Add a background color */
          padding: 4px; /* Add some padding */
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .v-messages__message::before {
          content: '\f059'; /* Font Awesome "info-circle" icon or similar */
          font-family: 'Font Awesome 5 Free'; /* Make sure Font Awesome is available */
          font-weight: 900; /* Needed for solid Font Awesome icons */
          color: #fff; /* Icon color */
          margin-right: 8px; /* Space between the icon and the text */
          cursor: pointer; /* Show pointer on hover for the icon */
          display: inline-block;
          vertical-align: middle;
          position: relative;
        }
        .v-messages__message:hover::before::after {
          content: 'This field is required'; /* Tooltip text */
          position: absolute;
          top: -24px;
          left: 0;
          background-color: black;
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          z-index: 9999;
        }

        /* Triangle for tooltip */
        .v-messages__message:hover::before::before {
          content: '';
          position: absolute;
          top: -8px;
          left: 10px;
          border-width: 4px;
          border-style: solid;
          border-color: transparent transparent black transparent;
        }
        p {
          margin-top: 20px;
        }
        button {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 38px;
          text-align: center;
          text-transform: none;
        }
        .error {
          background-color: transparent !important;
          border-color: transparent !important;
          color: red;
        }
      }
    }
    .confirmation-wrapper {
      align-items: center;
      display: flex;
      font-size: 24px;
      justify-content: center;
      margin-bottom: 50px;
      text-align: center;
      width: 40%;
      .confirmation {
        .v-image {
          margin: 0 auto;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.existing-user {
  display: flex;
  justify-content: center;
  padding: 25px;
  text-align: center;
  p {
    font-size: 16px;
    font-weight: 500;
  }
}

.mobile-right {
  width: 100% !important;
  margin: 15px !important;
  justify-content: flex-start !important;
}

.mobile-content {
  justify-content: flex-start !important;

  .confirmation-wrapper {
    width: 100% !important;
    margin-top: 20px;
  }
}

.terms {
  p {
    margin-bottom: 0px;
  }
  a {
    text-decoration: underline;
    color: #fff !important;
  }
}
.registration-content-left {
  padding: 50px 80px 0px 80px;
  width: 50%;
  // .logo {
  //   padding: 0px 0 10px 0 !important;
  //   width: fit-content;
  // }
  // .registration-heading-left {
  //   font-family: Lato;
  //   font-weight: 400;
  //   font-size: 28px;
  //   line-height: 36px;
  //   color: #121619;
  //   padding-bottom: 20px;
  // }
  // .registration-subheading {
  //   font-family: Lato;
  //   font-weight: 400;
  //   font-size: 24px;
  //   line-height: 29px;
  //   color: #1075ab;
  //   padding-bottom: 15px;
  // }
  // .services-items {
  //   grid-row-gap: 25px;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   width: 100%;
  //   margin-top: 20px;
  //   margin-bottom: 40px;
  //   display: flex;
  //   .services-item {
  //     grid-column-gap: 25px;
  //     align-items: flex-start;
  //     display: flex;
  //     .service-icon-wrapper {
  //       border: 0.8px solid rgba(216, 230, 241, 1);
  //       background-color: #fff;
  //       border-radius: 12px;
  //       flex: none;
  //       justify-content: center;
  //       align-items: center;
  //       width: 75px;
  //       height: 75px;
  //       margin-bottom: 0;
  //       padding: 14px;
  //       display: flex;
  //       // box-shadow: rgba(179, 228, 245, 0.2);
  //     }
  //     .service-description-wrapper {
  //       grid-row-gap: 8px;
  //       flex-direction: column;
  //       display: flex;
  //       .service-description-title {
  //         font-size: 18px;
  //         font-weight: 500;
  //         color: #1075ab;
  //         margin: 0 !important;
  //       }
  //       .service-description-subtitle {
  //         font-size: 16px;
  //         font-weight: 400;
  //         color: #000;
  //         margin: 0 !important;
  //         line-height: 17px;
  //       }
  //     }
  //   }
  // }
}
</style>
