<template>
  <div class="slides-wrapper">
    <div
      class="slides-cntr"
      :class="showQuickSelection ? 'quick-panel-active' : ''"
    >
      <div class="slide-type-wrapper" data-pendo-id="slides-idea-wrapper">
        <v-sheet max-width="100%">
          <v-chip-group
            show-arrows
            mandatory
            cols="1"
            data-pendo-id="select-slide-idea"
          >
            <v-chip
              :class="
                ideaChipClicked.toLowerCase() === keyword.name.toLowerCase() &&
                !isImageReplaceWalkThrough
                  ? 'ideaChipActive'
                  : 'ideaChipAvailable'
              "
              outlined
              :name="keyword.name"
              :id="keyword.name === 'All' ? 'allChip' : ''"
              v-for="(keyword, index) in getTrendingKeywords"
              :key="index"
              exact-active-class=""
              @click="(e) => clickChipIdeas(e, keyword)"
            >
              {{ keyword.name }}
            </v-chip>
          </v-chip-group>
        </v-sheet>
      </div>
      <div class="filters-wrapper row" v-if="!isImageReplaceWalkThrough">
        <div class="left-filters">
          <SlidesSearch
            :showQuickSelection="showQuickSelection"
            :filterOptionCategory="filterOptionCategory"
          ></SlidesSearch>
        </div>
        <div class="right-filters">
          <div class="quick-action-cta">
            <template v-if="showQuickSelection || !leftSideBarStatus">
              <v-tooltip
                top
                max-width="250"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    class="filter-btn"
                    :name--auto="`slides-audience-filter-btn${
                      toolType === 'audience' ? '--active' : ''
                    }`"
                    data-pendo-id="audience-selection"
                    :class="
                      searchProgress || !startLoadingSlidesFlag
                        ? 'filter-btn-disabled'
                        : toolType === 'audience'
                        ? 'filter-btn-active'
                        : ''
                    "
                    @click="handleQuickSelection('audience')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img src="/assets/img/audience.svg" alt />
                    Audience
                  </button>
                </template>
                <span>{{ getCurrentAudienceName }}</span>
              </v-tooltip>
            </template>
            <template v-else>
              <button
                class="filter-btn"
                :name--auto="`slides-audience-filter-btn${
                  toolType === 'audience' ? '--active' : ''
                }`"
                data-pendo-id="audience-selection"
                :class="
                  searchProgress || !startLoadingSlidesFlag
                    ? 'filter-btn-disabled'
                    : toolType === 'audience'
                    ? 'filter-btn-active'
                    : ''
                "
                @click="handleQuickSelection('audience')"
              >
                <img src="/assets/img/audience.svg" alt />
                <span class="aud-name"
                  >Audience: {{ getCurrentAudienceName }}</span
                >
              </button>
            </template>
            <template v-if="showQuickSelection || !leftSideBarStatus">
              <v-tooltip
                top
                max-width="250"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    class="filter-btn"
                    :name--auto="`slides-template-filter-btn${
                      toolType === 'template' ? '--active' : ''
                    }`"
                    data-pendo-id="template-selection"
                    :class="
                      searchProgress || !startLoadingSlidesFlag
                        ? 'filter-btn-disabled'
                        : toolType === 'template'
                        ? 'filter-btn-active'
                        : ''
                    "
                    @click="handleQuickSelection('template')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img src="/assets/img/template.svg" alt />
                    Template
                  </button>
                </template>
                <span>{{ getTemplateName }}</span>
              </v-tooltip>
            </template>
            <template v-else>
              <button
                class="filter-btn"
                :name--auto="`slides-template-filter-btn${
                  toolType === 'template' ? '--active' : ''
                }`"
                data-pendo-id="template-selection"
                :class="
                  searchProgress || !startLoadingSlidesFlag
                    ? 'filter-btn-disabled'
                    : toolType === 'template'
                    ? 'filter-btn-active'
                    : ''
                "
                @click="handleQuickSelection('template')"
              >
                <img src="/assets/img/template.svg" alt />
                <span class="template-name"
                  >Template: {{ getTemplateName }}</span
                >
              </button>
            </template>
            <button
              class="filter-btn"
              :name--auto="`slides-collection-filter-btn${
                toolType === 'collection' ? '--active' : ''
              }`"
              data-pendo-id="collection-selection"
              :class="
                searchProgress || !startLoadingSlidesFlag
                  ? 'filter-btn-disabled'
                  : toolType === 'collection'
                  ? 'filter-btn-active'
                  : ''
              "
              @click="handleQuickSelection('collection')"
            >
              <img src="/assets/img/collection.svg" alt />
              Collection
            </button>

            <button
              class="filter-btn"
              :name--auto="`slides-filter-sort-btn${
                toolType === 'filter' ? '--active' : ''
              }`"
              data-pendo-id="filter-selection"
              :class="
                searchProgress || !startLoadingSlidesFlag
                  ? 'filter-btn-disabled'
                  : toolType === 'filter'
                  ? 'filter-btn-active'
                  : ''
              "
              @click="handleQuickSelection('filter')"
            >
              <img src="/assets/img/filter-sort.svg" alt />
              Filter & Sort
            </button>
          </div>
          <div
            class="uploadCTA"
            v-if="isUploadFullAccess"
            data-pendo-id="upload-slides"
          >
            <NewFeatureBadge
              v-if="!isRoleBusinessStandared"
              module-name="upload"
              :feature-id="'upload_landing_id'"
              :additionalFeatureId="'release_2210'"
              :offsetX="'60'"
              :offsetY="'-5'"
              :size="'small'"
            ></NewFeatureBadge>
            <v-btn @click="handleUploadNavigation">
              <v-icon :size="20">{{ 'mdi-upload-outline' }}</v-icon>
              {{ $t('uploadFlow.navigationCTA') }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="main-wrapper">
        <div class="inner-wrapper" v-if="!isImageReplaceWalkThrough">
          <div
            class="resultsContainer"
            :style="{
              width: `${getWidthOfSlideList()}%`,
            }"
          >
            <Loading v-if="searchProgress || !startLoadingSlidesFlag" />
            <div
              class="listOrNotFound"
              v-if="!searchProgress && startLoadingSlidesFlag"
            >
              <InlineSlides
                :favoriteSlides="getFavoriteSlides"
                :can-load-more="isLoading || isNoMore"
                :is-no-more="isNoMore"
                :load-more-slides="loadMoreSlides"
                v-if="
                  slidesFilteredChipAdvanced &&
                  slidesFilteredChipAdvanced.length > 0 &&
                  currentSlidesPage === 1
                "
                :handle-favorite="handleFavorite"
                :handle-download-from-list="handleDownloadFromList"
                :saved-query-payload="savedQueryPayload"
                :current-audience="currentAudience"
                :log-download="logDownload"
                :slides="slidesFilteredChipAdvanced"
                :items-per-row="itemsPerRow"
                :list-downloads="getDownloadedSlides"
                :parent-refs="$refs"
                :trending-slides="trendingSlides"
                :list-image-replaced="getImageReplacedSlides"
                :listIconReplaced="getIconReplacedSlides"
                :hideAdvancedFilter="hideAdvancedFilter"
                :currentThemeCode="currentThemeCode"
                :origin="'slidesListPage'"
                :slideOrigin="filterOptionCategory"
                :slides-meta-data="slideMetaData"
                :snapshot="createSlidesStateSnapshot"
                :removeSlideOnDelete="removeSlideOnDelete"
                :downloadingSlides="downloadingSlides"
                :addingToFavSlides="addingToFavSlides"
                :searchQuery="searchQuery"
                @openCollections="openCollections"
                @scrollToOption="handleScrollToOption"
              />
              <div v-else class="not-found-wrapper">
                <template>
                  <div class="not-found-container">
                    <div>
                      <img src="/assets/img/no-results-found-slides.svg" alt />
                    </div>

                    <div class="not-found-descriptions">
                      <div class="font-weight-bold mb-1 mt-6">
                        {{ errorToShowBold }}
                      </div>
                      <div>
                        {{ errorToShow }}
                      </div>
                      <template
                        v-if="
                          savedQueryPayload &&
                          savedQueryPayload.query &&
                          firstSlide
                        "
                      >
                        <div class="mb-1 mt-6">
                          {{ $t('slides.lookingForSlideText1') }}
                          <span class="font-weight-bold"
                            >'{{ savedQueryPayload.query }}'</span
                          >
                          <template v-if="!getGenerateAccessLevel">
                            {{ $t('slides.lookingForSlideText2') }}
                          </template>
                          ?
                        </div>
                        <template v-if="getGenerateAccessLevel">
                          <div class="mb-4">
                            {{ $t('slides.generateSlideText') }}
                          </div>
                          <v-btn
                            class="primary-button"
                            rounded
                            color="#21a7e0"
                            height="48"
                            min-width="134"
                            @click="generate"
                          >
                            {{ $t('slides.generate') }}
                          </v-btn>
                        </template>
                        <template v-else>
                          <div class="mb-4">
                            {{ $t('slides.buildSlideText') }}
                          </div>
                          <v-btn
                            id="walkthrough-try-now-btn-id"
                            class="primary-button"
                            rounded
                            color="#21a7e0"
                            height="48"
                            min-width="134"
                            @click="onTryNow"
                            :loading="tryNowLoading"
                          >
                            {{ $t('slides.tryNow') }}
                          </v-btn>
                        </template>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isImageReplaceWalkThrough">
          <SlideDetailWrapper
            :favorite-slides="getFavoriteSlides"
            :handleFavorite="handleFavorite"
            :setInternalResetFn="setInternalResetFn"
            :handleUpdateActiveSlide="handleUpdateActiveSlide"
            :audience="currentAudience"
            :handleDownload="handleDownloadFromList"
            :slideDownloaded="() => false"
            :logDownload="logDownload.bind(this, { fromExpandedView: true })"
            :savedQueryPayload="savedQueryPayload"
            :selected-slide="activeSlide"
            :handleClose="tryNowClose"
            :isImageReplaceWalkThrough="isImageReplaceWalkThrough"
            :slideType="'prezentSourcedSlide'"
          />
        </div>
      </div>
      <div
        id="go-top"
        class="go-top"
        v-show="showGoToTop"
        v-scroll="onScroll"
        @click="toTop"
      >
        <v-icon color="#fff" size="15" class="top-arrow">mdi-arrow-up</v-icon>
        <span>{{ $t('common.backToTop') }}</span>
      </div>

      <ConfirmationDialog
        :show="dialogReset"
        :on-primary-click="onResetConfirm"
        :on-secondary-click="onResetCancel"
        :on-close="onResetCancel"
        :primary-btn-text="$t('common.yes')"
        :secondary-btn-text="$t('common.no')"
        :text="templateName"
        :title="templateTitle"
      />
    </div>

    <QuickSelectionCntr
      class="quick-panel"
      v-show="showQuickSelection"
      :toolType="toolType"
      @close="handleQuickSelection"
      :filterTailor="filterTailor"
      :handleAudienceChange="handleAudienceChange"
      :handleAudienceChangeInitial="handleAudienceChangeInitial"
      :handleAudienceFocus="handleAudienceFocus"
      :handleAddAudience="handleAddAudience"
      :downloadCollection="downloadCollection"
      :showReset="showReset"
      :hideCollection="hideCollection"
      :handleDeleteCollection="handleDeleteCollection"
      :logDownloadForSaveToGdrive="logCollectionDownload"
    >
      <SlidesAdvancedFilters />
    </QuickSelectionCntr>
  </div>
</template>

<script>
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';
import ideaMappingData from '../../../../utilities/ideas/ideas.json';
import {
  getCompanies,
  handleSearch,
  getSlideDetail,
  addSearchTerms,
  createFavorite,
  deleteFavorite,
  createCollectionDownload,
  createDownload,
  getUserSearchTerms,
  getCollection,
  getGeneratedUploadedSlides,
} from '@/utils/api-helper';
import utils from '../../utils';
import { performSearch } from '@/graphql/queries';
// import SlideDetail from '../../common/SlideDetail.vue';
// import AudienceSelectorTypesense from '../../common/AudienceSelectorTypesense.vue';
import ReminderDialog from '../MyPrezentation/ReminderDialog.vue';
import SlideThemeChangeDetail from '../Profile/SlideThemeChangeDetail.vue';
import Loading from '../../common/Loading.vue';
import EventBus from '../../common/event-bus';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import { getTrendingSlides } from '@/utils/trending-slides';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
// import { getFileURL } from '@/utils/calyrex';
// import { getFileURLDownload } from '@/utils/calyrex-test';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';
import {
  SLIDES,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_ISDOWNLOAD,
  isSlideFavorite,
  getSlideId,
  TD_ISFAVORITED,
  TD_QUERY,
  TD_NORESULTSFOUND,
  TD_DOWNLOADTYPE_GS,
  TD_SLIDEID,
  NA,
  TD_AUD,
  TD_POPULAR,
  TD_RECOMMENDED_FOR_SELECTED_AUDIENCE,
  TD_FAVORITES,
  TD_DOWNLOADS,
  TD_SORTTYPE,
  TD_AUDIENCE_ORIGIN_SLIDES,
  TD_TEMPLATE,
  TD_REPLACE_IMAGE_SOURCE,
  TD_PLACEMENT,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  TD_NUMBEROFSLIDES,
  getAudienceCompanyName,
  // TD_CONSTRUCT,
  // TD_HASIMAGES,
  // TD_NODECOUNT,
  // TD_GRAPHICS,
  // TD_DIAGRAMS,
  // TD_NODE_COUNT,
  // TD_SOURCE,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_SECTION_CHANGED,
  TD_REST_OF_THE_SLIDES_IN_UI,
  TD_THUMBNAIL_VIEW,
  TD_SLIDES_LIBRARY,
  TD_IDEA,
  TD_FROM_ADVANCED_FILTER,
  TD_FROM_SNACK_BAR,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import {
  // downloadFileWithCustomName,
  // downloadFileWithAPI,
  capitalizeFirstLetter,
} from '@/utils/common';
import InlineSlides from '@/components/common/InlineSlides';
// import SlideCollectionDownload from './SlideCollectionDownload.vue';
import SlideDetailWrapper from '@/components/Main/Slides/SlideDetailWrapper';
import usersApi from '../../../API/users-api';
import {
  updateSearchTerms,
  logSearchQuery,
  deleteCollection,
  updateCountForSlideView,
  postFeatureUsage,
  getFeatureUsage,
  getHiddenIdeas,
} from '../../../utils/api-helper';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
// import DownloadLimitDetail from '../../common/DownloadLimitDetail.vue';
// import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import AddingMoreTemplateDetail from '../Profile/AddingMoreTemplateDetail.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { GraphQLService } from '../../../services/GraphQLService';
// import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';
// import { miscConfig } from '../../../runtimeConfig';
import QuickSelectionCntr from '../../common/QuickSelectionTools/QuickSelectionCntr.vue';
import SlidesAdvancedFilters from '../../common/QuickSelectionTools/Filter&Sort/SlidesAdvancedFilters.vue';
import SlidesSearch from './SlidesSearch.vue';

const handleDebounce = debounce((callback) => {
  callback();
}, 1000);

const GENERIC_BOOSTER_PACK = 'generic_booster_pack';

export default {
  name: 'Slides',
  components: {
    Loading,
    InlineSlides,
    // AudienceSelectorTypesense,
    SlideDetailWrapper,
    ConfirmationDialog,
    // SlideCollectionDownload,
    NewFeatureBadge,
    QuickSelectionCntr,
    SlidesAdvancedFilters,
    SlidesSearch,
  },
  provide() {
    return {
      query: this.$route.query.query,
    };
  },
  data() {
    return {
      nonBrandSlideSearchQuery: '',
      isSlideLibrarySearch: false,
      slideLibrarySearchType: '',
      templateSelectorWidth: 0,
      sortByFilterWidth: 0,
      audienceSelectorWidth: 0,
      downloadingSlides: [],
      addingToFavSlides: [],
      isDefaultAudienceEventEmitted: false,
      templateSearch: '',
      // showDownload: false,
      hiddenIdeas: [],
      panel: 0,
      templateSelected: '',
      templatesList: [],
      tryNowLoading: false,
      firstSlide: false,
      imageReplaceWalkthroughSlide: null,
      isImageReplaceWalkThrough: false,
      compKey: 0,
      currentRefs: null,
      popularSlides: [],
      advancedFilterBy: '',
      searchTerms: [],
      slideMetaData: {},
      internalResetFn: null,
      dirtySearch: false,
      activeSlide: null,
      activeWrapper: '',
      choice: null,
      isNoMore: false,
      paginationKey: 1000,
      pageSize: Number(process.env.VUE_APP_NUM_OF_SLIDES),
      currentSlidesPage: 1,
      totalVisible: 1,
      listDownloads: [],
      trendingSlides: [],
      showOnedrive: false,
      disableDownload: false,
      audienceList: [],
      gdriveSrc: '',
      gdriveName: '',
      onedriveParam: '',
      onedriveFilename: '',
      showDownloadList: false,
      isLoading: false,
      cacheKey: +new Date(),
      savedQueryPayload: {},
      currentAudience: null,
      advancedFilterKey: 0,
      offsetTop: 0,
      skip: 0,
      username: '',
      filterTailor: null,
      filterByAdvanced: ['Cartoons', 'Icons', 'Images', '3-D'],
      trendingKeywords: [],
      userAssociatedCompanies: [],
      clickedKeyword: {},
      keywords: [
        { name: 'All', category: 'All', count: 0 },
        { name: 'Agenda', category: 'Agenda', count: 0 },
        { name: 'Vision', category: 'Vision', count: 0 },
        { name: 'Executive Summary', category: 'Executive_Summary', count: 0 },
        { name: 'Thank You', category: 'Thank_You', count: 0 },
        {
          name: 'Process',
          category: 'process',
          construct: 'Process',
          count: 0,
        },
        { name: 'Award', category: 'Award', count: 0 },
        { name: 'Timeline', category: 'Timeline', count: 0 },
        { name: 'Journey', category: 'Journey', count: 0 },
        // {
        //   name: 'Line Graph',
        //   category: 'Line_Graph',
        //   construct: 'line_graph',
        //   count: 0,
        // },
        {
          name: 'Financials',
          category: 'Financials',
          count: 0,
        },
        { name: 'Status', category: 'Status', count: 0 },
        { name: 'Title', category: 'Title', count: 0 },
        { name: 'People', category: 'People', count: 0 },
      ],
      boosterPackKeywords: [
        { name: 'All', category: 'All', count: 0 },
        { name: '4th Of July', category: '4th_of_july', count: 0 },
        { name: 'Christmas', category: 'christmas', count: 0 },
        { name: 'Thanksgiving', category: 'thanksgiving', count: 0 },
        { name: 'Sports', category: 'sports', count: 0 },
        { name: 'Movie', category: 'movie', count: 0 },
        { name: 'Nature', category: 'nature', count: 0 },
        { name: 'Mountain', category: 'mountain', count: 0 },
        { name: 'Sea', category: 'sea', count: 0 },
        { name: 'Space', category: 'space', count: 0 },
        { name: 'Race car', category: 'race_car', count: 0 },
        { name: '80s', category: '80s', count: 0 },
      ],
      rejectedKeywords: [
        'nature',
        'mountain',
        '80s',
        'sports',
        '4th of july',
        'thanksgiving',
        'christmas',
        'sea',
        'movie',
        'space',
        'race car',
        'prezfingerprinttest',
      ],
      slides: [],
      slidesFilteredChip: [],
      slidesFilteredChipAdvanced: [],
      generatedOrUploadSlidesArray: [],
      searchAudience: '',
      componentKey: 0,
      types: [
        {
          title: 'Title',
          value: 'title',
        },
        {
          title: 'Executive Summary',
          value: 'executive',
        },
        {
          title: 'SWOT',
          value: 'swot',
        },
        {
          title: 'Agenda',
          value: 'agenda',
        },
        {
          title: 'Table of Contents',
          value: 'table',
        },
        {
          title: 'Objectives',
          value: 'objectives',
        },
        {
          title: 'Value Proposition',
          value: 'proposition',
        },
        {
          title: 'Vision',
          value: 'vision',
        },
        {
          title: 'Vision',
          value: 'vision',
        },
        {
          title: 'Vision',
          value: 'vision',
        },
      ],
      tailorItems: ['You'],
      advancedFilterOpened: false,
      collectionOpened: false,
      dialogContentFinal: null,
      templateIDD: '',
      templateName: '',
      templateTitle: '',
      dialogReset: false,
      selectTemplateKey: 0,
      selectedNodeIds: [{ selectedNodes: [] }, { selectedNodes: [] }],
      advancedItemsUploadOnLoad: [
        {
          items: [
            {
              title: 'Slide Shells',
              display_title: 'filters.slideShells',
              active: true,
              disabled: false,
              value: 'Slide Shells',
            },
            {
              title: 'Partially Anonymized',
              display_title: 'filters.partiallyAnonymized',
              active: true,
              disabled: false,
              value: 'Best Practice Slides',
            },
            {
              title: 'Original',
              display_title: 'upload.original',
              active: true,
              disabled: false,
              value: 'Original Slides',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Slide Type',
          display_title: 'filters.slideType',
          active: false,
          icon: 'slide-type-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Me',
              display_title: 'fingerprint.compareFingAud.me',
              active: true,
              disabled: false,
              value: 'me',
            },
            {
              title: 'My Colleagues',
              display_title: 'filters.myColleagues',
              active: true,
              disabled: false,
              value: 'team,company',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Author',
          display_title: 'build.step3.author',
          active: false,
          icon: 'author-slides',
          disabled: false,
        },
        {
          items: [
            {
              title: 'Viewer',
              display_title: 'prezentationShareStatus.viewer',
              active: true,
              disabled: false,
              value: 'viewer',
            },
            {
              title: 'Restricted',
              display_title: 'prezentationShareStatus.restricted',
              active: true,
              disabled: false,
              value: 'restricted',
            },
            {
              title: 'Editor',
              display_title: 'prezentationShareStatus.editor',
              active: true,
              disabled: false,
              value: 'editor',
            },
            {
              title: 'Shared by me',
              display_title: 'filters.sharedByMe',
              active: true,
              disabled: false,
              value: 'shared',
            },
            {
              title: 'Private',
              display_title: 'prezentationShareStatus.private',
              active: true,
              disabled: false,
              value: 'private',
            },
          ],
          checkAll: true,
          disableCheckAll: false,
          title: 'Access',
          display_title: 'filters.access',
          active: false,
          icon: 'access-slides',
          disabled: false,
        },
        {
          checkAll: false,
          title: 'Uploaded on',
          display_title: 'prezentationsList.updatedOn',
          active: false,
          includeAllOption: false,
          icon: 'created-uploaded-on-slides',
          isCustomGroup: true,
        },
      ],
      excludedCategories: {
        category: [
          'nature',
          'mountain',
          '80s',
          'sports',
          '4th_of_july',
          'thanksgiving',
          'christmas',
          'sea',
          'movie',
          'space',
          'race_car',
          'prezfingerprinttest',
        ],
      },
      ideaChipClicked: 'All',
      matomoFilterMap: {
        Popular: TD_POPULAR,
        'Recommended for selected audience':
          TD_RECOMMENDED_FOR_SELECTED_AUDIENCE,
        'My Favorites': TD_FAVORITES,
        'My Downloads': TD_DOWNLOADS,
      },
      isSimilarSlideSelected: false,
      isImageReplaceWalkThroughFromHybridSearch: false,
      slidesListContainerWidth: 83,
      showQuickSelection: false,
      toolType: null,
    };
  },
  watch: {
    templateSearch(val) {
      if (val && this.templateSelected?.name !== val) this.onSearch(val);
    },
    '$route.query': {
      async handler() {
        if (this.$route.query.origin) {
          this.firstSlide = true;
        }
      },
    },
    slidesFilteredChipAdvanced: function handleDisableImageReplacement() {
      this.reset();
      this.resetReturn();
      this.activeWrapper = '';
      this.setSlidesLoadedInUI(this.slidesFilteredChipAdvanced);
    },
    async startLoadingSlidesFlag(val) {
      // putting the logic of before mount and mounted on change of flag.
      if (val) {
        this.filterTailor = {
          defaultAudience: this.$route.query?.audience || 'SELF',
        };
      }
      // await this.createAudienceListBeforeMount();
    },
    async currentTheme(newVal, oldVal) {
      if (newVal.code === oldVal?.code) {
        return;
      }
      this.templateSelected = newVal;
      this.templatesList = this.sortedThemeArray();

      if (this.savedQueryPayload.query) {
        this.ideaChipClicked = this.savedQueryPayload.query
          ? this.savedQueryPayload.query
          : 'All';
      }
      if (oldVal) {
        await this.getHiddenIdeasSearch();
        await this.getSearchTerms();
        this.savedQueryPayload = {
          username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          limit: this.pageSize,
          category: this.savedQueryPayload.category,
          query: '',
          skip: 0,
        };
        await this.doSearch(false, true, false, true, true, false);
        this.setSlidesSearchProgress(false);
        // this.searchProgress = false;
      }
    },
    filterTailor(val) {
      if (val) {
        this.setTailorID(val.id || this.currentUser.user.id);
      }
    },
    // getDownloadCompletedStatus(val) {
    //   if (val && this.downloadStatus) {
    //     setTimeout(() => {
    //       if (this.getDownloadCompletedStatus) {
    //         this.showDownload = false;
    //         this.$nextTick(() => {
    //           this.setDownloadStatus(false);
    //         });
    //       }
    //     }, 2000);
    //   }
    // },
    leftSideBarStatus(val) {
      if (!val) {
        this.showQuickSelection = false;
        this.toolType = '';
      }
    },

    getNavFromDownloadSnackbarSlides(val) {
      if (val.navigated && this.$route.fullPath === '/home/slides') {
        this.handleLoadWhenNavFromDownloadSnackbar();
        this.setNavFromDownloadSnackbarSlides({ navigated: false, type: '' });
      }
    },

    getNavFromAddToLibOrFavSnackbar(val) {
      if (
        val.navigated &&
        val.type === 'addToFavSlides' &&
        val.origin === 'fav_slides' &&
        this.$route.fullPath === '/home/slides'
      ) {
        this.handleLoadWhenNavFromLibOrFavSnackbar(val.origin);
        this.setNavFromAddToLibOrFavSnackbar({
          navigated: false,
          type: '',
          origin: '',
        });
      }
    },
  },
  computed: {
    filterOptionCategory: {
      get() {
        return this.$store.state.advancedFilterStore.slides
          .filterOptionCategory;
      },
      set() {},
    },
    searchProgress: {
      get() {
        return this.$store.state.advancedFilterStore.slides.searchProgress;
      },
      set() {},
    },
    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.slides.advancedItems;
      },
      set() {},
    },
    advancedItemsOnLoad: {
      get() {
        return this.$store.state.advancedFilterStore.slides.advancedItemsOnLoad;
      },
      set() {},
    },
    advancedItemsOnLoadGraphs: {
      get() {
        return this.$store.state.advancedFilterStore.slides
          .advancedItemsOnLoadGraphs;
      },
      set() {},
    },
    advancedItemsUpload: {
      get() {
        return this.$store.state.advancedFilterStore.slides.advancedItemsUpload;
      },
      set() {},
    },
    fromDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.slides.fromDateMenu;
      },
      set(value) {
        this.$store.dispatch(
          'advancedFilterStore/setSlidesFromDateMenu',
          value,
        );
      },
    },
    toDateMenu: {
      get() {
        return this.$store.state.advancedFilterStore.slides.toDateMenu;
      },
      set(value) {
        this.$store.dispatch('advancedFilterStore/setToDateMenu', value);
      },
    },
    fromDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.slides.fromDateStr;
      },
      set() {},
    },
    toDateStr: {
      get() {
        return this.$store.state.advancedFilterStore.slides.toDateStr;
      },
      set() {},
    },
    fromDate: {
      get() {
        return this.$store.state.advancedFilterStore.slides.fromDate;
      },
      set(value) {
        const payload = {
          type: 'from',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/handleDatePickerTextChange',
          payload,
        );
      },
    },
    toDate: {
      get() {
        return this.$store.state.advancedFilterStore.slides.toDate;
      },
      set(value) {
        const payload = {
          type: 'to',
          date: value,
        };
        this.$store.dispatch(
          'advancedFilterStore/handleDatePickerTextChange',
          payload,
        );
      },
    },
    filterItems: {
      get() {
        return this.$store.state.advancedFilterStore.slides.filterItems;
      },
      set() {},
    },
    filterBy: {
      get() {
        return this.$store.state.advancedFilterStore.slides.filterBy;
      },
      set() {},
    },
    getTemplateName() {
      return this.currentTheme?.name || '';
    },
    getCurrentAudienceName() {
      return this.filterTailor?.fullName || '';
    },
    isRoleBusinessStandared() {
      return this.currentUser?.subscriptions[0]?.planID === '12348';
    },
    orderbySortValue() {
      if (this.filterBy === 'Popular') {
        return 'POPULARITY';
      }
      return 'CHOICE_DESC';
    },
    errorToShow() {
      // const errorItems = [];
      // const errorFilters = [
      //   'Source',
      //   'Node Count',
      //   'Graphics',
      //   'Diagram',
      //   'Slide Type',
      //   'Created From',
      //   'Author',
      //   'Access',
      // ];
      switch (this.filterOptionCategory) {
        case 'downloads':
          return this.$t('slides.useDownloadFeature');
        case 'favorites':
          return this.$t('slides.addToFavourites');
        case 'generated':
          return this.$t('slides.useGenerateFeature');
        case 'uploaded':
          return this.$t('slides.useUploadFeature');
        case 'synthesis':
          return this.$t('slides.useSynthesis');
        case 'redesign':
          return this.$t('slides.useRedesign');
        case 'comply':
          return this.$t('slides.useComply');
        default:
          return this.$t('slides.addingFreshContent');
      }
    },
    errorToShowBold() {
      switch (this.filterOptionCategory) {
        case 'downloads':
          return this.$t('slides.noDownloadFiles');
        case 'favorites':
          return this.$t('slides.noFavourites');
        case 'generated':
          return this.$t('slides.noGeneratedFiles');
        case 'uploaded':
          return this.$t('slides.noUploadedSlides');
        case 'synthesis':
          return this.$t('slides.noSynthesisSlides');
        case 'redesign':
          return this.$t('slides.noRedesignSlides');
        case 'comply':
          return this.$t('slides.noComplySlides');
        default:
          return this.$t('bestPracticesGuide.knowContainer.noResultsFound');
      }
    },
    showGoToTop() {
      return this.offsetTop > 200;
    },
    itemsPerRow() {
      if (this.$vuetify.breakpoint.xl) {
        return 3;
      }
      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }
      if (this.$vuetify.breakpoint.md) {
        return 2;
      }
      return 3;
    },
    ...mapState('dialogStore', {
      initialDialogContent: 'content',
    }),
    ...mapState('users', [
      'currentUser',
      'themes',
      'currentTheme',
      'companyData',
      'startLoadingSlidesFlag',
      'limitsInfoObj',
    ]),
    ...mapState('slidesCollection', [
      // 'downloadSet',
      // 'downloadStatus',
      'nodeCountChanged',
    ]),
    isGraphics() {
      return findIndex(this.advancedItems[2], ['active', 'false']) < 0;
    },
    isDiagrams() {
      return findIndex(this.advancedItems[3], ['active', 'false']) < 0;
    },
    slideContents() {
      return get(this.initialDialogContent, 'imageProducts');
    },

    ...mapGetters('replaceImageStore', [
      'getIsDirty',
      'getShowDialog',
      'getShouldReturnToSlide',
      'getSearchTerm',
    ]),
    ...mapGetters('slidesCollection', [
      // 'isCollectionLimitReached',
      // 'getDownloadCompletedStatus',
      'getAllCollections',
      'getAddedCollections',
      'getAllAddedCollections',
    ]),
    ...mapGetters('slidesStore', [
      'getFavoriteSlides',
      'getImageReplacedSlides',
      'getIconReplacedSlides',
      'getDownloadedSlides',
      'getSlidesSnapshot',
      'getSlideDetailViewChangedAudienceAndTemplate',
      'getSlideCollectionToolStatus',
      'getSlideLocalSearchText',
    ]),
    ...mapGetters('users', [
      'getGenerateAccessLevel',
      'getUploadAccessLevel',
      'isUploadFullAccess',
      'getSynthesisAccessLevel',
      'isRedesignFullAccess',
      'isComplyFullAccess',
    ]),

    ...mapGetters('commonDownloads', [
      'getNavFromDownloadSnackbarSlides',
      'getNavFromAddToLibOrFavSnackbar',
    ]),

    ...mapState('users', ['assetLoaded']),
    ...mapState('users', ['imageLoaded']),
    ...mapState('users', ['assetsNextToken']),
    ...mapState('users', ['assetsStartIndex', 'savedQuery', 'searchToken']),
    ...mapState('users', ['lastAction']),
    ...mapState('users', ['currentUser', 'assetVotes']),
    ...mapState('users', ['assetFolder', 'leftSideBarStatus']),
    // ...mapState("users", ["sessionKey"]),
    // ...mapState('metrics', ['organizationLimit']),
    searchQuery() {
      return this.savedQueryPayload?.query;
    },
    noDataFound() {
      return (
        (!this.slidesFilteredChipAdvanced ||
          this.slidesFilteredChipAdvanced.length < 1) &&
        this.currentSlidesPage === 1
      );
    },
    currentThemeCode() {
      return (
        this.currentTheme?.code || this.currentUser?.theme?.code || 'default'
      );
    },
    createSlidesStateSnapshot() {
      return {
        downloadingSlides: this.downloadingSlides,
        addingToFavSlides: this.addingToFavSlides,
        filterOptionCategory: this.filterOptionCategory,
        fromDate: this.fromDate,
        toDate: this.toDate,
        fromDateMenu: this.fromDateMenu,
        toDateMenu: this.toDateMenu,
        toDateStr: this.toDateStr,
        fromDateStr: this.fromDateStr,
        templateSearch: this.templateSearch,
        // showDownload: this.showDownload,
        panel: this.panel,
        templateSelected: this.templateSelected,
        templatesList: this.templatesList,
        tryNowLoading: this.tryNowLoading,
        filterOptionsList: this.filterOptionsList,
        imageReplaceWalkthroughSlide: this.imageReplaceWalkthroughSlide,
        isImageReplaceWalkThrough: this.isImageReplaceWalkThrough,
        compKey: this.compKey,
        currentRefs: this.currentRefs,
        searchTerms: this.searchTerms,
        // downloadedSlides: [],
        // imageReplacedSlides: [],
        // favoriteSlides: [],
        slideMetaData: this.slideMetaData,
        internalResetFn: this.internalResetFn,
        dirtySearch: this.dirtySearch,
        activeSlide: this.activeSlide,
        activeWrapper: this.activeWrapper,
        choice: this.choice,
        isNoMore: this.isNoMore,
        paginationKey: this.paginationKey,
        pageSize: this.pageSize,
        currentSlidesPage: this.currentSlidesPage,
        totalVisible: this.totalVisible,
        listDownloads: this.listDownloads,
        trendingSlides: this.trendingSlides,
        showOnedrive: this.showOnedrive,
        disableDownload: this.disableDownload,
        audienceList: this.audienceList,
        gdriveSrc: this.gdriveSrc,
        gdriveName: this.gdriveName,
        onedriveParam: this.onedriveParam,
        onedriveFilename: this.onedriveFilename,
        showDownloadList: this.showDownloadList,
        searchProgress: this.searchProgress,
        isLoading: this.isLoading,
        cacheKey: this.cacheKey,
        savedQueryPayload: this.savedQueryPayload,
        currentAudience: this.currentAudience,
        advancedFilterKey: this.advancedFilterKey,
        offsetTop: this.offsetTop,
        skip: this.skip,
        username: this.username,
        filterBy: this.filterBy,
        filterTailor: this.filterTailor,
        filterByAdvanced: this.filterByAdvanced,
        trendingKeywords: this.trendingKeywords,
        userAssociatedCompanies: this.userAssociatedCompanies,
        clickedKeyword: this.clickedKeyword,
        keywords: this.keywords,
        boosterPackKeywords: this.boosterPackKeywords,
        rejectedKeywords: this.rejectedKeywords,
        slides: this.slides,
        slidesFilteredChip: this.slidesFilteredChip,
        slidesFilteredChipAdvanced: this.slidesFilteredChipAdvanced,
        searchAudience: this.searchAudience,
        componentKey: this.componentKey,
        types: this.types,
        items: this.filterItems,
        tailorItems: this.tailorItems,
        advancedFilterOpened: this.advancedFilterOpened,
        collectionOpened: this.collectionOpened,
        dialogContentFinal: this.dialogContentFinal,
        templateIDD: this.templateIDD,
        templateName: this.templateName,
        templateTitle: this.templateTitle,
        dialogReset: this.dialogReset,
        selectTemplateKey: this.selectTemplateKey,
        selectedNodeIds: this.selectedNodeIds,
        advancedItems: this.advancedItems,
        advancedItemsOnLoad: this.advancedItemsOnLoad,
        excludedCategories: this.excludedCategories,
        ideaChipClicked: this.ideaChipClicked,
        matomoFilterMap: this.matomoFilterMap,
        isSimilarSlideSelected: this.isSimilarSlideSelected,
        isImageReplaceWalkThroughFromHybridSearch:
          this.isImageReplaceWalkThroughFromHybridSearch,
        isAudienceChangedWhileEdit: false,
        isTemplateChangedWhileEdit: false,
        popularSlides: this.popularSlides,
        generatedOrUploadSlidesArray: this.generatedOrUploadSlidesArray,
        slidesListContainerWidth: this.slidesListContainerWidth,
        advancedFilterBy: this.advancedFilterBy,
        advancedItemsUpload: this.advancedItemsUpload,
        advancedItemsUploadOnLoad: this.advancedItemsUploadOnLoad,
      };
    },
    getTrendingKeywords() {
      if (this.trendingKeywords && this.trendingKeywords.length) {
        return [
          ...this.trendingKeywords.filter(
            (payload) =>
              !this.hiddenIdeas.includes(payload.category.toLowerCase()),
          ),
        ];
      }
      return [];
    },
  },
  async beforeMount() {
    if (
      this.getSlidesSnapshot &&
      Object.keys(this.getSlidesSnapshot).length <= 0
    ) {
      if (
        this.getSlideDetailViewChangedAudienceAndTemplate &&
        Object.keys(this.getSlideDetailViewChangedAudienceAndTemplate).length >
          0 &&
        this.getSlideDetailViewChangedAudienceAndTemplate.audienceToApply
      ) {
        this.filterTailor =
          this.getSlideDetailViewChangedAudienceAndTemplate.audienceToApply;
      } else if (this.startLoadingSlidesFlag) {
        this.filterTailor = {
          defaultAudience: this.$route.query?.audience || 'SELF',
        };
      }
      EventBus.$on('SET_SIMILAR_SLIDE_SELECTED', (isSelected) => {
        this.isSimilarSlideSelected = isSelected;
      });
      if (this.$route.query.category || this.$route.query.query) {
        if (this.$route.query.imgReplaceWalkthrough === 'true') {
          this.isImageReplaceWalkThroughFromHybridSearch = true;
        }
      }
      if (
        this.getSlideDetailViewChangedAudienceAndTemplate &&
        Object.keys(this.getSlideDetailViewChangedAudienceAndTemplate).length >
          0 &&
        this.getSlideDetailViewChangedAudienceAndTemplate.templateToApply
      ) {
        this.templateSelected =
          this.getSlideDetailViewChangedAudienceAndTemplate.templateToApply;
      } else if (this.currentTheme) {
        this.templateSelected = this.currentTheme;
      }
      this.templatesList = this.sortedThemeArray();
      this.setSlideDetailViewChangedAudienceAndTemplate({});
    }
  },

  created() {
    if (
      this.getSlidesSnapshot &&
      Object.keys(this.getSlidesSnapshot).length <= 0
    ) {
      window.addEventListener('resize', this.setGoTopPosition);
    }
    this.getMainHeadingsWidth();
  },
  methods: {
    ...mapActions('users', [
      'setFilteredThemes',
      'setUploadFlowCTAOrigin',
      'setLimitData',
      'setUpgradePopup',
      'updateRecentSlideQueries',
      'setSlidesAudienceSelected',
      'setLeftSideBarStatus',
    ]),
    ...mapActions('slidesCollection', [
      'setCollectionData',
      // 'setDownloadStatus',
      // 'setDownloadSet',
      'setTailorID',
      'setNodeCountChange',
      'setCollectionUpdateProgress',
    ]),
    ...mapActions('slidesStore', [
      'addToFavoriteSlides',
      'removeFromFavoriteSlides',
      'addToImageReplacedSlides',
      'addToIconReplacedSlides',
      'addToDownloadedSlides',
      'saveSlidesStateSnapshot',
      'setSlideDetailViewChangedAudienceAndTemplate',
      'setSlidesLoadedInUI',
      'setSlideCollectionTool',
      'setSlideLocalSearch',
    ]),
    ...mapActions('upload', ['setUploadPlacement']),
    ...mapActions('advancedFilterStore', [
      'setSlidesFilterOptionCategory',
      'setSlidesSearchProgress',
      'setSlidesAdvancedItems',
      'setSlidesAdvancedItemsOnLoad',
      'setSlidesAdvancedItemsUpload',
      'setSlidesFromDateMenu',
      'setSlidesToDateMenu',
      'setSlidesFromDateStr',
      'setSlidesToDateStr',
      'setSlidesFromDate',
      'setSlidesToDate',
      'setSlidesFilterItems',
      'setSlidesFilterBy',
    ]),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadItemsMultiple',
      'setDownloadLoaders',
      'setNavFromDownloadSnackbarSlides',
      'setNavFromAddToLibOrFavSnackbar',
      'setAddedToLibOrFavSnackbar',
    ]),

    async getRecentSlideSearches() {
      try {
        const userInfo = await usersApi.methods.getUserInfo(
          this.currentUser.email,
        );
        if (userInfo?.user?.recentSlideSearches.length > 0)
          this.setSlideRecentQueriesOnMount(
            userInfo?.user?.recentSlideSearches,
          );
      } catch (err) {
        console.log(err);
      }
    },

    getDownloadMetric(source, type) {
      if (
        source === 'uploaded' &&
        ['slide shells', 'best practice slides'].indexOf(type.toLowerCase()) >
          -1
      ) {
        return 'sanitizedSlidesDownloaded';
      }
      if (source === 'generated') {
        return 'generateSlidesDownloaded';
      }
      if (source === 'uploaded') {
        return 'uploadSlidesDownloaded';
      }
      if (source === 'synthesis') {
        return 'synthesizeSlidesDownloaded';
      }
      if (source === 'redesign') {
        return 'redesignSlidesDownloaded';
      }
      if (source === 'comply') {
        return 'complySlidesDownloaded';
      }
      return 'downloadUsed';
    },

    async getHiddenIdeasSearch() {
      try {
        const hiddenIdea = await getHiddenIdeas({
          companyID: this.currentUser?.company?.id,
          themeID:
            this.currentTheme && this.currentTheme.id
              ? this.currentTheme.id
              : this.currentUser?.theme?.id,
          isBPLSlides: false,
        });
        if (hiddenIdea && hiddenIdea.data.hiddenIdeas.length) {
          this.hiddenIdeas = [...hiddenIdea.data.hiddenIdeas];
        } else {
          this.hiddenIdeas = [];
        }
      } catch (err) {
        console.log(err);
      }
    },

    getMainHeadingsWidth() {
      if (
        window.innerWidth > 1300 &&
        window.innerWidth <= 1400 &&
        !this.leftSideBarStatus
      ) {
        this.audienceSelectorWidth = 210;
        this.sortByFilterWidth = 145;
        this.templateSelectorWidth = 220;
      } else if (
        window.innerWidth > 1200 &&
        window.innerWidth <= 1300 &&
        !this.leftSideBarStatus
      ) {
        this.audienceSelectorWidth = 180;
        this.sortByFilterWidth = 110;
        this.templateSelectorWidth = 120;
      } else if (
        window.innerWidth > 1500 &&
        window.innerWidth <= 1600 &&
        !this.leftSideBarStatus
      ) {
        this.audienceSelectorWidth = 260;
        this.sortByFilterWidth = 180;
        this.templateSelectorWidth = 270;
      } else if (
        window.innerWidth > 1300 &&
        window.innerWidth <= 1400 &&
        this.leftSideBarStatus
      ) {
        this.audienceSelectorWidth = 330;
        this.sortByFilterWidth = 165;
        this.templateSelectorWidth = 320;
      } else if (
        window.innerWidth > 1200 &&
        window.innerWidth <= 1300 &&
        this.leftSideBarStatus
      ) {
        this.audienceSelectorWidth = 237;
        this.sortByFilterWidth = 165;
        this.templateSelectorWidth = 250;
      } else if (!this.leftSideBarStatus) {
        this.audienceSelectorWidth = 380;
        this.sortByFilterWidth = 200;
        this.templateSelectorWidth = 300;
      } else {
        this.audienceSelectorWidth = 380;
        this.sortByFilterWidth = 200;
        this.templateSelectorWidth = 350;
      }
    },

    openCollections() {
      this.toolType = 'collection';
      this.showQuickSelection = true;
      this.panel = 0;
      // this.advancedFilterOpened = false;
      // this.collectionOpened = true;
    },

    handleUploadNavigation() {
      this.setUploadFlowCTAOrigin('slide_library');
      this.$router.push('/home/upload');
      this.setUploadPlacement(TD_SLIDES_LIBRARY);
      trackUploadEvents.uploadIconClick(this.currentUser, {
        [TD_PLACEMENT]: TD_SLIDES_LIBRARY,
      });
    },

    handleFilterCategoryChangeFn(placement = 'advanced filter') {
      if (
        this.$route?.query?.query ||
        this.$route?.query?.category ||
        this.$route?.query?.origin
      ) {
        this.$router.replace({ query: null }).catch(() => {});
      }
      this.firstSlide = false;
      this.setSlidesSearchProgress(true);
      this.advancedFilterBy = '';
      this.setSlidesFromDate('');
      this.setSlidesFromDateMenu(false);
      this.setSlidesFromDateStr('');
      this.setSlidesToDate('');
      this.setSlidesToDateMenu(false);
      this.setSlidesToDateStr('');
      this.setSlidesFilterBy('Recommended for selected audience');
      this.ideaChipClicked = 'All';
      this.savedQueryPayload.query = '';
      this.clickedKeyword = {};
      this.savedQueryPayload.category = '';
      this.trackAdvancedFilterEvents(placement);
      if (this.filterOptionCategory !== 'brand_slides') {
        const onloadFilters = JSON.parse(
          JSON.stringify(this.advancedItemsOnLoad),
        );
        this.setSlidesAdvancedItems([...onloadFilters]);
      }
      if (
        this.filterOptionCategory !== 'uploaded' &&
        this.filterOptionCategory !== 'synthesis'
      ) {
        const onloadFilters = JSON.parse(
          JSON.stringify(this.advancedItemsUploadOnLoad),
        );
        this.advancedItemsUpload = [...onloadFilters];
      }
      if (
        this.filterOptionCategory === 'favorites' ||
        this.filterOptionCategory === 'downloads'
      ) {
        this.advancedFilterBy =
          this.filterOptionCategory === 'favorites'
            ? 'My Favorites'
            : 'My Downloads';
        this.handleChangeAdvancedFilter();
        return;
      }
      if (
        this.filterOptionCategory === 'generated' ||
        this.filterOptionCategory === 'uploaded' ||
        this.filterOptionCategory === 'synthesis' ||
        this.filterOptionCategory === 'redesign' ||
        this.filterOptionCategory === 'comply'
      ) {
        this.getGeneratedOrUploadSlideDetails();
        return;
      }
      if (this.filterOptionCategory === 'brand_slides') {
        this.onAdvancedFilerChangeNew();
      }
    },

    trackAdvancedFilterEvents(placement) {
      const sectionNameMap = {
        brand_slides: 'Brand Slides',
        favorites: 'Favorites',
        downloads: 'Downloads',
        generated: 'Generated Slides',
        uploaded: 'Uploads',
        synthesis: 'Synthesis output Slides',
        redesign: 'Redesigned Slides',
        comply: 'Converted Slides',
      };
      trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
        [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
        [TD_COMMON_COLUMN_VALUE]: sectionNameMap[this.filterOptionCategory],
        [TD_PLACEMENT]:
          placement === 'snackbar'
            ? TD_FROM_SNACK_BAR
            : TD_FROM_ADVANCED_FILTER,
      });
    },

    getWidthOfSlideList() {
      if (this.advancedFilterOpened === true) {
        return this.leftSideBarStatus
          ? this.slidesListContainerWidth - 5
          : this.slidesListContainerWidth - 10;
      }
      if (this.collectionOpened === true) {
        return this.leftSideBarStatus
          ? this.slidesListContainerWidth - 10
          : this.slidesListContainerWidth - 13;
      }
      return 100;
    },

    isCurrentSelected(id) {
      return this.currentTheme.code === id;
    },

    isAllUnchecked(advancedFilterItem) {
      if (advancedFilterItem.items) {
        let isAllUncheck = false;
        advancedFilterItem.items.forEach((opt) => {
          if (opt.active) {
            isAllUncheck = true;
          }
        });
        return !isAllUncheck;
      }
      return true;
    },

    getAudienceCompany() {
      return getAudienceCompanyName(this.currentAudience, this.currentUser);
    },

    /*
      Function for determining
      if the this slide belongs to a collection
      based on slide's template
    */
    isAddedToCollection(slide) {
      let slidesInCollectionUnderSlideTemplate = [];
      const slideThemeCode = this.getTemplateCodeFromSlideLoaded(slide);
      const slideThemeObj = this.themes.find(
        (thm) => thm.code === slideThemeCode,
      );
      const currentCollection = this.getAddedCollections.find(
        (i) => i.templateID === slideThemeObj?.id,
      );
      if (currentCollection) {
        slidesInCollectionUnderSlideTemplate = currentCollection.slides
          .filter(
            (i) =>
              !i.isImageReplaced &&
              !i.isIconReplaced &&
              !['partial', 'full'].includes(
                i.sanitizedLevel && i.sanitizedLevel.toLowerCase(),
              ),
          )
          .map((i) => i.slideID);
      } else {
        slidesInCollectionUnderSlideTemplate = [];
      }
      return slidesInCollectionUnderSlideTemplate.includes(slide.unique_id);
    },

    /*
      function for determining
      if the this slide belongs to a collection after image replacement
      based on slide's template
    */
    isAddedToCollectionImageReplaced(slide) {
      let slidesInCollectionUnderSlideTemplate = [];
      const slideThemeCode = this.getTemplateCodeFromSlideLoaded(slide);
      const slideThemeObj = this.themes.find(
        (thm) => thm.code === slideThemeCode,
      );
      const currentCollection = this.getAllAddedCollections.find(
        (i) => i.templateID === slideThemeObj?.id,
      );
      if (currentCollection) {
        slidesInCollectionUnderSlideTemplate = currentCollection.slides.map(
          (i) => i.slideID,
        );
      } else {
        slidesInCollectionUnderSlideTemplate = [];
      }

      return slidesInCollectionUnderSlideTemplate.includes(slide.unique_id);
    },

    sortedThemeArray() {
      let finalArray = [];
      const currentTheme = this.themes.find(
        (x) => x.code === this.currentTheme.code,
      );
      finalArray = this.themes.filter((x) => x.code !== this.currentTheme.code);

      finalArray.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
      finalArray.unshift(currentTheme);
      return finalArray;
    },

    handleThemeBlur() {
      this.templateSelected = this.currentTheme;
      setTimeout(() => {
        this.selectTemplateKey++;
      }, 500);
    },

    handleClickTheme(theme) {
      if (!theme) {
        return;
      }
      this.savedQueryPayload.query = 'All';
      this.templateSelected = this.currentTheme;
      this.selectTemplateKey++;
      if (theme.code === this.currentTheme.code) {
        return;
      }
      this.$modal.show(
        SlideThemeChangeDetail,
        {
          index: theme,
          updateThemeIndex: this.updateThemeIndex,
        },
        {
          name: 'SlideThemeChangeDetail',
          width: '540px',
          height: '165px',
          styles: { borderRadius: '18px' },
        },
      );
    },

    async updateThemeIndex(theme) {
      try {
        MatomoAnalyticsHandler.templateSwitched(this.currentUser, theme);
        // trackProfileEvents.profileTemplatesSwitch(this.currentUser, {
        //   [TD_TEMPLATE]: theme?.name,
        // });
        trackSlideEvents.slidesTemplateInfoYesIamSure(this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
          [TD_TEMPLATE]: theme?.name,
        });

        await usersApi.methods
          .updateUserProfile({ defaultTheme: theme.code })
          .then(async (updatedUserDetail) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetail;
            userInfo.theme = theme;
            await this.setCurrentUser(userInfo);
          });
        this.setCurrentTheme(theme);
        this.resetAdvancedFilter();
        this.resetIdeaChipSelection();
      } catch (err) {
        console.error(err);
      }
    },

    onSearch(searchTerm) {
      handleDebounce(() => this.trackTemplateSearchEvent(searchTerm.trim()));
    },

    trackTemplateSearchEvent(searchTerm) {
      trackSlideEvents.slidesTemplateInfoSearch(this.currentUser, {
        [TD_AUD]: this.getAudienceDataToTrack()?.email,
        [TD_QUERY]: searchTerm,
      });
    },

    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          origin: SLIDES,
          tailorID: this.getAudienceDataToTrack()?.email,
          audienceCompany: getAudienceCompanyName(
            this.currentAudience,
            this.currentUser,
          ),
          templateRequestFrom: 'Slides',
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },

    generate() {
      this.$router.push(`/home/generate?prompt=${this.$route.query.query}`);
    },

    async onTryNow() {
      this.tryNowLoading = true;
      try {
        this.imageReplaceWalkthroughSlide =
          this.imageReplaceWalkthroughSlide || (await this.getSlideData())[0];
        this.activeSlide = this.imageReplaceWalkthroughSlide;
        this.activeSlide = {
          ...this.activeSlide,
          companies: [this.currentUser?.company?.name || ''],
        };
        this.tryNowLoading = false;
        this.isImageReplaceWalkThrough = true;
      } catch (err) {
        this.tryNowLoading = false;
        console.log(err);
      }
    },

    async downloadCollection(templateID) {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      if (
        !this.limitsInfoObj?.slide?.isLimitCrossed ||
        this.currentUser.user.role !== 'TrialUser'
      ) {
        if (this.$store.getters['users/getIsDownloadLimitExceeded']('slide')) {
          // this.$modal.show(
          //   DownloadLimitDetail,
          //   {
          //     dialogContent: null,
          //   },
          //   {
          //     name: 'DownloadLimitDetail',
          //     width: '750px',
          //     height: '600px',
          //   },
          // );
        } else {
          // this.setDownloadStatus(true);
          this.setShowDownloadSnackbar(true);
          if (templateID) {
            this.setDownloadLoaders({
              loaderId: templateID,
              downloading: true,
            });
            const setToDownload = this.getAllCollections.find(
              (st) => st.templateID === templateID,
            );
            this.setDownloadItems({
              status: 'assembling',
              itemIndex: uuidv4(),
              originType: 'slideCollections',
              logDownloadCallback: () => {
                this.logCollectionDownload(setToDownload);
                const setSelected = this.getAllCollections.find(
                  (st) => st.templateID === templateID,
                );
                const noOfSlides = setSelected?.slides.length;

                postFeatureUsage({
                  feature: 'collection',
                  noofslides: noOfSlides,
                })
                  .then((response) => {
                    console.log(response);
                    if (response?.data) {
                      this.setLimitData(response.data);
                    }
                  })
                  .catch((error) => console.log(error));

                trackSlideEvents.slidesSetDownload(this.currentUser, {
                  [TD_ISDOWNLOAD]: 1,
                  [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
                  [TD_AUD]: this.getAudienceDataToTrack()?.email,
                  [TD_NUMBEROFSLIDES]: noOfSlides,
                  [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                    this.currentAudience,
                    this.currentUser,
                  ),
                });
              },
              downloadStatusCallback: (payload) => {
                this.setDownloadLoaders({
                  loaderId: payload.templateId,
                  downloading: false,
                });
              },
              cancelDownloadCallBack: (payload) => {
                const audCompName = getAudienceCompanyName(
                  payload.currentAudience,
                  payload.currentUser,
                );
                trackSlideEvents.slidesSnackBarCancel(payload.currentUser, {
                  [TD_AUD]:
                    payload.filterTailorId || payload.currentUser.user.id,
                  [TD_COMPANY_OF_AUD_TAILORED_FOR]: audCompName,
                });
              },
              metaData: {
                ...setToDownload,
                fileName: setToDownload.templateName,
                limitsKey: 'prezentation',
                origin: 'prezentation',
                cancelDownloadCallBackPayload: {
                  filterTailorId: this.filterTailor.id,
                  currentAudience: { ...this.currentAudience },
                  currentUser: { ...this.currentUser },
                },
                downloadStatusCallbackPayload: {
                  templateId: templateID,
                },
              },
            });
          } else {
            const collectionsBeingDownloaded = this.getAllCollections.map(
              (cl) => {
                this.setDownloadLoaders({
                  loaderId: cl.templateID,
                  downloading: true,
                });
                return {
                  status: 'assembling',
                  itemIndex: uuidv4(),
                  originType: 'slideCollections',
                  logDownloadCallback: () => {
                    this.logCollectionDownload(cl);
                    const noOfSlides = cl.slides.length;

                    postFeatureUsage({
                      feature: 'collection',
                      noofslides: noOfSlides,
                    })
                      .then((response) => {
                        console.log(response);
                        if (response?.data) {
                          this.setLimitData(response.data);
                        }
                      })
                      .catch((error) => console.log(error));

                    trackSlideEvents.slidesSetDownload(this.currentUser, {
                      [TD_ISDOWNLOAD]: 1,
                      [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
                      [TD_AUD]: this.getAudienceDataToTrack()?.email,
                      [TD_NUMBEROFSLIDES]: noOfSlides,
                      [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                        this.currentAudience,
                        this.currentUser,
                      ),
                    });
                  },
                  downloadStatusCallback: (payload) => {
                    this.setDownloadLoaders({
                      loaderId: payload.templateId,
                      downloading: false,
                    });
                  },
                  cancelDownloadCallBack: (payload) => {
                    const audCompName = getAudienceCompanyName(
                      payload.currentAudience,
                      payload.currentUser,
                    );
                    trackSlideEvents.slidesSnackBarCancel(payload.currentUser, {
                      [TD_AUD]:
                        payload.filterTailorId || payload.currentUser.user.id,
                      [TD_COMPANY_OF_AUD_TAILORED_FOR]: audCompName,
                    });
                  },
                  metaData: {
                    ...cl,
                    fileName: cl.templateName,
                    limitsKey: 'prezentation',
                    origin: 'prezentation',
                    cancelDownloadCallBackPayload: {
                      filterTailorId: this.filterTailor.id,
                      currentAudience: { ...this.currentAudience },
                      currentUser: { ...this.currentUser },
                    },
                    downloadStatusCallbackPayload: {
                      templateId: cl.templateID,
                    },
                  },
                };
              },
            );
            this.setDownloadItemsMultiple(collectionsBeingDownloaded);
          }
        }
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'slide' });
      }
    },

    async onResetConfirm() {
      this.resetReturn();
      this.handleDeleteCollection();
      this.dialogReset = false;
    },

    showReset(item) {
      this.dialogReset = true;
      if (item) {
        this.templateIDD = item.templateID;
        this.templateTitle = 'Clear Set';
        this.templateName = this.$t('slides.wouldYouRemoveSet', {
          Name: item.templateName,
        });
      } else {
        this.templateTitle = 'Clear Collection';
        this.templateName = this.$t('slides.removeAllSetsFromCollection');
      }
    },

    onResetCancel() {
      if (this.templateTitle === 'Clear Set')
        trackSlideEvents.slidesSetDeleteNo(this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.currentAudience,
            this.currentUser,
          ),
        });
      if (this.templateTitle === 'Clear Collection')
        trackSlideEvents.slidesCollectionDeleteNo(this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.currentAudience,
            this.currentUser,
          ),
        });
      this.templateTitle = '';
      this.templateName = '';
      this.dialogReset = false;
    },

    async tryNowClose() {
      this.$router.replace({ query: null });
      this.resetAdvancedFilter();
      this.setSlidesSearchProgress(true);
      // this.searchProgress = true;
      EventBus.$emit('CLEAR_SEARCH');
      this.savedQueryPayload = {
        username: this.currentUser.user.cognitoID, // "pz_rath_admin",
        query: '',
        category: [],
        orprefs: {},
        limit: this.pageSize,
        skip: 0,
      };
      AnalyticsHandler.selectedSlideChip(this.currentUser, 'All');
      MatomoAnalyticsHandler.slideChipSelected('All', this.currentUser, {
        audience: this.getAudienceDataToTrack(),
      });
      await this.doSearch(false, true, true);
      this.isImageReplaceWalkThrough = false;
    },

    getSlideData() {
      return new Promise((resolve, reject) => {
        const params = {
          username:
            this.savedQueryPayload.username || this.currentUser.user.cognitoID,
          category: 'break',
          orderby: this.orderbySortValue,
          andprefs: {
            theme: this.currentThemeCode,
            sid: '0001',
          },
          // company: this.currentUser.companyName.toLowerCase(),
          // company: (payload.category && payload.category.length > 0 && payload.category.toLowerCase()==="agenda") || this.currentUser.organizationID.includes('test')? 'prezentium': 'prezentium',
          limit: this.pageSize,
          skip:
            this.savedQueryPayload.skip >= 0 ? this.savedQueryPayload.skip : 0,
        };
        params.onlyids = false;

        if (this.filterTailor?.email) {
          params.audience_id = this.filterTailor?.email;
          params.audience_type = this.filterTailor?.type;
        }

        const serialized = JSON.stringify(params);

        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performSearch);
            this.compKey += 1;
            if (result.statusCode === 200) {
              resolve(result.body.payloads);
              console.log(result);
            }
          })
          .catch((err) => reject(err));
      });
    },

    setGoTopPosition() {
      const loadMoreBtnEle = document.getElementById('loadmore-btn');
      if (loadMoreBtnEle) {
        const gotopLeftPosition = `${
          loadMoreBtnEle.getBoundingClientRect().left + 30
        }px`;
        document.getElementById('go-top').style.left = gotopLeftPosition;
      }
    },

    onScroll(event) {
      this.offsetTop = event.target.scrollingElement.scrollTop;
    },

    toTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      trackSlideEvents.slidesBackToTop(this.currentUser);
    },

    deactivateAllChip() {
      const ele = document.getElementById('allChip');
      if (ele) {
        ele.classList.remove('v-chip--active');
      }
    },

    isTrendingSlide(slide) {
      return this.trendingSlides.includes(slide.unique_id);
    },

    getSlideDetails(params) {
      getSlideDetail(params)
        .then((slideData) => {
          this.slideMetaData = { ...this.slideMetaData, ...slideData.data };
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isDownloaded === true &&
              !this.getDownloadedSlides.includes(key)
            ) {
              this.addToDownloadedSlides(key);
            }
          }
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isImageReplaced === true &&
              !this.getImageReplacedSlides.includes(key)
            ) {
              this.addToImageReplacedSlides(key);
            }
          }
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isIconReplaced === true &&
              !this.getIconReplacedSlides.includes(key)
            ) {
              this.addToIconReplacedSlides(key);
            }
          }
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isFavourite === true &&
              !this.getFavoriteSlides.includes(key)
            ) {
              this.addToFavoriteSlides(key);
            } else if (
              (!this.slideMetaData[key].isFavourite ||
                this.slideMetaData[key].isFavourite === false) &&
              this.getFavoriteSlides.includes(key)
            ) {
              this.removeFromFavoriteSlides(key);
            }
          }
          for (const key in this.slideMetaData) {
            if (
              this.slideMetaData[key].isTrending === true &&
              !this.trendingSlides.includes(key)
            ) {
              this.trendingSlides.push(key);
            }
          }
        })
        .catch((err) => console.log(err));
    },

    async getCollectionData() {
      await getCollection()
        .then(async (resp) => {
          this.setCollectionData(resp.data.collections);
          // console.log(resp);
          // console.log(this.getAllCollections);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async makeApiCallsForSlidesAfterMount(isSnackbarNav) {
      this.trendingKeywords = [];
      if (this.currentThemeCode === GENERIC_BOOSTER_PACK) {
        this.trendingKeywords = this.boosterPackKeywords;
      } else {
        this.getSearchTerms();
      }
      const { company } = this.currentUser;
      const companyName = company.displayName
        ? company.displayName
        : company.name;
      if (company.name && companyName && !isSnackbarNav) {
        const capitalizedCompanyName = capitalizeFirstLetter(companyName);
        const tempAdvancedItems = [...this.advancedItems];
        tempAdvancedItems[0].items[1].name = company.name;
        tempAdvancedItems[0].items[1].title = capitalizedCompanyName;
        tempAdvancedItems[0].items[1].display_title = capitalizedCompanyName;
        this.setSlidesAdvancedItems(tempAdvancedItems);

        const tempAdvanceItemsOnLoad = [...this.advancedItemsOnLoad];
        tempAdvanceItemsOnLoad[0].items[1].name = company.name;
        tempAdvanceItemsOnLoad[0].items[1].title = capitalizedCompanyName;
        tempAdvanceItemsOnLoad[0].items[1].display_title =
          capitalizedCompanyName;
        this.setSlidesAdvancedItemsOnLoad(tempAdvanceItemsOnLoad);
      }
      this.currentAudience = this.currentUser;
      this.setSlidesSearchProgress(true);
      if (this.$route.query.category || this.$route.query.query) {
        if (this.$route.query.imgReplaceWalkthrough === 'true') {
          this.onTryNow();
        }
        this.savedQueryPayload = {
          username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          limit: this.pageSize,
          query: this.$route.query.query ? this.$route.query.query : '',
          category: this.$route.query.category
            ? this.$route.query.category
            : 'none',
          skip: 0,
        };
        if (this.$route.query.filter && this.$route.query.filter === 'graphs') {
          const notprefs = {
            construct: [],
          };
          const morprefs = {
            construct: [],
          };
          this.advancedItemsOnLoadGraphs[3].items.forEach((child) => {
            if (!child.active) {
              notprefs.construct.push(child.name);
            } else {
              morprefs.construct.push(child.name);
            }
          });
          this.savedQueryPayload.morprefs = morprefs;
          this.savedQueryPayload.notprefs = notprefs;
        }
        await this.doSearch(false, true, false, true);
        this.setSlidesSearchProgress(false);
      } else if (
        this.$route.query?.type === 'uploaded' ||
        this.$route.query?.type === 'synthesis'
      ) {
        this.setSlidesFilterOptionCategory(this.$route.query.type);
        // this.handleOpenAdvancedFilter();
        this.handleFilterCategoryChangeFn();
      } else if (this.getSlideLocalSearchText.length > 0) {
        this.savedQueryPayload.query = this.getSlideLocalSearchText;
        this.savedQueryPayload.category = 'none';
        await this.doSearch(false, true, false, true, true, false);
        this.setSlidesSearchProgress(false);
      } else if (!isSnackbarNav) {
        this.savedQueryPayload = {
          username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          limit: this.pageSize,
          query: '',
          skip: 0,
        };
        await this.doSearch(false, true, false, true, true, false);
        this.setSlidesSearchProgress(false);
      }
      this.resetReturn();
      this.reset();
      // while landing from Search page clicking on Go To feature button PM-7020
      if (this.getSlideLocalSearchText.length > 0) {
        this.ideaChipClicked = this.trendingKeywords.some(
          (item) =>
            item?.name.toLowerCase() ===
            this.getSlideLocalSearchText.toLowerCase(),
        )
          ? this.getSlideLocalSearchText
          : 'All';
      } else {
        this.ideaChipClicked = this.$route.query?.query
          ? this.$route.query?.query
          : 'All';
        this.savedQueryPayload.query = this.$route.query?.query
          ? this.$route.query?.query
          : '';
      }
    },

    setInternalResetFn(e) {
      if (this.isImageReplaceWalkThrough) {
        this.isImageReplaceWalkThroughFromHybridSearch = false;
      }
      this.internalResetFn = e;
    },

    onDialogConfirm() {
      this.closeDialog();
      this.reset();
      this.internalResetFn();
      if (!this.getShouldReturnToSlide) {
        this.activeWrapper = '';
        this.resetReturn();
      }
    },

    onDialogCancel() {
      this.closeDialog();
    },

    ...mapActions('replaceImageStore', [
      'reset',
      'closeDialog',
      'openDialog',
      'resetReturn',
    ]),

    handleClose() {
      if (this.getIsDirty) {
        this.openDialog();
      } else {
        this.activeWrapper = '';
      }
    },

    removeSlideOnDelete(slide) {
      const posToDelete = this.slidesFilteredChipAdvanced.findIndex(
        (item) => item.unique_id === slide.unique_id,
      );
      this.slidesFilteredChipAdvanced.splice(posToDelete, 1);

      // delete from slides state property
      const posToDeleteSlds = this.slides.findIndex(
        (item) => item.unique_id === slide.unique_id,
      );
      if (posToDeleteSlds > 1) {
        this.slides.splice(posToDeleteSlds, 1);
      }

      // delete from slideFilteredChip state property
      const posToDeleteFC = this.slidesFilteredChip.findIndex(
        (item) => item.unique_id === slide.unique_id,
      );
      if (posToDeleteFC) {
        this.slidesFilteredChip.splice(posToDeleteFC, 1);
      }
    },

    handleUpdateActiveSlide(selected) {
      this.activeSlide = selected.asset;
    },

    ...mapActions('users', ['fetchCurrentUserFavorites']),

    ...mapActions('users', [
      'setAudience',
      'setCurrentUser',
      'addSearchTerms',
      'setCurrentTheme',
      'setSlideRecentQueriesOnMount',
    ]),
    ...mapActions('dialogStore', ['getDialogContent']),

    handleAddAudience(val) {
      if (val && !val.fingerPrint) {
        MatomoAnalyticsHandler.tailorSlidesGrayAudienceSelected(
          'slides',
          null,
          this.currentUser,
        );
        this.openRemindModal(val);
      } else {
        this.$modal.show(
          AddCollegueDetail,
          {
            addColleague: this.addColleague,
            origin: TD_AUDIENCE_ORIGIN_SLIDES,
          },
          AddNewAudienceModalProps,
        );
      }
    },

    handleAudienceChange(val) {
      this.savedQueryPayload.skip = 0;
      this.filterTailor = val;
      trackSlideEvents.slidesTailorSlides(this.currentUser, {
        [TD_AUD]: val?.id,
      });
      const isDefaultValueChangeEvent = !this.currentAudience;

      if (!isDefaultValueChangeEvent) {
        this.loadDefaultSlides();
      }
    },

    handleAudienceChangeInitial(val) {
      this.savedQueryPayload.skip = 0;
      this.setSlidesAudienceSelected(val);
      this.filterTailor = val;
      const isDefaultValueChangeEvent = !this.currentAudience;
      const isBrandSlides = !this.$route.query.type?.length;

      if (
        !this.isDefaultAudienceEventEmitted &&
        !isDefaultValueChangeEvent &&
        isBrandSlides &&
        performance.navigation.type !== 1
      ) {
        this.loadDefaultSlides();
      }
      this.isDefaultAudienceEventEmitted = true;
    },

    loadDefaultSlides() {
      // Important to keep below as if instead of else if
      // because we want the slides to get reset
      this.currentAudience = { ...this.filterTailor };
      this.choice = this.getChoice(
        this.currentAudience.fingerPrint || 'director',
      );
      this.resetAdvancedFilter();
      this.resetIdeaChipSelection();
      handleDebounce(() => this.doSearch(false, true, true));

      if (this.filterTailor.email !== this.currentUser.user.id) {
        MatomoAnalyticsHandler.tailorSlidesAudienceSelected(
          'slides',
          null,
          this.currentUser,
        );
      }
    },

    handleAudienceFocus() {
      MatomoAnalyticsHandler.tailorSlidesAudienceAccessed(
        'slides',
        this.currentPrezentation,
        this.currentUser,
      );
    },

    openRemindModal(currentAudience) {
      const isUser = !!this.currentUser.user;
      this.$forceUpdate();
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: !!isUser,
          origin: 'slides',
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },

    async getSearchTerms() {
      getUserSearchTerms()
        .then((searchData) => {
          const termsData = searchData.data.searchByUser.items;
          const allIndex = termsData.findIndex(
            (searchTerm) => searchTerm.searchTerm === 'All',
          );
          if (allIndex > -1) termsData.splice(allIndex, 1);
          if (termsData.length) {
            this.searchTerms = termsData;
          }
          if (this.searchTerms?.length > 0) {
            const terms = sortBy(this.searchTerms, ['count']);
            const termsDesc = terms.reverse();

            // Adding "All" to the chips as first option
            this.trendingKeywords = [];
            this.trendingKeywords.push({ name: 'All', category: 'All' });
            let count = 1;
            let i = 0;
            // Adding trending keywords of the user to chips
            while (count <= 12 && i < termsDesc.length) {
              const searchTerm = termsDesc[i].searchTerm.toLowerCase();
              const item = this.rejectedKeywords.find(
                (it) => it === searchTerm,
              );
              if (!item) {
                this.trendingKeywords.push({
                  name: termsDesc[i].searchTerm,
                  category: termsDesc[i].category,
                });
                count++;
                i++;
              } else {
                i++;
              }
            }
            let j = 0;
            // Filling remaining ideas from predefined keywords
            while (count <= 12 && j < this.keywords.length) {
              const searchTerm = this.keywords[j].category
                .toLowerCase()
                .replace('_', ' ');
              const item = this.trendingKeywords.find(
                (it) =>
                  it.category.toLowerCase().replace('_', ' ') === searchTerm,
              );
              if (!item) {
                this.trendingKeywords.push(this.keywords[j]);
                count++;
                j++;
              } else {
                j++;
              }
            }
          } else {
            this.trendingKeywords = this.keywords.slice(0);
          }
        })
        .catch((err) => console.log(err));
      this.$forceUpdate();
    },

    async logSearch(term) {
      try {
        await this.getSearchTerms();
        const index =
          this.searchTerms &&
          this.searchTerms.findIndex((item) => {
            const category = item.category.toLowerCase().replace('_', ' ');
            const termName = term.clickedKeyword.name
              .toLowerCase()
              .replace('_', ' ');
            return category === termName;
          });
        if (index >= 0) {
          this.searchTerms[index].count += 1;
          const searchData = {
            id: this.searchTerms[index].id,
            count: this.searchTerms[index].count,
            searchTermResult: !(
              term.results === undefined ||
              term.results === null ||
              term.results.length <= 0
            ),
          };
          console.log('searchData', searchData);
          updateSearchTerms(searchData)
            .then(() => {
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        } else {
          console.log('add_search_term');
          const searchData = {
            id: uuidv4(),
            userID: this.currentUser.user.id,
            searchTerm: term.clickedKeyword.name,
            category: term.clickedKeyword.category,
            count: 1,
            createdAt: new Date().toISOString(),
            searchTermResult: !(
              term.results === undefined ||
              term.results === null ||
              term.results.length <= 0
            ),
          };
          await addSearchTerms(searchData)
            .then((value) => {
              this.addSearchTerms(value.data.createSearchTerm);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }
    },

    getSlidesParam(item) {
      const file = item && item.filename ? item.filename.substring(1) : '';
      return `&slides=${file}`;
    },

    noNodesSelected(index) {
      const selectedItems = this.advancedItems[index].items.filter(
        (item) => item.active,
      );
      return selectedItems.length === 0;
    },

    addColleague(colleague) {
      console.log(colleague);
    },

    someNodesSelected(index) {
      const selectedItems = this.advancedItems[index].items.filter(
        (item) => item.active,
      );
      return selectedItems.length > 0 && !this.allNodesSelected(index);
    },

    allNodesSelected(index) {
      const selectedItems = this.advancedItems[index].items.filter(
        (item) => item.active,
      );
      return selectedItems.length === this.advancedItems[index].items.length;
    },

    async loadMoreSlides() {
      if (
        this.filterOptionCategory === 'generated' ||
        this.filterOptionCategory === 'uploaded' ||
        this.filterOptionCategory === 'synthesis' ||
        this.filterOptionCategory === 'redesign'
      ) {
        this.loadMoreGenerateOrUploadSlides();
        return;
      }
      if (
        this.advancedFilterBy === 'My Downloads' ||
        this.advancedFilterBy === 'My Favorites'
      ) {
        this.skip += 1;
        const { payloads, totalFound } = await handleSearch({
          query_type:
            this.advancedFilterBy === 'My Downloads'
              ? 'downloaded'
              : 'favorites',
          theme_id: this.currentThemeCode, // TODO: API is not using this value. So we had to filter manually in client side.
          search: this.nonBrandSlideSearchQuery
            ? this.nonBrandSlideSearchQuery
            : '',
          limit: this.pageSize * (this.skip + 1),
          skip: 0,
        });
        const favSlides = payloads || [];
        this.popularSlides = favSlides;
        const parametersMeta = {
          assetIds: [...this.popularSlides].map((sld) => sld.unique_id),
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        this.getSlideDetails(parametersMeta);
        this.slidesFilteredChipAdvanced = this.popularSlides.slice(
          0,
          this.slidesFilteredChipAdvanced.length + this.pageSize,
        );
        this.isNoMore = !(totalFound > this.slidesFilteredChipAdvanced.length);
      } else {
        const payload = this.savedQueryPayload;
        payload.skip = this.slidesFilteredChip.length;
        this.savedQueryPayload = payload;

        this.isLoading = true;
        this.cacheKey = +new Date();
        handleDebounce(() => this.doSearch(true, false));
      }
      trackSlideEvents.slidesSeeMoreResults(this.currentUser, {});
    },

    loadMoreGenerateOrUploadSlides() {
      if (
        this.generatedOrUploadSlidesArray?.length >
        this.slidesFilteredChipAdvanced?.length
      ) {
        this.slidesFilteredChipAdvanced =
          this.generatedOrUploadSlidesArray.slice(
            0,
            this.slidesFilteredChipAdvanced.length + this.pageSize,
          );
        this.isNoMore = !(
          this.generatedOrUploadSlidesArray?.length >
          this.slidesFilteredChipAdvanced?.length
        );
      } else {
        this.isNoMore = true;
      }
    },

    setIntervalX(callback, delay, repetitions) {
      let x = 0;
      const intervalID = window.setInterval(() => {
        callback();

        if (++x === repetitions) {
          window.clearInterval(intervalID);
        }
      }, delay);
    },

    onAdvancedFilerChangeNew(filterItem, filterCategory) {
      filterCategory?.items.forEach((child) => {
        if (!child.active) {
          filterCategory.checkAll = false;
        }
      });
      // let matomoData = [];
      // if (filterCategory) {
      //   matomoData = filterCategory.items.map((item) => {
      //     if (item.active) {
      //       return item.title;
      //     }
      //     return '';
      //   });
      // }
      // const slideFilterChangedArray = matomoData.filter((str) => str !== '');
      // const slideFilterChanged = slideFilterChangedArray.length
      //   ? slideFilterChangedArray.join('_')
      //   : '';
      // if (filterCategory?.title === 'Graphics') {
      //   trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
      //     [TD_HASIMAGES]: slideFilterChanged,
      //     [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
      //     [TD_COMMON_COLUMN_VALUE]: TD_GRAPHICS,
      //   });
      // } else if (filterCategory?.title === 'Node Count') {
      //   trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
      //     [TD_NODECOUNT]: slideFilterChanged,
      //     [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
      //     [TD_COMMON_COLUMN_VALUE]: TD_NODE_COUNT,
      //   });
      // } else if (filterCategory?.title === 'Diagrams') {
      //   trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
      //     [TD_CONSTRUCT]: slideFilterChanged,
      //     [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
      //     [TD_COMMON_COLUMN_VALUE]: TD_DIAGRAMS,
      //   });
      // } else if (filterCategory?.title === 'Source') {
      //   trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
      //     [TD_REPLACE_IMAGE_SOURCE]: slideFilterChanged,
      //     [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
      //     [TD_COMMON_COLUMN_VALUE]: TD_SOURCE,
      //   });
      // }
      try {
        if (filterItem && filterCategory) {
          MatomoAnalyticsHandler.advancedFilter(
            {
              selected: filterItem.active,
              category: filterCategory.title.replace(/ /g, ''),
              title: filterItem.title.replace(/ /g, ''),
            },
            this.currentUser,
          );
        }
      } catch (e) {
        console.log(e);
      }
      if (
        this.filterOptionCategory === 'uploaded' ||
        this.filterOptionCategory === 'synthesis'
      ) {
        this.getGeneratedOrUploadSlideDetails();
        return;
      }
      const prefs = {};
      const morprefs = {};
      const notprefs = {};
      const orprefs = {};
      morprefs.node = [];
      morprefs.source = [];
      notprefs.node = [];
      notprefs.source = [];

      const tempAdvancedItems = [...this.advancedItems];

      tempAdvancedItems[0].items.forEach((child) => {
        if (child.active) {
          morprefs.source.push(
            child.name ? child.name : child.title.toLowerCase().split('.')[0],
          );
        } else {
          notprefs.source.push(
            child.name ? child.name : child.title.toLowerCase().split('.')[0],
          );
        }
      });
      tempAdvancedItems[0].checkAll =
        tempAdvancedItems[0].items.filter((item) => item.active).length === 2;

      let checkAll = true;
      let moreSelected = false;
      tempAdvancedItems[1].items.forEach((child) => {
        if (child.title !== '10 or more') {
          if (child.active) {
            morprefs.node.push(child.title);
          } else {
            notprefs.node.push(child.title);
            checkAll = false;
          }
        } else if (child.title === '10 or more' && child.active)
          moreSelected = true;
      });
      if (moreSelected) morprefs.node = [];
      // "0" is added for a special case: if none is selected
      // then no slides will be returned
      // Given the scenario, none of the slides will be tagged with "0"
      else morprefs.node.push('0');
      tempAdvancedItems[1].checkAll = checkAll;
      checkAll = true;

      tempAdvancedItems[2].items.forEach((child) => {
        if (!child.active) {
          checkAll = false;
        }
      });
      tempAdvancedItems[2].items.forEach((child) => {
        switch (child.title.toLowerCase()) {
          case 'cartoons':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_cartoon = 'yes';
              }
            } else {
              notprefs.has_cartoon = 'yes';
              checkAll = false;
            }
            break;
          case 'icons':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_icons = 'yes';
              }
            } else {
              notprefs.has_icons = 'yes';
              checkAll = false;
            }
            break;
          case 'images':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_image_bus = 'yes'; // in solr (has_image and has_image_bus) will be checked
              }
            } else {
              notprefs.has_image_bus = 'yes'; // in solr (has_image and has_image_bus) will be checked
              checkAll = false;
            }
            break;
          case '3-d':
            if (child.active) {
              if (!checkAll) {
                morprefs.has_three_d = 'yes';
              }
            } else {
              notprefs.has_three_d = 'yes';
              checkAll = false;
            }
            break;

          default:
            break;
        }
      });
      tempAdvancedItems[2].checkAll = checkAll;
      checkAll = true;
      morprefs.construct = [];
      /* "pyramid","bar_column_graph","donut_pie_graph","radar_chart","area_graph","multi_bar","line_graph","stacked_bar_graph","histogram","pictogram_chart","tree_map" */
      notprefs.construct = [];
      tempAdvancedItems[3].items.forEach((child) => {
        if (!child.active) {
          notprefs.construct.push(child.name);
          checkAll = false;
        } else {
          morprefs.construct.push(child.name);
        }
      });
      tempAdvancedItems[3].checkAll = checkAll;
      this.setSlidesAdvancedItems(tempAdvancedItems);
      const payload = this.savedQueryPayload;
      payload.morprefs = morprefs;
      payload.prefs = prefs;
      payload.notprefs = notprefs;
      payload.orprefs = orprefs;
      payload.skip = 0;
      this.savedQueryPayload = payload;
      handleDebounce(() => this.doSearch(false, true, true));
    },

    async getGeneratedOrUploadSlideDetails() {
      this.setSlidesSearchProgress(true);
      let slidesList = [];
      this.generatedOrUploadSlidesArray = [];
      // const createdFrom = this.filterOptionCategory;
      const slideType = this.advancedItemsUpload[0].items
        .filter((item) => item.active === true)
        .map((item) => item.value.replace(/ /g, '%20'));
      this.advancedItemsUpload[0].checkAll =
        this.advancedItemsUpload[0].items.filter((item) => item.active)
          .length === this.advancedItemsUpload[0].items.length;

      const author = this.advancedItemsUpload[1].items
        .filter((item) => item.active === true)
        .map((item) => item.value.replace(/ /g, '%20'));
      this.advancedItemsUpload[1].checkAll =
        this.advancedItemsUpload[1].items.filter((item) => item.active)
          .length === this.advancedItemsUpload[1].items.length;

      const access = this.advancedItemsUpload[2].items
        .filter((item) => item.active === true)
        .map((item) => item.value.replace(/ /g, '%20'));
      this.advancedItemsUpload[2].checkAll =
        this.advancedItemsUpload[2].items.filter((item) => item.active)
          .length === this.advancedItemsUpload[2].items.length;

      const createdFromString = this.filterOptionCategory;
      const slideTypeString =
        slideType?.length === this.advancedItemsUpload[0].items.length
          ? 'all'
          : slideType.join(',');
      const authorString =
        author?.length === this.advancedItemsUpload[1].items.length
          ? 'all'
          : author.join(',');
      const accessString =
        access?.length === this.advancedItemsUpload[2].items.length
          ? 'all'
          : access.join(',');
      const createdOnFromDate = this.fromDateStr ? this.fromDateStr : '';
      const createdOnToDate = this.toDateStr ? this.toDateStr : '';
      const categories = this.clickedKeyword?.category
        ? this.clickedKeyword?.category.toLowerCase()
        : '';
      const templateCode = this.currentTheme?.code
        ? this.currentTheme?.code
        : '';
      const search = this.nonBrandSlideSearchQuery;
      let offset = 0;
      let totalcount = 0;
      const limit = 2000;
      do {
        try {
          const params = {
            limit,
            offset,
            createdFromString,
            slideTypeString,
            authorString,
            accessString,
            createdOnFromDate,
            createdOnToDate,
            categories,
            templateCode,
            search,
          };
          const resp = await getGeneratedUploadedSlides(params);
          slidesList = resp?.data?.slides;
          offset = resp?.data?.last_offset;
          totalcount = resp?.data?.total_count;
          this.generatedOrUploadSlidesArray = [
            ...slidesList,
            ...this.generatedOrUploadSlidesArray,
          ];
        } catch (e) {
          console.log(e);
        }
      } while (offset < totalcount);
      if (this.generatedOrUploadSlidesArray?.length) {
        this.slidesFilteredChipAdvanced =
          this.generatedOrUploadSlidesArray.slice(0, this.pageSize);
        const parametersMeta = {
          assetIds: [...this.generatedOrUploadSlidesArray].map(
            (sld) => sld.unique_id,
          ),
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        this.getSlideDetails(parametersMeta);
      } else this.slidesFilteredChipAdvanced = [];
      this.setSlidesSearchProgress(false);
      this.isNoMore = !(
        this.generatedOrUploadSlidesArray?.length >
        this.slidesFilteredChipAdvanced?.length
      );
    },

    resetIdeaChipSelection() {
      this.savedQueryPayload.category = [];
      this.savedQueryPayload.query = '';
      this.clickedKeyword = { name: 'All', category: 'All' };
      this.ideaChipClicked = 'All';
      this.$router
        .replace({ query: { query: 'all', category: 'All' } })
        .catch(() => {});
    },

    resetAdvancedFilter(makeApiCall = false) {
      this.setSlidesSearchProgress(true);
      this.currentSlidesPage = 1;
      const onloadFilters = JSON.parse(
        JSON.stringify(this.advancedItemsOnLoad),
      );
      this.setSlidesAdvancedItems([...onloadFilters]);
      const onloadUploadFilters = JSON.parse(
        JSON.stringify(this.advancedItemsUploadOnLoad),
      );
      this.setSlidesAdvancedItemsUpload([...onloadUploadFilters]);
      this.setSlidesFilterOptionCategory('brand_slides');
      this.advancedFilterBy = '';
      this.setSlidesFromDate('');
      this.setSlidesToDate('');
      this.setSlidesFromDateMenu(false);
      this.setSlidesToDateMenu(false);
      this.setSlidesToDateStr('');
      this.setSlidesFromDateStr('');
      if (makeApiCall) {
        this.onAdvancedFilerChangeNew();
        trackSlideEvents.slidesResetAllFiltersClick(this.currentUser);
      }
      this.advancedFilterKey++;
    },

    handleOpenAdvancedFilter(firstLogin = false) {
      if (!firstLogin) {
        this.advancedFilterOpened = !this.advancedFilterOpened;
      }
      this.collectionOpened = false;
      if (this.advancedFilterOpened) {
        EventBus.$emit('MINIMIZE_LEFTNAV');
      }
      setTimeout(() => {
        this.setGoTopPosition();
      }, 300);
    },

    hideAdvancedFilter() {
      this.advancedFilterOpened = false;
      setTimeout(() => {
        this.setGoTopPosition();
      }, 300);
    },

    hideCollection() {
      this.toolType = null;
      this.showQuickSelection = false;
      // this.collectionOpened = false;
      setTimeout(() => {
        this.setGoTopPosition();
      }, 300);
    },

    handleDeleteCollection(item, templateID) {
      console.log('handleDeleteCollection----');
      this.setCollectionUpdateProgress(true);
      const params = {};
      if (this.templateIDD) {
        params.type = 'set';
        params.templateID = this.templateIDD;
      } else if (item && templateID) {
        console.log(item);
        params.type = 'slide';
        params.templateID = templateID;
        params.slideID = item.slideID;
        params.slideFileName = item.slideFileName;
      } else {
        params.type = 'collection';
      }
      deleteCollection(params)
        .then((resp) => {
          console.log(resp);
          this.setCollectionData(resp.data.collections);
          this.templateIDD = '';
        })
        .catch((err) => {
          console.log(err);
          this.templateIDD = '';
        })
        .finally(() => {
          this.setCollectionUpdateProgress(false);
        });
      if (params.type === 'collection')
        trackSlideEvents.slidesCollectionDeleteYes(this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.currentAudience,
            this.currentUser,
          ),
        });
      if (params.type === 'set')
        trackSlideEvents.slidesSetDeleteYes(this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.currentAudience,
            this.currentUser,
          ),
        });
      if (params.type === 'slide') {
        trackSlideEvents.slidesRemoveFromCollection(item, this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
          [TD_PLACEMENT]: 'Collection',
          [TD_ISFAVORITED]: isSlideFavorite(item, this.getFavoriteSlides)
            ? '1'
            : NA,
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.currentAudience,
            this.currentUser,
          ),
        });
      }
    },

    getRandomNumber(min, max) {
      const num = (Math.random() * (max - min) + min).toFixed(2);
      return Number(num);
    },

    getChoice(fingerprint) {
      let choice = {
        visual: this.getRandomNumber(4.5, 6.0),
        data: this.getRandomNumber(4.0, 5.0),
      };
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'performer':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'navigator':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(0.0, 2.0),
          };
          return choice;

        case 'surgeon':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'architect':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scholar':
          choice = {
            visual: this.getRandomNumber(4.5, 6.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;

        case 'scientist':
          choice = {
            visual: this.getRandomNumber(0.0, 2.0),
            data: this.getRandomNumber(4.0, 5.0),
          };
          return choice;
        case 'producer':
          choice = { visual: 1.0, data: 1.0 };
          return choice;
        default:
          return choice;
      }
    },

    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },

    incrementSearchCount() {
      const userData = {
        performIncrements: {
          countSearches: 1,
        },
      };
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUserDetails) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUserDetails;
          this.setCurrentUser(userInfo);
        })
        .catch((err) =>
          console.log('error while updating the search count', err),
        );
      // return PrezentAnalyticsAPI.incrementSearchCount().catch((e) => {
      //   console.log(e);
      // });
    },

    searchWithinCategory(category) {
      return new Promise((resolve, reject) => {
        const andprefs = {};
        andprefs.theme = this.currentThemeCode;
        let notprefs = {};
        if (this.currentThemeCode !== GENERIC_BOOSTER_PACK) {
          // eslint-disable-next-line no-nested-ternary
          notprefs = this.savedQueryPayload.notprefs
            ? this.savedQueryPayload.notprefs
            : !category || category === 'all'
            ? this.excludedCategories
            : [];
        }
        const params = {
          username:
            this.savedQueryPayload.username || this.currentUser.user.cognitoID,
          query: this.savedQueryPayload.query
            ? this.savedQueryPayload.query
            : '',
          category:
            !category || category.toLowerCase() === 'all' || category === 'none'
              ? []
              : category,
          orderby: this.orderbySortValue,
          choice: this.choice,
          morprefs: this.savedQueryPayload.morprefs
            ? this.savedQueryPayload.morprefs
            : [],
          orprefs: this.savedQueryPayload.orprefs
            ? this.savedQueryPayload.orprefs
            : [],
          prefs: this.savedQueryPayload.prefs
            ? this.savedQueryPayload.prefs
            : [],
          notprefs,
          andprefs,
          // company: this.currentUser.companyName.toLowerCase(),
          // company: (payload.category && payload.category.length > 0 && payload.category.toLowerCase()==="agenda") || this.currentUser.organizationID.includes('test')? 'prezentium': 'prezentium',
          limit: this.pageSize,
          skip:
            this.savedQueryPayload.skip >= 0 ? this.savedQueryPayload.skip : 0,
        };
        params.onlyids = false;

        if (this.filterTailor?.email) {
          params.audience_id = this.filterTailor?.email;
          params.audience_type = this.filterTailor?.type;
        }

        const serialized = JSON.stringify(params);
        // Leaving this to log an empty search
        AnalyticsHandler.performSlideSearch(
          this.currentUser,
          this.savedQueryPayload.query || this.savedQueryPayload.category,
        );
        AnalyticsHandler.logUserStats(this.currentUser);
        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performSearch);
            this.compKey += 1;
            if (result.statusCode === 200) {
              resolve(result.body);
            }
          })
          .catch((err) => reject(err));
      });
    },

    // logSearchQueryFlag used to not log the search in some edge case
    async doSearch(
      isLoadMore = false,
      resetFilter = true,
      resetSearchProgress = false,
      endLoop = true,
      isSearchViaChip = false,
      logSearchQueryFlag = true,
    ) {
      this.isImageReplaceWalkThrough = false;
      if (resetFilter) {
        this.setSlidesFilterBy('Recommended for selected audience');
      }
      if (!isLoadMore) {
        this.setSlidesSearchProgress(true);
      }
      this.slides.splice(0, this.slides.length);
      let cat = [];
      if (this.savedQueryPayload.category) {
        if (!Array.isArray(this.savedQueryPayload.category)) {
          cat.push(this.savedQueryPayload.category);
        } else {
          cat = [...this.savedQueryPayload.category];
          if (!cat.length) {
            cat = ['all'];
          }
        }
      } else {
        cat.push('all');
      }
      if (!this.choice) {
        this.choice = this.getChoice(
          this.currentAudience?.fingerPrint ||
            this.currentUser?.user?.fingerPrint ||
            'director',
        );
      }
      if (
        (this.savedQueryPayload.query || this.savedQueryPayload.category) &&
        !isLoadMore
      ) {
        cat.forEach(() => this.incrementSearchCount());
      }
      AnalyticsHandler.performSlideSearch(
        this.currentUser,
        this.savedQueryPayload.query || this.savedQueryPayload.category,
      );
      AnalyticsHandler.logUserStats(this.currentUser);
      /* eslint-disable prefer-const */
      const [{ payloads: results, translated_query: translatedQuery }] =
        await Promise.all(
          cat.map((category) => this.searchWithinCategory(category)),
        );
      this.deactivateAllChip();
      let searchTermResult = false;
      if (!results || !results.length) {
        this.isNoMore = true;
        if (isLoadMore) {
          this.setSlidesSearchProgress(false);
          this.slides = [...this.slidesFilteredChip];
        } else {
          this.slides = [];
        }
      } else {
        this.isNoMore = false;
        if (isLoadMore) {
          this.slides = [...this.slidesFilteredChip, ...results];
        } else {
          this.slides = results;
          searchTermResult = true;
        }
        if (results.length < this.pageSize) {
          this.isNoMore = true;
        }
        const arr = results.map((item) => item.unique_id);
        const params = {
          assetIds: arr,
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        this.getSlideDetails(params);
      }

      // log search query
      if (!isLoadMore && logSearchQueryFlag) {
        const logPayload = {
          searchTermResult,
          type: isSearchViaChip ? 'Slide Chip' : 'Slides',
        };
        if (this.isSlideLibrarySearch)
          logPayload.type = 'Slide Library Local Search';
        if (this.savedQueryPayload.query) {
          logPayload.searchTerm = this.savedQueryPayload.query.toLowerCase();
        } else {
          const catName = cat.map((item) =>
            item.replace('_', ' ').toLowerCase(),
          );
          logPayload.searchTerm = catName.join(', ');
        }
        logPayload.searchTermTranslated = translatedQuery;
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
        // this.isSlideLibrarySearch = false; // resettig to false
      }

      this.isLoading = false;
      this.slidesFilteredChip = [...this.slides];
      if (this.slidesFilteredChip.length > 0) {
        this.slidesFilteredChipAdvanced = this.slidesFilteredChip.slice();
      } else {
        this.slidesFilteredChipAdvanced = [];
      }
      if (!endLoop) await this.handleChangeFilter(results);
      if (resetSearchProgress) this.setSlidesSearchProgress(false);
      this.setIntervalX(
        () => {
          this.cacheKey = +new Date();
        },
        500,
        1,
      );
      const { clickedKeyword } = this;
      if (
        this.clickedKeyword.category &&
        this.clickedKeyword.category.toLowerCase() !== 'all'
      )
        this.logSearch({ clickedKeyword, results });
      return results;
    },

    async clickChipIdeas(e, keyword) {
      EventBus.$emit('CLEAR_SLIDE_LIBRARY_SEARCH');
      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (query && query.query !== keyword.name.toLowerCase()) {
        query.query = keyword.name.toLowerCase();
        query.category = keyword.category;
        delete query.origin;
        this.$router.replace({ query }).catch(() => {});
        this.firstSlide = false;
      }
      if (keyword.name === this.ideaChipClicked) return;
      // update recent slide queries also
      if (keyword?.name !== 'All') {
        const queryPayload = {
          type: 'Slides',
          category: '',
          name: keyword.name,
        };
        this.updateRecentSlideQueries(queryPayload);
      }
      if (
        this.filterOptionCategory === 'redesign' ||
        this.filterOptionCategory === 'synthesis' ||
        this.filterOptionCategory === 'generated' ||
        this.filterOptionCategory === 'downloads' ||
        this.filterOptionCategory === 'favorites' ||
        this.filterOptionCategory === 'comply'
      ) {
        this.setSlidesFilterOptionCategory('brand_slides');
      }
      this.clickedKeyword = keyword;
      this.setSlidesSearchProgress(true);
      this.ideaChipClicked = keyword.name;
      if (
        this.filterOptionCategory === 'uploaded' ||
        this.filterOptionCategory === 'synthesis'
      ) {
        this.getGeneratedOrUploadSlideDetails();
        this.setSlidesSearchProgress(false);
        return;
      }
      EventBus.$emit('CLEAR_SEARCH');
      const construct = [];
      const orprefs = {};
      if (keyword.construct) {
        construct.push(keyword.construct);
        orprefs.construct = construct;
      }
      if (keyword.name.toLowerCase().startsWith('all')) {
        // this.savedQueryPayload = {
        //   username: this.currentUser.user.cognitoID, // "pz_rath_admin",
        //   query: '',
        //   category: [],
        //   orprefs,
        //   limit: this.pageSize,
        //   skip: 0,
        // };
        this.savedQueryPayload.category = [];
        this.savedQueryPayload.query = '';
        this.savedQueryPayload.skip = 0;
        await this.doSearch(false, true, true, true, true);
        AnalyticsHandler.selectedSlideChip(this.currentUser, 'All');
        MatomoAnalyticsHandler.slideChipSelected('All', this.currentUser, {
          audience: this.getAudienceDataToTrack(),
        });
      } else {
        // let payload = null;
        if (keyword.construct) {
          // payload = {
          //   username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          //   query: keyword.name,
          //   category: [],
          //   limit: this.pageSize,
          //   skip: 0,
          // };
          this.savedQueryPayload.query = keyword?.name ? keyword?.name : '';
          this.savedQueryPayload.category = [];
          this.savedQueryPayload.skip = 0;
        } else {
          // payload = {
          //   username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          //   query: '',
          //   category: keyword.category ? keyword.category : [],
          //   limit: this.pageSize,
          //   skip: 0,
          // };
          // check if the idea chip is valid exist in idea list
          const ideaData = ideaMappingData.find(
            (a) => a.category.toLowerCase() === keyword.category.toLowerCase(),
          );
          const category = keyword.category && ideaData ? keyword.category : [];
          this.savedQueryPayload.category = category;
          this.savedQueryPayload.skip = 0;

          this.savedQueryPayload.query = keyword?.name ? keyword?.name : '';
        }
        await this.doSearch(false, true, true, true, true);
        // this.savedQueryPayload = payload;
        AnalyticsHandler.selectedSlideChip(this.currentUser, keyword.name);
        MatomoAnalyticsHandler.slideChipSelected(
          keyword.name,
          this.currentUser,
          { audience: this.getAudienceDataToTrack() },
        );
        trackSlideEvents.slidesIdeaChipClick(this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
          [TD_QUERY]: keyword.name,
          [TD_NORESULTSFOUND]:
            this.slides && this.slides.length > 0 ? '0' : '1',
        });
      }
    },

    trackSlideSearchEvents() {
      if (this.isSlideLibrarySearch) {
        if (this.slideLibrarySearchType === 'itemSelect')
          trackSlideEvents.slidesSearchDropdown(this.currentUser, {
            [TD_IDEA]:
              this.savedQueryPayload?.category === 'none'
                ? ''
                : this.savedQueryPayload?.category,
            [TD_QUERY]: this.savedQueryPayload?.query || '',
            [TD_NORESULTSFOUND]: this.slides.length > 0 ? 1 : 0,
          });
        if (this.slideLibrarySearchType === 'enterPress')
          trackSlideEvents.slidesSearchDropHybrid(this.currentUser, {
            [TD_QUERY]: this.savedQueryPayload?.query || '',
            [TD_NORESULTSFOUND]: this.slides.length > 0 ? 1 : 0,
          });
      }
    },

    goToFav() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavSlides',
        origin: 'fav_slides',
      });
      this.$router.push('/home/slides').catch(() => {});
    },

    async handleFavorite(item) {
      const favorite = this.getFavoriteSlides.includes(item.asset.unique_id);
      if (favorite || item.isFavorite) {
        this.addingToFavSlides.push({
          removing: true,
          id: item.asset.unique_id,
        });
        let favSlideId = this.slideMetaData[item.asset.unique_id].favouriteId;
        if (!favSlideId) {
          const params = {
            assetIds: [item.asset.unique_id],
            getFavourite: true,
          };
          const resp = await getSlideDetail(params);
          favSlideId = resp.data[item.asset.unique_id].favouriteId;
        }
        await deleteFavorite(favSlideId)
          .then(() => {
            const index = this.getFavoriteSlides.indexOf(item.asset.unique_id);
            if (index > -1) this.removeFromFavoriteSlides(item.asset.unique_id);
          })
          .catch((err) => console.log(err));
        this.addingToFavSlides = [...this.addingToFavSlides].filter(
          (itm) => itm.id !== item.asset.unique_id,
        );
        if (
          this.filterOptionCategory !== '' &&
          this.filterOptionCategory === 'favorites'
        ) {
          const favIndex = [...this.slidesFilteredChipAdvanced].findIndex(
            (itm) => itm.unique_id === item.asset.unique_id,
          );
          this.slidesFilteredChipAdvanced.splice(favIndex, 1);
        }
      } else {
        const favoriteData = {
          id: uuidv4(),
          assetID: item.asset.unique_id,
          source: item.asset.prefs.source,
          themeID: this.getTemplateCodeFromSlideLoaded(item.asset),
          tailorID: this.currentAudience?.id
            ? this.currentAudience?.id
            : this.currentUser?.user?.id,
          audienceType: this.currentAudience?.audienceType
            ? this.currentAudience?.audienceType
            : '',
        };
        this.addingToFavSlides.push({ adding: true, id: item.asset.unique_id });
        await createFavorite(favoriteData)
          .then(() => {
            this.addToFavoriteSlides(item.asset.unique_id);
            if (this.slideMetaData[item.unique_id || item.asset.unique_id]) {
              this.slideMetaData[
                item.unique_id || item.asset.unique_id
              ].favouriteId = favoriteData.id;
            } else {
              this.slideMetaData[item.unique_id || item.asset.unique_id] = {
                favouriteId: favoriteData.id,
              };
            }

            this.setAddedToLibOrFavSnackbar({
              show: true,
              ctaText: 'snackBarItems.goToFavorites',
              snackbarText: 'snackBarItems.slideAddedToFav',
              navFn: this.goToFav,
              snackbarType: 'goToFav',
            });
          })
          .catch((err) => console.log(err));
        this.addingToFavSlides = [...this.addingToFavSlides].filter(
          (itm) => itm.id !== item.asset.unique_id,
        );

        AnalyticsHandler.markedSlideFavorite(this.currentUser, item.unique_id);
        const { slideTitle, slideId } = utils.getSlideTitleAndID(item);
        MatomoAnalyticsHandler.slideFavorited(
          slideTitle,
          slideId,
          this.currentUser,
          {
            audience: this.getAudienceDataToTrack(),
          },
        );
        const assetIds = [...this.slides].map((sld) => sld.unique_id);
        let beforeInteractionSlides = [];
        let afterInteractionSlides = [];
        if (assetIds.length) {
          const itemToRemove = item.asset
            ? item.asset.unique_id
            : item.unique_id;
          const itemIndexToRemove = assetIds.indexOf(itemToRemove);
          if (itemIndexToRemove !== -1) {
            beforeInteractionSlides = assetIds.slice(0, itemIndexToRemove);
            afterInteractionSlides = assetIds.slice(itemIndexToRemove + 1);
          }
        }
        const slidesLoadedInUIBefore = beforeInteractionSlides.join('&');
        const slidesLoadedInUIAfter = afterInteractionSlides.join('&');
        const slidesLoadedInUI = `Before-Interaction&${slidesLoadedInUIBefore}&After-Interaction&${slidesLoadedInUIAfter}`;
        if (this.searchQuery.length) {
          trackSearchEvents.searchDropdownSlideFavorited(
            item.asset,
            this.currentUser,
            {
              [TD_ISFAVORITED]: 1,
              [TD_AUD]: this.getAudienceDataToTrack()?.email,
              [TD_COMMON_COLUMN_NAME]: TD_REST_OF_THE_SLIDES_IN_UI,
              [TD_COMMON_COLUMN_VALUE]: slidesLoadedInUI,
              [TD_QUERY]: this.searchQuery,
              [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
            },
          );
        } else {
          trackSlideEvents.slidesFaviourite(item.asset, this.currentUser, {
            [TD_ISFAVORITED]: 1,
            [TD_AUD]: this.getAudienceDataToTrack()?.email,
          });
        }
      }
      await this.fetchCurrentUserFavorites(this.currentUser.id);
    },

    resetDownloading(state) {
      this.onedriveParam = '';
      this.showDownloadList = state;
    },

    handleDownload() {
      this.showDownloadList = true;
    },

    getCommonOtherDataForSlideDownload(item) {
      return {
        [TD_ISDOWNLOAD]: 1,
        [TD_ISFAVORITED]: isSlideFavorite(item, this.getFavoriteSlides)
          ? '1'
          : NA,

        [TD_QUERY]: this.searchQuery || NA,
        [TD_SLIDEID]: getSlideId(item),
      };
    },

    logCollectionDownload(set) {
      let countSlideDownloads = 0;
      let countImgReplacedSlides = 0;
      let countSynthesisSlides = 0;
      let countComplySlides = 0;
      let countGeneratedSlides = 0;
      let countRedesignedSlides = 0;
      let countUploadedSlides = 0;
      let countIconReplacedSlides = 0;
      let countSanitizedSlides = 0;
      //  let countSanitizedSlides = 0;
      for (let i = 0; i < set.slides.length; i++) {
        const slide = set.slides[i];
        console.log('set.slides[i] =======>', slide);
        const downloadData = {
          id: uuidv4(),
          assetID: slide.slideID,
          themeID: set.templateCode,
          tailorID: slide.tailorID,
          idea: slide.idea,
          construct: slide?.construct,
          source: slide.source,
          downloadedFrom: 'Slide library (Collection)',
          sanitizedLevel: slide.sanitizedLevel,
          acceptedRejectedCount: slide.acceptedRejectedCount,
        };
        if (set.slides[i].isImageReplaced) {
          downloadData.replacedImage = true;
          countImgReplacedSlides++;
        }
        if (set.slides[i].isIconReplaced) {
          downloadData.replacedIcon = true;
          countIconReplacedSlides++;
        }
        if (set.slides[i].isComply) {
          countComplySlides++;
        }
        if (set.slides[i].isSynthesis) {
          countSynthesisSlides++;
        }
        if (set.slides[i].isGenerated) {
          countGeneratedSlides++;
        }
        if (set.slides[i].isRedesign) {
          countRedesignedSlides++;
        }
        if (set.slides[i].isUploaded) {
          countUploadedSlides++;
        }
        if (
          !set.slides[i].isUploaded &&
          !set.slides[i].isSynthesis &&
          !set.slides[i].isGenerated &&
          !set.slides[i].isRedesign &&
          !set.slides[i].isComply
        ) {
          countSlideDownloads++;
        }
        if (
          set.slides[i].isUploaded &&
          ['partial', 'full'].indexOf(
            set.slides[i].sanitizedLevel.toLowerCase(),
          ) > -1
        ) {
          countSanitizedSlides++;
        }
        // if (this.set.slides[i].isUploaded) {
        //   countSanitizedSlides++;
        //   countUploadedSlides++;
        // }
        createDownload(downloadData)
          .then(() => {
            console.log('emit');
            set.slides.forEach((sld) => {
              if (sld.isImageReplaced && sld.isIconReplaced) {
                this.addToImageReplacedSlides(sld.slideID);
                this.addToIconReplacedSlides(sld.slideID);
              } else if (sld.isImageReplaced) {
                this.addToImageReplacedSlides(sld.slideID);
              } else if (sld.isIconReplaced) {
                this.addToIconReplacedSlides(sld.slideID);
              } else {
                this.addToDownloadedSlides(sld.slideID);
              }
            });
          })
          .catch((err) => console.log(err));
      }
      createCollectionDownload({ templateID: set.templateID })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      const userData = {
        performIncrements: {},
      };
      if (countImgReplacedSlides > 0) {
        userData.performIncrements.downloadWithImgReplaced =
          countImgReplacedSlides;
      }
      if (countIconReplacedSlides > 0) {
        userData.performIncrements.downloadWithIconReplaced =
          countIconReplacedSlides;
      }
      if (countComplySlides > 0) {
        userData.performIncrements.complySlidesDownloaded = countComplySlides;
      }
      if (countSynthesisSlides > 0) {
        userData.performIncrements.synthesizeSlidesDownloaded =
          countSynthesisSlides;
      }
      if (countSlideDownloads > 0) {
        userData.performIncrements.downloadUsed = countSlideDownloads;
      }
      if (countUploadedSlides > 0) {
        userData.performIncrements.uploadSlidesDownloaded = countUploadedSlides;
      }
      if (countRedesignedSlides > 0) {
        userData.performIncrements.redesignSlidesDownloaded =
          countRedesignedSlides;
      }
      if (countGeneratedSlides > 0) {
        userData.performIncrements.generateSlidesDownloaded =
          countGeneratedSlides;
      }
      if (countSanitizedSlides > 0) {
        userData.performIncrements.sanitizedSlidesDownloaded =
          countSanitizedSlides;
      }
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(userInfo);
        })
        .catch((err) => console.log(err));
    },

    async logDownload(
      fromExpandedViewObj,
      item,
      imageReplaced = false,
      isGSDownload = false,
      iconReplaced = false,
    ) {
      const downloadData = {
        id: uuidv4(),
        assetID: item.unique_id,
        themeID: this.getTemplateCodeFromSlideLoaded(item),
        tailorID: this.filterTailor?.id
          ? this.filterTailor?.id
          : this.currentUser?.user?.id,
        construct: item.prefs.construct,
        idea: item.title,
        downloadedFrom: 'Slides',
        isOverightPrez: false,
        source: item.prefs.source,
        sanitizedLevel: item.sanitizedLevel,
        acceptedRejectedCount: item.acceptedRejectedCount,
        isRestricted: item.permission === 'Restricted',
      };
      if (imageReplaced) {
        downloadData.replacedImage = true;
      }
      if (iconReplaced) {
        downloadData.replacedIcon = true;
      }
      console.log('Slides', downloadData);
      await createDownload(downloadData)
        .then(() => {
          if (imageReplaced && iconReplaced) {
            this.addToImageReplacedSlides(item.unique_id);
            this.addToIconReplacedSlides(item.unique_id);
          } else if (imageReplaced) {
            this.addToImageReplacedSlides(item.unique_id);
          } else if (iconReplaced) {
            this.addToIconReplacedSlides(item.unique_id);
          } else {
            this.addToDownloadedSlides(item.unique_id);
          }
        })
        .catch((err) => console.log(err));
      // this.currentUser.user.downloadUsed++;
      if (fromExpandedViewObj) {
        try {
          const { searchTerm, source } = this.getSearchTerm;
          const otherData = {
            ...this.getCommonOtherDataForSlideDownload(item),
            [TD_DOWNLOADTYPE]: isGSDownload
              ? TD_DOWNLOADTYPE_GS
              : TD_DOWNLOADTYPE_DIRECT,
            [TD_AUD]: this.getAudienceDataToTrack()?.email,
            [TD_QUERY]: searchTerm || NA,
            [TD_REPLACE_IMAGE_SOURCE]: source || NA,
          };
          if (fromExpandedViewObj.fromExpandedView) {
            if (
              isGSDownload &&
              this.isAddedToCollection(item) &&
              this.nodeCountChanged
            ) {
              trackSlideEvents.slidesNodeCountChangeAddToCollectionDownload(
                item,
                this.currentUser,
                {
                  ...otherData,
                  [TD_ISDOWNLOAD]: 1,
                  [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_GS,
                  [TD_AUD]: this.getAudienceDataToTrack()?.email,
                  [TD_ISFAVORITED]: isSlideFavorite(
                    item,
                    this.getFavoriteSlides,
                  )
                    ? '1'
                    : NA,
                  [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                    this.currentAudience,
                    this.currentUser,
                  ),
                },
              );
            }
            if (
              imageReplaced &&
              isGSDownload &&
              this.isAddedToCollectionImageReplaced(item)
            ) {
              trackSlideEvents.slidesImageReplacementAddToCollectionDownload(
                item,
                this.currentUser,
                {
                  ...otherData,
                  [TD_ISDOWNLOAD]: 1,
                  [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_GS,
                  [TD_AUD]: this.getAudienceDataToTrack()?.email,
                  [TD_ISFAVORITED]: isSlideFavorite(
                    item,
                    this.getFavoriteSlides,
                  )
                    ? '1'
                    : NA,
                  [TD_REPLACE_IMAGE_SOURCE]: source || NA,
                },
              );
            }

            if (this.isSimilarSlideSelected) {
              trackSlideEvents.slidesSimilarSlidesDownload(
                item,
                this.currentUser,
                otherData,
              );
            } else {
              trackSlideEvents.slideThumbNailClickDownload(
                { ...item, imageReplaced },
                this.currentUser,
                otherData,
              );
            }
          } else {
            trackSlideEvents.slides(item, this.currentUser, otherData);
          }
        } catch (e) {
          console.log(e);
        }
      }
      const downloadMetric = this.getDownloadMetric(
        item.prefs.source,
        item.type,
      );
      const userData = {
        performIncrements: {
          [downloadMetric]: 1,
        },
      };
      if (downloadMetric === 'sanitizedSlidesDownloaded') {
        userData.performIncrements.uploadSlidesDownloaded = 1;
      }
      if (imageReplaced) userData.performIncrements.downloadWithImgReplaced = 1;
      if (iconReplaced) userData.performIncrements.downloadWithIconReplaced = 1;
      usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(userInfo);
        })
        .catch((err) => console.log(err));
      // await PrezentAnalyticsAPI.incrementSlideDownloadCount(1);
    },

    getSlideBasedTemplateForDownload(code) {
      const template = this.themes.find((thm) => thm.code === code);
      if (template) {
        return template.name;
      }
      return code;
    },

    /*
      Function to return the slide object related theme code
      based on type of slide
    */
    getTemplateCodeFromSlideLoaded(data) {
      if (
        ['generated', 'uploaded', 'synthesis', 'redesign', 'comply'].includes(
          data?.prefs?.source,
        )
      ) {
        return data?.templateCode || '';
      }
      return data?.prefs?.theme || '';
    },

    async handleDownloadFromList(_, item, fromExpandedView) {
      const payload = {
        themeID: this.getTemplateCodeFromSlideLoaded(item),
        tailorID: this.currentAudience?.id
          ? this.currentAudience?.id
          : this.currentUser?.user?.id,
        audienceType: this.currentAudience?.audienceType
          ? this.currentAudience?.audienceType
          : '',
        assetID: item.unique_id,
      };
      updateCountForSlideView(payload)
        .then((res) => {
          const metaData = this.slideMetaData;
          Object.keys(metaData).every((i) => {
            if (i === item.unique_id) {
              metaData[i] = {
                ...metaData[i],
                viewCount:
                  metaData[i].viewCount && metaData[i].viewCount !== 'NaN'
                    ? Number(Number(metaData[i].viewCount) + 1).toString()
                    : '1',
              };
              return false;
            }
            return true;
          });
          this.slideMetaData = metaData;
          console.log(res);
        })
        .catch((er) => {
          console.log(er);
        });
      AnalyticsHandler.slideDownloadStarted(this.currentUser, item.asset);
      AnalyticsHandler.logUserStats(this.currentUser);

      const filePath = item.filename ? item.filename : item.asset.filename;

      let fileName = '';
      if (item.asset) {
        fileName = `
        ${
          item.asset.prefs &&
          item.asset.prefs.source &&
          (item.asset.prefs.source === 'uploaded' ||
            item.asset.prefs.source === 'generated' ||
            item.asset.prefs.source === 'redesign' ||
            item.asset.prefs.source === 'synthesis' ||
            item.asset.prefs.source === 'comply')
            ? item.asset.name
            : `${capitalizeFirstLetter(
                item.asset.title || item.asset.categories[0],
              )} - ${this.getSlideBasedTemplateForDownload(
                this.item.asset.prefs.theme,
              )}`
        }`;
      } else {
        fileName = `${
          item.prefs &&
          item.prefs.source &&
          (item.prefs.source === 'uploaded' ||
            item.prefs.source === 'generated' ||
            item.prefs.source === 'redesign' ||
            item.prefs.source === 'synthesis' ||
            item.prefs.source === 'comply')
            ? item.name
            : `${capitalizeFirstLetter(
                item.title || item.categories[0],
              )} - ${this.getSlideBasedTemplateForDownload(item.prefs.theme)}`
        }`;
      }

      // downloading slides with common download snackbar
      const slideId =
        item.prefs.source === 'uploaded' ||
        item.prefs.source === 'generated' ||
        item.prefs.source === 'synthesis' ||
        item.prefs.source === 'redesign' ||
        item.prefs.source === 'comply'
          ? item.id
          : item.unique_id;
      this.setShowDownloadSnackbar(true);
      this.setDownloadLoaders({
        loaderId: slideId,
        downloading: true,
      });
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'slideLibCard',
        logDownloadCallback: async () => {
          await this.logDownload(null, item);

          AnalyticsHandler.slideDownloadComplete(this.currentUser, item.asset);
          try {
            const otherData = {
              ...this.getCommonOtherDataForSlideDownload(item),
              [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
            };

            if (fromExpandedView) {
              trackSlideEvents.slideThumbNailClickDownload(
                item,
                this.currentUser,
                {
                  ...otherData,
                  [TD_AUD]: this.getAudienceDataToTrack()?.email,
                },
              );
              if (this.nodeCountChanged) {
                trackSlideEvents.slidesNodeCountChangeDownload(
                  item,
                  this.currentUser,
                  {
                    ...otherData,
                    [TD_AUD]: this.getAudienceDataToTrack()?.email,
                  },
                );
                if (this.isAddedToCollection(item)) {
                  trackSlideEvents.slidesNodeCountChangeAddToCollectionDownload(
                    item,
                    this.currentUser,
                    {
                      ...otherData,
                      [TD_ISDOWNLOAD]: 1,
                      [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
                      [TD_AUD]: this.getAudienceDataToTrack()?.email,
                      [TD_ISFAVORITED]: isSlideFavorite(
                        item,
                        this.getFavoriteSlides,
                      )
                        ? '1'
                        : NA,
                      [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
                        this.currentAudience,
                        this.currentUser,
                      ),
                    },
                  );
                }
                this.setNodeCountChange(false);
              }
            } else {
              trackSlideEvents.slides(item, this.currentUser, {
                ...otherData,
                [TD_AUD]: this.getAudienceDataToTrack()?.email,
              });
            }
          } catch (e) {
            console.log(e);
          }
        },
        downloadStatusCallback: (callbackPayload) => {
          this.setDownloadLoaders({
            loaderId: callbackPayload.slideId,
            downloading: false,
          });
        },
        metaData: {
          item: { ...item },
          filePath,
          fileName,
          limitsKey: 'slide',
          origin: 'slide',
          downloadStatusCallbackPayload: {
            slideId,
          },
        },
      });

      // const filePath = item.filename ? item.filename : item.asset.filename;
      // this.downloadingSlides.push({
      //   downloading: true,
      //   id: item.unique_id,
      // });
      // await this.logDownload(null, item);

      // let fileUrl = '';
      // let fileType = 'normal';
      // if (item.prefs.source === 'uploaded') {
      //   fileUrl = await getFileURL(
      //     this.currentUser.user.id,
      //     filePath,
      //     this.currentUser.userIp,
      //     'IOCUpload',
      //   );
      // } else if (
      //   item.prefs.source === 'generated' ||
      //   item.prefs.source === 'synthesis' ||
      //   item.prefs.source === 'redesign'
      // ) {
      //   if (
      //     filePath.startsWith('/newImageReplaceOutput/output/') ||
      //     filePath.startsWith('/output/pptx/')
      //   ) {
      //     fileUrl = await getFileURLDownload(
      //       this.currentUser.user.id,
      //       filePath,
      //       this.currentUser.userIp,
      //     );
      //   } else {
      //     fileUrl = await getFileURL(
      //       this.currentUser.user.id,
      //       filePath,
      //       this.currentUser.userIp,
      //       'IOCUpload',
      //     );
      //   }
      // } else {
      //   fileUrl = await getFileURL(
      //     this.currentUser.user.id,
      //     filePath,
      //     this.currentUser.userIp,
      //   );
      // }

      // let fileName = '';
      // if (item.asset) {
      //   fileName = `
      //   ${
      //     item.asset.prefs &&
      //     item.asset.prefs.source &&
      //     (item.asset.prefs.source === 'uploaded' ||
      //       item.asset.prefs.source === 'generated' ||
      //       item.asset.prefs.source === 'synthesis' ||
      //       item.asset.prefs.source === 'redesign' ||
      //       item.asset.prefs.source === 'comply')
      //       ? item.asset.name
      //       : `${item.asset.categories[0]}_${item.asset.prefs.theme}`
      //   }`;
      // } else {
      //   fileName = `${
      //     item.prefs &&
      //     item.prefs.source &&
      //     (item.prefs.source === 'uploaded' ||
      //       item.prefs.source === 'generated' ||
      //       item.prefs.source === 'synthesis' ||
      //       item.prefs.source === 'redesign' ||
      //       item.prefs.source === 'comply')
      //       ? item.name
      //       : `${item.categories[0]}_${item.prefs.theme}`
      //   }`;
      // }

      // downloadFileWithCustomName(fileUrl, fileName);
      // console.log(fileUrl, fileName);
      // const isRestricted =
      //   item?.prefs?.isRestricted ||
      //   item?.prefs?.permission_level === 0 ||
      //   item?.permission === 'Restricted';
      // let mergePath;
      // if (isRestricted) {
      //   let s = {
      //     outputFormat: 'pdf',
      //     outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
      //     slides: [
      //       {
      //         slideId:
      //           item.filename.charAt(0) === '/'
      //             ? item.filename.substring(1)
      //             : item.filename,
      //         isReplaced:
      //           item.filename.startsWith('/newImageReplaceOutput/output/') ||
      //           item.filename.startsWith('/output/pptx/'),
      //         isUploaded: item.prefs.source === 'uploaded',
      //         isGenerated: item.prefs.source === 'generated',
      //         isSynthesis: item.prefs.source === 'synthesis',
      //         isRedesign: item.prefs.source === 'redesign',
      //         isComply: item.prefs.source === 'comply',
      //       },
      //     ],
      //   };
      //   await callMergeSlidesApi(s)
      //     .then((res) => {
      //       const mergedslide = res.data;
      //       // if (mergedslide.includes('.pptx')) {
      //       mergePath = `${mergedslide}`;
      //       // }
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.downloadingSlide = false;
      //     });
      // }

      // await downloadFileWithAPI(
      //   fileType,
      //   isRestricted ? mergePath : filePath,
      //   `${fileName}${isRestricted ? '.pdf' : '.pptx'}`,
      //   item?.prefs?.source,
      // );
      // this.downloadingSlides = [...this.downloadingSlides].filter(
      //   (itm) => itm.id !== item.unique_id,
      // );
      // return true;
    },

    getAudienceDataToTrack() {
      try {
        return {
          email: this.currentAudience?.id || this.currentAudience?.user?.id,
        };
      } catch (e) {
        return {
          email: '',
        };
      }
    },

    async handleChangeFilter(loadedPayloads) {
      this.isNoMore = false;
      this.savedQueryPayload.skip = 0;
      this.setSlidesSearchProgress(true);
      this.resetAdvancedFilter();
      this.resetIdeaChipSelection();

      const isRecommended =
        this.filterBy === 'Recommended for selected audience';

      if (process.env.VUE_APP_USE_SOLR === 'true' || isRecommended) {
        const payloads = loadedPayloads || (await this.doSearch(false, false));
        this.slidesFilteredChipAdvanced = payloads.splice(0);
      } else {
        const { payloads } = await handleSearch({
          query_type: 'popular',
          theme_id: this.currentThemeCode,
        });
        this.popularSlides = payloads ? payloads.splice(0) : [];
        this.slidesFilteredChipAdvanced = this.popularSlides.slice(
          0,
          this.pageSize,
        );
        this.isNoMore = !(
          this.popularSlides?.length > this.slidesFilteredChipAdvanced?.length
        );
        const arr = this.slidesFilteredChipAdvanced.map(
          (item) => item.unique_id,
        );
        const chunkSize = 90;
        for (let i = 0; i < arr.length; i += chunkSize) {
          const chunk = arr.slice(i, i + chunkSize);
          const params = {
            assetIds: chunk,
            getDownload: true,
            getFavourite: true,
            getTrending: true,
            getViewCount: true,
          };
          this.getSlideDetails(params);
        }
        this.cacheKey = +new Date();
      }
      this.setSlidesSearchProgress(false);

      trackSlideEvents.slidesSortBy(this.currentUser, {
        [TD_SORTTYPE]: this.matomoFilterMap[this.filterBy],
      });
    },

    async handleChangeAdvancedFilter() {
      this.isNoMore = false;
      this.savedQueryPayload.skip = 0;
      this.skip = 0;
      switch (this.advancedFilterBy) {
        case 'My Favorites': {
          this.setSlidesSearchProgress(true);
          const { payloads, totalFound } = await handleSearch({
            query_type: 'favorites',
            theme_id: this.currentThemeCode, // TODO: API is not using this value. So we had to filter manually in client side.
            search: this.nonBrandSlideSearchQuery
              ? this.nonBrandSlideSearchQuery
              : '',
            limit: this.pageSize,
            skip: this.skip,
          });

          // const favSlides = payloads ? payloads.splice(0) : [];
          const favSlides = payloads || [];

          this.popularSlides = favSlides;
          this.slidesFilteredChipAdvanced = this.popularSlides.slice(
            0,
            this.pageSize,
          );
          this.setSlidesSearchProgress(false);
          this.isNoMore = !(totalFound > this.pageSize);
          break;
        }
        case 'My Downloads': {
          this.setSlidesSearchProgress(true);
          const { payloads, totalFound } = await handleSearch({
            query_type: 'downloaded',
            theme_id: this.currentThemeCode,
            search: this.nonBrandSlideSearchQuery
              ? this.nonBrandSlideSearchQuery
              : '',
            limit: this.pageSize,
            skip: this.skip,
          });
          // this.popularSlides = payloads ? payloads : [];

          const favSlides = payloads || [];
          this.popularSlides = favSlides;
          this.slidesFilteredChipAdvanced = this.popularSlides.slice(
            0,
            this.pageSize,
          );
          this.setSlidesSearchProgress(false);
          this.isNoMore = !(totalFound > this.pageSize);
          break;
        }
        default: {
          return;
        }
      }
      const arr = this.slidesFilteredChipAdvanced.map((item) => item.unique_id);
      const chunkSize = 90;
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        const params = {
          assetIds: chunk,
          getDownload: true,
          getFavourite: true,
          getTrending: true,
          getViewCount: true,
        };
        this.getSlideDetails(params);
      }
      // if (
      //   this.savedQueryPayload &&
      //   this.savedQueryPayload.category &&
      //   typeof this.savedQueryPayload.category === 'string'
      // ) {
      //   this.slidesFilteredChipAdvanced =
      //     this.slidesFilteredChipAdvanced.filter(
      //       (e) =>
      //         e &&
      //         e.categories &&
      //         e.categories.length > 0 &&
      //         e.categories[0].toLowerCase() ===
      //           this.savedQueryPayload.category.toLowerCase(),
      //     );
      // }
      this.cacheKey = +new Date();
      // this.$nextTick(() => {
      //   if (this.$refs.slideswrapper) {
      //     console.log
      //     this.$refs.slideswrapper.scrollTop = 0;
      //   }
      // });
    },

    onCheckboxAllClicked(e, title) {
      e.preventDefault();
      e.stopPropagation();
      if (title === 'Slide Type' || title === 'Author' || title === 'Access') {
        this.advancedItemsUpload.forEach((item) => {
          if (item.title === title && item.checkAll) {
            item.checkAll = true;
            item.items.forEach((opt) => {
              opt.active = true;
              opt.disabled = false;
            });
          }
          if (item.title === title && !item.checkAll) {
            item.checkAll = false;
            item.items.forEach((opt) => {
              opt.active = false;
              opt.disabled = false;
            });
          }
        });
      }
      const dataToTrack = {
        isAll: true,
        category: title && title.replace(/ /g, ''),
      };
      if (title === 'Graphics') {
        let items = [];
        if (this.advancedItems[2].checkAll === true) {
          dataToTrack.selected = true;
          items = this.advancedItems[2].items.map((item) => ({
            ...item,
            active: true,
          }));
          // trackSlideEvents.slidesAdvanceFiltersSelected(this.currentUser, {
          //   [TD_HASIMAGES]: 'All',
          //   [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
          //   [TD_COMMON_COLUMN_VALUE]: TD_GRAPHICS,
          // });
        } else {
          dataToTrack.selected = false;
          items = this.advancedItems[2].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.$set(this.advancedItems[2], 'items', items);
      } else if (title === 'Diagrams') {
        let items = [];
        if (this.advancedItems[3].checkAll === true) {
          dataToTrack.selected = true;
          items = this.advancedItems[3].items.map((item) => ({
            ...item,
            active: true,
          }));
        } else {
          dataToTrack.selected = false;
          items = this.advancedItems[3].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        this.$set(this.advancedItems[3], 'items', items);
      } else if (title === 'Node Count') {
        let items = [];
        if (this.advancedItems[1].checkAll === true) {
          dataToTrack.selected = true;
          items = this.advancedItems[1].items.map((item) => ({
            ...item,
            active: true,
          }));
        } else {
          dataToTrack.selected = false;
          items = this.advancedItems[1].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        const tempAdvancedItems = [...this.advancedItems];
        tempAdvancedItems[1].items = items;
        this.setSlidesAdvancedItems(tempAdvancedItems);
      } else if (title === 'Source') {
        let items = [];
        if (this.advancedItems[0].checkAll === true) {
          items = this.advancedItems[0].items.map((item) => ({
            ...item,
            active: true,
          }));
        } else {
          items = this.advancedItems[0].items.map((item) => ({
            ...item,
            active: false,
          }));
        }
        const tempAdvancedItems = [...this.advancedItems];
        tempAdvancedItems[0].items = items;
        this.setSlidesAdvancedItems(tempAdvancedItems);
      }
      MatomoAnalyticsHandler.advancedFilter(dataToTrack, this.currentUser);
      this.onAdvancedFilerChangeNew();
    },

    async getUsersCompanyInfo() {
      const companyInfo = await getCompanies();
      return companyInfo.data.getCompanies;
    },

    async fetchTrendingSlides() {
      try {
        const { data } = await getTrendingSlides({
          companyID: this.currentUser.companyID,
          themeID: this.currentThemeCode,
        });

        this.trendingSlides = data.map((item) => item.assetID);
      } catch (e) {
        this.trendingSlides = [];
      }
    },

    assignSnapshotToState() {
      const snap = { ...this.getSlidesSnapshot };
      this.addingToFavSlides = snap.addingToFavSlides;
      this.downloadingSlides = snap.downloadingSlides;
      this.setSlidesFilterOptionCategory(snap.filterOptionCategory);
      this.setSlidesFromDate(snap.fromDate);
      this.setSlidesToDate(snap.toDate);
      this.setSlidesFromDateMenu(snap.fromDateMenu);
      this.setSlidesToDateMenu(snap.toDateMenu);
      this.setSlidesToDateStr(snap.toDateStr);
      this.setSlidesFromDateStr(snap.fromDateStr);
      this.templateSearch = snap.templateSearch;
      // this.showDownload = snap.showDownload;
      this.panel = snap.panel;
      this.filterOptionsList = snap.filterOptionsList;
      this.templateSelected = snap.templateSelected;
      this.templatesList = snap.templatesList;
      this.tryNowLoading = snap.tryNowLoading;
      this.imageReplaceWalkthroughSlide = snap.imageReplaceWalkthroughSlide;
      this.isImageReplaceWalkThrough = snap.isImageReplaceWalkThrough;
      this.compKey = snap.compKey;
      this.currentRefs = snap.currentRefs;
      this.searchTerms = snap.searchTerms;
      // downloadedSlides: [],
      // imageReplacedSlides: [],
      // favoriteSlides: [],
      this.slideMetaData = snap.slideMetaData;
      this.internalResetFn = snap.internalResetFn;
      this.dirtySearch = snap.dirtySearch;
      this.activeSlide = snap.activeSlide;
      this.activeWrapper = snap.activeWrapper;
      this.choice = snap.choice;
      this.isNoMore = snap.isNoMore;
      this.paginationKey = snap.paginationKey;
      this.popularSlides = snap.popularSlides;
      this.advancedFilterBy = snap.advancedFilterBy;
      this.pageSize = snap.pageSize;
      this.currentSlidesPage = snap.currentSlidesPage;
      this.totalVisible = snap.totalVisible;
      this.listDownloads = snap.listDownloads;
      this.trendingSlides = snap.trendingSlides;
      this.showOnedrive = snap.showOnedrive;
      this.disableDownload = snap.disableDownload;
      this.audienceList = snap.audienceList;
      this.gdriveSrc = snap.gdriveSrc;
      this.gdriveName = snap.gdriveName;
      this.onedriveParam = snap.onedriveParam;
      this.onedriveFilename = snap.onedriveFilename;
      this.showDownloadList = snap.showDownloadList;
      this.setSlidesSearchProgress(snap.searchProgress);
      this.isLoading = snap.isLoading;
      this.cacheKey = snap.cacheKey;
      this.savedQueryPayload = snap.savedQueryPayload;
      this.currentAudience = snap.currentAudience;
      this.advancedFilterKey = snap.advancedFilterKey;
      this.offsetTop = snap.offsetTop;
      this.skip = snap.skip;
      this.username = snap.username;
      this.setSlidesFilterBy(snap.filterBy);
      this.isDefaultAudienceEventEmitted = true;
      if (snap.filterTailor.email === this.currentUser.user.id) {
        this.filterTailor = { ...snap.filterTailor, defaultAudience: 'SELF' };
      } else {
        this.filterTailor = snap.filterTailor;
      }
      this.filterByAdvanced = snap.filterByAdvanced;
      this.trendingKeywords = snap.trendingKeywords;
      this.userAssociatedCompanies = snap.userAssociatedCompanies;
      this.clickedKeyword = snap.clickedKeyword;
      this.keywords = snap.keywords;
      this.boosterPackKeywords = snap.boosterPackKeywords;
      this.rejectedKeywords = snap.rejectedKeywords;
      this.slides = snap.slides;
      this.slidesFilteredChip = snap.slidesFilteredChip;
      this.slidesFilteredChipAdvanced = snap.slidesFilteredChipAdvanced;
      this.searchAudience = snap.searchAudience;
      this.componentKey = snap.componentKey;
      this.types = snap.types;
      this.setSlidesFilterItems(snap.items);
      this.tailorItems = snap.tailorItems;
      this.advancedFilterOpened = snap.advancedFilterOpened;
      this.collectionOpened = snap.collectionOpened;
      this.dialogContentFinal = snap.dialogContentFinal;
      this.templateIDD = snap.templateIDD;
      this.templateName = snap.templateName;
      this.templateTitle = snap.templateTitle;
      this.dialogReset = snap.dialogReset;
      this.selectTemplateKey = snap.selectTemplateKey;
      this.selectedNodeIds = snap.selectedNodeIds;
      this.setSlidesAdvancedItems(snap.advancedItems);
      this.setSlidesAdvancedItemsOnLoad(snap.advancedItemsOnLoad);
      this.excludedCategories = snap.excludedCategories;
      this.ideaChipClicked = snap.ideaChipClicked;
      this.matomoFilterMap = snap.matomoFilterMap;
      this.isSimilarSlideSelected = snap.isSimilarSlideSelected;
      this.isImageReplaceWalkThroughFromHybridSearch =
        snap.isImageReplaceWalkThroughFromHybridSearch;
      this.generatedOrUploadSlidesArray = snap.generatedOrUploadSlidesArray;
      this.slidesListContainerWidth = snap.slidesListContainerWidth;
      this.advancedItemsUpload = snap.advancedItemsUpload;
      this.advancedItemsUploadOnLoad = snap.advancedItemsUploadOnLoad;
    },

    getContainersWidths() {
      if (window.innerWidth <= 700) {
        this.slidesListContainerWidth = 55;
      } else if (window.innerWidth > 700 && window.innerWidth <= 900) {
        this.slidesListContainerWidth = 60;
      } else if (window.innerWidth > 900 && window.innerWidth <= 1100) {
        this.slidesListContainerWidth = 65;
      } else if (window.innerWidth > 1100 && window.innerWidth <= 1200) {
        this.slidesListContainerWidth = 70;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        this.slidesListContainerWidth = 75;
      } else if (window.innerWidth > 1300 && window.innerWidth <= 1500) {
        this.slidesListContainerWidth = 80;
      } else if (window.innerWidth > 1500 && window.innerWidth <= 1700) {
        this.slidesListContainerWidth = 83;
      } else if (window.innerWidth > 1700 && window.innerWidth <= 1900) {
        this.slidesListContainerWidth = 83;
      } else {
        this.slidesListContainerWidth = 83;
      }
      this.getMainHeadingsWidth();
    },

    handleQuickSelection(action) {
      EventBus.$emit('MINIMIZE_LEFTNAV');
      if (action && (this.toolType !== action || !this.toolType)) {
        this.toolType = action;
        this.showQuickSelection = true;
      } else if (this.toolType === action || !action) {
        this.toolType = null;
        this.showQuickSelection = false;
      }
      if (action === 'template' && this.showQuickSelection)
        trackSlideEvents.slidesTemplateInfoClick(this.currentUser, {
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
        });
      if (action === 'collection' && this.showQuickSelection)
        trackSlideEvents.slidesCollectionClick(this.currentUser, {
          [TD_COMPANY_OF_AUD_TAILORED_FOR]: getAudienceCompanyName(
            this.currentAudience,
            this.currentUser,
          ),
          [TD_AUD]: this.getAudienceDataToTrack()?.email,
        });
    },

    handleScrollToOption(option) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (option === 'search') {
        const searchInput = document.getElementById('slide-library-search');
        if (window.scrollY === 0) {
          searchInput.focus();
        } else {
          setTimeout(() => {
            searchInput.focus();
          }, 1000);
        }
      } else if (this.toolType !== 'filter') {
        this.handleQuickSelection(option);
      }
    },

    handleLoadWhenNavFromLibOrFavSnackbar(filterType) {
      let type = '';
      if (filterType === 'fav_slides') {
        type = 'favorites';
      } else if (filterType.includes('generate')) {
        type = 'generated';
      } else if (filterType.includes('redesign')) {
        type = 'redesign';
      } else if (filterType.includes('comply')) {
        type = 'comply';
      } else if (filterType.includes('synthesis')) {
        type = 'synthesis';
      }

      this.setSlidesFilterOptionCategory(type);

      this.showQuickSelection = true;
      this.toolType = 'filter';
      this.handleFilterCategoryChangeFn();
    },

    handleLoadWhenNavFromDownloadSnackbar() {
      this.setSlidesFilterOptionCategory('downloads');

      this.showQuickSelection = true;
      this.toolType = 'filter';
      this.handleFilterCategoryChangeFn('snackbar');
    },
  },
  async mounted() {
    this.setLeftSideBarStatus(true);
    if (this.currentUser && !this.currentUser.user.isSlideVisited) {
      this.handleOpenAdvancedFilter(true);
    }
    if (this.$route.query && this.$route.query.origin) {
      this.firstSlide = true;
    }
    this.getRecentSlideSearches();
    this.resetAdvancedFilter();
    if (
      this.$route.query &&
      this.$route.query.filter &&
      this.$route.query.filter === 'graphs'
    ) {
      this.setSlidesAdvancedItems(
        JSON.parse(JSON.stringify(this.advancedItemsOnLoadGraphs)),
      );
    }
    await this.getHiddenIdeasSearch();

    // handling scenario where navigation is from download snackbar items
    if (this.getNavFromDownloadSnackbarSlides.navigated) {
      this.handleLoadWhenNavFromDownloadSnackbar();
      this.setNavFromDownloadSnackbarSlides({ navigated: false, type: '' });

      await this.makeApiCallsForSlidesAfterMount(true);
      this.setGoTopPosition();
      await this.getCollectionData();
      this.fetchTrendingSlides();

      this.saveSlidesStateSnapshot({});
    } else if (
      this.getNavFromAddToLibOrFavSnackbar.navigated &&
      ['addToLibSlides', 'addToFavSlides'].includes(
        this.getNavFromAddToLibOrFavSnackbar.type,
      )
    ) {
      this.handleLoadWhenNavFromLibOrFavSnackbar(
        this.getNavFromAddToLibOrFavSnackbar.origin,
      );
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: false,
        type: '',
        origin: '',
      });

      await this.makeApiCallsForSlidesAfterMount(true);
      this.setGoTopPosition();
      await this.getCollectionData();
      this.fetchTrendingSlides();

      this.saveSlidesStateSnapshot({});
    } else if (
      this.getSlidesSnapshot && // mount operations after metadata load
      Object.keys(this.getSlidesSnapshot).length <= 0
    ) {
      if (this.themes.length) {
        this.setFilteredThemes(this.themes);
      }
      // this.setDownloadStatus(false);
      await this.makeApiCallsForSlidesAfterMount();
      this.setGoTopPosition();
      await this.getCollectionData();
      this.fetchTrendingSlides();
    } else if (
      this.getSlidesSnapshot?.isAudienceChangedWhileEdit === true ||
      this.getSlidesSnapshot?.isTemplateChangedWhileEdit === true
    ) {
      // make new slide search with these changed values search with

      // beforeMount operations
      if (this.startLoadingSlidesFlag) {
        this.filterTailor = this.getSlidesSnapshot.filterTailor;
      }
      EventBus.$on('SET_SIMILAR_SLIDE_SELECTED', (isSelected) => {
        this.isSimilarSlideSelected = isSelected;
      });
      if (this.getSlidesSnapshot.templateSelected) {
        this.templateSelected = this.getSlidesSnapshot.templateSelected;
        this.templatesList = this.sortedThemeArray();
      }

      // mounted operations
      if (this.themes.length) {
        this.setFilteredThemes(this.themes);
      }
      // this.setDownloadStatus(false);
      await this.makeApiCallsForSlidesAfterMount();
      this.setGoTopPosition();
      await this.getCollectionData();
      this.fetchTrendingSlides();
      this.saveSlidesStateSnapshot({});
    } else {
      console.log('here');
      setTimeout(() => {
        window.scrollTo({
          top: this.getSlidesSnapshot.offsetTop,
          behaviour: 'smooth',
        });
        this.saveSlidesStateSnapshot({});
      }, 200);
      this.assignSnapshotToState();
    }
    EventBus.$on('MINIMIZE_ADVANCED_FILTER', this.hideAdvancedFilter);
    if (this.getSlideCollectionToolStatus) {
      this.openCollections();
      this.setSlideCollectionTool(false);
    }
    EventBus.$on('DO_SEARCH', async (payload) => {
      if (
        this.filterOptionCategory !== 'brand_slides' &&
        payload.isSlideLibrarySearch
      ) {
        this.nonBrandSlideSearchQuery = payload?.query ? payload.query : '';
        if (
          this.filterOptionCategory === 'favorites' ||
          this.filterOptionCategory === 'downloads'
        ) {
          await this.handleChangeAdvancedFilter();
        } else {
          await this.getGeneratedOrUploadSlideDetails();
        }
        const logPayload = {
          type: 'Slide Library Local Search',
          searchTerm: this.nonBrandSlideSearchQuery.toLowerCase(),
        };
        const searchTermResult = ['favorites', 'downloads'].includes(
          this.filterOptionCategory,
        )
          ? !!this.slidesFilteredChipAdvanced.length
          : !!this.generatedOrUploadSlidesArray.length;
        logPayload.searchTermResult = searchTermResult;
        logSearchQuery(logPayload).catch((err) => console.log(err));
        this.incrementSearchCount();
        this.isSlideLibrarySearch = false;
        this.nonBrandSlideSearchQuery = '';
        return;
      }
      this.setSlidesSearchProgress(true);
      this.resetAdvancedFilter();
      this.choice = this.getChoice(
        this.currentAudience.fingerPrint
          ? this.currentAudience.fingerPrint
          : 'director',
      );
      this.ideaChipClicked = this.trendingKeywords.some(
        (item) => item?.name.toLowerCase() === payload.query.toLowerCase(),
      )
        ? payload.query
        : 'All';
      this.savedQueryPayload = payload;
      this.isSlideLibrarySearch = payload?.isSlideLibrarySearch || false;
      this.slideLibrarySearchType = payload?.type ? payload?.type : '';
      if (this.isSlideLibrarySearch) {
        this.trackSlideSearchEvents();
        EventBus.$emit('CLEAR_SEARCH');
      }
      handleDebounce(() => this.doSearch(false, true, true));
      AnalyticsHandler.selectedSlideFromAutoComplete(
        this.currentUser,
        payload.query ? payload.query : payload.category,
      );
      this.componentKey++;
    });
    this.getContainersWidths();
    window.addEventListener('resize', this.getContainersWidths);

    EventBus.$on(
      'SLIDES_FILTER_CATEGORY_CHANGE',
      this.handleFilterCategoryChangeFn,
    );
    EventBus.$on('SLIDES_CHECK_ALL_FILTER', this.onCheckboxAllClicked);
    EventBus.$on('SLIDES_ADVANCE_FILTER_CHANGE', this.onAdvancedFilerChangeNew);
    EventBus.$on(
      'SLIDES_IS_ADVANCE_FILTER_CHANGE',
      this.isAdvancedFilterChanged,
    );
    EventBus.$on('RESET_SLIDES_ADVANCED_FILTERS', this.resetAdvancedFilter);
    EventBus.$on('CHANGE_SLIDES_SORT_FILTER', this.handleChangeFilter);
  },
  beforeRouteLeave(to, from, next) {
    if (to?.path.includes('slides')) {
      next();
    } else {
      this.setSlidesAudienceSelected(null);
      next();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setGoTopPosition);
    EventBus.$off('DO_SEARCH');
    EventBus.$off('MINIMIZE_ADVANCED_FILTER', this.hideAdvancedFilter);
    EventBus.$off('SET_SIMILAR_SLIDE_SELECTED');
    EventBus.$off(
      'SLIDES_FILTER_CATEGORY_CHANGE',
      this.handleFilterCategoryChangeFn,
    );
    EventBus.$off('SLIDES_CHECK_ALL_FILTER', this.onCheckboxAllClicked);
    EventBus.$off(
      'SLIDES_ADVANCE_FILTER_CHANGE',
      this.onAdvancedFilerChangeNew,
    );
    EventBus.$off(
      'SLIDES_IS_ADVANCE_FILTER_CHANGE',
      this.isAdvancedFilterChanged,
    );
    EventBus.$off('RESET_SLIDES_ADVANCED_FILTERS', this.resetAdvancedFilter);
    EventBus.$off('CHANGE_SLIDES_SORT_FILTER', this.handleChangeFilter);
    EventBus.$off('GOTO_SLIDES_COLLECTION');
  },
  destroyed() {
    this.setSlideLocalSearch('');
    window.removeEventListener('resize', this.getContainersWidths);
  },
};
</script>

<style>
.v-main {
  overflow: visible !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.slides-wrapper {
  display: flex;
}
.quick-panel-active {
  width: calc(100% - 550px) !important;
}
.quick-panel {
  width: 550px;
}
.v-chip.v-chip--outlined.v-chip--active:before {
  opacity: 0 !important;
}
.slides-cntr {
  width: 100%;
  padding: 20px 30px;
  position: relative;

  .slide-type-wrapper {
    display: flex;
    // padding: 20px 0 20px 16px;
    width: 100%;

    ::v-deep .v-slide-group__prev {
      justify-content: left !important;
      align-items: left !important;
      min-width: 40px !important;
    }
    ::v-deep .v-slide-group__next {
      justify-content: right !important;
      align-items: right !important;
      min-width: 40px !important;
    }

    .slide-type-wrapper-title {
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      margin-right: 8px;
    }
    ::v-deep .v-chip--active {
      background-color: white !important;
      color: #21a7e0 !important;
    }
    .ideaChipActive {
      border-radius: 40px;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      margin-right: 18px !important;
      padding: 0 25px !important;
      background: #21a7e0 !important;
      color: white !important;
    }
    .ideaChipAvailable {
      border-radius: 40px;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      margin-right: 18px !important;
      padding: 0 25px !important;
      background: white !important;
      color: #21a7e0 !important;
      border-color: #21a7e0 !important;
    }
  }
  .main-wrapper {
    display: block;
    position: relative;
    width: 100%;

    .inner-wrapper {
      display: flex;
      // padding-top: 16px;
      .resultsContainer {
        .spinning-loading-wrapper {
          margin: 160px 0px 0px 0px !important;
          display: block !important;
          position: relative !important;
        }
        .listOrNotFound {
          width: 100%;
          // padding: 0px 22px 0px 30px;
        }
      }
    }

    .advanced-filter-wrapper {
      box-shadow: -10px 0px 16px -6px rgba(0, 0, 0, 0.15);
      height: calc(100vh - 70px);
      // max-height: calc(100vh - 35px);
      position: relative;
      // right: -27px;
      // min-width: 280px;
      z-index: 4;
      margin-left: auto;

      // sticky filter on scroll
      position: sticky;
      top: 70px;
      bottom: 0px;

      ::v-deep .v-list-group__header .v-list-item__content {
        flex-wrap: nowrap;
      }
      ::v-deep .v-list-item {
        margin-bottom: 0px !important;
      }

      ::v-deep .v-list-group__header {
        justify-content: space-between;
      }
      ::v-deep .v-list {
        // height: calc(100vh - 127px);
        padding: 0 16px;
        overflow: auto;
      }
      ::v-deep .v-list-item img {
        filter: none;
      }
      ::v-deep .v-list-group__items > .v-list-item {
        padding-left: 50px;
      }

      .customGroupWrapper {
        padding: 15px;
        .fromDateWrapper,
        .toDateWrapper {
          .label {
            font-size: 16px;
            font-weight: 600;
            color: black;
          }
          .datePicker {
            .v-input {
              .v-input__control {
                .v-input__slot {
                  .v-input__append-inner {
                    .clearIcon,
                    .calendarIcon {
                      &:hover {
                        color: #21a7e0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .applyDatesCTA {
          width: 70%;
          margin: 0 auto;
          .v-btn {
            background: transparent;
            border: none;
            box-shadow: none;
            letter-spacing: normal;
            text-transform: none;
            font-size: 16px;
            color: #21a7e0;
            width: 100%;
            background-color: transparent !important;
          }
        }
      }
      .filter-header {
        padding: 5px 10px 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          display: inline-block;
          font-size: 17px;
        }
      }
      .resetFilters {
        display: flex;
        padding: 14px 15px 0px 15px;
        justify-content: space-between;
        .v-icon,
        .resetText {
          color: #21a7e0;
          margin: 0px 0px 0px 4px;
          cursor: pointer;
        }
        .resetDisabled {
          color: grey;
          margin: 0px 0px 0px 4px;
        }
      }
      .advanced-filter-options {
        height: calc(100% - 100px);
        overflow: auto;
        padding: 0px 15px 15px 18px;
        ::v-deep .v-input {
          .v-input__control {
            .v-input__slot {
              .v-input--radio-group__input {
                .v-radio {
                  padding: 8px 0;
                }
                .v-list {
                  .v-list-group {
                    .v-list-group__items {
                      .v-list-item {
                        max-height: 40px !important;
                        min-height: 40px !important;
                        .v-list-item__action {
                          margin: 0px 10px 0px 0px !important;
                        }
                        img {
                          margin: 10px 16px 0px 6px !important;
                          transform: none !important;
                        }
                        .v-list-item__content {
                          padding: 0 !important;
                          .v-list-item__title {
                            font-size: 16px !important;
                            padding: 12px 0px 0px 0px !important;
                            line-height: normal !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ::v-deep .v-label {
          color: black;
          font-size: 17px;
          margin-left: 10px;
        }
        ::v-deep .v-input--selection-controls {
          margin-top: 12px !important;
        }
        ::v-deep .v-input--selection-controls__input {
          margin-right: 0 !important;
        }
      }
    }
    .collection-wrapper {
      box-shadow: -10px 0px 16px -6px rgba(0, 0, 0, 0.15);
      height: 90vh;
      background-color: white;
      // max-height: calc(100vh - 35px);
      overflow: auto;
      position: relative;
      right: -27px;
      width: 615px;
      z-index: 4;
      margin-left: auto;

      // sticky filter on scroll
      position: sticky;
      top: 70px;
      bottom: 0px;

      ::v-deep .v-list-group__header .v-list-item__content {
        flex-wrap: nowrap;
      }
      ::v-deep .v-list-item {
        margin-bottom: 0px !important;
      }

      ::v-deep .v-list-group__header {
        justify-content: space-between;
      }
      ::v-deep .v-list {
        height: calc(100vh - 127px);
        overflow: auto;
      }
      ::v-deep .v-list-item img {
        filter: none;
      }
      ::v-deep .v-list-group__items > .v-list-item {
        padding-left: 50px;
      }

      .filter-header {
        padding: 5px 10px 5px 20px;
        display: flex;
        border-bottom: 1px solid #bdbdbd;
        justify-content: space-between;
        align-items: center;

        h3 {
          display: inline-block;
          font-size: 17px;
        }
        .collection-heading {
          width: 70%;
          margin-top: 10px;
          .collection-sub {
            font-size: 13px;
          }
        }
      }
      ::v-deep .v-expansion-panel-header {
        margin-right: -5px;
        padding-top: 0;
        padding-bottom: 0;
      }
      ::v-deep .v-expansion-panel-header__icon {
        margin: 0px -10px 0px 13px;
      }
      ::v-deep .v-expansion-panel-content__wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px 10px;
      }
      .slide-image {
        box-shadow: $item-box-shadow;
      }
      .slide-image-container {
        position: relative;
      }
      .limit-reaced__message {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        font-size: 10px;
        p {
          margin: 0;
        }
      }
      .additional-options {
        position: absolute;
        top: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: none;
        z-index: 100;
      }
      .image-replaced__collection {
        width: 100%;
        text-align: center;
      }
      .slide-image-container:hover .additional-options {
        display: flex;
      }
      .slide-image:hover {
        box-shadow: $item-box-shadow-hover;
      }
      .empty-collection {
        text-align: center;
        margin: 15px;
        background-color: white;
        .empty-collection__text {
          margin-top: 15px;
        }
        p {
          margin: 0;
          font-size: 12.5px;
        }
      }
      .set-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: -5px;
        color: #000000;
      }
    }

    .advanced-filter-icon {
      margin-left: 10px;
      margin-right: 10px;
      transform: translateX(-28px);
    }
    .advanced-filter-title {
      color: black;
      font-size: 17px;
      margin-left: 10px;
    }
    // .advanced-filter-content-text {
    //   font-size: 16px;
    //   overflow: visible;
    //   position: absolute;
    //   transform: translateX(-22px);
    // }
  }
  .vuepagination-container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 80vw;
  }
  .vuepagination {
    align-items: center;
    display: flex;
    font-size: 18px;
    justify-content: center;
    text-align: center;
    .pagination-content {
      align-items: center;
      display: flex;
      text-align: center;
      width: 150px;
    }
    .pagination-input {
      margin-right: 6px;
      margin-top: 24px;
      width: 45px;
    }
    .icon {
      cursor: pointer;
    }
  }
  .not-found-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .not-found-container {
    position: absolute !important;
    top: 6vh !important;
    width: 600px;
  }
  .not-found-descriptions {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: auto;
    text-align: center;
    width: fit-content;

    .final-not-found-description {
      margin-bottom: 24px;
    }

    button {
      color: white;
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      text-transform: none;
    }
  }

  .slides-wrapper {
    display: block;
    // height: calc(100vh - 230px);
    overflow: auto;
    padding-bottom: 4rem;
    padding-right: 0px !important;
    width: 100%;
    &::-webkit-scrollbar {
      -webkit-appearance: scrollbartrack-vertical;
    }
    /* .slide-wrapper:nth-child(odd) {
      .slider-content-wrapper {
        margin-left: 12.5px;
        margin-right: 12.5px;
      }
    }
    .slide-wrapper:nth-child(even) {
      .slider-content-wrapper {
        margin-left: 12.5px;
        margin-right: 12.5px;
      }
    } */

    .row {
      margin-right: -30px !important;
    }
  }

  .filters-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 24px 0px 24px 0px;
    padding: 0px 0px 0px 8px;
    width: 100%;

    ::v-deep .v-input__slot {
      box-shadow: 0 0px 0 0 rgba(0, 0, 0, 0.5), 0 1px 0px 0 #68788c,
        0 1px 2px 0 rgba(0, 0, 0, 0.5);
    }

    .slides-filter__sort {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-left: 20px;

      .label {
        margin: auto 0.5rem auto 0;
      }

      ::v-deep .v-select__selections .v-select__selection {
        font-size: 14px;
      }
    }

    ::v-deep label {
      font-weight: bold;
    }

    ::v-deep > .v-input {
      flex: none;
      width: 100%;
    }

    .filter-by {
      flex: none;
      font-size: 14px;

      ::v-deep .v-text-field__details {
        display: none;
      }

      ::v-deep .v-input__slot {
        border-bottom: 0.8px solid #b6b6b6;
        box-shadow: none;
        font-family: 'Lato';
      }
    }
    .collection-icon {
      margin: 12.5px 0px 0px 0px;
    }
    .advanced-filter-open {
      .show {
        display: block;
      }

      .hide {
        display: none;
      }
    }
    .template-selector {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-left: 20px;

      .label {
        margin: auto 0.5rem auto 0;
      }
      ::v-deep .v-list-item {
        display: unset !important;
        padding: 0 !important;
      }
      ::v-deep .v-list-item--link:before {
        background-color: unset !important;
      }
    }
    .templates-dropdown,
    .templates-dropdown-expanded {
      flex: none;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 19px;
      // width: 350px;
      ::v-deep .v-text-field__details {
        display: none;
      }

      ::v-deep .v-input__slot {
        border-bottom: 0.8px solid #b6b6b6;
        box-shadow: none;
        font-family: 'Lato';
      }
    }
    .right-filters {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;

      .quick-action-cta {
        display: flex;
        gap: 0.75rem;
      }

      .filter-btn {
        color: #21a7e0;
        border-radius: 10px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.01em;
        text-align: center;
        height: 100%;
        min-width: 100px;
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;
        align-items: center;
        .aud-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
        .template-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }

      .filter-btn-active {
        background-color: #e1f5fb;
      }

      .filter-btn-disabled {
        color: rgb(117, 117, 117) !important;
        pointer-events: none;
        caret-color: rgb(117, 117, 117);

        img {
          filter: grayscale(1);
        }
      }

      .uploadCTA {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .v-btn {
          background-color: #21a7e0;
          color: #ffffff;
          text-transform: none;
          letter-spacing: normal;
          border-radius: 20px;
          box-shadow: none;
          font-weight: 600;
        }
      }
    }
  }

  .left-filters {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .go-top {
    position: fixed;
    bottom: 6px;
    left: 48%;
    background-color: #21a7e0;
    padding: 3px 10px;
    cursor: pointer;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: #fff !important;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: 0 -1px 5px 1px rgb(64 60 67 / 16%);
    // animation: fadein 2s linear 1 forwards;
    // opacity: 0;

    // &.show {
    //   animation: fadein 2s linear 1 forwards;
    //   opacity: 0;
    // }

    // &.hide {
    //   animation: fadein 2s linear 1 forwards;
    //   opacity: 0;
    // }

    .top-arrow {
      margin-right: 5px;
      margin-bottom: 3px;
      font-size: 14px;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
.template-list-item {
  padding: 0 16px;
}
.template-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
  margin-top: 10px;
  .template-title {
    color: #212121;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .current-selection {
    color: #7b7b7b;
    width: 46%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.available-theme-img,
.example-slide-img {
  img {
    box-shadow: 0.413462px 0.413462px 1.65385px rgba(0, 0, 0, 0.1),
      -0.413462px -0.413462px 1.65385px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
}
.no-templates-fount {
  color: #000000;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  .no-result-text {
    border-bottom: 0.8px solid #b6b6b6;
    padding: 20px 0px 20px 20px;
  }
  .add-more-templates-btn {
    cursor: pointer;
    align-items: flex-end;
    display: flex;
    padding: 20px 0px 20px 20px;
    p {
      margin-left: 10px;
      margin-bottom: 2px !important;
    }
  }
}
.tip-bar {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  color: #7b7b7b;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 54px;
  line-height: 17px;
  letter-spacing: 0.01em;
  p {
    height: 34px;
    padding: 15px;
  }
}
.advanced-filter-open {
  .advancedActive,
  .advancedInActive {
    cursor: pointer;
    margin: 12px 0px 0px 0px;
  }
  .advancedActive {
    color: #21a7e0 !important;
  }
}
.download-btn {
  color: black;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: normal;
  margin-left: -15px;
  text-transform: none;

  .v-btn::before {
    background-color: transparent !important;
    text-align: left;
  }
  .v-btn__content {
    justify-content: flex-start !important;
  }
  .add-icon {
    color: #21a7e0;
    position: relative;
    transform: translateY(-2px);
  }
  .v-icon.v-icon {
    font-size: 2vw;
  }
  .v-list .v-list-item--active .v-icon {
    color: black;
  }

  &:hover::before {
    opacity: 0 !important;
  }
}

// .slide-collection-download-container {
//   position: fixed;
//   bottom: 6px;
//   left: 5%;
//   z-index: 1001;
//   width: 400px;
//   background-color: white;
//   border-radius: 5px 5px 0 0;
//   overflow: hidden;

//   .slide-collection-download-header {
//     padding: 10px;
//     color: white;
//     background-color: #21a7e0;
//     display: flex;
//     font-weight: bold;
//     justify-content: space-between;
//     align-items: center;
//   }
// }

.v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  color: black;
}

.audience-selector {
  ::v-deep .v-text-field input {
    padding: 8px 0 2px !important;
  }
  ::v-deep .v-input__slot {
    margin-bottom: 6px !important;
  }
}
</style>
